import {
    Table
} from "reactstrap";

import { Dropdown } from "primereact/dropdown";
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';

const ShotDetails = ({
    shotDst,
    setShotDst,
    club,
    setClub,
    mental,
    setMental,
    parHole,
    setParHole,
    shotHole,
    holeNo,
    currentLie,
    setCurrentLie,
    layup,
    setLayup,
    penalty,
    handlePenalty,
    missDir,
    setMissDir,
    missDst,
    setMissDst,
    difficulty,
    setDifficulty,
    forcedLayupDistance,
    setForcedLayupDistance,
    pinDst,
    setPinDst,
    pinDir,
    setPinDir,
    units,
    gfOpp,
    setGfOpp,
    addNextHole
}) => {

    const clubs = ["driver", "3w", "5w", "3h", "4h", "2i", "3i", "4i", "5i", "6i",
        "7i", "8i", "9i", "PW", "GW", "SW", "LW",];
    const mentals = ["good", "technique", "mental state", "decision"];
    const lies = ["fairway", "recovery", "rough", "fringe", "bunker", "green", "tee"];
    const missDirArr = ["left", "right", "no miss"]
    const missDstArr = ["long", "short", "no miss"]
    const missPuttDirArr = ["high", "in the hole", "low", "no miss"];
    const missPuttDstArr = ["short", "no miss"];
    const pinDstArr = ["back", "middle", "front"];

    const pinDirArr = ["left", "center", "right"];

    const difficultyArr = ["easy", "medium", "hard"];
    const baseline = window.localStorage.getItem("baseline") ?? "PGA";

    return (
        <Table striped>
            <thead>
                <tr>
                    <th>

                    </th>
                    <th>

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">
                        Hole
                    </th>
                    <td>
                        {holeNo}
                    </td>
                </tr>
                <tr>
                    <th scope="row">
                        Par
                    </th>
                    <td>
                        {addNextHole ?
                            <Dropdown value={parHole} options={[3, 4, 5]} onChange={(e) => setParHole(e.value)} />
                            :
                            parHole
                        }
                    </td>
                </tr>
                <tr>
                    <th scope="row">
                        Shot
                    </th>
                    <td>
                        {shotHole}
                    </td>
                </tr>
                <tr>
                    <th scope="row">
                        Distance
                    </th>
                    <td>
                        <InputNumber value={shotDst} onChange={(event) => setShotDst(event.value)}
                            suffix={currentLie.toLowerCase() === "green" ? " ft" : units === "meters" ? " m" : " yds"}
                            max={800} />
                    </td>
                </tr>
                {currentLie === "tee" &&
                    <tr>
                        <th scope="row">
                            Layup Distance
                        </th>
                        <td>
                            <InputNumber value={forcedLayupDistance} onChange={(e) => setForcedLayupDistance(e.value)}
                                suffix={units === "meters" ? " m" : " yds"} max={baseline === "LPGA" ? (units === "meters" ? 235 : 257) : (units === "meters" ? 272 : 197)} />
                        </td>
                    </tr>
                }
                <tr>
                    <th scope="row">
                        Lie
                    </th>
                    <td>
                        <Dropdown options={lies} value={currentLie} onChange={(e) => { setCurrentLie(e.value) }} />
                    </td>
                </tr>
                {currentLie.toLowerCase() !== "green" && currentLie !== "recovery" && shotDst > 30 && < tr >
                    <th scope="row">
                        Club
                    </th>
                    <td>
                        <Dropdown options={clubs} value={club} onChange={(e) => { setClub(e.value) }} />
                    </td>
                </tr>}
                {currentLie !== "recovery" && (currentLie === "green" || (shotDst > 30 && currentLie != "green")) && <tr>
                    <th scope="row">
                        Miss direction
                    </th>
                    <td>
                        <Dropdown options={currentLie.toLowerCase() === "green" ? missPuttDirArr : missDirArr}
                            value={missDir}
                            onChange={(e) => { setMissDir(e.value) }} />
                    </td>
                </tr>}
                {currentLie !== "recovery" && (currentLie === "green" || (shotDst > 30 && currentLie != "green")) && currentLie !== "tee" && < tr >
                    <th scope="row">
                        Miss distance
                    </th>
                    <td>
                        <Dropdown options={currentLie.toLowerCase() === "green" ? missPuttDstArr : missDstArr}
                            value={missDst}
                            onChange={(e) => { setMissDst(e.value) }} />
                    </td>
                </tr>}
                {difficulty !== null && (currentLie === "recovery" || (currentLie !== "green" && shotDst <= 30)) && <tr>
                    <th scope="row">
                        Difficulty
                    </th>
                    <td>
                        <Dropdown options={difficultyArr}
                            value={difficulty}
                            onChange={(e) => { setDifficulty(e.value) }} />
                    </td>
                </tr>}
                {/* {pinDst !== null && currentLie !== "tee" && currentLie !== "green" && <tr>
                    <th scope="row">
                        Pin Distance Location
                    </th>
                    <td>
                        <Dropdown options={pinDstArr}
                            value={pinDst}
                            onChange={(e) => { setPinDst(e.value) }} />
                    </td>
                </tr>}
                {pinDir !== null && currentLie !== "tee" && currentLie !== "green" && <tr>
                    <th scope="row">
                        Pin Direction Location
                    </th>
                    <td>
                        <Dropdown options={pinDirArr}
                            value={pinDir}
                            onChange={(e) => { setPinDir(e.value) }} />
                    </td>
                </tr>} */}
                {((currentLie === "green" && shotDst > 2) || (currentLie !== "green")) && <tr>
                    <th scope="row">
                        Mistake
                    </th>
                    <td>
                        <Dropdown options={mentals} value={mental} onChange={(e) => { setMental(e.value) }} />
                    </td>
                </tr>}
                {((parHole === 5 && shotHole === 2) || (parHole === 4 && shotHole === 1)) && < tr >
                    <th scope="row">
                        Going for it Opportunity?
                    </th>
                    <td>
                        <Checkbox onChange={(e) => { setGfOpp(e.checked) }} checked={gfOpp} />
                    </td>
                </tr>}
                {currentLie !== "green" && currentLie !== "recovery" && < tr >
                    <th scope="row">
                        Layup?
                    </th>
                    <td>
                        <Checkbox onChange={(e) => { setLayup(e.checked) }} checked={layup} />
                    </td>
                </tr>}
                <tr>
                    <th scope="row">
                        Penalty?
                    </th>
                    <td>
                        <Checkbox inputId="penalty" onChange={(e) => { handlePenalty(e) }} checked={penalty} />
                    </td>
                </tr>
            </tbody>
        </Table >
    )
}

export { ShotDetails };