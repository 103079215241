import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../appwrite-testing/AuthContext";
import { Button } from "reactstrap";

const LogoutButton = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <Button 
      className="ms-2 btn-gold" 
      onClick={handleLogout}
    >
      Log Out
    </Button>
  );
};

export { LogoutButton };