import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

const ClubsDropdown = ({ bagArr, currentShot, setCurrentShot }) => {


    function findIndex() {
        return bagArr.findIndex(club => club === currentShot.club);
    }

    function moreClub() {
        const newIndex = (findIndex() - 1 + bagArr.length) % bagArr.length;
        setCurrentShot((prevData) => ({
            ...prevData,
            club: bagArr[newIndex]
        }))
    }

    function lessClub() {
        const newIndex = (findIndex() + 1) % bagArr.length;
        setCurrentShot((prevData) => ({
            ...prevData,
            club: bagArr[newIndex]
        }))
    }


    return (
        <div className="p-fluid">
            <label>Club</label>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button size="small" icon="pi pi-chevron-circle-up" style={{
                    backgroundColor: "green",
                    width: ' 117.390625px', borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                }}
                    onClick={() => moreClub()} />
                <Dropdown options={bagArr} value={currentShot?.club} onChange={(e) => setCurrentShot((prevData) => ({
                    ...prevData,
                    club: e.value
                }))} style={{ width: '117.390625px', borderRadius: "0px" }} />
                <Button size="small" icon="pi pi-chevron-circle-down"
                    style={{ backgroundColor: "red", width: '117.390625px', borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
                    onClick={() => lessClub()} />
            </div>
        </div>
    )
}
export default ClubsDropdown;