import { Button } from "primereact/button";
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method

const ListMembers = ({ category, list, setIsInvite, setCategory, teams, team, toast, onMemberDeleted, isOwner }) => {

    const confirm = (p) => {
        confirmDialog({
            message: `Do you want to remove ${p.userName} from ${team.name}?`,
            header: `Remove ${p.userName}`,
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => deleteMember(p), // Calls deleteMember when user accepts
        });
    };
    function inviteMember() {
        setIsInvite(true);
        setCategory(category);
    }

    const deleteMember = async (p) => {
        try {
            await teams.deleteMembership(team.$id, p.$id).then(result => {
                onMemberDeleted();
                toast.current.show({
                    severity: 'success',
                    summary: 'Deleted',
                    detail: `You have removed ${p.userName}`,
                    life: 3000,
                })
            })

        } catch (error) {
            console.error("Failed to delete member:", error).then(result => toast.current.show({
                severity: 'danger',
                summary: 'Error',
                detail: `Failed to remove ${p.userName}`,
                life: 3000,
            }));

        }
    };


    return (
        <div className="m-4">

            <h3>{category === "Player" ? "Players" : "Coaches"}: </h3>
            {list.map((p) => (
                <div
                    className="card m-2 d-flex justify-content-between align-items-center"
                    style={{ maxWidth: "400px", border: "1px solid #ccc", borderRadius: "5px", padding: "10px" }}
                >
                    <div className="m-3 d-flex" style={{ width: "100%" }}>
                        {/* Left Column: Name and Email */}
                        <div style={{ flex: 1 }}>
                            <div>
                                <label style={{ fontWeight: "bold" }}>Name:</label>
                                <div>{p.userName}</div>
                            </div>
                            <div className="mt-2">
                                <label style={{ fontWeight: "bold" }}>Email:</label>
                                <div>{p.userEmail}</div>
                            </div>
                        </div>
                        {/* Right Column: Delete Button */}
                        {isOwner && !p.roles.includes('owner') && <div className="d-flex align-items-center" style={{ marginLeft: "10px" }}>
                            <Button label="Delete" severity="danger" onClick={(e) => confirm(p)} />
                        </div>}
                    </div>
                </div>

            ))}
            {isOwner && <Button label={`Invite a ${category}`} onClick={() => inviteMember()} className="m-4" />}
        </div>
    )
}
export default ListMembers;