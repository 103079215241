import React, { Component, } from 'react';
import { Collapse, Navbar, NavbarToggler, NavItem, NavLink, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { LogNavButton } from './auth0/LogNavButton';

import { ShowCoachTools } from './auth0/ShowCoachTools';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };

  }



  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {

    return (
      <header>
        <Navbar className="navbar-expand-lg navbar-toggleable-lg ng-white border-bottom box-shadow mb-3" container dark>
          <a href="/" className="hidden-xs hidden-sm hidden-md hidden-lg me-3">
            <img alt="logo" src={process.env.PUBLIC_URL + 'logoNav.png'} style={{ height: 60 }} tag={Link} to="/" />
          </a>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink onClick={() => this.setState({ collapsed: true })} tag={Link} className="text-light" to="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => this.setState({ collapsed: true })} tag={Link} className="text-light" to="/practice">Practice</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => this.setState({ collapsed: true })} tag={Link} className="text-light" to="/courses">Courses</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => this.setState({ collapsed: true })} tag={Link} className="text-light" to="/my-rounds">My Rounds</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => this.setState({ collapsed: true })} tag={Link} className="text-light" to="/round">Add round</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => this.setState({ collapsed: true })} tag={Link} className="text-light" to="/stats">My Stats</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => this.setState({ collapsed: true })} tag={Link} className="text-light" to="/user-profile">My Account</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => this.setState({ collapsed: true })} tag={Link} className="text-light" to="/sg-calculator">Calculator</NavLink>
              </NavItem>
              <ShowCoachTools />
              <LogNavButton> </LogNavButton>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
