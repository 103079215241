export function fillAnalysis(data, units, setState, setScoreAverage, setTotalImprovement, setNewScoreAverage,
    selectedFacilities, numberImprovement, numberStrength, isAdvanced) {
    // Define the variables and their values
    console.log(isAdvanced)
    var variables = isAdvanced ? getVariablesAdvanced(data, units) : getVariables(data, units);

    // Sort the variables based on their values in ascending order
    variables.sort(function (a, b) {
        return a.value - b.value;
    });
    // Get the first five variables with the lowest values
    let result = selectedFacilities.length > 0 ? variables.filter(e => selectedFacilities.includes(e.facility)) : variables;

    // Get the first five variables with the lowest values
    var numberOfBad = numberImprovement < 5 ? 5 : numberImprovement
    var lowestVariables = result.slice(0, numberOfBad);
    for (let i = 0; i < numberOfBad; i++) {
        setState((prev) => ({
            ...prev,
            [`analysis${i + 1}Name`]: lowestVariables[i].name,
            [`analysis${i + 1}Total`]: parseFloat(lowestVariables[i].value).toFixed(2),
            [`analysis${i + 1}Attempts`]: lowestVariables[i].attempts,
            [`analysis${i + 1}PerShot`]: parseFloat(lowestVariables[i].perShot).toFixed(3),
            [`analysis${i + 1}Improvement`]: parseFloat(lowestVariables[i].improvement),
        }));
    }


    setScoreAverage(data.scoreAvg);
    let sum = 0.0;
    for (var i = 0; i < 5; i++) {
        if (lowestVariables[i].name !== "ARG Sand" && lowestVariables[i].name !== 'ARG Fringe') {
            sum += lowestVariables[i].improvement;
        }
    }
    setTotalImprovement((parseFloat(sum).toFixed(2)));
    setNewScoreAverage((parseFloat(data.scoreAvg + sum).toFixed(2)))

    var numberOfGood = numberStrength < 5 ? 5 : numberStrength
    // Get the last five variables with the lowest values
    var highestVariables = result.slice(result.length - numberOfGood, result.length);
    for (let i = 0; i < numberOfGood; i++) {
        setState((prev) => ({
            ...prev,
            [`strength${i + 1}Name`]: highestVariables[4 - i].name,
            [`strength${i + 1}Total`]: parseFloat(highestVariables[4 - i].value).toFixed(2),
            [`strength${i + 1}Attempts`]: highestVariables[4 - i].attempts,
            [`strength${i + 1}PerShot`]: parseFloat(highestVariables[4 - i].perShot).toFixed(3),
            [`strength${i + 1}Improvement`]: parseFloat(highestVariables[4 - i].improvement),
        }));
    }
    return result;
}

export function sortAnalysisToList(data, units, selectedFacilities, numberImprovement, numberStrength, totalMinutes, isAdvanced) {
    // Define the variables and their values
    var variables = isAdvanced ? getVariablesAdvanced(data, units) : getVariables(data, units);

    // Sort the variables based on their values in ascending order
    variables.sort(function (a, b) {
        return a.value - b.value;
    });

    return calculateTime(variables.filter(e => selectedFacilities.includes(e.facility)), numberImprovement, numberStrength, totalMinutes);
}

function calculateTime(variables, numberImprovement, numberStrength, totalMinutes) {
    var weaknesses = variables.slice(0, numberImprovement);
    var strengths = variables.slice(variables.length - numberStrength, variables.length);
    var totalLost = 0
    var totalGain = 0
    weaknesses.forEach(function (val, i) {
        totalLost += Math.abs(val.value)
    })
    strengths.forEach(function (val, i) {
        totalGain += Math.pow(val.value, 1 / 3)
    })
    var mult = numberImprovement / (numberImprovement + numberStrength) * 1.2;
    var timeStrength = (totalMinutes * (1 - mult)) / (Math.abs(totalGain));
    var timeWeak = (totalMinutes * mult) / (Math.abs(totalLost));
    weaknesses.forEach((val, i) => {
        var t = timeWeak * Math.abs(weaknesses[i].value);
        val.time = parseFloat(t).toFixed(0);
    })
    strengths.forEach((val, i) => {
        var t = timeStrength * Math.pow(Math.abs(strengths[numberStrength - 1 - i].value), 1 / 3);
        val.time = parseFloat(t).toFixed(0);
    })
    return weaknesses.concat(strengths);

}

function getVariables(data, units) {
    return [
        { name: "PUTT 3'", value: data.putt3.strokesGained, attempts: data.putt3.attempts, perShot: data.putt3.strokesGainedPerShot, improvement: data.putt3.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: "PUTT 3-5'", value: data.putt35.strokesGained, attempts: data.putt35.attempts, perShot: data.putt35.strokesGainedPerShot, improvement: data.putt35.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: "PUTT 5-10'", value: data.putt510.strokesGained, attempts: data.putt510.attempts, perShot: data.putt510.strokesGainedPerShot, improvement: data.putt510.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: "PUTT 10-20'", value: data.putt1020.strokesGained, attempts: data.putt1020.attempts, perShot: data.putt1020.strokesGainedPerShot, improvement: data.putt1020.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: "PUTT 20-30'", value: data.putt2030.strokesGained, attempts: data.putt2030.attempts, perShot: data.putt2030.strokesGainedPerShot, improvement: data.putt2030.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: "PUTT 30+'", value: data.putt30.strokesGained, attempts: data.putt30.attempts, perShot: data.putt30.strokesGainedPerShot, improvement: data.putt30.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: `ARG ${units === "meters" ? "0-14m" : "0-15y"}`, value: data.sg015.strokesGained, attempts: data.sg015.attempts, perShot: data.sg015.strokesGainedPerShot, improvement: data.sg015.strokesGainedPerRoundRounded, facility: "Short Game Area" },
        { name: `ARG ${units === "meters" ? "14-27m" : "15-30y"}`, value: data.sg1530.strokesGained, attempts: data.sg1530.attempts, perShot: data.sg1530.strokesGainedPerShot, improvement: data.sg1530.strokesGainedPerRoundRounded, facility: "Short Game Area" },
        { name: `ARG ${units === "meters" ? "27-46m" : "30-50y"}`, value: data.sg3050.strokesGained, attempts: data.sg3050.attempts, perShot: data.sg3050.strokesGainedPerShot, improvement: data.sg3050.strokesGainedPerRoundRounded, facility: "Short Game Area" },
        { name: `APP ${units === "meters" ? "46-91m" : "50-100y"}`, value: data.sg50100.strokesGained, attempts: data.sg50100.attempts, perShot: data.sg50100.strokesGainedPerShot, improvement: data.sg50100.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: `APP ${units === "meters" ? "91-137m" : "100-150y"}`, value: data.sg100150.strokesGained, attempts: data.sg100150.attempts, perShot: data.sg100150.strokesGainedPerShot, improvement: data.sg100150.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: `APP ${units === "meters" ? "137-183m" : "150-200y"}`, value: data.sg150200.strokesGained, attempts: data.sg150200.attempts, perShot: data.sg150200.strokesGainedPerShot, improvement: data.sg150200.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: `APP ${units === "meters" ? "183+m" : "200+y"}`, value: data.sg200.strokesGained, attempts: data.sg200.attempts, perShot: data.sg200.strokesGainedPerShot, improvement: data.sg200.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: 'Tee Shots', value: data.sgOTT.strokesGainedRounded, attempts: data.sgOTT.attempts, perShot: data.sgOTT.strokesGainedPerShot, improvement: data.sgOTT.strokesGainedPerRoundRounded, facility: "Range Driver" },
    ];
}
function getVariablesAdvanced(data, units) {
    return [
        { name: "PUTT 3'", value: data.putt3.strokesGained, attempts: data.putt3.attempts, perShot: data.putt3.strokesGainedPerShot, improvement: data.putt3.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: "PUTT 3-5'", value: data.putt35.strokesGained, attempts: data.putt35.attempts, perShot: data.putt35.strokesGainedPerShot, improvement: data.putt35.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: "PUTT 5-10'", value: data.putt510.strokesGained, attempts: data.putt510.attempts, perShot: data.putt510.strokesGainedPerShot, improvement: data.putt510.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: "PUTT 10-20'", value: data.putt1020.strokesGained, attempts: data.putt1020.attempts, perShot: data.putt1020.strokesGainedPerShot, improvement: data.putt1020.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: "PUTT 20-30'", value: data.putt2030.strokesGained, attempts: data.putt2030.attempts, perShot: data.putt2030.strokesGainedPerShot, improvement: data.putt2030.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: "PUTT 30+'", value: data.putt30.strokesGained, attempts: data.putt30.attempts, perShot: data.putt30.strokesGainedPerShot, improvement: data.putt30.strokesGainedPerRoundRounded, facility: "Putting Green" },
        { name: `ARG ${units === "meters" ? "0-14m" : "0-15y"}`, value: data.sg015.strokesGained, attempts: data.sg015.attempts, perShot: data.sg015.strokesGainedPerShot, improvement: data.sg015.strokesGainedPerRoundRounded, facility: "Short Game Area" },
        { name: `ARG ${units === "meters" ? "14-27m" : "15-30y"}`, value: data.sg1530.strokesGained, attempts: data.sg1530.attempts, perShot: data.sg1530.strokesGainedPerShot, improvement: data.sg1530.strokesGainedPerRoundRounded, facility: "Short Game Area" },
        { name: `ARG ${units === "meters" ? "27-46m" : "30-50y"}`, value: data.sg3050.strokesGained, attempts: data.sg3050.attempts, perShot: data.sg3050.strokesGainedPerShot, improvement: data.sg3050.strokesGainedPerRoundRounded, facility: "Short Game Area" },
        { name: `APP ${units === "meters" ? "46-69m" : "50-75y"}`, value: data.sg5075.strokesGained, attempts: data.sg5075.attempts, perShot: data.sg5075.strokesGainedPerShot, improvement: data.sg5075.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: `APP ${units === "meters" ? "69-91m" : "75-100y"}`, value: data.sg75100.strokesGained, attempts: data.sg75100.attempts, perShot: data.sg75100.strokesGainedPerShot, improvement: data.sg75100.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: `APP ${units === "meters" ? "91-114m" : "100-125y"}`, value: data.sg100125.strokesGained, attempts: data.sg100125.attempts, perShot: data.sg100125.strokesGainedPerShot, improvement: data.sg100125.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: `APP ${units === "meters" ? "114-137m" : "125-150y"}`, value: data.sg125150.strokesGained, attempts: data.sg125150.attempts, perShot: data.sg125150.strokesGainedPerShot, improvement: data.sg125150.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: `APP ${units === "meters" ? "137-160m" : "150-175y"}`, value: data.sg150175.strokesGained, attempts: data.sg150175.attempts, perShot: data.sg150175.strokesGainedPerShot, improvement: data.sg150175.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: `APP ${units === "meters" ? "160-183m" : "175-200y"}`, value: data.sg175200.strokesGained, attempts: data.sg175200.attempts, perShot: data.sg175200.strokesGainedPerShot, improvement: data.sg175200.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: `APP ${units === "meters" ? "183+m" : "200+y"}`, value: data.sg200.strokesGained, attempts: data.sg200.attempts, perShot: data.sg200.strokesGainedPerShot, improvement: data.sg200.strokesGainedPerRoundRounded, facility: "Range Irons" },
        { name: 'Tee Shots - Driver', value: data.sgOTTdriver.strokesGainedRounded, attempts: data.sgOTTdriver.attempts, perShot: data.sgOTTdriver.strokesGainedPerShot, improvement: data.sgOTTdriver.strokesGainedPerRoundRounded, facility: "Range Driver" },
        { name: 'Tee Shots - Other', value: data.sgOTTother.strokesGainedRounded, attempts: data.sgOTTother.attempts, perShot: data.sgOTTother.strokesGainedPerShot, improvement: data.sgOTTother.strokesGainedPerRoundRounded, facility: "Range Woods" }
    ];
}