import { useConstant } from "../../../../ConstantContext"
import { Table } from "reactstrap";
import { useOptions } from "../../StatsOptionsProvider";
const ARGindividual = ({ state, data, isAdvanced }) => {
    const constants = useConstant();
    const options = useOptions();
    function renderARGStrokesGained() {
        return (
            <div className="table-responsive">
                <Table id='argtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Total</th>
                            <th>Att. </th>
                            <th>Per Shot</th>
                            <th>Per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(isAdvanced ? options.argSGAdvanced : options.argSG).map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`${stat.name}`]?.attempts > 0) &&
                                        (stat.name === "sgARG" ?
                                            state[`sgARG`]?.strokesGained / state[`noOfRounds`] > constants.tourAvg[`sgARG`].greenValue ||
                                            state[`sgARG`]?.strokesGained / state[`noOfRounds`] < constants.tourAvg[`sgARG`].redValue
                                            :
                                            state[`${stat.name}`]?.strokesGainedPerShot > constants.sgPerShotUpper ||
                                            state[`${stat.name}`]?.strokesGainedPerShot < constants.sgPerShotLower) && 'white',
                                    backgroundColor: (state[`${stat.name}`]?.attempts > 0) &&
                                        (stat.name === "sgARG" ?
                                            state[`sgARG`]?.strokesGained / state[`noOfRounds`] > constants.tourAvg[`sgARG`].greenValue ? constants.green :
                                                state[`sgARG`] < constants.tourAvg[`sgARG`].redValue ? constants.red : ''
                                            :
                                            state[`${stat.name}`]?.strokesGainedPerShot > constants.sgPerShotUpper ? constants.green :
                                                state[`${stat.name}`]?.strokesGainedPerShot < constants.sgPerShotLower ? constants.red : '')
                                }}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]?.strokesGainedRounded}</td>
                                <td>{state[`${stat.name}`]?.attempts}</td>
                                <td>{state[`${stat.name}`]?.strokesGainedPerShot}</td>
                                <td>{state[`${stat.name}`]?.strokesGainedPerRoundRounded}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div >
        )
    }

    return (
        <div>
            {renderARGStrokesGained()}
            <div className="table-responsive">
                <Table id='argtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Value</th>
                            <th>Count</th>
                            <th>Attempts</th>
                            <th>Tour Avg.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isAdvanced && <tr key={`expectedARGMiss`}>
                            <td>Expected Scrambling</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{parseFloat((1 - (state[`girMissedExpectedApp`]?.averageExpected - 2)) * 100).toFixed(0)}%</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                        </tr>}
                        {options.argStats.map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`${stat.name}`]?.attempts > 0) &&
                                        (state[`${stat.name}`]?.percentage > constants.tourAvg[`${stat.name}`]?.percentage.greenValue ||
                                            state[`${stat.name}`]?.percentage < constants.tourAvg[`${stat.name}`]?.percentage.redValue) && 'white',
                                    backgroundColor: (state[`${stat.name}`]?.attempts > 0) &&
                                        (state[`${stat.name}`]?.percentage > constants.tourAvg[`${stat.name}`]?.percentage.greenValue ? constants.green :
                                            state[`${stat.name}`]?.percentage < constants.tourAvg[`${stat.name}`]?.percentage.redValue ? constants.red : '')
                                }}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]?.percentage}%</td>
                                <td>{state[`${stat.name}`]?.hitCount}</td>
                                <td>{state[`${stat.name}`]?.attempts}</td>
                                <td>{constants.tourAvg[`${stat.name}.percentage`]?.value}</td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div >
        </div>

    )
}
export default ARGindividual