import React, { useState, useEffect } from "react";
import {
    Container, Table, Row, Col,
} from "reactstrap";
import { InputNumber } from 'primereact/inputnumber';
import { Button } from "primereact/button";
import { useAuth } from "../../appwrite-testing/AuthContext";

const NewPuttingPractice = ({ setShowPuttingResult, setStartNewPutting, }) => {
    const rows = Array.from({ length: 18 }, (_, i) => i + 1);
    const { user, getJWT } = useAuth();
    const userId = user?.$id;
    const holes = [5, 40, 1, 13, 4, 52, 26, 10, 20, 30, 3, 23, 7, 11, 33, 6, 16, 8];
    const [currentRow, setCurrentRow] = useState(0);

    const [distance, setDistance] = useState([]);
    var [score, setScore] = useState(Array(18).fill(0));
    const [practiceId, setPracticeId] = useState(null);
    function shuffleArray() {
        let array = holes;
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        setDistance(array)
    }

    const updateScoreArray = (index, value) => {
        const newArray = [...score];
        newArray[index - 1] = Number(value);
        setScore(newArray);
    };
    function setDefaultScore() {
        const newArray = [...score];
        for (var i = 0; i < 18; i++) {
            if (distance[i] <= 8) {
                newArray[i] = 1;
            } else {
                newArray[i] = 2;
            }
        }
        setScore(newArray);
    }

    useEffect(() => {
        shuffleArray();
    }, [])

    useEffect(() => {
        if (distance.length > 0) {
            const createPractice = async () => {
                try {
                    const jwt = await getJWT();
                    const response = await fetch('/api/puttingpractice', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        },
                        body: JSON.stringify({ 
                            userId: userId, 
                            date: new Date().toISOString(), 
                            distances: distance, 
                            scores: score 
                        })
                    });
                    const data = await response.json();
                    setPracticeId(data.id);
                } catch (error) {
                    console.error('Error creating putting practice:', error);
                }
            };
            createPractice();
        }
    }, [distance.join()]);

    useEffect(() => {
        const updatePractice = async () => {
            try {
                const jwt = await getJWT();
                await fetch(`/api/puttingpractice?id=${practiceId}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    },
                    body: JSON.stringify(score)
                });
            } catch (error) {
                console.error('Error updating putting practice:', error);
            }
        };
        if (practiceId) {
            updatePractice();
        }
    }, [score]);

    function submitPutting() {
        setShowPuttingResult(true);
        setStartNewPutting(false);
    }

    return (
        <Container >
            <Row>
                <Col md="12" lg="12">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Hole</th>
                                <th>Distance (ft)</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>
                                    {currentRow + 1}
                                </td>
                                <td>
                                    {distance[currentRow]}
                                </td>
                                <td>

                                    <InputNumber
                                        onValueChange={(e) => updateScoreArray(currentRow + 1, e.target.value)}
                                        value={score[currentRow]}
                                        showButtons buttonLayout="horizontal"
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                        decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                        className="p-fluid"
                                        maxLength={1}
                                        min={1}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                    <span className="p-buttonset" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Button className="m-1" label="Previous" icon="pi pi-arrow-circle-left" severity="secondary" onClick={() => { setCurrentRow(currentRow - 1) }} style={{ width: "45%" }} />
                        {currentRow === 17 ? <Button className="m-1" label="Submit" icon="pi pi-check" iconPos="right" severity="success" onClick={() => { submitPutting() }} style={{ width: "45%" }} /> :
                            <Button className="m-1" icon="pi pi-arrow-circle-right" iconPos="right" label="Next" severity="secondary" onClick={() => { setCurrentRow(currentRow + 1) }} style={{ width: "45%" }} />
                        }
                    </span>
                </Col>
            </Row>
            <Row className="table-container mt-3 mb-4">
                <Col md="12" lg="12">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Hole</th>
                                <th>Distance</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                                <tr style={{ backgroundColor: currentRow + 1 === row ? 'red' : '' }} key={row}>
                                    <td>
                                        {row}
                                    </td>
                                    <td>
                                        {distance[row - 1]}
                                    </td>
                                    <td>
                                        {score[row - 1]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container >
    )
}

export { NewPuttingPractice }