import { React } from "react";
import { NavLink } from "reactstrap";
import { Link } from 'react-router-dom';
import { useAuth } from "../appwrite-testing/AuthContext";

const ShowTeamRounds = () => {
    const { user, loading, isCoach } = useAuth();

    if (loading || !user) {
        return null;
    }

    return (
        <>
            {isCoach() && (
                <NavLink
                    tag={Link}
                    to="/team-rounds"
                    style={{ color: 'black' }}
                >
                    Individual Rounds
                </NavLink>
            )}
        </>
    );
};

export { ShowTeamRounds };

