import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "../css/new-round.css";
import { AddRound } from "./AddRound";
import { useAuth } from "./appwrite-testing/AuthContext";
import { RoundTypeDropdown } from './dropdowns/RoundTypeDropdown'
import { DateInput } from './DateInput'
import { Dropdown } from 'primereact/dropdown'
import { Button } from "primereact/button";
import { ProgressSpinner } from 'primereact/progressspinner';
import AddRound2 from "./add-round/AddRound2";
export function CreateRound() {
  const { user, getJWT } = useAuth();
  const userId = user?.$id || "000000";
  const [wasDataLoaded, setWasDataLoaded] = useState(false);
  const [roundId, setRoundId] = useState(null);
  const [roundType, setRoundType] = useState("");
  const [tournamentSelected, setTournamentSelected] = useState(true);
  const [visible, setVisibility] = useState(true);
  const [validInput, setValidInput] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [course, setCourse] = useState("Course");
  const [courseSelected, setCourseSelected] = useState(false);
  const [tee, setTee] = useState("Tee");
  const roundTypes = ["Tournament", "Practice", "Qualifying"];
  const [courseSelect, setCourseSelect] = useState(null);
  const [teeSelect, setTeeSelect] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [userBaseline, setUserBaseline] = useState(null);
  const [userUnits, setUserUnits] = useState(null);
  const [userInfoLoaded, setUserInfoLoaded] = useState(false);

  async function fetchRound() {
    try {
      const jwt = await getJWT();
      const response = await fetch('/api/round', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify({
          userId: userId,
          type: roundType,
          date: new Date(selectedDate.toISOString().substring(0, 10)),
          course: course.courseName,
          courseId: course.id,
          teeBox: tee.teeName,
          tournamentId: "123",
          shots: [],
          baseline: userBaseline
        })
      });
      const data = await response.json();
      setRoundId(data.id);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function fetchCourses() {
      try {
        const jwt = await getJWT();
        const response = await fetch("/api/course/all", {
          headers: {
            'Authorization': `Bearer ${jwt}`
          }
        });
        const data = await response.json();
        setCourseList(data);
        setWasDataLoaded(true);
      } catch (error) {
        console.error(error);
      }
    }
    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const jwt = await getJWT();
        const response = await fetch(`api/User/myself?userId=${user.$id}`, {
          headers: {
            'Authorization': `Bearer ${jwt}`
          }
        });
        const responseData = await response.json();
        setUserBaseline(responseData.baseline);
        setUserUnits(responseData.units);
        setUserInfoLoaded(true);
        window.localStorage.setItem("units", responseData.units);
        window.localStorage.setItem("baseline", responseData.baseline);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserInfoLoaded(false);
      }
    };

    const unitsLocal = window.localStorage.getItem("units");
    const baselineLocal = window.localStorage.getItem("baseline");

    if (unitsLocal && baselineLocal) {
      setUserBaseline(baselineLocal);
      setUserUnits(unitsLocal);
      setUserInfoLoaded(true);
    } else {
      fetchUser();
    }
  }, [user]);

  function changeVisibility() {
    setVisibility(!visible);
    fetchRound();
  }

  useEffect(() => {
    if (courseSelect && teeSelect && selectedDate && roundType && userBaseline) {
      setValidInput(true);
    }

  }, [courseSelect, teeSelect, selectedDate, roundType, userBaseline]);

  function changeCourse(event) {
    setCourse(event.target.value);
    setCourseSelect(event.target.value);
    setCourseSelected(true);
  }

  function changeTee(event) {
    setTee(event.target.value);
    setTeeSelect(event.target.value);
  }

  const selectedTeeTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center"><div>{option.teeName}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const teeOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center"><div>{option.teeName}</div>
      </div>
    );
  };
  function renderTeeSet() {
    return (
      <div>
        <Dropdown value={tee} onChange={(event) => changeTee(event)} options={courseSelect.teeSet} optionLabel="name" placeholder="Select a Teebox"
          valueTemplate={selectedTeeTemplate} itemTemplate={teeOptionTemplate} className="w-full md:w-14rem" />

      </div>
    )
  }

  const selectedCourseTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center"> <div>{option.courseName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const courseOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.courseName}</div>
      </div>
    );
  };
  return (
    <div>
      {visible && (
        <div>
          <h1>Create New Round</h1>
          <Container className="ps-0">
            <Row className="m-3">
              <Col xs="6" md="2" lg="2">
                <RoundTypeDropdown setTournamentSelected={setTournamentSelected} setRoundType={setRoundType} roundType={roundType} roundTypes={roundTypes} />
              </Col>
              <Col xs="6" md="2" lg="2">
                {/* {tournamentSelected && <TournamentDropdown />} */}
              </Col>
            </Row>
            <Row className="m-3">
              <Col xs="6" md="6" lg="3">
                <DateInput setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
              </Col>

            </Row>
            <Row className="m-3">
              <Col className="mb-3 mb-md-0" xs="12" md="5" lg="4">
                <Dropdown
                  id="course"
                  value={course}
                  onChange={
                    (event) => changeCourse(event)
                  }
                  options={courseList}
                  optionLabel="courseName"
                  placeholder={wasDataLoaded ? "Select a Course" : "Loading"}
                  dropdownIcon={wasDataLoaded ? "pi pi-chevron-down" : "pi pi-spinner"}
                  filter
                  filterPlaceholder="Search course"
                  valueTemplate={selectedCourseTemplate}
                  itemTemplate={courseOptionTemplate}
                  className="w-full md:w-14rem" />
              </Col>
              <Col xs="4" md="3" lg="3">
                {courseSelected && renderTeeSet()}
              </Col>
            </Row>
            {!userInfoLoaded && <Row className="m-4">
              <Col>
                <p>Loading user data</p>
                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
              </Col>
            </Row>}
            <Row className="m-4">
              <Col>
                {/* <Button className="mt-3" disabled={!validInput} onClick={changeVisibility}>Start</Button> */}
                <Button label="Start" className="mt-1" disabled={!validInput} onClick={changeVisibility} />
              </Col>
            </Row>


          </Container>
        </div>
      )}
      {!visible && (
        <AddRound2
          roundId={roundId}
          teeSelect={teeSelect}
          baseline={userBaseline}
          units={userUnits}
        />
      )}
    </div>
  );
}
