import { InputNumber } from "primereact/inputnumber"
const PlannerTimeAndNumber = ({ hours, setHours, minutes, setMinutes, elements, setElements }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div>
                <label htmlFor="HoursInput">Hours</label>
                <InputNumber className="m-2" inputId="HoursInput" value={hours} min={0} onValueChange={(e) => setHours(e.value)} showButtons buttonLayout="horizontal"
                    decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
            </div>
            <div>
                <label htmlFor="MinutesInput">Minutes</label>
                <InputNumber className="m-2" inputId="MinutesInput" value={minutes} onValueChange={(e) => setMinutes(e.value)} showButtons buttonLayout="horizontal"
                    max={59} step={15} min={0}
                    decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
            </div>
            <div>
                <label htmlFor="noElem">Number of Elements</label>
                <InputNumber className="m-2" inputId="noElem" value={elements} min={3} max={10} onValueChange={(e) => setElements(e.value)} showButtons buttonLayout="horizontal"
                    decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
            </div>
        </div>
    )
}
export default PlannerTimeAndNumber