import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import '../../css/loading.css'
/**Table with strokes gained from last 10 rounds */
const HomeStrokesGained = (props) => {
    const [sgOTT, setSGOTT] = useState("");
    const [sgAPP, setSGAPP] = useState("");
    const [sgARG, setSGARG] = useState("");
    const [sgPUTT, setSGPUTT] = useState("");



    useEffect(() => {
        if (props.noRounds) {
            var table = document.getElementById("sghometable");
            var cells = table.getElementsByClassName("loading-cell");

            // Show the loading animation
            while (cells.length) {
                cells[0].classList.remove("loading-cell");
            }
        }
        else if (props.data) {
            fillQuickStats(props.data)
            var table = document.getElementById("sghometable");
            var cells = table.getElementsByClassName("loading-cell");

            // Show the loading animation
            while (cells.length) {
                cells[0].classList.remove("loading-cell");
            }
        }
    }, [props.data, props.noRounds]);

    /**Fills out the table with strokes gained in last 20 rounds */
    function fillQuickStats(data) {
        setSGOTT(data.sgOTT.strokesGainedPerRoundRounded);
        setSGAPP(data.sgAPP.strokesGainedPerRoundRounded);
        setSGARG(data.sgARG.strokesGainedPerRoundRounded);
        setSGPUTT(data.putt.strokesGainedPerRoundRounded);
    }

    return (
        <Table id="sghometable">
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>OTT</td>
                    <td className="loading-cell">{sgOTT}</td>
                </tr >
                <tr>
                    <td>APP</td>
                    <td className="loading-cell" >{sgAPP}</td>
                </tr>
                <tr>
                    <td>ARG</td>
                    <td className="loading-cell" >{sgARG}</td>
                </tr>
                <tr>
                    <td>PUTT</td>
                    <td className="loading-cell" >{sgPUTT}</td>
                </tr>
            </tbody >

        </Table>
    );
};

export default HomeStrokesGained;
