import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';
import Footer from './home/Footer';
import { Button } from 'primereact/button';
const FAQ = () => {
    return (
        <>
            <Card title="FAQ">
                <Accordion>
                    <AccordionTab header="Round Input">
                        <Accordion>
                            <AccordionTab header="How do I change the units?">
                                To set the units you want to use for stats go to "My Account" and select desired units.
                                For putting the default unit is feet. The reason we use fee is that they are more accurate than yards or meters and especially inside 10 feet, every foot has a huge differene on the make percentage. For reference 1 yard = 3 feet, 1 meter = 3.3 feet, 1 pace = 2.5 feet.
                            </AccordionTab>
                            <AccordionTab header='What is a "Forced Layup"?'>
                                Because our stats program isn't like Shot Link on tour, we rely on some elements from the best guess of the players. Forced Layup happens when there is an obstacle that prevents the majority of the field from hitting the full driver, i.e. creek going through the fairway, dog leg hole, extremely tight hole. Player has to make their best guess on how the majority of the field is playing the hole. This will allow the stats calculations to not decrease the strokes gained when the tee shot is shorter than average tour drive. This also works another way, if most of the field is laying up and you're one of a few that is still able to hit driver you can gain some more strokes. If you're ever in doubt, just dont use this feature.
                            </AccordionTab>
                            <AccordionTab header='What is a "Mistake"?'>
                                After every shot you can note why you think you hit a bad shot. The three reasons are:
                                <li>Technique - when you just make a bad swing. For putting it is when you pushed/pulled the putt or if you did not hit your intended pace</li>
                                <li>Decision - Wrong club, wrong line, wrong read (line or pace), etc.</li>
                                <li>Mental State - Uncomitted over the shot, stressed more than usual, not focused, etc.</li>
                            </AccordionTab>
                            <AccordionTab header='What is a "Going for it Opportunity"?'>
                                Because our stats program isn't like Shot Link on tour, we rely on some elements from the best guess of the players. A going for it opportunity happens on any par 4 or par 5 where AT LEAST 1 PLAYER has gone for the green under regulation.
                            </AccordionTab>
                            <AccordionTab header='What is a "Layup"?'>
                                Off the tee it's any time you're not hitting a driver. On approaches it happens when you know you can't reach the green, i.e. recovery situation, too far on shot to reach the green under regulation.
                            </AccordionTab>
                            <AccordionTab header='How do I determine "Difficulty"?'>
                                <li>
                                    Recovery:
                                    <ul>
                                        <li style={{ listStyleType: "circle" }}>Easy - no clean shot at the pin, but still
                                            able to advance the green. For example a tree is in the way and you have to punch out but you're able to put the ball close or on the green.
                                        </li>
                                        <li style={{ listStyleType: "circle" }}> Medium - cannot take a normal line at the pin.
                                        </li>
                                        <li style={{ listStyleType: "circle" }}>Hard - basically a sideways or backwards chip out.
                                        </li>
                                    </ul>
                                    If not sure select medium.
                                </li>
                                <li>
                                    Around the Green:
                                    <ul>
                                        <li style={{ listStyleType: "circle" }}>
                                            Easy - the distance from the ball to the edge of the green is less
                                            than 1/3 of the distance of the shot.
                                        </li>
                                        <li style={{ listStyleType: "circle" }}>Hard - the distance from the ball to the edge of the green is more than 2/3
                                            of the total distance.
                                        </li>
                                        <li style={{ listStyleType: "circle" }}>Medium shot is in between.
                                        </li>

                                    </ul>
                                    Adjust for lies. If not sure select medium.
                                </li>
                            </AccordionTab>
                        </Accordion>
                    </AccordionTab>
                    <AccordionTab header="Courses">
                        <Accordion>
                            <AccordionTab header="What if I made a mistake in the par of the hole?">
                                To make sure other player's data doesn't get messed with, only administration is allowed to edit already created tees and courses. Please <a href='/contact'>contact us</a> with a clear description of what course, which hole and what par should it be.
                            </AccordionTab>
                        </Accordion>
                    </AccordionTab>

                    <AccordionTab header="Practice">
                        <Accordion>
                            <AccordionTab header="Can I add my own drills?">
                                <a href='/contact'>Contact us</a> with a suggestion of a drill you want us to add and we'll add it to the database.
                            </AccordionTab>
                        </Accordion>
                    </AccordionTab>
                    <AccordionTab header="Stats">

                    </AccordionTab>
                    <AccordionTab header="Billing">
                        <Accordion>
                            <AccordionTab header="How can I change my subscription plan?">
                                You can change your subscription plan at any time by going to My Account {'>'}  Subscription and Billing.
                                If upgrading (e.g., Basic to Pro), the change will take effect immediately.
                                If downgrading, the change will take effect at the end of your current billing period.
                            </AccordionTab>
                            <AccordionTab header="How can I add a player to a team after paying for the team subscription?">
                                Team administrators can add new players through the My Account {'>'} My Team section.
                                Each new player will be billed at your team's per-player rate.
                                For teams with 10+ players, please contact us for special bulk pricing.
                            </AccordionTab>
                            <AccordionTab header="Will I lose my data after cancelling my plan?">
                                If you cancel we will store your data for up to 2 years after the last day of your plan.
                                After that all your practice entries and rounds will be permanently deleted.
                            </AccordionTab>
                            <AccordionTab header="How can I cancel my plan?">
                                You can cancel your subscription through My Account {'>'} Subscription and Billing.
                                Your access will continue until the end of your current billing period.
                                No refunds are provided for partial months/years.
                            </AccordionTab>
                            <AccordionTab header="What's included in the Pro plan vs Basic plan?">
                                The Pro plan includes advanced statistics, enhanced practice tools, and priority support
                                in addition to all Basic plan features. For teams, Pro members get access to additional
                                analytics and team management features.
                            </AccordionTab>
                            <AccordionTab header="How does the Academy program work?">
                                Academy program members receive a free coach account and a unique 10% discount code
                                to share with their clients. Contact us for full details and to set up your academy account.
                            </AccordionTab>
                        </Accordion>
                    </AccordionTab>
                </Accordion >
            </Card >
            <Footer />
        </>
    )
}
export default FAQ