import { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { TabMenu } from "primereact/tabmenu";
import PersonalInformation from "./PersonalInformation"
import Billing from "./Billing";
import MyTeam from "./MyTeam";
import { useAuth } from "../appwrite-testing/AuthContext";

const MyAccount = () => {
    const { user } = useAuth();
    const [activeIndex, setActiveIndex] = useState(0);

    const [items, setItems] = useState([
        { label: 'Personal Information', icon: 'pi pi-user' },
        { label: 'Subscription and Billing', icon: 'pi pi-wallet' }
    ]);
    useEffect(() => {
        if (user.labels.includes('coach')) {
            setItems([{ label: 'Personal Information', icon: 'pi pi-user' },
            { label: 'Subscription and Billing', icon: 'pi pi-wallet' },
            { label: 'My Team', icon: 'pi pi-users' }])
        }
    }, [user])

    function displayMenu() {
        switch (activeIndex) {
            case 0:
                return <PersonalInformation />
            case 1:
                return <Billing />
            case 2:
                return <MyTeam user={user} />

        }
    }

    return (
        <Card title="My Account" className="mb-4">
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
            <div className="mt-4">
                {displayMenu()}
            </div>
        </Card>
    )
}

export default MyAccount;