import { Card } from "primereact/card";
import { useEffect, useState } from "react";
import { Table } from "reactstrap";

const GreenReadResults = ({ data }) => {
    const [lr02, setlr02] = useState(null);
    const [rl02, setrl02] = useState(null);
    const [lr24, setlr24] = useState(null);
    const [rl24, setrl24] = useState(null);

    useEffect(() => {
        if (data)
            calculateTendencies(data)
    }, [data])



    function calculateTendencies(data) {
        if (data.reads02LR && data.reads02LR.length > 0) {
            setlr02(calculateAverageError(data.reads02LR));
        }

        if (data.reads02RL && data.reads02RL.length > 0) {
            setrl02(calculateAverageError(data.reads02RL));
        }

        if (data.reads24LR && data.reads24LR.length > 0) {
            setlr24(calculateAverageError(data.reads24LR));
        }

        if (data.reads24RL && data.reads24RL.length > 0) {
            setrl24(calculateAverageError(data.reads24RL));
        }

    }

    function calculateAverageError(jsonArray) {
        let totalError = 0;
        let count = Math.min(jsonArray.length, 70); // Limit to first 50 elements or array length

        for (let i = 0; i < count; i++) {
            let readPct = Math.abs(jsonArray[i].readPct);
            let slopePct = Math.abs(jsonArray[i].slopePct);
            let error = readPct - slopePct; // Regular difference
            totalError += error;
        }

        let averageError = totalError / count;
        return averageError.toFixed(2);
    }

    return (
        <Card title="Results">
            <Table>
                <thead>
                    <tr>
                        <th>0-2% L-R</th>
                        <th>0-2% R-L</th>
                        <th>2-4% L-R</th>
                        <th>2-4% R-L</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{isNaN(lr02) ? "no data" : lr02 + " %"}</td>
                        <td>{isNaN(rl02) ? "no data" : rl02 + " %"}</td>
                        <td>{isNaN(lr24) ? "no data" : lr24 + " %"}</td>
                        <td>{isNaN(rl24) ? "no data" : rl24 + " %"}</td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    )
}
export default GreenReadResults;