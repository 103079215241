import { ToggleButton } from "primereact/togglebutton";
import { useState } from "react";
import { Col } from 'reactstrap'

const ShowAllButton = ({ teamName, setSelectedUsers, selectedUsers, groupedPlayers }) => {
    const [isAllTeam, setIsAllTeam] = useState(false);
    function allTeam(value) {
        setIsAllTeam(value);
        if (value) {
            const match = groupedPlayers.find(grp => grp.groupName === teamName);
            if (match) {
                let arr = selectedUsers.concat(match.players)
                setSelectedUsers(arr);
            }
        } else {
            const updatedArr = selectedUsers.filter(user => user.teamName !== teamName);
            setSelectedUsers(updatedArr)
        }
    }

    return (
        <Col className="mt-2" lg="3">
            <ToggleButton checked={isAllTeam} onChange={(e) => allTeam(e.value)}
                onLabel={`Show All ${teamName}`} offLabel={`Show All ${teamName}`}
                onIcon="pi pi-check" offIcon="pi pi-times" />
        </Col>
    )
}
export default ShowAllButton;