import ListMembers from "./ListMembers";
import { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import InviteMember from "./InviteMember";
import { Toast } from 'primereact/toast';

import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
const TeamList = ({ team, teams, inviteSent, user }) => {

    const [players, setPlayers] = useState([]);
    const [coaches, setCoaches] = useState([])

    const [category, setCategory] = useState("");//player of coach
    const [isInvite, setIsInvite] = useState(false);
    const [reload, setReload] = useState(false); // <-- Add a reload state
    const [isOwner, setIsOwner] = useState(false);
    const [list, setList] = useState([])

    const toast = useRef(null);

    useEffect(() => {
        teams.listMemberships(
            team.$id, // teamId
            undefined, // queries (optional)
            undefined // search (optional)
        ).then(result => assignRole(result.memberships))
    }, [reload])

    function assignRole(l) {
        setPlayers([]);
        setCoaches([]);
        setList([]);
        setList(l)
        l.forEach((e) => {
            if (e.roles.includes('Coach')) {
                setCoaches((prevCoaches) => [...prevCoaches, e]);
            } else {
                setPlayers((prevPlayers) => [...prevPlayers, e]);
            }
        });
    }
    const handleMemberDeleted = () => {
        setReload(prev => !prev); // Toggle the reload state to trigger useEffect
    };
    useEffect(() => {
        setIsOwner(
            list.some((p) => {
                if (p.userId === user.$id && p.roles.includes("owner")) {
                    return true;
                }
                return false;

            })
        )
    }, [players, coaches])

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            {!isInvite ?
                <Card title="My Team" subTitle={team.name}>
                    <div style={{ display: 'flex', flexWrap: "wrap", flexDirection: 'row', alignItems: 'flex-start', overflowX: 'hidden', justifyContent: 'center' }} className="mt-3 .coach-container">
                        <ListMembers category="Player" list={players} className="m-3" setIsInvite={setIsInvite} setCategory={setCategory} teams={teams} team={team} toast={toast} onMemberDeleted={handleMemberDeleted} isOwner={isOwner} />
                        <ListMembers category="Coach" list={coaches} className="m-3" setIsInvite={setIsInvite} setCategory={setCategory} teams={teams} team={team} toast={toast} onMemberDeleted={handleMemberDeleted} isOwner={isOwner} />
                    </div>
                </Card>
                :
                <Card title={`Invite a ${category}`} subTitle={team.name} >
                    <InviteMember category={category} setIsInvite={setIsInvite} team={team} inviteSent={inviteSent} />
                </Card>}
        </div>
    )
}
export default TeamList;