import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { SelectButton } from "primereact/selectbutton";
import { useEffect, useRef, useState } from "react";
import { Table } from "reactstrap";
import GreenReadResults from "./GreenReadResults";
import { Toast } from "primereact/toast"
import GreenReadingTable from "./GreenReadingTable";

const GreenReading = ({ user, getJWT }) => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const toast = useRef(null);

    const [slopePct, setSlopePct] = useState(null);
    const [slopeDir, setSlopeDir] = useState("L-R");
    const [readPct, setReadPct] = useState(null);
    const [readDir, setReadDir] = useState("L-R");
    const dirOptions = ["L-R", "R-L"]
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null)
    const [isDelete, setDelete] = useState(false);

    function clearInput() {
        setSlopePct(null);
        setReadPct(null);
        setSlopeDir("L-R");
        setReadDir("L-R")
    }

    function changeDirection(value) {
        setSlopeDir(value);
        setReadDir(value);
    }

    async function getReads() {
        try {
            const jwt = await getJWT();
            const response = await fetch(`/api/greenreadingpractice?userId=${user.$id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });
            if (response.ok) {
                const data = await response.json()

                console.log('here')
                setDelete(false);
                setData(data);
            } else {
                console.error(response);
            }
        } catch (error) {
            console.error('Error adding a read:', error);
        }
    }
    useEffect(() => {
        getReads()
    }, [isLoading, isDelete])

    async function submitRead() {
        var slope = slopeDir === "L-R" ? slopePct : slopePct * (-1);
        var read = readDir === "L-R" ? readPct : readPct * (-1);
        setIsLoading(true);
        try {
            const jwt = await getJWT();
            const response = await fetch(`/api/greenreadingpractice?userId=${user.$id}&slopePct=${slope}&readPct=${read}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });
            if (response.ok) {
                clearInput();
                setTimeout(setIsLoading(false), 2000);
                toast.current.show({ severity: 'success', summary: 'Submitted', life: 3000 })

            }
        } catch (error) {
            console.error('Error adding a read:', error);
        }
    }

    function desktopEntry() {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Slope %</th>
                        <th>Slope direction</th>
                        <th>Read %</th>
                        <th>Read direction</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <InputNumber
                                value={slopePct}
                                onChange={(e) => setSlopePct(e.value)}
                                maxFractionDigits={2}
                                max={4}
                                min={0} />
                        </td>
                        <td>
                            <SelectButton
                                value={slopeDir}
                                options={dirOptions}
                                onChange={(e) => changeDirection(e.value)}
                            />
                        </td>
                        <td>
                            <InputNumber
                                value={readPct}
                                onChange={(e) => setReadPct(e.value)}
                                maxFractionDigits={2}
                                max={5}
                                min={0} />
                        </td>
                        <td><SelectButton
                            value={readDir}
                            options={dirOptions}
                            onChange={(e) => setReadDir(e.value)}
                        />
                        </td>
                        <td>
                            <Button label="Submit" onClick={submitRead} loading={isLoading}
                                disabled={slopePct == null || readPct == null} />
                        </td>
                        <td>
                            <Button label="Clear" severity="danger" onClick={clearInput} />
                        </td>
                    </tr>
                </tbody>

            </Table>
        )
    }

    function mobileEntry() {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Slope %</th>
                        <th>Slope direction</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <InputNumber
                                value={slopePct}
                                onChange={(e) => setSlopePct(e.value)}
                                maxFractionDigits={2}
                                max={4}
                                min={0} />
                        </td>
                        <td>
                            <SelectButton
                                value={slopeDir}
                                options={dirOptions}
                                onChange={(e) => changeDirection(e.value)}
                                allowEmpty={true}
                                pt={{ button: { style: { borderRadius: "6px", margin: "1px" } } }}
                            />
                        </td>
                    </tr>
                </tbody>
                <thead>
                    <tr>

                        <th>Read %</th>
                        <th>Read direction</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <InputNumber
                                value={readPct}
                                onChange={(e) => setReadPct(e.value)}
                                maxFractionDigits={2}
                                max={5}
                                min={0} />
                        </td>
                        <td><SelectButton
                            value={readDir}
                            options={dirOptions}
                            onChange={(e) => setReadDir(e.value)}
                            allowEmpty={true}

                            pt={{ button: { style: { borderRadius: "6px", margin: "1px" } } }}
                        />
                        </td>
                    </tr>
                </tbody>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Button label="Submit" onClick={submitRead} loading={isLoading}
                                disabled={slopePct == null || readPct == null} />
                        </td>
                        <td>
                            <Button label="Clear" severity="danger" onClick={clearInput} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    }

    return (
        <>
            <Card title="Enter a Read" clasName="mb-2">
                <Toast ref={toast} />
                {isMobile ? mobileEntry() : desktopEntry()}
            </Card>
            <GreenReadResults data={data} />
            <GreenReadingTable data={data} getJWT={getJWT} userId={user.$id} setDelete={setDelete} />
        </>
    )
}
export default GreenReading;