import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { client } from "../appwrite-testing/appwriteConfig";
import { useAuth } from "../appwrite-testing/AuthContext";
import { useEffect } from "react";
import { Teams } from "appwrite";

const AcceptInvitation = () => {
    const teams = new Teams(client);
    const urlParams = new URLSearchParams(window.location.search);

    function acceptInvite() {
        teams.updateMembershipStatus(
            urlParams.get('teamId'), // teamId
            urlParams.get('membershipId'), // membershipId
            urlParams.get('userId'), // userId
            urlParams.get('secret') // secret
        ).then(result => window.open(`/`, "_self"))
    }
    return (
        <div>
            <Card title="Accept Invitation">
                <Button className="m-2" label="Accept" severity="success" onClick={acceptInvite} />
                <Button className="m-2" label="Cancel" severity="danger" onClick={() => window.close()} />
            </Card>
        </div>
    )
}
export default AcceptInvitation;