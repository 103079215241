import React, { Component, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// Optional: Import DevTools if you want to use them in development
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { ConstantProvider } from './ConstantContext';
import { AuthProvider } from './components/appwrite-testing/AuthContext';
import ProtectedRoute from './components/appwrite-testing/ProtectedRoute';


//theme
import "primereact/resources/themes/lara-light-teal/theme.css";
//core
import "primereact/resources/primereact.min.css";
import { GamesProvider } from './GamesProvider';
import { StatsOptionsProvider } from './components/stats_components/StatsOptionsProvider';


// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
      cacheTime: 1000 * 60 * 30, // Keep unused data in cache for 30 minutes
      retry: 1, // Only retry failed requests once
      refetchOnWindowFocus: true, // Disable automatic refetch on window focus
    },
  },
});

// Convert to functional component for better React practices
export default function App() {
  useEffect(() => {
    // Dynamically set the viewport meta tag
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1, maximum-scale=1';
    document.head.appendChild(meta);

    // Cleanup function to remove the meta tag when the component unmounts
    return () => {
      document.head.removeChild(meta);
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ConstantProvider>
          <GamesProvider>
            <StatsOptionsProvider>
              <Layout>
                <Routes>
                  {AppRoutes.map((route, index) => {
                    const { element, requiresAuth, requireSubscription, ...rest } = route;
                    return (
                      <Route
                        key={index}
                        {...rest}
                        element={
                          requiresAuth ? (
                            <ProtectedRoute requireSubscription={requireSubscription}>
                              {element}
                            </ProtectedRoute>
                          ) : (
                            element
                          )
                        }
                      />
                    );
                  })}
                </Routes>
              </Layout>
            </StatsOptionsProvider>
          </GamesProvider>
        </ConstantProvider>
      </AuthProvider>
      {/* Optional: Add React Query DevTools */}
      {/* {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />} */}
    </QueryClientProvider>
  );
}
