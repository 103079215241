/**
     * Gets rounds from database depending on user's selection.
     * @param {string} durationType - wheter time/number of rounds/custom/tournament
     * @param {int} timeDuration - how many months back
     * @param {int} numberOfRounds - how many last rounds
     * @param {string} roundType - type of rounds to get
     */
export function getRoundsCallback(userId, durationType, timeDuration, numberOfRounds, roundType, callback, jwt) {
    // TODO: Restrict data to userId of logged in user *and* coach

    var baseUrl = 'api/Calculation/'
    var endpoint = ''
    var query = `?userId=${userId}&roundType=${roundType}`
    if (durationType.toLowerCase() === "time") {
        endpoint = 'user-last-duration'
        query += `&timeType=month&duration=${timeDuration}`
    } else if (durationType.toLowerCase() === "number") {
        endpoint = 'user-last'
        query += `&numberOfRounds=${numberOfRounds}`
    }

    fetch(`${baseUrl}${endpoint}${query}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('No data found for requested period');
            }
            return response.json();
        })
        .then(data => {
            callback(data);
        })
        .catch(error => {
            console.error(error);
        });
}

export function getRoundsCallbackList(users, durationType, timeDuration, numberOfRounds, roundType, callback, jwt) {
    // TODO: Restrict data to userId of logged in user *and* coach
    var userIds = [];
    users.forEach(u => {
        userIds.push(u.userId.substring(u.userId.indexOf('|') + 1),)
    });
    var baseUrl = 'api/Calculation/'
    var endpoint = ''
    var query = `?userId=${userIds}&roundType=${roundType}`
    if (durationType.toLowerCase() === "time") {
        endpoint = 'list-last-duration'
        query += `&timeType=month&duration=${timeDuration}`
    } else if (durationType.toLowerCase() === "number") {
        endpoint = 'list-last'
        query += `&numberOfRounds=${numberOfRounds}`
    }

    fetch(`${baseUrl}${endpoint}${query}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('No data found for requested period');
            }
            return response.json();
        })
        .then(data => {
            callback(data);
        })
        .catch(error => {
            console.error(error);
        });
}

export function getRoundsCustomCallback(userID, startDate, endDate, roundType, callback, jwt) {
    fetch(`/api/Calculation/user-date-range?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}&userId=${userID}&roundType=${roundType}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('No rounds in that time span');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            callback(data)
        })
        .catch(error => {
            console.error(error);
        });
}

export function getRoundsCustomCallbackList(users, startDate, endDate, roundType, callback, jwt) {
    var userIds = [];
    users.forEach(u => {
        userIds.push(u.userId.substring(u.userId.indexOf('|') + 1),)
    });
    fetch(`/api/Calculation/list-date-range?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}&userId=${userIds}&roundType=${roundType}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('No rounds in that time span');
            }
            return response.json();
        })
        .then(data => {
            callback(data)
        })
        .catch(error => {
            console.error(error);
        });
}

export function getRoundsSemesterCallback(userID, startDate, endDate, roundType, callback, jwt) {
    fetch(`/api/Calculation/user-date-range?startDate=${startDate}&endDate=${endDate}&userId=${userID}&roundType=${roundType}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('No rounds in that time span');
            }
            return response.json();
        })
        .then(data => {
            callback(data)
        })
        .catch(error => {
            console.error(error);
        });
}
export function getRoundsSemesterCallbackList(users, startDate, endDate, roundType, callback, jwt) {
    var userIds = [];
    users.forEach(u => {
        userIds.push(u.userId.substring(u.userId.indexOf('|') + 1),)
    });
    fetch(`/api/Calculation/list-date-range?startDate=${startDate}&endDate=${endDate}&userId=${userIds}&roundType=${roundType}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('No rounds in that time span');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            callback(data)
        })
        .catch(error => {
            console.error(error);
        });
}