import { useConstant } from "../../../../ConstantContext";
import { useOptions } from "../../StatsOptionsProvider";
import { Table } from "reactstrap";
const OTTindividual = ({ state, data, isAdvanced }) => {
    const constants = useConstant();
    const options = useOptions();

    function renderOTTStrokesGained() {
        return (
            <div className="table-responsive">
                <Table id='argtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Total</th>
                            <th>Att. </th>
                            <th>Per Shot</th>
                            <th>Per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {options.sgTee.filter((e) => e.advanced === isAdvanced).map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`${stat.name}`]?.attempts > 0) &&
                                        (stat.name === "sgOTT" ?
                                            state[`sgOTT`]?.strokesGainedPerRound > constants.tourAvg[`sgOTT`].greenValue ||
                                            state[`sgOTT`]?.strokesGainedPerRound < constants.tourAvg[`sgOTT`].redValue
                                            :
                                            state[`${stat.name}`]?.strokesGainedPerShot > constants.sgPerShotUpper ||
                                            state[`${stat.name}`]?.strokesGainedPerShot < constants.sgPerShotLower) && 'white',
                                    backgroundColor: (state[`${stat.name}`]?.attempts > 0) &&
                                        (stat.name === "sgOTT" ?
                                            state[`sgOTT`]?.strokesGainedPerRound > constants.tourAvg[`sgOTT`].greenValue ? constants.green :
                                                state[`sgOTT`]?.strokesGainedPerRound < constants.tourAvg[`sgOTT`].redValue ? constants.red : ''
                                            :
                                            state[`${stat.name}`]?.strokesGainedPerShot > constants.sgPerShotUpper ? constants.green :
                                                state[`${stat.name}`]?.strokesGainedPerShot < constants.sgPerShotLower ? constants.red : '')
                                }}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]?.strokesGainedRounded}</td>
                                <td>{state[`${stat.name}`]?.attempts}</td>
                                <td>{state[`${stat.name}`]?.strokesGainedPerShot}</td>
                                <td>{state[`${stat.name}`]?.strokesGainedPerRoundRounded}</td>
                            </tr>
                        ))}


                    </tbody>
                </Table>
            </div >
        )
    }

    return (
        <div className="table-responsive">
            {renderOTTStrokesGained()}
            <Table id='teetable' className='stats-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Value</th>
                        <th>Count</th>
                        <th>Attempts</th>
                        <th>Tour average</th>
                    </tr>
                </thead>
                <tbody>
                    {options.teeStats.map((stat) => (
                        <tr key={`${stat.name}`}
                            style={{
                                color: (state[`${stat.name}`]?.attempts > 0) &&
                                    (constants.tourAvg[`${stat.name}.percentage`].low ?
                                        (state[`${stat.name}`]?.percentage < constants.tourAvg[`${stat.name}.percentage`].greenValue ||
                                            state[`${stat.name}`]?.percentage > constants.tourAvg[`${stat.name}.percentage`].redValue) && 'white' :
                                        (state[`${stat.name}`]?.percentage > constants.tourAvg[`${stat.name}.percentage`].greenValue ||
                                            state[`${stat.name}`]?.percentage < constants.tourAvg[`${stat.name}.percentage`].redValue) && 'white'),

                                backgroundColor: (state[`${stat.name}`]?.attempts > 0) &&
                                    (constants.tourAvg[`${stat.name}.percentage`].low ?
                                        (state[`${stat.name}`]?.percentage < constants.tourAvg[`${stat.name}.percentage`].greenValue ? constants.green :
                                            state[`${stat.name}`]?.percentage > constants.tourAvg[`${stat.name}.percentage`].redValue ? constants.red : '')
                                        :
                                        (state[`${stat.name}`]?.percentage > constants.tourAvg[`${stat.name}.percentage`].greenValue ? constants.green :
                                            state[`${stat.name}`]?.percentage < constants.tourAvg[`${stat.name}.percentage`].redValue ? constants.red : ''))
                            }}>
                            <td>{`${stat.display}`}</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]?.percentage}%</td>
                            <td>{state[`${stat.name}`]?.hitCount}</td>
                            <td>{state[`${stat.name}`]?.attempts}</td>
                            <td>{constants.tourAvg[`${stat.name}.percentage`]?.value}</td>
                        </tr>
                    ))}
                    {isAdvanced && options.clubsTee.map((stat) => (
                        <tr key={`${stat}`}
                            style={{
                                display: state[`ott${stat}Hit`]?.attempts > 0 ? '' : 'none'
                            }}>
                            <td>{`${stat}`}</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`ott${stat}Hit`]?.percentage}%</td>
                            <td>{state[`ott${stat}Hit`]?.hitCount}</td>
                            <td>{state[`ott${stat}Hit`]?.attempts}</td>
                            <td>--</td>
                        </tr>
                    ))}
                    {options.teeMiss.map((stat) => (
                        <tr key={`${stat.name}`}>
                            <td>{`${stat.display}`}</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]}%</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                        </tr>
                    ))}

                </tbody>

            </Table>
        </div>
    )
}
export default OTTindividual;