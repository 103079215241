import { React } from "react";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from 'reactstrap'
import { ShowCoachPages } from './ShowCoachPages';
import { ShowIndividualStats } from './ShowIndividualStats';
import { ShowTeamPractice } from './ShowTeamPractice';
import { ShowTeamRounds } from './ShowTeamRounds';
import { ShowToDateRounds } from "./ShowToDateRounds";
import { useAuth } from "../appwrite-testing/AuthContext";

const ShowCoachTools = () => {
    const { user, loading } = useAuth();

    // Check if user is a coach by looking at their labels
    const isCoach = user?.labels?.includes('coach');

    // Don't render anything while loading or if user isn't authenticated
    if (loading || !user) {
        return null;
    }

    return (
        <>
            {isCoach && (
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="text-light" nav>
                        Coach Tools
                    </DropdownToggle>
                    <DropdownMenu className="mb-3">
                        <DropdownItem><ShowIndividualStats /></DropdownItem>
                        <DropdownItem><ShowCoachPages /></DropdownItem>
                        <DropdownItem><ShowTeamRounds /></DropdownItem>
                        <DropdownItem><ShowTeamPractice /></DropdownItem>
                        <DropdownItem><ShowToDateRounds /></DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )}
        </>
    );
};

export { ShowCoachTools };

