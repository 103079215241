import React, { useState, useEffect } from "react";
import {
    Container, Row, Col, Table
} from "reactstrap";
import { Button } from "primereact/button";
import { SelectButton } from 'primereact/selectbutton'
import { useAuth0 } from "@auth0/auth0-react";
import { EditApproach } from "./EditApproach";
import { Message } from 'primereact/message'
const ApproachPracticeResult = ({ lowDistance, userId }) => {

    const { getJWT } = useAuth0();
    const [rounds, setRounds] = useState([]);
    const [scores, setScores] = useState([]);
    /**How many rounds should be shown on the table */
    const [numRoundsToShow, setNumRoundsToShow] = useState(10);
    const [lastScore, setLastScore] = useState(null);
    const [last5Score, setLast5Score] = useState(null);
    const [last10Score, setLast10Score] = useState(null);
    const [lowDistancePicked, setLowDistancePicked] = useState(75);
    const options = ["50-100y", "75-125y", "100-150y", "150-200y"];
    const [combinePicked, setCombinePicked] = useState("75-125y");
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(0);

    const [showMessage, setShowMessage] = useState(false);
    /**Formats the datae to mm/dd/yyyy */
    const formatDate = (date) => {
        const d = new Date(date);
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };
    /**Will increse the number of rounds shown in the table */
    const handleLoadMore = () => {
        setNumRoundsToShow(numRoundsToShow + 10);
    };

    async function getScores() {
        const jwt = await getJWT();
        fetch(`/api/practiceapproachcalculation/user-last?userId=${userId}&lowDistance=${lowDistancePicked}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
        })
            .then(response => response.json())
            .then(data => {
                setScores(data);
                setLastScore(data[0])
                setLast5Score(data.slice(0, 5).reduce((sum1, score) => sum1 + score, 0))
                setLast10Score(data.slice(0, 10).reduce((sum2, score) => sum2 + score, 0))
                setShowMessage(false)
            })
            .catch(error => setShowMessage(true));
    }

    async function getRounds() {
        const jwt = await getJWT();

        fetch(`/api/approachpractice/user?userId=${userId}&lowDistance=${lowDistancePicked}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
        })
            .then(response => response.json())
            .then(data => {
                setRounds(data);
                getScores();
                setShowMessage(false)
            })
            .catch(error => setShowMessage(true));
    }


    useEffect(() => {
        setRounds([])
        setScores([])
        setLastScore(null);
        setLast5Score(null);
        setLast10Score(null);
        getRounds();
    }, [lowDistancePicked])

    async function deleteCombine(id) {
        const jwt = await getJWT();

        const confirmDelete = window.confirm("Are you sure you want to delete this approach combine?");
        if (confirmDelete) {
            fetch(`/api/approachpractice/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                },
            })
                .then(response => response)
                .then(data => {
                    setRounds([])
                    setLastScore(null);
                    setLast5Score(null);
                    setLast10Score(null);
                    getRounds();
                })
                .catch(error => console.error(error));
        }
    }

    function selectCombine(value) {
        setCombinePicked(value);
        setLowDistancePicked(parseInt(value.split('-')[0]));
    }

    function editApproach(id) {
        setEditId(id)
        setEdit(true);
    }

    return (
        <div>
            {showMessage && <Message className="mb-3" severity="info" text="No practice sessions were found" />}
            {edit ?
                <EditApproach id={editId} setEdit={setEdit} />
                : <Container>
                    <Row >
                        <div className="p-fluid mb-3" style={{ display: "flex", justifyContent: "center", width: "100%", }} >
                            <SelectButton value={combinePicked} onChange={(e) => selectCombine(e.value)} options={options} style={{ display: "flex", justifyContent: "center", width: "90%", }} />
                        </div>
                    </Row>
                    <Row>
                        <Col md="12" lg="12">
                            <Table striped bordered >
                                <thead>
                                    <tr>
                                        <th>Last SG</th>
                                        <th>Last 5 SG avg. </th>
                                        <th>Last 10 SG avg.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td className={lastScore ? "" : "loading-cell"}>
                                            {lastScore}
                                        </td>
                                        <td className={last5Score ? "" : "loading-cell"}>
                                            {last5Score}
                                        </td>
                                        <td className={last10Score ? "" : "loading-cell"}>
                                            {last10Score}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="table-responsive">
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Strokes Gained</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {rounds.length > 0 ? (
                                        <tbody>
                                            {rounds.slice(0, numRoundsToShow).map((round, i) => (
                                                <tr key={round.id}>
                                                    <td>{formatDate(round.date)}</td>
                                                    <td>{scores[i]}</td>
                                                    <td><Button label="Edit" icon="pi pi-file-edit" size="small" severity="secondary" onClick={() => editApproach(round.id)} /></td>
                                                    <td>
                                                        <Button label="Delete" icon="pi pi-trash" size="small" severity="danger" onClick={() => deleteCombine(round.id)} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>) : (<tbody>
                                            <tr >
                                                <td className="loading-cell"></td>
                                                <td className="loading-cell"></td>
                                                <td className="loading-cell"></td>
                                                <td className="loading-cell"></td>
                                            </tr>
                                        </tbody>)}

                                </Table>
                            </div>
                        </Col>
                        <Col lg="12">
                            {numRoundsToShow < rounds.length && (
                                <div className="d-flex justify-content-center">
                                    <Button style={{ width: 300 }} color="secondary" onClick={handleLoadMore}>
                                        Load More
                                    </Button>
                                </div>
                            )}
                        </Col>
                    </Row >
                </Container >}
        </div>

    )
}

export { ApproachPracticeResult }