import { MultiSelect } from "primereact/multiselect"
const Facilities = ({ selectedFacilities, setSelectedFacilities, statsType }) => {
    const facilitiesAdvanced = ["Range Driver", "Range Woods", "Range Irons", "Short Game Area", "Putting Green"];
    const facilities = ["Range Driver", "Range Irons", "Short Game Area", "Putting Green"];
    return (
        <div className="card flex justify-content-center mt-2">
            <MultiSelect
                options={statsType !== "Basic" ? facilitiesAdvanced : facilities}
                value={selectedFacilities}
                onChange={(e) => setSelectedFacilities(e.value)}
                display="chip"
                className="m-2"
            />
        </div>
    )
}
export default Facilities;