import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { useLocation } from 'react-router-dom';
import { useAuth } from './appwrite-testing/AuthContext';
import '../css/Layout.css';

const LoadingScreen = () => (
  <div style={{
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5'
  }}>
    <div className="loading-spinner">
      Loading...
    </div>
  </div>
);

const Layout = ({ children }) => {
  const location = useLocation();
  const { loading, user } = useAuth();
  const isAuthPage = ['/login', '/signup'].includes(location.pathname);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      {!isAuthPage && <NavMenu />}
      <Container className={!isAuthPage ? "mt-5" : ""} tag="main" fluid={isAuthPage}>
        {children}
      </Container>
    </div>
  );
};

export { Layout };
