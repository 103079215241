import { Client, Account,} from 'appwrite';

export const client = new Client();

client
    .setEndpoint(process.env.REACT_APP_APPWRITE_URL)
    .setProject('671e628a003e53f0c494');

export const account = new Account(client);
export { ID , OAuthProvider} from 'appwrite';
