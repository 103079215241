import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import "../../css/ButtonGroup.css";
import "../../css/add-round.css";
import { useAuth } from "../appwrite-testing/AuthContext"; // Update path as needed
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Badge } from 'primereact/badge';

import { SelectButton } from 'primereact/selectbutton';

import { useConstant } from '../../ConstantContext';
import { ChipDifficultyInfo, ForcedLayupInfo, GfOppInfo, RecoveryDifficultyInfo } from "./InfoTooltips";
import { GetBag, GetBaseline, GetUnits } from "./GetUserInfoDB";
import ClubsDropdown from "./ClubsDropdown";
const AddRound2 = ({ roundId, teeSelect, units }) => {
    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const { user, getJWT } = useAuth(); // Replace Auth0 hook with Appwrite AuthContext
    const userId = user?.$id; // Use Appwrite user ID
    const urlParams = new URLSearchParams(window.location.search);
    const [jwt, setJWT] = useState(undefined);

    const [shotsArray, setShotsArray] = useState({
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
        13: [],
        14: [],
        15: [],
        16: [],
        17: [],
        18: []
    }
    );
    var [currentIndex, setCurrentIndex] = useState(0);
    const [endOfRound, setEndOfRound] = useState(false);

    const { yardsToMeters, metersToYards } = useConstant();
    /** selected tee from the database*/
    const [selectedTee, setSelectedTee] = useState(teeSelect ? teeSelect : null);
    /** Clubs for the beggining of the round*/
    const clubs = ["Driver", "3w", "5w", "7w", "3h", "4h", "5h", "2i", "3i", "4i", "5i", "6i",
        "7i", "8i", "9i", "PW", "GW", "SW", "LW"];
    const [bag, setBag] = useState({})//used for sugesting clubs
    const [bagArr, setBagArr] = useState(clubs)//used for options in the dropdown
    const [userUnits, setUserUnits] = useState("");
    const [userBaseline, setUserBaseline] = useState("");


    const mentals = [{ value: "good", label: "Good" }, { value: "technique", label: "Technique" },
    { value: "mental state", label: "Mental State" }, { value: "decision", label: "Decision" }];


    const difficultyOptions = ["easy", "medium", "hard"];
    /**Possible next lie for approaches */
    const liesApp = ["fairway", "recovery", "rough", "fringe", "bunker", "green"];
    /**Possible misses off the tee */
    const dirTee = [
        { miss: "left", icon: "pi pi-arrow-left", pos: "left", display: "Left" },
        { miss: "long/short", icon: "pi pi-arrows-v", pos: "top", display: "Long/Short" },
        { miss: "right", icon: "pi pi-arrow-right", pos: "right", display: "Right" }];
    /**Possible misses for approaches */
    const dirApp = [
        { miss: "long left", icon: "pi pi-arrow-up-left", pos: "left", display: "Left" },
        { miss: "long", icon: "pi pi-arrow-up", pos: "top", display: "Long" },
        { miss: "long right", icon: "pi pi-arrow-up-right", pos: "right", display: "Long Right" },
        { miss: "left", icon: "pi pi-arrow-left", pos: "left", display: "Left" },
        { miss: "in the hole", icon: "pi pi-flag", pos: "bottom", display: "In the Hole!" },
        { miss: "right", icon: "pi pi-arrow-right", pos: "right", display: "Right" },
        { miss: "short left", icon: "pi pi-arrow-down-left", pos: "left", display: "Short Left" },
        { miss: "short", icon: "pi pi-arrow-down", pos: "bottom", display: "Short" },
        { miss: "short right", icon: "pi pi-arrow-down-right", pos: "right", display: "Short Right" }
    ];
    /**Possible misses for putting */
    const dirPutt = [
        { miss: "high", icon: "pi pi-align-justify", pos: "left", display: "High" },
        { miss: "in the hole", icon: "pi pi-flag", pos: "bottom", display: "In the Hole!" },
        { miss: "low", icon: "pi pi-minus", pos: "right", display: "Low" },
        { miss: "high short", icon: "pi pi-align-justify", pos: "left", display: "High Short" },
        { miss: "short", icon: "pi pi-arrow-down", pos: "bottom", display: "Short" },
        { miss: "low short", icon: "pi pi-minus", pos: "right", display: "Low Short" }
    ];

    /**Possible pin positions */
    const pinPos = [
        { miss: "back left", icon: "pi pi-arrow-up-left", pos: "left", display: "Back Left" },
        { miss: "back center", icon: "pi pi-arrow-up", pos: "top", display: "Back Center" },
        { miss: "back right", icon: "pi pi-arrow-up-right", pos: "right", display: "Back Right" },
        { miss: "left", icon: "pi pi-arrow-left", pos: "left", display: "Left" },
        { miss: "middle", icon: "pi pi-plus-circle", pos: "bottom", display: "Middle" },
        { miss: "right", icon: "pi pi-arrow-right", pos: "right", display: "Right" },
        { miss: "front left", icon: "pi pi-arrow-down-left", pos: "left", display: "Front Left" },
        { miss: "front center", icon: "pi pi-arrow-down", pos: "bottom", display: "Front Center" },
        { miss: "front right", icon: "pi pi-arrow-down-right", pos: "right", display: "Front Right" }
    ];
    const [currentHole, setCurrentHole] = useState(1);
    const [currentShot, setCurrentShot] = useState({
        shotTotal: 1,
        shotHole: 1,
        parHole: teeSelect != undefined ? teeSelect?.holePar[1] : 4,
        holeNo: 1,
        shotDst: teeSelect != undefined ? (userUnits === "meters" ? Math.round(teeSelect.holeDist[1] * yardsToMeters) : teeSelect.holeDist[1]) : "",
        lie: "tee",
        club: "Driver",
        mental: "good",
        missDst: "",
        missDir: "",
        penalty: false,
        layup: false,
        shortSided: false,
        difficulty: "medium",
        pinDst: "",
        pinDir: "",
        gfOpp: false,
        forcedLayupDistance: 0,
    });
    const [forcedLayup, setForcedLayup] = useState(false);

    const [nextLie, setNextLie] = useState("")

    const [localRoundId, setLocalRoundId] = useState(roundId);

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    async function getCourse() {
        fetch(`/api/Course?id=${urlParams.get('courseId')}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            }
        })
            .then(response => {
                return response.json();
            }).then(data => {
                data.teeSet.forEach(tee => {
                    if (tee.teeName === urlParams.get('tee')) {
                        setSelectedTee(tee);
                        if (urlParams.get('roundId') !== null) {
                            setLocalRoundId(urlParams.get('roundId'));
                            // setWasContinue(true);
                        }
                        getRound();
                    }
                });
            })
            .catch(error => {
                console.error(error);
            });
    }

    async function getRound() {
        fetch(`/api/Round/id?id=${urlParams.get('roundId')}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            }
        })
            .then(response => {
                return response.json();
            }).then(data => {
                organizeShots(data[0].shots)
            })
    }

    const organizeShots = (shots) => {
        let newShotsArray = {};

        shots.forEach(shot => {
            let holeNo = shot.holeNo;

            // Ensure there's an array for this holeNo in newShotsArray
            if (!newShotsArray[holeNo]) {
                newShotsArray[holeNo] = [];
            }

            // Add the current shot to the appropriate array
            newShotsArray[holeNo].push(shot);
        });

        // Update the state with the new shotsArray
        setShotsArray(newShotsArray);
        setCurrentShot({
            ...shots[shots.length - 1],
            shotDst: shots[shots.length - 1].lie !== "green" && userUnits === "meters" ? Math.round(shots[shots.length - 1].shotDst * yardsToMeters) : shots[shots.length - 1].shotDst,
            forcedLayupDistance: shots[shots.length - 1].lie !== "green" && userUnits === "meters" ? Math.round(shots[shots.length - 1].forcedLayupDistance * yardsToMeters) : shots[shots.length - 1].forcedLayupDistance,
        });
        setCurrentHole(shots[shots.length - 1].holeNo);
        var i = 0;
        newShotsArray[shots[shots.length - 1].holeNo].forEach((s, index) => {
            i = index;
        })
        setCurrentIndex(i);
        setNextLie(newShotsArray[shots[shots.length - 1].holeNo].length > 0 ? newShotsArray[shots[shots.length - 1].holeNo][1] : "")

    };
    async function getsetJWT() {
        setJWT(await getJWT());
    }
    //get the bag, units and baseline at the beggining
    useEffect(() => {
        getsetJWT();
    }, [])
    useEffect(() => {
        if (jwt && user.$id != undefined) {
            if (urlParams.get('roundId') != null) {
                getCourse();
            }
            bagSetup();
            unitsSetup();
            baselineSetup();

        }
    }, [jwt, user])

    useEffect(() => {
        setCurrentShot((prevData) => ({
            ...prevData,
            shotDst: currentShot.shotDst ? (userUnits === "meters" ? Math.round(currentShot.shotDst * yardsToMeters) : currentShot.shotDst) : null
        }))
    }, [userUnits])

    function bagSetup() {
        var bagtmp = JSON.parse(window.localStorage.getItem("bag"));
        if (bagtmp) {
            var tmp = {};
            var tmpArr = []
            Object.keys(bagtmp).forEach((key) => {
                if (bagtmp[key] !== 0) {
                    tmp[key] = bagtmp[key];
                    tmpArr.push(key)
                }
            })
            setBagArr(tmpArr.reverse())
            setBag(tmp)
        } else {
            GetBag(setBag, setBagArr, jwt, user.$id);
        }
    }
    function unitsSetup() {
        var unitstmp = window.localStorage.getItem("units");
        if (unitstmp) {
            setUserUnits(unitstmp)
        } else {
            GetUnits(setUserUnits, jwt, user.$id)
        }
    }
    function baselineSetup() {
        var baselinetmp = window.localStorage.getItem("baseline");
        if (baselinetmp) {
            setUserBaseline(baselinetmp);
        } else {
            GetBaseline(setUserBaseline, jwt, user.$id)
        }
    }

    //if the round is continued get Id from the url param. otherwise it's passed from the CreateRound component
    useEffect(() => {
        if (urlParams.get('roundId') !== null)
            setLocalRoundId(urlParams.get('roundId'))
        else if (roundId)
            setLocalRoundId(roundId)
    }, [roundId]);



    /** Values of cells on the scorecard*/
    const [cellValues, setCellValues] = useState(Array(18).fill(0));
    /** total score on front 9 for the scorecard*/
    const frontTotal = cellValues.slice(0, 9).reduce((a, b) => a + b, 0);
    /** total score on back 9 for the scorecard*/
    const backTotal = cellValues.slice(9).reduce((a, b) => a + b, 0);
    /** Colors of cells for the scorecard*/
    const [cellStyles, setCellStyles] = useState(Array(18).fill({ backgroundColor: "white" }));


    function handleScorecardChange() {
        Object.keys(shotsArray).forEach((holeNo) => {
            const shots = shotsArray[holeNo];
            shots.forEach((s) => {
                setCellValues((prevValues) => {
                    const newValues = [...prevValues];
                    newValues[holeNo - 1] = s.shotHole;
                    return newValues;
                });

                let color = "white";
                let text = "black";

                const diff = s.shotHole - s.parHole;
                if (diff === 1) {
                    color = "green";
                } else if (diff === 2) {
                    color = "blue";
                    text = "white";
                } else if (diff >= 3) {
                    color = "black";
                    text = "white";
                } else if (diff === -1) {
                    color = "red";
                    text = "white";
                } else if (diff <= -2) {
                    color = "yellow";
                }

                setCellStyles((prevValues) => {
                    const newValues = [...prevValues];
                    newValues[holeNo - 1] = { backgroundColor: color, color: text };
                    return newValues;
                });
            });
        });

    }
    /**
  * Render scorecard with pars taken from the database
  * @returns Table
  */
    function renderScorecard() {
        return (
            <div className="table-responsive">
                <Table style={{ textAlign: "center" }}>
                    <thead>
                        <tr>
                            <th>Hole</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>9</th>
                            <th>Front</th>
                            <th>10</th>
                            <th>11</th>
                            <th>12</th>
                            <th>13</th>
                            <th>14</th>
                            <th>15</th>
                            <th>16</th>
                            <th>17</th>
                            <th>18</th>
                            <th>Back</th>
                            <th>Total</th>
                        </tr>
                        <tr>
                            <th>PAR</th>
                            {
                                Object.keys(selectedTee.holePar).slice(0, 9).map((rowy, indy) => (
                                    <td>
                                        {selectedTee.holePar[indy + 1]}
                                    </td>
                                ))
                            }

                            <td>{selectedTee.holeParOutTotal}</td>

                            {
                                Object.keys(selectedTee.holePar).slice(9, 18).map((rowy, indy) => (
                                    <td>
                                        {selectedTee.holePar[indy + 10]}
                                    </td>
                                ))
                            }
                            <td>{selectedTee.holeParInTotal}</td>
                            <td>{selectedTee.holeParTotal}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th id="score">Score</th>
                            <td style={cellStyles[0]}>{cellValues[0]}</td>
                            <td style={cellStyles[1]}>{cellValues[1]}</td>
                            <td style={cellStyles[2]}>{cellValues[2]}</td>
                            <td style={cellStyles[3]}>{cellValues[3]}</td>
                            <td style={cellStyles[4]}>{cellValues[4]}</td>
                            <td style={cellStyles[5]}>{cellValues[5]}</td>
                            <td style={cellStyles[6]}>{cellValues[6]}</td>
                            <td style={cellStyles[7]}>{cellValues[7]}</td>
                            <td style={cellStyles[8]}>{cellValues[8]}</td>
                            <td id="front">{frontTotal}</td>
                            <td style={cellStyles[9]}>{cellValues[9]}</td>
                            <td style={cellStyles[10]}>{cellValues[10]}</td>
                            <td style={cellStyles[11]}>{cellValues[11]}</td>
                            <td style={cellStyles[12]}>{cellValues[12]}</td>
                            <td style={cellStyles[13]}>{cellValues[13]}</td>
                            <td style={cellStyles[14]}>{cellValues[14]}</td>
                            <td style={cellStyles[15]}>{cellValues[15]}</td>
                            <td style={cellStyles[16]}>{cellValues[16]}</td>
                            <td style={cellStyles[17]}>{cellValues[17]}</td>
                            <td id="back">{backTotal}</td>
                            <td id="total">{cellValues.reduce((sum, value) => sum + value)}</td>
                        </tr>
                    </tbody>

                </Table>
            </div>
        )
    }

    /**
   * Renders next/previous hole/shot buttons.
   * On last hole changes next hole to finish round.
   * @returns Buttons
   */
    function renderButtons() {
        return (
            <div className="p-buttonset mt-2" style={{ display: "flex", flexDirection: "row" }} >
                {currentShot.holeNo !== 1 && <Button label="Prev Hole" icon="pi pi-fast-backward" severity="secondary" iconPos="bottom" style={{ margin: "2px" }} disabled={currentShot.holeNo === 1} id="prevShot" onClick={prevHoleListiner} />}
                {currentShot.shotHole !== 1 && <Button label="Prev Shot" icon="pi pi-caret-left" severity="secondary" iconPos="bottom" style={{ margin: "2px" }} disabled={currentShot.shotHole === 1} id="prevShot" onClick={prevShotListiner} />}
                <Button label="Next Shot" icon="pi pi-caret-right" iconPos="bottom" severity="secondary" style={{ margin: "2px" }} disabled={!isInputValid} onClick={nextShotListiner} id="nextShot" />
                <Button label="Tap-in" icon="pi pi-forward" iconPos="bottom" severity="secondary" style={{ margin: "2px" }} disabled={!isValidTapIn} onClick={tapInListiner} id="nextHole" />
                {currentShot.holeNo !== 18 && shotsArray[currentShot.holeNo + 1]?.length > 0 && <Button label="Next Hole" icon="pi pi-fast-forward" iconPos="bottom" severity="secondary" style={{ margin: "2px" }} disabled={!shotsArray[currentShot.holeNo + 1]?.length > 0} onClick={nextHoleListiner} id="nextHole" />}
            </div>
        )

    }
    var tapInJSON = {
        shotTotal: currentShot.shotTotal + 1,
        shotHole: currentShot.shotHole + 1,
        parHole: currentShot.parHole,
        holeNo: currentShot.holeNo,
        shotDst: Math.floor(Math.random() * 2 + 1),
        lie: "green",
        club: "",
        mental: "good",
        missDst: "",
        missDir: "",
        penalty: false,
        layup: false,
        shortSided: false,
        difficulty: "",
        pinDst: currentShot.pinDst,
        pinDir: currentShot.pinDir
    };

    useEffect(() => {
        console.log(shotsArray)
        updateShots();
        handleScorecardChange();
    }, [shotsArray])

    /**
   * Adds the shot to the database
   */
    function addShot(wasTapIn) {
        setShotsArray((prevShotsArray) => {
            // Clone the shots array for the current hole and add/replace the shot at currentIndex
            const currentHoleShots = prevShotsArray[currentShot.holeNo] || [];
            const newShotsArray = [
                ...currentHoleShots.slice(0, currentIndex),
                {
                    ...currentShot,
                    shotDst: currentShot.lie !== "green" && userUnits === "meters"
                        ? Math.round(currentShot.shotDst * metersToYards)
                        : currentShot.shotDst,
                    forcedLayupDistance: userUnits === "meters" ? Math.round(currentShot.forcedLayupDistance * metersToYards) : currentShot.forcedLayupDistance,
                },
                ...(wasTapIn ? [tapInJSON] : []),
                ...currentHoleShots.slice(currentIndex + 1)
            ];


            // Update the current hole's shots
            const updatedShotsArray = {
                ...prevShotsArray,
                [currentShot.holeNo]: newShotsArray
            };

            // Validate and correct shotTotal for all holes
            const validatedShotsArray = {};

            let expectedShotTotal = 1;
            for (const hole in updatedShotsArray) {
                if (updatedShotsArray[hole].length > 0) {
                    validatedShotsArray[hole] = updatedShotsArray[hole].map(shot => {
                        // Correct the shotTotal based on expectedShotTotal
                        const correctedShot = {
                            ...shot,
                            shotTotal: expectedShotTotal
                        };

                        // Adjust the expectedShotTotal for the next shot
                        expectedShotTotal += shot.penalty ? 2 : 1;

                        return correctedShot;
                    });
                } else {
                    validatedShotsArray[hole] = []
                }
            }
            return validatedShotsArray;
        });
    }




    async function updateShots() {
        const combinedArray = Object.values(shotsArray).flat();
        console.log(endOfRound)
        if (combinedArray.length > 0) {
            const Sjwt = await getJWT();
            fetch(`/api/round/update-shots/${localRoundId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Sjwt}`
                },
                body: JSON.stringify(combinedArray)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to add item to array');
                    }
                    return response;
                }).then(data => {
                    if (endOfRound) {
                        addScore(combinedArray)
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }

    }

    async function addScore(array) {
        var score = array[array.length - 1].shotTotal;
        const Sjwt = await getJWT();
        fetch(`/api/round/add-score/${localRoundId}?score=${score}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Sjwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to add score');
                } else {
                    window.location.href = `/round-stats/${localRoundId}`;
                    return response;
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    function prevHoleListiner() {
        setCurrentShot({
            ...shotsArray[currentHole - 1][0],
            shotDst: currentShot.lie !== "green" && userUnits === "meters" ? Math.round(shotsArray[currentHole][currentIndex + 1].shotDst * yardsToMeters) : shotsArray[currentHole][currentIndex + 1].shotDst,
            forcedLayupDistance: userUnits === "meters" ? Math.round(shotsArray[currentHole][currentIndex + 1].forcedLayupDistance * yardsToMeters) : shotsArray[currentHole][currentIndex + 1].forcedLayupDistance
        });
        setNextLie(shotsArray[currentHole - 1][1] ? shotsArray[currentHole - 1][1].lie : "");
        setCurrentIndex(0);
        setCurrentHole(currentHole - 1)
    }
    function prevShotListiner() {
        setNextLie(currentShot.lie)
        setCurrentShot(shotsArray[currentHole][currentIndex - 1])
        setCurrentIndex(currentIndex - 1);
    }
    function nextShotListiner() {
        addShot(false);
        resetCurrentShotNextShot();
        setNextLie(shotsArray[currentHole] && shotsArray[currentHole][currentIndex + 2]
            ? shotsArray[currentHole][currentIndex + 2].lie
            : "");
        setCurrentIndex(currentIndex + 1);
    }
    function resetCurrentShotNextShot() {
        if (shotsArray[currentHole] != undefined && shotsArray[currentHole][currentIndex + 1] != undefined)
            setCurrentShot({
                ...shotsArray[currentHole][currentIndex + 1],
                lie: nextLie,
                shotDst: currentShot.lie !== "green" && userUnits === "meters" ? Math.round(shotsArray[currentHole][currentIndex + 1].shotDst * yardsToMeters) : shotsArray[currentHole][currentIndex + 1].shotDst,
                forcedLayupDistance: userUnits === "meters" ? Math.round(shotsArray[currentHole][currentIndex + 1].forcedLayupDistance * yardsToMeters) : shotsArray[currentHole][currentIndex + 1].forcedLayupDistance
            })
        else
            setCurrentShot({
                shotTotal: currentShot.shotTotal + (currentShot.penalty ? 2 : 1),
                shotHole: currentShot.shotHole + (currentShot.penalty ? 2 : 1),
                parHole: selectedTee.holePar[currentShot.holeNo],
                holeNo: currentShot.holeNo,
                shotDst: currentShot.lie === "tee" && currentShot.penalty && nextLie === "tee" ? currentShot.shotDst : null,
                lie: currentShot.lie === "green" ? "green" : nextLie,
                club: "",
                mental: "good",
                missDst: "",
                missDir: "",
                penalty: false,
                layup: nextLie === "recovery" ? true : false,
                shortSided: false,
                difficulty: "medium",
                pinDst: currentShot.pinDst,
                pinDir: currentShot.pinDir,
                gfOpp: false,
                forcedLayupDistance: 0,
            })

    }

    function nextHoleListiner() {
        setCurrentHole(currentHole + 1)
        resetCurrentShotNextHole();
        setCurrentIndex(0);
        setNextLie(shotsArray[currentHole + 1]?.length > 1 ? shotsArray[currentHole + 1][1].lie : "");
    }

    function ithListiner() {
        setEndOfRound(currentShot.holeNo === 18);
        addShot(false);
        if (currentShot.holeNo !== 18) {
            resetCurrentShotNextHole(false);
            setNextLie(shotsArray[currentHole + 1]?.length > 1 ? shotsArray[currentHole + 1][1].lie : "");
            setCurrentIndex(0);
            setCurrentHole(currentHole + 1)
        }

    }
    function tapInListiner() {
        setEndOfRound(currentShot.holeNo === 18);
        addShot(true);
        if (currentShot.holeNo !== 18) {
            setCurrentHole(currentHole + 1)
            resetCurrentShotNextHole(true);
            setCurrentIndex(0);
            setNextLie(shotsArray[currentHole + 1]?.length > 1 ? shotsArray[currentHole + 1][1].lie : "");
        }
    }
    function resetCurrentShotNextHole(tapIn) {
        if (shotsArray[currentHole + 1]?.length > 0) {
            setCurrentShot({
                ...shotsArray[currentHole + 1][0],
                shotDst: currentShot.lie !== "green" && userUnits === "meters" ? Math.round(shotsArray[currentHole + 1][0].shotDst * yardsToMeters) : shotsArray[currentHole + 1][0].shotDst,
                forcedLayupDistance: userUnits === "meters" ? Math.round(shotsArray[currentHole + 1][0].forcedLayupDistance * yardsToMeters) : shotsArray[currentHole + 1][0].forcedLayupDistance
            })
        } else {
            setCurrentShot({
                shotTotal: currentShot.shotTotal + (currentShot.penalty ? 2 : 1) + (tapIn ? 1 : 0),
                shotHole: 1,
                parHole: selectedTee.holePar[currentShot.holeNo + 1],
                holeNo: currentShot.holeNo + 1,
                shotDst: selectedTee.holePar[currentShot.holeNo + 1] === 3 ? null : userUnits === "meters" ? Math.round(selectedTee.holeDist[currentShot.holeNo + 1] * yardsToMeters) : selectedTee.holeDist[currentShot.holeNo + 1],
                lie: "tee",
                club: "Driver",
                mental: "good",
                missDst: "",
                missDir: "",
                penalty: false,
                layup: false,
                shortSided: false,
                difficulty: "medium",
                pinDst: "",
                pinDir: "",
                gfOpp: false,
                forcedLayupDistance: 0,
            })
        }
    }

    /**
   * When user is inputting distance it:
   * 1. checks if it's a number
   * 2. if value is less than 50 and is not a putt it disables uneccessary buttons,
   * and will try to guess what club will be hit if value is more than 50
   * 3. checks that if current lie is green it will disable mistake button.
   * @param {*} value 
   */
    function handleInput(value) {
        setCurrentShot((prevData) => ({
            ...prevData,
            shotDst: value
        }))
    };

    useEffect(() => {
        if (bag) {
            bagGuess(currentShot.shotDst);
        }
    }, [currentShot.shotDst, bag])

    function bagGuess(dst) {
        const possibleClubs = ["LW", "SW", "GW", "PW", "9i", "8i", "7i", "6i", "5i", "4i", "3i", "2i", "5h", "4h", "3h", "7w", "5w", "3w", "Driver"];
        let clubGuess;
        for (let c of possibleClubs) {
            if (currentShot.lie !== "green" && currentShot.shotDst > 50) {
                if (bag[c] !== 0 && dst <= (bag[c] + 3)) {
                    clubGuess = c
                    break; // Exit the loop
                }
            }
        }

        if (clubGuess)
            setCurrentShot((prevData) => ({
                ...prevData,
                club: clubGuess
            }));
    }


    /**
   * Depending on shot type it renders appropriate buttons for next lie. 
   * Doesn't render anything if putting
   * @returns Buttons
   */
    function renderLies() {
        if (currentShot.lie !== "green") {
            return (
                <div className="p-fluid" style={{ width: "90%" }}>
                    <label>Resulting Lie:</label>
                    {liesApp.reduce((rows, lie, i) => {
                        return (
                            i % 3 === 0
                                ? [...rows, [lie]]
                                : [...rows.slice(0, -1), [...rows.slice(-1)[0], lie]]
                        );
                    }, []).map((row, i) => {
                        return (
                            <div className="mb-3 p-buttonset" key={i}>
                                {row.map((lie) => {
                                    return (
                                        <Button
                                            severity={lie === nextLie ? 'primary' : 'secondary'}
                                            key={lie}
                                            value={lie}
                                            label={capitalizeFirstLetter(lie)}
                                            size="small"
                                            onClick={() => handleNextLie(lie)}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                    {currentShot.lie === "tee" && <Button
                        severity={"tee" === nextLie ? 'primary' : 'secondary'}
                        key={"tee"}
                        value={"tee"}
                        label={"Tee"}
                        size="small"
                        style={{ maxWidth: "33.3%", marginLeft: "33.3%" }}
                        onClick={() => handleNextLie("tee")}
                    />}
                </div>
            );
        }
    }

    function handleNextLie(lie) {
        setNextLie(lie);
        if (lie === "tee") {
            setCurrentShot((prevData) => ({
                ...prevData,
                penalty: true
            }))
        }
    }

    /**
   * Renders possible misses depending on the shot type
   * @returns Buttons
   */
    function renderMiss() {
        if (currentShot.lie === "tee" && currentShot.parHole !== 3) {
            return (
                <div className="p-fluid mb-3">
                    <label>Miss: </label>
                    <div className="p-buttonset" >
                        {dirTee.map((dir) => (
                            <Button size="small" label={dir.display}
                                disabled={nextLie === "fairway" || nextLie === "green" || nextLie === "fringe"}
                                onClick={() => handleDirMiss(dir.miss)} severity={dir.miss === currentShot.missDir ? 'primary' : 'secondary'}
                                key={dir.miss} value={dir.miss}
                                icon={dir.icon} iconPos={dir.pos} />
                        ))}
                    </div>
                </div>
            )
        } else if (currentShot.lie === "fringe") {
            return (<div className="p-fluid mb-3">
                <label>Miss: </label>
                <Button
                    /* disabled={isMissDisabled} */
                    onClick={() => handleMiss("in the hole")}
                    severity="secondary"
                    key={"in the hole"}
                    value={"in the hole"}
                    size="small"
                    label={"In the Hole"}
                    icon={"pi pi-flag"}
                    iconPos={"bottom"}
                    disabled={!isValidITH}
                /></div>)
        }
        else if (currentShot.lie === "recovery" ||
            (currentShot.lie !== "tee" && currentShot.lie !== "green" && currentShot.lie !== "fringe" &&
                currentShot.shotDst < 50)) {
            return (renderDifficulty())
        }
        else if (currentShot.lie === "green") {
            return (
                <div className="p-fluid mb-3">
                    <label>Miss: </label>
                    {
                        dirPutt.reduce((rows, lie, i) => {
                            return (
                                i % 3 === 0
                                    ? [...rows, [lie]]
                                    : [...rows.slice(0, -1), [...rows.slice(-1)[0], lie]]
                            );
                        }, []).map((row, i) => {
                            return (
                                <div className="mb-3 p-buttonset" key={i}>
                                    {row.map((dir) => {
                                        return (
                                            <Button
                                                onClick={() => handleMiss(dir.miss)}
                                                severity={dir.miss === buildFullMiss(currentShot.missDir, currentShot.missDst) ? 'primary' : 'secondary'}
                                                key={dir.miss}
                                                value={dir.miss}
                                                label={dir.display}
                                                icon={dir.icon}
                                                iconPos={dir.pos}
                                                size="small"
                                                disabled={dir.miss === "in the hole" && !isValidITH}
                                            />
                                        );
                                    })}
                                </div>
                            );
                        })}
                </div>
            )
        }
        else if (!currentShot.layup) {
            return (
                <div className="p-fluid mb-3">
                    <label>Miss: </label>
                    {dirApp.reduce((rows, dir, i) => {
                        return (
                            i % 3 === 0
                                ? [...rows, [dir]]
                                : [...rows.slice(0, -1), [...rows.slice(-1)[0], dir]]
                        );
                    }, []).map((row, i) => {
                        return (
                            <div className="mb-3 p-buttonset" key={i}>
                                {row.map((dir) => {
                                    return (
                                        <Button
                                            onClick={() => handleMiss(dir.miss)}
                                            severity={dir.miss === buildFullMiss(currentShot.missDir, currentShot.missDst) ? 'primary' : 'secondary'}
                                            key={dir.miss}
                                            value={dir.miss}
                                            size="small"
                                            label={dir.display}
                                            icon={dir.icon}
                                            iconPos={dir.pos}
                                            disabled={dir.miss === "in the hole" && !isValidITH}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            );
        }

    }

    function buildFullMiss(dir, dst) {
        console.log(dst !== "" ? (dst + " " + dir) : dir)
        if (currentShot.lie === "green") {
            return dir !== "" ? (dst === "" ? dir : dir + " " + dst) : dst;
        }
        else
            if (dir === "")
                return dst;
            else
                return dst !== "" ? (dst + " " + dir) : dir;
    }
    /**
       * Depending on the button clicked sets direction and distance miss
       * @param {*} miss 
       */
    function handleMiss(miss) {
        switch (miss) {
            case "high":
                handleDirMiss("high");
                handleDstMiss("");
                break;
            case "high short":
                handleDirMiss("high");
                handleDstMiss("short");
                break;
            case "long":
                handleDstMiss("long");
                handleDirMiss("");
                break;
            case "in the hole":
                handleDstMiss("");
                handleDirMiss("");
                if (currentShot.shotDst) {
                    ithListiner();
                }
                break;
            case "short":
                handleDstMiss("short");
                handleDirMiss("");
                break;
            case "low":
                handleDirMiss("low");
                handleDstMiss("");
                break;
            case "low short":
                handleDirMiss("low");
                handleDstMiss("short");
                break;
            case "long left":
                handleDirMiss("left");
                handleDstMiss("long");
                break;
            case "left":
                handleDirMiss("left");
                handleDstMiss("");
                break;
            case "short left":
                handleDirMiss("left");
                handleDstMiss("short");
                break;
            case "long right":
                handleDirMiss("right");
                handleDstMiss("long");
                break;
            case "right":
                handleDirMiss("right");
                handleDstMiss("");
                break;
            case "short right":
                handleDirMiss("right");
                handleDstMiss("short");
                break;
            default:
                handleDirMiss("");
                handleDstMiss("");
        }
    }
    function handleDirMiss(m) {
        setCurrentShot((prevData) => ({
            ...prevData,
            missDir: m
        }))
    }
    function handleDstMiss(m) {
        setCurrentShot((prevData) => ({
            ...prevData,
            missDst: m
        }))
    }

    function renderDifficulty() {
        return (
            <div className="p-fluid mb-3" style={{ width: "85%" }}>
                <label>Miss: </label>
                <Button
                    /* disabled={isMissDisabled} */
                    onClick={() => handleMiss("in the hole")}
                    severity="secondary"
                    key={"in the hole"}
                    value={"in the hole"}
                    size="small"
                    label={"In the Hole"}
                    icon={"pi pi-flag"}
                    iconPos={"bottom"}
                    disabled={!isValidITH}
                />
                <label>Difficulty: {currentShot.lie === "recovery" ? RecoveryDifficultyInfo() : ChipDifficultyInfo()}</label>
                <SelectButton severity="secondary" value={currentShot.difficulty} options={difficultyOptions} onChange={(e) => setCurrentShot((prevData) => ({
                    ...prevData,
                    difficulty: e.value
                }))} />
            </div>
        )
    }


    /**
   * Renders input box for forced layup
   * @returns Input
   */
    function renderForcedLayupInput() {
        return (
            <div className="mb-2 mt-2">
                <label>
                    The layup distance:
                </label>
                <InputNumber
                    value={currentShot.forcedLayupDistance}
                    onChange={(e) =>
                        setCurrentShot((prevData) => ({
                            ...prevData,
                            forcedLayupDistance: e.value
                        }))}
                    max={userBaseline === "LPGA" ? (userBaseline === "meters" ? 235 : 257) : (userBaseline === "meters" ? 272 : 297)}
                    placeholder={userBaseline === "meters" ? "meters" : "yards"}
                />
            </div>
        )
    }


    function pinPosHandler(pos) {
        switch (pos) {
            case "back left":
                handleDstPin("back");
                handleDirPin("left");
                break;
            case "back center":
                handleDstPin("back");
                handleDirPin("center");
                break;
            case "back right":
                handleDstPin("back");
                handleDirPin("right");
                break;
            case "left":
                handleDstPin("middle");
                handleDirPin("left");
                break;
            case "middle":
                handleDstPin("middle");
                handleDirPin("center");
                break;
            case "right":
                handleDstPin("middle");
                handleDirPin("right");
                break;
            case "front left":
                handleDstPin("front");
                handleDirPin("left");
                break;
            case "front center":
                handleDstPin("front");
                handleDirPin("center");
                break;
            case "front right":
                handleDstPin("front");
                handleDirPin("right");
                break;
            default:
                break;
        }
    }
    function handleDirPin(m) {
        setCurrentShot((prevData) => ({
            ...prevData,
            pinDir: m
        }))
    }
    function handleDstPin(m) {
        setCurrentShot((prevData) => ({
            ...prevData,
            pinDst: m
        }))
    }
    function renderPinPos() {
        if ((currentShot.parHole === 3 && currentShot.shotHole === 1) || (currentShot.shotHole > 1 && currentShot.shotDst > 50 && currentShot.lie !== "green"))
            return (
                <div className="p-fluid mb-3">
                    <label>Pin Position: </label>
                    {pinPos.reduce((rows, pos, i) => {
                        return (
                            i % 3 === 0
                                ? [...rows, [pos]]
                                : [...rows.slice(0, -1), [...rows.slice(-1)[0], pos]]
                        );
                    }, []).map((row, i) => {
                        return (
                            <div className="mb-3 p-buttonset" key={i}>
                                {row.map((pos) => {
                                    return (
                                        <Button
                                            onClick={() => pinPosHandler(pos.miss)}
                                            severity={pos.miss === buildFullPin(currentShot.pinDir, currentShot.pinDst) ? 'primary' : 'secondary'}
                                            key={pos.miss}
                                            value={pos.miss}
                                            size="small"
                                            label={pos.display}
                                            icon={pos.icon}
                                            iconPos={pos.pos}
                                            outlined={pos.miss !== ""}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            );
    }
    function buildFullPin(dir, dst) {
        switch (dst) {
            case "back":
                switch (dir) {
                    case "left":
                        return "back left"
                    case "center":
                        return "back center"
                    case "right":
                        return "back right"
                }
                break;
            case "middle":
                switch (dir) {
                    case "left":
                        return "left"
                    case "right":
                        return "right"
                    default:
                        return "middle"
                }
            case "front":
                switch (dir) {
                    case "left":
                        return "front left"
                    case "center":
                        return "front center"
                    case "right":
                        return "front right"
                }
            default:
                break;
        }
    }

    /**
  * Renders clubs based on whether it's a tee shot or approach
  * @returns Dropdown
  */
    function renderClubs() {
        if ((currentShot.shotDst > 50 && currentShot.lie !== "green" && currentShot.lie !== "fringe" && (!currentShot.layup || currentShot.lie === "tee")))
            return (
                <ClubsDropdown bagArr={bagArr} currentShot={currentShot} setCurrentShot={setCurrentShot} />


            )
        {/* <div className="p-fluid">
                    <label>Club</label>
                    <Dropdown options={bagArr} value={currentShot?.club} onChange={(e) => setCurrentShot((prevData) => ({
                        ...prevData,
                        club: e.value
                    }))} />
                </div> */}
    }


    /**
       * Renders dropdown menu for mistakes
       * @returns Dropdown
       */
    function renderMistakes() {
        return (
            <>
                {!(currentShot.lie === "green" && currentShot.shotDst <= 2) &&
                    <div className="mb-2">
                        <label>Mistake</label>
                        <div className="p-fluid"><SelectButton pt={{
                            label: {
                                style: {
                                    fontSize: "12px",
                                }
                            },
                            button: {
                                style: {
                                    width: "25%",
                                    justifyContent: "center"
                                }
                            }
                        }} options={mentals} optionLabel="label" optionValue="value" value={currentShot.mental} onChange={(e) => handleMental(e.value)} /></div>

                    </div>}
            </>
        )
    }

    function handleMental(m) {
        setCurrentShot((prevData) => ({
            ...prevData,
            mental: m
        }))
    }

    function handleIsForcedLayup(value) {
        setForcedLayup(value);
        setCurrentShot((prevData) => ({
            ...prevData,
            layup: value,
            club: bagArr[value ? 1 : 0]
        }))
    }
    /**
 * Renders checks for layup and penalty
 * @returns Checks
 */
    function renderChecks() {
        if (isMobile)
            return (
                <div style={{ display: "flex", width: "90%", justifyContent: "center", gap: "10px" }} className={isMobile ? "mt-1" : ""}>
                    {currentShot.lie === "tee" && currentShot.parHole !== 3 &&
                        <div className="flex align-items-center mb-3">
                            <label htmlFor="forcedlayup" style={{ marginLeft: "5px" }}>{ForcedLayupInfo(userBaseline, userUnits)}</label>
                            <Checkbox inputId="forcedlayup" name="forcedLayup" onChange={(e) => handleIsForcedLayup(e.checked)} checked={forcedLayup} />
                        </div>}
                    {selectedTee && ((selectedTee.holePar[currentShot.holeNo] === 4 && currentShot.shotHole === 1 && currentShot.shotDst < 400) || (selectedTee.holePar[currentShot.holeNo] === 5 && currentShot.shotHole === 2)) &&
                        <div className="flex align-items-center mb-3">
                            <label htmlFor="gfOpp" style={{ marginLeft: "5px" }}>{GfOppInfo()}</label>
                            <Checkbox inputId="gfOpp" name="gfOpp" onChange={(e) => setCurrentShot((prevData) => ({
                                ...prevData,
                                gfOpp: e.checked
                            }))} checked={currentShot.gfOpp} />

                        </div>}
                    {currentShot.lie !== "green" &&
                        <div className="flex align-items-center mb-3">
                            <label htmlFor="layup" style={{ marginLeft: "5px" }}>Layup</label>
                            <Checkbox inputId="layup" name="layup" onChange={(e) => setCurrentShot((prevData) => ({
                                ...prevData,
                                layup: e.checked
                            }))} checked={currentShot.layup} />

                        </div>}
                    <div className="flex align-items-center mb-3">
                        <label htmlFor="penalty" style={{ marginLeft: "5px" }}>Penalty</label>
                        <Checkbox inputId="penalty" name="penalty" onChange={(e) => setCurrentShot((prevData) => ({
                            ...prevData,
                            penalty: e.checked
                        }))} checked={currentShot.penalty} />

                    </div>
                </div>
            )
        else
            return (
                <div className="flex flex-wrap justify-content-center gap-6">
                    {currentShot.lie === "tee" && currentShot.parHole !== 3 && <div className="flex align-items-center mb-3">
                        <Checkbox inputId="forcedlayup" name="forcedLayup" onChange={(e) => handleIsForcedLayup(e.checked)} checked={forcedLayup} />
                        <label htmlFor="forcedlayup" style={{ marginLeft: "5px" }}>{ForcedLayupInfo(userBaseline, userUnits)}</label>
                    </div>}
                    {selectedTee && ((selectedTee.holePar[currentShot.holeNo] === 4 && currentShot.shotHole === 1 && currentShot.shotDst < 400) || (selectedTee.holePar[currentShot.holeNo] === 5 && currentShot.shotHole === 2)) &&
                        <div className="flex align-items-center mb-3">
                            <Checkbox inputId="gfOpp" name="gfOpp" onChange={(e) => setCurrentShot((prevData) => ({
                                ...prevData,
                                gfOpp: e.checked
                            }))} checked={currentShot.gfOpp} />
                            <label htmlFor="gfOpp" style={{ marginLeft: "5px" }}>{GfOppInfo()}</label>
                        </div>}
                    {currentShot.lie !== "green" && <div className="flex align-items-center mb-3">
                        <Checkbox inputId="layup" name="layup" onChange={(e) => setCurrentShot((prevData) => ({
                            ...prevData,
                            layup: e.checked
                        }))} checked={currentShot.layup} />
                        <label htmlFor="layup" style={{ marginLeft: "5px" }}>Layup</label>
                    </div>}
                    <div className="flex align-items-center mb-3">
                        <Checkbox inputId="penalty" name="penalty" onChange={(e) => setCurrentShot((prevData) => ({
                            ...prevData,
                            penalty: e.checked
                        }))} checked={currentShot.penalty} />
                        <label htmlFor="penalty" style={{ marginLeft: "5px" }}>Penalty</label>
                    </div>
                </div>
            )
    }

    const [isInputValid, setIsInputValid] = useState(false);
    const [isValidITH, setIsValidITH] = useState(false);//whether user can click on in the hole
    const [isValidTapIn, setIsValidTapIn] = useState(false);

    //if input is valid for next shot
    useEffect(() => {
        if (currentShot.lie === "tee" && currentShot.holePar !== 3) {
            setIsInputValid(nextLie !== "" && ((nextLie === "green" || nextLie === "fringe" || nextLie === "fairway") || currentShot.missDir !== "")
                && currentShot.club !== "" && currentShot.mental !== "" && currentShot.shotDst !== null);
        } else if (currentShot.lie === "green") {
            if (currentShot.shotDst > 2)
                setIsInputValid(currentShot.shotDst !== null && (currentShot.missDir !== "" || currentShot.missDst !== "") && currentShot.mental !== "");
            else
                setIsInputValid(currentShot.shotDst !== null && (currentShot.missDir !== "" || currentShot.missDst !== ""));
        } else if (currentShot.shotDst < 50) {
            setIsInputValid(!(nextLie === "" || currentShot.shotDst === null || currentShot.mental === ""));
        } else if (currentShot.lie === "recovery" || currentShot.layup) {
            setIsInputValid(!(nextLie === "" || currentShot.shotDst === null || currentShot.mental === ""));
        }
        else {
            setIsInputValid(nextLie !== "" && currentShot.shotDst !== null && (currentShot.missDir !== "" || currentShot.missDst !== "") && currentShot.mental !== "" && currentShot.club !== "");
        }
    }, [currentShot, nextLie])




    useEffect(() => {
        if (currentShot.lie === "tee" && currentShot.holePar !== 3) {
            setIsValidITH(true)
        } else if (currentShot.lie === "green") {
            if (currentShot.shotDst > 2)
                setIsValidITH(currentShot.shotDst !== null && currentShot.mental !== "");
            else
                setIsValidITH(currentShot.shotDst !== null);
        } else if (currentShot.shotDst < 50) {
            setIsValidITH(!(nextLie === "" || currentShot.shotDst === null || currentShot.mental === ""));
        } else if (currentShot.lie === "recovery" || currentShot.layup) {
            setIsValidITH(!(nextLie === "" || currentShot.shotDst === null || currentShot.mental === ""));
        }
        else {
            setIsValidITH(nextLie !== "" && currentShot.shotDst !== null && currentShot.mental !== "" && currentShot.club !== "");
        }
    }, [currentShot, nextLie])

    useEffect(() => {
        if (currentShot.lie === "tee" && currentShot.holePar !== 3) {
            setIsValidTapIn(((nextLie === "green" || nextLie === "fringe" || nextLie === "fairway") || currentShot.missDir !== "")
                && currentShot.club !== "" && currentShot.mental !== "" && currentShot.shotDst !== null);
        } else if (currentShot.lie === "green") {
            if (currentShot.shotDst > 2)
                setIsValidTapIn(currentShot.shotDst !== null && (currentShot.missDir !== "" || currentShot.missDst !== "") && currentShot.mental !== "");
            else
                setIsValidTapIn(currentShot.shotDst !== null && (currentShot.missDir !== "" || currentShot.missDst !== ""));
        } else if (currentShot.shotDst < 50 || currentShot.lie === "recovery" || currentShot.layup) {
            setIsValidTapIn(currentShot.shotDst !== null && currentShot.mental !== "");
        } else {//approach shots
            setIsValidTapIn(currentShot.shotDst !== null && (currentShot.missDir !== "" || currentShot.missDst !== "") && currentShot.mental !== "" && currentShot.club !== "");
        }
    }, [currentShot, nextLie])

    const [bkgColor, setBKGColor] = useState("white");
    const [borderColor, setBorderColor] = useState("white")

    useEffect(() => {
        switch (currentShot.lie) {
            case "tee":
                setBKGColor("white");
                setBorderColor("white")
                break;
            case "green":
                setBKGColor("rgba(0, 255, 0, 0.2)");
                setBorderColor("rgb(0, 255, 0)")
                break;
            case "fringe":
                setBKGColor("rgba(20, 230, 30, 0.2)");
                setBorderColor("rgb(20, 230, 30)")
                break;
            case "fairway":
                setBKGColor("rgba(30, 150, 40, 0.2)");
                setBorderColor("rgb(30, 150, 40)")
                break;
            case "rough":
                setBKGColor("rgba(0, 70, 0, 0.3)");
                setBorderColor("rgb(0, 70, 0)")
                break;
            case "bunker":
                setBKGColor("rgba(255, 255, 0, 0.3)");
                setBorderColor("rgb(255, 255, 0)")
                break;
            case "recovery":
                setBKGColor("rgba(77, 38, 0, 0.3)");
                setBorderColor("rgb(77, 38, 0)")
                break;
            default:
                setBKGColor("white");
                setBorderColor("white")
        }
    }, [currentShot])

    function renderInput() {
        var color = "white";
        switch (currentShot.lie) {
            case "fairway":
                color = "rgba(0, 200, 0, 0.2)";
                break;
            case "rough":
                color = "rgba(0, 153, 0, 0.2)";
                break;
            case "bunker":
                color = "rgba(255, 255, 77, 0.2)";
                break;
            case "recovery":
                color = "rgba(102, 51, 0, 0.2)";
                break;
            case "tee":
                color = "rgba(125, 256, 125, 0.2)";
                break;
            case "fringe":
                color = "rgba(0, 225, 0, 0.2)";
                break;
            case "green":
                color = "rgba(0, 255, 0, 0.2)";
                break;
            default:
                color = "white";
        }
        return (
            <div style={{
                display: "flex", flexDirection: "column", minWidth: "50%"
            }} >
                {/* <div style={{ backgroundColor: color }}>
                    Current lie: {currentShot.lie}
                </div> */}
                <label htmlFor="distance-in">Distance: </label>
                <InputNumber id="distance-in" value={currentShot.shotDst}
                    onChange={(e) => handleInput(e.value)}
                    max={800}
                    placeholder={currentShot.lie === "green" ? "feet" : userUnits}
                    className="mb-3"
                    style={{ width: '100%', maxWidth: '100%' }} />
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: bkgColor, border: `3px ${borderColor} solid`, borderRadius: "3px", padding: "0.5vw" }}>

            {selectedTee && renderScorecard()}
            <div style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: "center", gap: "3rem" }} >
                <div>
                    Shot: <Badge value={currentShot.shotHole} size={'large'} />
                </div>
                <div>
                    Hole: <Badge value={currentShot.holeNo} size={'large'} />
                </div>
            </div>
            {isMobile ?
                <div style={{
                    width: "100%", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center",
                    borderRadius: "10px", padding: "5px"
                }}
                    className="mt-2">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "2rem", width: "100%" }} className="mb-2">
                        {renderInput()}
                        {renderClubs()}
                    </div>
                    {renderChecks()}
                    {forcedLayup && renderForcedLayupInput()}
                    {renderLies()}
                    {renderMiss()}
                    {renderPinPos()}
                    {renderMistakes()}
                    {renderButtons()}
                </div>
                :
                <div>
                    <div style={{ width: "100%", display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        {renderButtons()}
                    </div>
                    <div style={{
                        width: "100%", display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "flex-start", gap: "2rem",
                        padding: "10px"
                    }} className="mt-4">
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "top", gap: "2rem", width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "top", gap: "2rem", width: "100%" }}>
                                {renderInput()}
                                {renderClubs()}
                            </div>
                            <div style={{ width: "100%" }}>
                                {renderLies()}
                            </div>

                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "top", gap: "0.5rem", width: "100%" }}>
                            {renderMiss()}
                            {renderMistakes()}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "0.5rem", width: "100%" }}>
                            {renderPinPos()}
                            {renderChecks()}
                        </div>
                    </div>
                </div>
            }

        </div>

    );
}
export default AddRound2;

{/* <div>
    <Container>
        <Row>
            <Col xs="12" md="12" lg="12">
                {selectedTee && renderScorecard()}
            </Col>
        </Row>
        <Row className="mt-3">
            <Col xs="6" md={{ offset: 2, size: 4 }} lg={{ offset: 4, size: 2 }}>
                <div>
                    Shot: <Badge value={currentShot.shotHole} size={'large'} />
                </div>
            </Col>
            <Col xs="6" md={{ offset: 2, size: 4 }} lg={{ offset: 0, size: 4 }}>
                <div>
                    Hole: <Badge value={currentShot.holeNo} size={'large'} />
                </div>
            </Col>
            <Row className="d-none d-md-flex mt-3">
                <Col xs="12" lg={{ offset: 0, size: 12 }} style={{ display: "flex", justifyContent: "center" }}>
                    {renderButtons()}
                </Col>
            </Row>

        </Row>
        <Row className="mt-3 mb-5">
            <Col xs="12" md="4" lg="4" className="p-fluid">
                <div>Current lie: {currentShot.lie}</div>
                <label htmlFor="distance-in">Distance: </label>
                <InputNumber id="distance-in" value={currentShot.shotDst}
                    onChange={(e) => handleInput(e.value)}
                    max={800}
                    placeholder={currentShot.lie === "green" ? "feet" : userUnits}
                    className="mb-3" />
                {currentShot.lie !== "green" && renderLies()}
            </Col>
            <Col xs="12" md="4" lg="4">
                {renderMiss()}
                {forcedLayup && renderForcedLayupInput()}
            </Col>
            <Col xs="12" md="4" lg="4">
                <Row>
                    {renderPinPos()}
                </Row>
                <Row >
                    {isMobile ?
                        <>
                            <Col xs="6" md="6" lg="4">
                                {renderClubs()}
                            </Col>
                            <Col xs="6" md="6" lg="6" className="mt-3">
                                {renderChecks()}
                            </Col>
                            <Col xs="12" md="12" lg="12">
                                {renderMistakes()}
                            </Col>
                        </>
                        :
                        <>
                            <Col xs="6" md="6" lg="4">
                                {renderClubs()}
                            </Col>
                            <Col xs="12" md="6" lg="5">
                                {renderMistakes()}
                            </Col>
                            <Col className="mt-3" xs="12" md="12" lg="12">
                                {renderChecks()}
                            </Col>
                        </>
                    }

                </Row>
            </Col>

        </Row>
        <Row className="d-flex d-md-none">
            <Col xs="12" className="mb-4" >
                {renderButtons()}
            </Col>
        </Row>

    </Container>
</div > */}