import React, { useState, useEffect } from "react";
import "../../css/new-round.css";
import { Row } from 'reactstrap'
import { MultiSelect } from 'primereact/multiselect';
import { Teams } from "appwrite";
import { client } from "../appwrite-testing/appwriteConfig";
import ShowAllButton from "./ShowAllButton";

export function MultiSelectPlayers({ selectedUsers, setSelectedUsers }) {
    const [loading, setLoading] = useState(true);
    const [groupedPlayers, setGroupedPlayers] = useState(null);
    const [userTeams, setUserTeams] = useState([]);

    const teams = new Teams(client);

    useEffect(() => {
        teams.list().then(result => setUserTeams(result.teams))
    }, [])

    useEffect(() => {
        const fetchTeamsAndPlayers = async () => {
            if (userTeams) {
                const groups = [];

                for (const t of userTeams) {
                    const group = {
                        groupName: `${t.name}`,
                        players: []
                    };
                    console.log(t)
                    try {
                        const result = await teams.listMemberships(
                            t.$id, // teamId
                            undefined, // queries (optional)
                            undefined // search (optional)
                        );

                        console.log(result);
                        for (const p of result.memberships) {
                            if (p.roles.includes('Player')) {
                                group.players.push(p)
                            }
                        }
                        setLoading(false)
                    } catch (error) {
                        console.error(`Error fetching memberships for team ${t.teamName}:`, error);
                    }
                    groups.push(group);
                }
                // Set the updated group data to state
                setGroupedPlayers(groups);
            }
        };

        fetchTeamsAndPlayers();
    }, [userTeams]);



    const groupedPlayerTemplate = (option) => {
        return (
            <div className="flex align-items-center" let-group>
                <div>{option.groupName}</div>
            </div>
        );
    };

    return (
        <div className="m-3">
            <Row>

                <MultiSelect
                    value={selectedUsers}
                    onChange={(e) => setSelectedUsers(e.value)}
                    options={groupedPlayers}
                    optionLabel="userName"
                    optionGroupLabel="groupName"
                    optionGroupChildren="players"
                    optionGroupTemplate={groupedPlayerTemplate}
                    display="chip"
                    filter
                    filterPlaceholder="Search Player"
                    placeholder={loading ? "Loading" : "Select Players"}
                    className={"w-full md:w-14rem"}
                    dropdownIcon={loading ? "pi pi-spinner" : "pi pi-chevron-down"}
                />

            </Row>
            <Row className="mt-1">
                {userTeams.map((t) => (
                    <ShowAllButton teamName={t.name} setSelectedUsers={setSelectedUsers} selectedUsers={selectedUsers}
                        groupedPlayers={groupedPlayers} />

                ))}

            </Row>
        </div >
    )
}