import { useConstant } from "../../../../ConstantContext";
import { useOptions } from "../../StatsOptionsProvider";
import { Table } from "reactstrap";
const APPindividual = ({ state, data, isAdvanced }) => {
    const options = useOptions();
    const constants = useConstant();

    function renderApproachesStrokesGained() {
        return (
            <div className="table-responsive">
                <Table id='approachtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Total</th>
                            <th>Att.</th>
                            <th>Per Shot</th>
                            <th>Per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(isAdvanced ? options.sgAppAdvanced : options.sgApp).map((range) => (
                            <tr
                                key={`${range.name}`}
                                style={{
                                    color:
                                        (range.display === "SG" ? (state[`sgApp`]?.strokesGained < constants.tourAvg[`sgApp`].redValue ||
                                            state[`sgApp`]?.strokesGained > constants.tourAvg[`sgApp`].greenValue)
                                            : (state[`${range.name}`]?.strokesGainedPerShot < constants.sgPerShotLower ||
                                                state[`${range.name}`]?.strokesGainedPerShot > constants.sgPerShotUpper)) &&
                                        'white',
                                    backgroundColor:
                                        (range.display === "SG" ?
                                            (state[`sgApp`]?.strokesGained > constants.tourAvg[`sgApp`].greenValue ? constants.green :
                                                state[`sgApp`]?.strokesGained < constants.tourAvg[`sgApp`].redValue ? constants.red : '')
                                            : state[`${range.name}`]?.strokesGainedPerShot > constants.sgPerShotUpper ? constants.green :
                                                state[`${range.name}`]?.strokesGainedPerShot < constants.sgPerShotLower ? constants.red : '')
                                }}>
                                <td>{range.display}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${range.name}`]?.strokesGainedRounded}</td>
                                <td>{state[`${range.name}`]?.attempts}</td>
                                <td>{state[`${range.name}`]?.strokesGainedPerShot}</td>
                                <td>{state[`${range.name}`]?.strokesGainedPerRoundRounded}</td>
                            </tr>
                        ))}


                    </tbody>
                </Table>
            </div>
        )
    }
    return (
        <div>
            {renderApproachesStrokesGained()}
            <div className="table-responsive">
                <Table id='approachtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Value</th>
                            <th>Count</th>
                            <th>Attempts</th>
                            <th>Tour Avg.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {options.gir.map((range) => (
                            <tr
                                key={`${range.name}`}
                                style={{
                                    color:
                                        (state[`${range.name}`]?.perRound < constants.tourAvg[`${range.name}.perRound`].redValue ||
                                            state[`${range.name}`]?.perRound > constants.tourAvg[`${range.name}.perRound`].greenValue) &&
                                        'white',
                                    backgroundColor:
                                        (state[`${range.name}`]?.perRound > constants.tourAvg[`${range.name}.perRound`].greenValue ? constants.green :
                                            state[`${range.name}`]?.perRound < constants.tourAvg[`${range.name}.perRound`].redValue ? constants.red : '')
                                }}>
                                <td>{range.display}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${range.name}`]?.perRound}</td>
                                <td>{state[`${range.name}`]?.hitCount}</td>
                                <td>{state[`${range.name}`]?.attempts}</td>
                                <td>{constants.tourAvg[`${range.name}.perRound`].value}</td>
                            </tr>
                        ))}
                        {(isAdvanced ? options.rangesAppAdvanced : options.rangesApp).map((range) => (
                            <tr
                                key={`${range.name}`}
                                style={{
                                    color:
                                        (state[`${range.name}`]?.percentage < constants.tourAvg[`${range.name}.percentage`].redValue ||
                                            state[`${range.name}`]?.percentage > constants.tourAvg[`${range.name}.percentage`].greenValue) &&
                                        'white',
                                    backgroundColor:
                                        (state[`${range.name}`]?.percentage > constants.tourAvg[`${range.name}.percentage`].greenValue ? constants.green :
                                            state[`${range.name}`]?.percentage < constants.tourAvg[`${range.name}.percentage`].redValue ? constants.red : '')
                                }}>
                                <td>{range.display}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${range.name}`]?.percentage}{range.display.substring(0, 4) === 'GIR ' && '%'}</td>
                                <td>{state[`${range.name}`]?.hitCount}</td>
                                <td>{state[`${range.name}`]?.attempts}</td>
                                <td>{constants.tourAvg[`${range.name}.percentage`].value}</td>
                            </tr>
                        ))}
                        {options.gfApp.map((range) => (
                            <tr
                                key={`${range}`}
                                style={{
                                    color:
                                        (state[`${range.name}`]?.perRound < constants.tourAvg[`${range.name}.perRound`].redValue ||
                                            state[`${range.name}`]?.perRound > constants.tourAvg[`${range.name}.perRound`].greenValue) &&
                                        'white',
                                    backgroundColor:
                                        (state[`${range.name}`]?.perRound > constants.tourAvg[`${range.name}.perRound`].greenValue ? constants.green :
                                            state[`${range.name}`]?.perRound < constants.tourAvg[`${range.name}.perRound`].redValue ? constants.red : '')
                                }}>
                                <td>{range.display}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${range.name}`]?.perRound}</td>
                                <td>{state[`${range.name}`]?.hitCount}</td>
                                <td>{state[`${range.name}`]?.attempts}</td>
                                <td>{constants.tourAvg[`${range.name}.perRound`].value}</td>
                            </tr>
                        ))}
                        {options.missApp.map((range) => (
                            <tr key={`${range.name}`}>
                                <td>{range.display}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${range.name}`]}%</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                            </tr>
                        ))}
                        {options.difficultyMiss.map((range) => (
                            <tr key={`${range.name}`}>
                                <td>{range.display}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${range.name}`]?.percentage}%</td>
                                <td>{state[`${range.name}`]?.hitCount}</td>
                                <td>{state[`${range.name}`]?.attempts}</td>
                                <td>--</td>
                            </tr>
                        ))}
                        {isAdvanced && options.expected.map((e) => (
                            <tr key={`${e.name}`}>
                                <td>{e.display}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${e.name}`]?.averageExpected}</td>
                                <td>--</td>
                                <td>{state[`${e.name}`]?.count}</td>
                                <td>--</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
export default APPindividual;
