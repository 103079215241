import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { useState, useEffect, useRef } from "react";
import ChangePassword from "./ChangePassword";
import EditBag from "./EditBag";
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from "primereact/progressspinner";
import { useAuth } from "../appwrite-testing/AuthContext";
import { Toast } from "primereact/toast";
const PersonalInformation = () => {

    const { user, getJWT } = useAuth();
    const userId = user?.$id || "000000";
    const [name, setName] = useState("");
    const [region, setRegion] = useState("");
    const [baseline, setBaseline] = useState("");
    const [units, setUnits] = useState(null);
    const [wasDataLoaded, setWasDataLoaded] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const regions = [
        'Europe',
        'North America',
        'Latin America',
        'South America',
        'Africa',
        'Asia',
        'Australia'
    ];
    const [email, setEmail] = useState("email@gmail.com")
    const [changePass, setChangePass] = useState(false);
    const [isEditBag, setIsEditBag] = useState(false);
    const toast = useRef(null);



    const fetchUser = async () => {
        try {
            const jwt = await getJWT();
            const response = await fetch(`api/User/myself?userId=${user.$id}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            });
            const responseData = await response.json();
            setBaseline(responseData.baseline);
            setUnits(responseData.units);
            setEmail(responseData.email);
            setName(responseData.name);
            setRegion(responseData.region)
            setUserInfo(responseData);
            setWasDataLoaded(true);
            window.localStorage.setItem("units", responseData.units);
            window.localStorage.setItem("baseline", responseData.baseline);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setWasDataLoaded(false);
        }
    };
    useEffect(() => {
        fetchUser();
    }, [user]);
    async function submitRegion(region) {
        setRegion(region);
        try {
            const jwt = await getJWT();
            await fetch(`api/User/myregion`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body:
                    JSON.stringify({
                        userId: userId,
                        region: region
                    })

            }).then(response => {
                toast.current.show({ severity: 'success', summary: 'Region is Updated!' });
                return response;
            }).catch(error => {
                console.error(error);
                toast.current.show({ severity: 'danger', summary: 'Region update unsuccesfull' });

            });
        } catch (error) {
            console.error("Error updating the bag:", error);
            toast.current.show({ severity: 'danger', summary: 'Region update unsuccesfull' });
        }
    }
    async function submitUnits(units) {
        setUnits(units);
        try {
            const jwt = await getJWT();
            await fetch(`api/User/myunits`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body:
                    JSON.stringify({
                        userId: userId,
                        units: units
                    })

            }).then(response => {
                window.localStorage.setItem("units", units);
                toast.current.show({ severity: 'success', summary: 'Units are Updated!' });
                return response;
            }).catch(error => {
                console.error(error);
                toast.current.show({ severity: 'danger', summary: 'Units update unsuccesfull :(' });
            });
        } catch (error) {
            console.error("Error updating the bag:", error);
            toast.current.show({ severity: 'danger', summary: 'Units update unsuccesfull :(' });
        }
    }
    async function submitBaseline(baseline) {
        setBaseline(baseline);
        try {
            const jwt = await getJWT();
            await fetch(`api/User/mybaseline`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body:
                    JSON.stringify({
                        userId: userId,
                        baseline: baseline
                    })

            }).then(response => {
                window.localStorage.setItem("baseline", baseline);
                toast.current.show({ severity: 'success', summary: 'Baseline is Updated!' });
                return response;
            }).catch(error => {
                console.error(error);
                toast.current.show({ severity: 'danger', summary: 'Baseline update unsuccesfull :(' });
            });
        } catch (error) {
            console.error("Error updating the baseline:", error);
            toast.current.show({ severity: 'danger', summary: 'Baseline update unsuccesfull :(' });
        }
    }
    function nameAndEmail() {
        return (
            <div>
                <div className="m-2 mt-0 mb-3">
                    <label style={{ fontWeight: "bold" }}>Name:</label>
                    <div>{wasDataLoaded ? name : <ProgressBar mode="indeterminate" style={{ height: '10px' }} />}</div>
                </div>
                <div className="m-2">
                    <label style={{ fontWeight: "bold" }}>Email:</label>
                    <div>{wasDataLoaded ? email : <ProgressBar mode="indeterminate" style={{ height: '10px' }} />}</div>
                </div>
                <div className="m-2">
                    <label style={{ fontWeight: "bold" }}>Region:</label>
                    <div>
                        <Dropdown value={region} onChange={(e) => submitRegion(e.value)} options={regions} dropdownIcon={wasDataLoaded ? "pi pi-chevron-down" : "pi pi-spinner"} />
                    </div>
                </div>
                <div className="m-2 mt-3">
                    <Button label="Change the Password" severity="help" size="small" onClick={(e) => setChangePass(true)} />
                </div>
            </div>
        )
    }

    return (
        <div>
            <Toast ref={toast} />
            <Card title="Personal Information">
                <div style={{ display: 'flex', flexWrap: "wrap", flexDirection: 'row', alignItems: 'flex-start', overflowX: 'hidden' }} className="mt-3 .coach-container">
                    <Card className="m-4" title="Personal Information" >{nameAndEmail()}</Card>
                    {changePass && <ChangePassword className="m-2" setChangePass={setChangePass} />}
                    <Card className="m-4" title="Baseline & Units">
                        {wasDataLoaded ?
                            <div>
                                <SelectButton value={baseline} onChange={(e) => submitBaseline(e.value)} options={["PGA", "LPGA"]} className="m-2" />
                                <SelectButton value={units} onChange={(e) => submitUnits(e.value)} options={["yards", "meters"]} className="m-2" />
                            </div>
                            :
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        }
                    </Card>
                    <Card className="m-4" title="Bag" >
                        {wasDataLoaded ?
                            <Button label="Edit the Bag" onClick={() => setIsEditBag(true)} />
                            :
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />}
                    </Card>
                    <style jsx>{`
             .coach-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            overflow-x: hidden;
        }
            `}</style>
                </div>
            </Card>
            {isEditBag && <EditBag setIsEditBag={setIsEditBag} userInfo={userInfo} />}
        </div>
    )
}
export default PersonalInformation;