import React, { useState, useEffect } from "react";
import {
    Container, Row, Col, Table
} from "reactstrap";
import { MultiSelectPlayers } from "../../dropdowns/MultiSelectPlayers";
import { GetScoresApproach } from "./GetScoresApproach";
import { Message } from "primereact/message";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { ApproachPracticeResult } from "../Individual/ApproachPracticeResults"
const TeamApproach = (props) => {
    /**Possible durations to filter rounds */
    const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds",
        "Last 20 Rounds"];
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [displayedPlayers, setDisplayedPlayers] = useState([]);
    const [allUserAccounts] = useState(props.allUserAccounts);

    const [seeDetails, setSeeDetails] = useState(false);
    const [userDetailsSelected, setUserDetailsSelected] = useState(null);

    const [lowDistancePicked, setLowDistancePicked] = useState(50);
    const options = ["50-100y", "75-125y", "100-150y", "150-200y"];
    const [value, setValue] = useState("50-100y");
    useEffect(() => {
        if (selectedUsers.length > 0) {
            setDisplayedPlayers(selectedUsers);
        } else {
            setDisplayedPlayers(allUserAccounts)
        }
    }, [selectedUsers])


    function setApproach(value) {
        setValue(value);
        setLowDistancePicked(parseInt(value.split('-')[0]))
    }

    return (
        <div>
            {seeDetails ?
                <div>
                    < Button label="Go Back" className="mb-3" onClick={() => setSeeDetails(false)} />
                    < ApproachPracticeResult userId={userDetailsSelected.userId.substring(userDetailsSelected.userId.indexOf('|') + 1)} />
                </div >
                :
                <Container>
                    {displayedPlayers.length <= 0 && <Message className='mt-3 mb-3' severity="warn" text="No player was selected" />}
                    <Row className="mb-2">
                        <Col>
                            <MultiSelectPlayers players={props.players} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} durations={durations} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <div className="p-fluid mb-3" style={{ display: "flex", justifyContent: "center", width: "100%", }} >
                                <SelectButton options={options} value={value} onChange={(e) => setApproach(e.value)} style={{ display: "flex", justifyContent: "center", width: "80%", }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Last SG</th>
                                    <th>Last 5 SG Avg.</th>
                                    <th>Last 10 SG Avg.</th>
                                    <th>Last 30 SG Avg.</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedPlayers.map((player) => {
                                    return <GetScoresApproach player={player} lowDistancePicked={lowDistancePicked} setSeeDetails={setSeeDetails} setUserDetailsSelected={setUserDetailsSelected} />
                                })}
                            </tbody>
                        </Table>
                    </Row>
                </Container>}
        </div>
    )
}

export { TeamApproach }