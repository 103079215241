import { Card } from "primereact/card";
import { useEffect, useState } from "react";
import { MultiSelectPlayers } from "../../dropdowns/MultiSelectPlayers";
import PlayerSchedule from "./PlayerSchedule";
import DateWithButtons from "../DateWithButtons";

const CurrentSchedule = ({ coachTeam, getJWT }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [practiceDate, setPracticeDate] = useState(new Date());

    return (
        <Card title="Current Schedule">
            <MultiSelectPlayers selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
            <DateWithButtons practiceDate={practiceDate} setPracticeDate={setPracticeDate} />
            {console.log(practiceDate.toISOString())}
            {selectedUsers.map((p) => (
                <PlayerSchedule player={p} getJWT={getJWT} date={practiceDate.toISOString()} />
            ))}
        </Card>
    )
}
export default CurrentSchedule;