import { useConstant } from "../../../../ConstantContext";
import { useOptions } from "../../StatsOptionsProvider";
import { Table } from "reactstrap";
const Tiger5Individual = ({ state, data, isAdvanced }) => {
    const constants = useConstant();
    const options = useOptions();
    return (
        <div className="table-responsive">
            <Table id='tiger5table' className='stats-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Per round</th>
                        <th>Count</th>
                        <th>Attempts</th>
                        <th>Tour average</th>
                    </tr>
                </thead>
                <tbody>
                    {options.tiger5stats.map((stat) => (
                        <tr key={`${stat.name}`}
                            style={{
                                color: (state[`${stat.name}Attempts`] > 0 || stat.name === "double") &&
                                    (constants.tourAvg[`${stat.name}PerRound`].low ?
                                        (state[`${stat.name}PerRound`] < constants.tourAvg[`${stat.name}PerRound`].greenValue ||
                                            state[`${stat.name}PerRound`] > constants.tourAvg[`${stat.name}PerRound`].redValue) && 'white' :
                                        (state[`${stat.name}PerRound`] > constants.tourAvg[`${stat.name}PerRound`].greenValue ||
                                            state[`${stat.name}PerRound`] < constants.tourAvg[`${stat.name}PerRound`].redValue) && 'white'),

                                backgroundColor: (state[`${stat.name}Attempts`] > 0 || stat.name === "double") &&
                                    (constants.tourAvg[`${stat.name}PerRound`].low === true ?
                                        (state[`${stat.name}PerRound`] < constants.tourAvg[`${stat.name}PerRound`].greenValue ? constants.green :
                                            state[`${stat.name}PerRound`] > constants.tourAvg[`${stat.name}PerRound`].redValue ? constants.red : '')
                                        :
                                        (state[`${stat.name}PerRound`] > constants.tourAvg[`${stat.name}PerRound`].greenValue ? constants.green :
                                            state[`${stat.name}PerRound`] < constants.tourAvg[`${stat.name}PerRound`].redValue ? constants.red : ''))
                            }}>
                            <td>{`${stat.display}`}</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}PerRound`]}{stat.name === "easyChip" && "%"}</td>
                            <td>{state[`${stat.name}Count`]}</td>
                            <td>{stat.name === "double" ? "--" : state[`${stat.name}Attempts`]}</td>
                            <td>{constants.tourAvg[`${stat.name}PerRound`]?.value}</td>
                        </tr>

                    ))}
                </tbody>

            </Table>
        </div>
    )
}
export default Tiger5Individual