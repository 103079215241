import { React, useState, useEffect } from "react";
import { useAuth } from "../../appwrite-testing/AuthContext";
import { Table, Container, Row, Col, } from "reactstrap";
import { getRoundsCallback, getRoundsCallbackList, getRoundsCustomCallback, getRoundsCustomCallbackList, getRoundsSemesterCallback, getRoundsSemesterCallbackList } from "../StatsAPICall";
import "../../../custom.css";
import '../../../css/loading.css'
import { DurationDropdown } from '../../dropdowns/DurationDropdown';
import { RoundTypeDropdown } from "../../dropdowns/RoundTypeDropdown";
import { MultiSelectPlayers } from "../../dropdowns/MultiSelectPlayers";
import { Message } from "primereact/message";
import { InputSwitch } from 'primereact/inputswitch';
import { Slider } from 'primereact/slider';
import { ProgressBar } from 'primereact/progressbar';
import { ToggleButton } from 'primereact/togglebutton';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import { DateInput } from "../../DateInput";
import { useConstant } from '../../../ConstantContext';
import { SelectButton } from "primereact/selectbutton";
export function CoachStats() {

  const { user, getJWT } = useAuth();

  const [isMobile, setIsMobile] = useState(false);
  const [wasDataLoaded, setWasDataLoaded] = useState(false);
  /**Selected type of the round */
  const [roundType, setRoundType] = useState("All");
  /**Is custom range selected */
  const [custom, setCustom] = useState(false);
  /**Is tournament selected */
  const [selectTournament, setSelectTournament] = useState(false);
  /**What torunament is selected */
  const [tournament, setTournament] = useState("tournament");
  /**Start of custom date range */
  const [startDate, setStartDate] = useState(null);
  /**End of custom date range */
  const [endDate, setEndDate] = useState(null);
  /**Type of selected duration */
  var [durationType, setDurationType] = useState("number");
  /**Selected time duration in months */
  var [timeDuration, setTimeDuration] = useState(0);
  /**Selected last number of rounds */
  var [numberOfRounds, setNumberOfRounds] = useState(10);
  //TODO: Set up coach user id if auth
  //const [allUsers, setAllUsers] = useState(["118339364062165403224", "108255300905790625711"]);
  const [allUserAccounts, setAllUserAccounts] = useState([]);
  /**users selected in the dropdown */
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [activeTabs, setActiveTabs] = useState([0, 1, 2, 3, 4, 6, 7, 8])

  const [showAttempts, setShowAttempts] = useState(false);
  //Tournament List
  const listTournaments = ["tournament1", "tournament2"];
  //Custom Date Selected
  //const [customDate, setCustomDate] = useState(false);
  //Start of custom date range
  //const [startDate, setStartDate] = useState(null);
  //End of custom date range
  //const [endDate, setEndDate] = useState(null);
  //Type of selected duration
  const roundTypes = ["All", "Tournament", "Qualifying", "Practice"];
  /**Possible durations to filter rounds */
  const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds", "Last 15 Rounds",
    "Last 20 Rounds", "Last Month", "Last 3 Months", "Last 6 Months", "Fall", "Spring", "Custom"];

  const [displayedPlayers, setDisplayedPlayers] = useState([]);

  const [primeTableSize, setPrimeTableSize] = useState(isMobile ? 1 : 50)
  const [loading, setLoading] = useState(false);

  const [coachTeam, setCoachTeam] = useState(null)
  const [roundsFound, setRoundsFound] = useState(false);

  const [statsType, setStatsType] = useState("Basic");
  const statsTypes = ["Basic", "Advanced"];

  useEffect(() => {
    // Function to check and update screen size on resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set breakpoint as per your requirement
      setPrimeTableSize(window.innerWidth <= 768 ? 0 : 50)
    };

    // Initial check for screen size
    handleResize();

    // Add event listener to check screen size on resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      if (!user) return;

      try {
        const jwt = await getJWT();
        const response = await fetch(`api/User/myself?userId=${user.$id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        const responseData = await response.json();
        window.localStorage.setItem("team", responseData.team);
        if (responseData.bag !== null) {
          window.localStorage.setItem("bag", JSON.stringify(responseData.bag));
        }
        setCoachTeam(responseData.team);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const team = window.localStorage.getItem("team");
    if (team) {
      setCoachTeam(team);
    } else {
      fetchUser();
    }
  }, [user]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      if (!user) return;

      try {
        const jwt = await getJWT();
        const response = await fetch('api/user', {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        const responseData = await response.json();
        setAllUserAccounts(responseData);
        setWasDataLoaded(true);
      } catch (error) {
        console.error("Error fetching all users:", error);
      }
    };

    fetchAllUsers();
  }, [user]);

  useEffect(() => {
    if (selectedUsers.length > 0) {
      setDisplayedPlayers(selectedUsers);
    }
  }, [selectedUsers]);

  const constants = useConstant();

  const makeApiCall = async (url) => {
    try {
      const jwt = await getJWT();
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      return await response.json();
    } catch (error) {
      console.error("API call failed:", error);
      throw error;
    }
  };

  // Helper function to fill out tables with data
  const fillOutTable = (data) => {
    if (!data) {
      setRoundsFound(false);
      return;
    }

    try {
      // Initialize arrays with tour averages
      const generalData = [tourA];
      const scoringData = [tourA];
      const approachData = [tourA];
      const argData = [tourA];
      const puttingData = [tourA];
      const ottData = [tourA];

      // Process data for each selected player
      selectedUsers.forEach(player => {
        const playerData = data[player.userId];
        if (!playerData) return; // Skip if no data for this player

        // General Stats
        generalData.push({
          name: player.name,
          noOfRounds: playerData.noOfRounds,
          scoreAvg: playerData.scoreAvg,
          sgPutting: playerData.sgPutting,
          sgARG: playerData.sgARG,
          sgApp: playerData.sgApp,
          sgOTT: playerData.sgOTT
        });

        // Scoring Stats
        scoringData.push({
          name: player.name,
          scoreAvg: playerData.scoreAvg,
          par3Avg: playerData.par3Avg,
          par4Avg: playerData.par4Avg,
          par5Avg: playerData.par5Avg,
          birdiesPerRound: playerData.birdiesPerRound,
          bogeysPerRound: playerData.bogeysPerRound,
          doublesPerRound: playerData.doublesPerRound,
          otherPerRound: playerData.otherPerRound
        });

        // Approach Stats
        approachData.push({
          name: player.name,
          scoreAvg: playerData.scoreAvg,
          sgApp: playerData.sgApp,
          sg50100: playerData.sg50100,
          sg100150: playerData.sg100150,
          sg150200: playerData.sg150200,
          sg200: playerData.sg200,
          gir: playerData.gir,
          gOrfir: playerData.gOrfir,
          gir100: `${playerData.gir100}%`,
          gir100150: `${playerData.gir100150}%`,
          gir150200: `${playerData.gir150200}%`,
          gir200: `${playerData.gir200}%`,
          eGPerRound: playerData.eGPerRound,
          iPPerRound: playerData.iPPerRound,
          bjKPerRound: playerData.bjKPerRound,
          appMissLeft: `${playerData.appMissLeft}%`,
          appMissRight: `${playerData.appMissRight}%`,
          appMissLong: `${playerData.appMissLong}%`,
          appMissShort: `${playerData.appMissShort}%`,
          easyMissPct: `${playerData.easyMissPct}%`,
          mediumMissPct: `${playerData.mediumMissPct}%`,
          hardMissPct: `${playerData.hardMissPct}%`,
          "expected.average": playerData.expected.average,
          "expected.hit": playerData.expected.averageHit,
          "expected.missed": playerData.expected.averageMissed
        });

        // Around the Green Stats
        argData.push({
          name: player.name,
          scoreAvg: playerData.scoreAvg,
          sgARG: playerData.sgARG,
          sgARG030: playerData.sgARG030,
          sgARG3050: playerData.sgARG3050,
          sgARGFringe: playerData.sgARGFringe,
          sgARGSand: playerData.sgARGSand,
          easyChipSG: playerData.easyChipSG,
          scrambling: `${playerData.scrambling}%`,
          p6: `${playerData.p6}%`,
          p12: `${playerData.p12}%`
        });

        // Putting Stats
        puttingData.push({
          name: player.name,
          scoreAvg: playerData.scoreAvg,
          sgPutting: playerData.sgPutting,
          makeRate3: `${playerData.makeRate3}%`,
          makeRate35: `${playerData.makeRate35}%`,
          makeRate510: `${playerData.makeRate510}%`,
          "sgPutt.sg20Birdie": playerData.sgPutt.sg20Birdie,
          "sgPutt.sg20Par": playerData.sgPutt.sg20Par,
          sgPuttingLagOver30Shot: playerData.sgPuttingLagOver30Shot,
          totalFirstPuttPerRound: playerData.totalFirstPuttPerRound,
          totalFeetMadePerRound: playerData.totalFeetMadePerRound
        });

        // Off the Tee Stats
        ottData.push({
          name: player.name,
          scoreAvg: playerData.scoreAvg,
          sgOTT: playerData.sgOTT,
          fairways: `${playerData.fairways}%`,
          leftRough: `${playerData.leftRough}%`,
          rightRough: `${playerData.rightRough}%`,
          driveDistance: playerData.driveDistance,
          "ottMiss.left": `${playerData.ottMiss.left}%`,
          "ottMiss.right": `${playerData.ottMiss.right}%`,
          "ottMiss.short": `${playerData.ottMiss.short}%`,
          "ottMiss.long": `${playerData.ottMiss.long}%`
        });
      });

      // Update all data tables
      setGeneralDataPrime(generalData);
      setScoringDataPrime(scoringData);
      setApproachDataPrime(approachData);
      setArgDataPrime(argData);
      setPuttingDataPrime(puttingData);
      setOttDataPrime(ottData);

      setRoundsFound(true);
    } catch (error) {
      console.error("Error filling out tables:", error);
      setRoundsFound(false);
    }
  };

  // Updated API call functions
  async function getMyRounds(durationType, timeDuration, numberOfRounds, roundType) {
    setLoading(true);
    try {
      let url;
      if (durationType.toLowerCase() === "time") {
        url = `/api/Calculation/user-last-duration?timeType=month&duration=${timeDuration}&userId=${user.$id}&roundType=${roundType}`;
      } else if (durationType.toLowerCase() === "number") {
        url = `/api/Calculation/user-last?numberOfRounds=${numberOfRounds}&userId=${user.$id}&roundType=${roundType}`;
      } else if (durationType.toLowerCase() === "semester") {
        url = `/api/Calculation/user-semester?semester=${timeDuration}&userId=${user.$id}&roundType=${roundType}`;
      } else if (durationType.toLowerCase() === "custom") {
        url = `/api/Calculation/user-custom?startDate=${startDate}&endDate=${endDate}&userId=${user.$id}&roundType=${roundType}`;
      }

      const data = await makeApiCall(url);
      fillOutTable({ [user.$id]: data }); // Format data for fillOutTable
      setRoundsFound(true);
    } catch (error) {
      console.error("Failed to get rounds:", error);
      setRoundsFound(false);
    } finally {
      setLoading(false);
    }
  }

  async function getSelectedRounds(durationType, timeDuration, numberOfRounds, roundType) {
    if (!selectedUsers.length) {
      console.warn("No users selected");
      return;
    }

    setLoading(true);
    try {
      let url;
      if (durationType.toLowerCase() === "time") {
        url = `/api/Calculation/team-last-duration?timeType=month&duration=${timeDuration}&roundType=${roundType}`;
      } else if (durationType.toLowerCase() === "number") {
        url = `/api/Calculation/team-last?numberOfRounds=${numberOfRounds}&roundType=${roundType}`;
      } else if (durationType.toLowerCase() === "semester") {
        url = `/api/Calculation/team-semester?semester=${timeDuration}&roundType=${roundType}`;
      } else if (durationType.toLowerCase() === "custom") {
        url = `/api/Calculation/team-custom?startDate=${startDate}&endDate=${endDate}&roundType=${roundType}`;
      }

      // Add selected users to query
      url += `&userIds=${selectedUsers.map(u => u.userId).join(',')}`;

      const data = await makeApiCall(url);
      fillOutTable(data); // Data should already be in correct format
      setRoundsFound(true);
    } catch (error) {
      console.error("Failed to get selected rounds:", error);
      setRoundsFound(false);
    } finally {
      setLoading(false);
    }
  }


  // Tour Avg. data into JSON
  const generateTourAvgGeneralJSON = (tourAvg) => {
    const tourAvgGeneralJSON = {
      name: "Tour Avg.",
    };
    // Iterate over the fields in tourAvg and include them in tourAvgGeneralJSON
    for (const field in tourAvg) {
      if (tourAvg.hasOwnProperty(field)) {
        for (const field2 in tourAvg[field]) {
          if (tourAvg[field][field2].hasOwnProperty("value")) {
            if (tourAvgGeneralJSON[field]) {
              tourAvgGeneralJSON[field][field2] = tourAvg[field][field2].value;
            } else {
              tourAvgGeneralJSON[field] = {};
              tourAvgGeneralJSON[field][field2] = tourAvg[field][field2].value;
            }
          }
          else {
            tourAvgGeneralJSON[field] = tourAvg[field].value
          }
        }
      }
    }
    return tourAvgGeneralJSON;
  };
  //Tour Avg JSON const
  const tourA = generateTourAvgGeneralJSON(constants.tourAvg);

  //GENERAL
  //General Columns //Headers are hardcoded as they never change
  const generalColumns = [
    { field: 'noOfRounds', header: "# Rounds" },
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'PUTT.strokesGainedPerRound', header: "SG PUTT" },
    { field: "sgARG.strokesGainedPerRound", header: "SG ARG" },
    { field: 'sgAPP.strokesGainedPerRound', header: "SG APP" },
    { field: "sgOTT.strokesGainedPerRound", header: "SG OTT" },
  ]
  //General data initialized to just tour avg
  const [generalDataPrime, setGeneralDataPrime] = useState([tourA]);
  //Matches data from databse to columns in prime table
  function getGeneral(name, data) {
    console.log(data)
    return {
      name: name,
      noOfRounds: data.noOfRounds,
      scoreAvg: data.scoreAvg,
      "PUTT.strokesGainedPerRound": data.putt.strokesGainedPerRound,
      "sgARG.strokesGainedPerRound": data.sgARG.strokesGainedPerRound,
      'sgAPP.strokesGainedPerRound': data.sgAPP.strokesGainedPerRound,
      "sgOTT.strokesGainedPerRound": data.sgOTT.strokesGainedPerRound
    }
  }

  //TIGER 5

  //Columns for prime table
  const tigerColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'bogeyPar5PerRound', header: 'Bogeys on par 5' },
    { field: 'doublePerRound', header: 'Doubles+' },
    { field: 'easyChipPerRound', header: 'Easy u&d' },
    { field: 'threePuttPerRound', header: '3-putts' },
    { field: 'bogey150PerRound', header: `Bogeys inside ${constants.pgaBase ? "150" : "125y"}` },
  ]
  //Data for Tiger 5 prime table
  var [tigerDataPrime, setTigerDataPrime] = useState([tourA]);

  //Matches data from databse to columns in prime table
  function getTiger(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      bogeyPar5PerRound: data.bogeyPar5PerRound,
      doublePerRound: data.doublePerRound,
      easyChipPerRound: data.easyChipPerRound + '%',
      threePuttPerRound: data.threePuttPerRound,
      bogey150PerRound: data.bogey150PerRound
    }
  }

  // Attempts tables
  const tourAvgTiger = ["Tour Avg", 0.16, 0.32, "90%", 0.53, 1.15];
  const [tigerData, setTigerData] = useState([tourAvgTiger]);
  var sampleTiger = {
    headers: [
      "Name",
      "Bogeys on Par 5",
      "Doubles+",
      "Easy u&d",
      "3-Putts",
      "Bogeys inside 150yds",
    ],
    rows: tigerData,
  };
  //Organizes data from database to match headers
  function getTigerRow(name, data) {
    return [
      name,
      [data.bogeyPar5Attempts, data.bogeyPar5Count, data.bogeyPar5PerRound], //"Bogeys on Par 5"
      ["--", data.doubleCount, data.doublePerRound], //"Doubles Bogeys"
      [data.easyChipAttempts, data.easyChipCount, data.easyChipPerRound + "%"], //"Easy chips"
      [data.threePuttAttempts, data.threePuttCount, data.threePuttPerRound], //"3-Putts"
      [data.bogey150Attempts, data.bogey150Count, data.bogey150PerRound], //"Bogeys inside 150yds"
    ];
  }


  //SCORING
  //Columns for prime table
  const scoringColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'par3Avg', header: "Par 3" },
    { field: 'par4Avg', header: "Par 4" },
    { field: 'par5Avg', header: "Par 5" },
    { field: 'birdiesPerRound', header: "Birdies" },
    { field: 'bogeysPerRound', header: "Bogeys" },
    { field: 'doublesPerRound', header: "Doubles" },
    { field: 'otherPerRound', header: "Other" },
  ]
  //Data for Scoring prime table
  var [scoringDataPrime, setScoringDataPrime] = useState([tourA])
  //Matches data from databse to columns in prime table
  function getScoring(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      par3Avg: data.par3Avg,
      par4Avg: data.par4Avg,
      par5Avg: data.par5Avg,
      birdiesPerRound: data.birdiesPerRound,
      bogeysPerRound: data.bogeysPerRound,
      doublesPerRound: data.doublesPerRound,
      otherPerRound: data.otherPerRound,
    }
  }

  //OFF THE TEE
  //Prime tables
  //Headers for prime tables
  const ottColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgOTT.strokesGainedPerRound', header: 'SG' },
    { field: 'ottFairwayHit.percentage', header: 'Fairway Hit' },
    { field: 'ottRecoveryHit.percentage', header: 'Tee Shots in Rec/Pen' },
    { field: 'ottSandHit.percentage', header: 'Tee Shots in Sand' },
    { field: 'teeMissLeft', header: 'Miss Left' },
    { field: 'teeMissRight', header: 'Miss Right' }
  ]
  const advancedOttColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgOTT.strokesGainedPerRound', header: 'SG' },
    { field: 'ottFairwayHit.percentage', header: 'Fairway Hit' },
    { field: 'ottRecoveryHit.percentage', header: 'Tee Shots in Rec/Pen' },
    { field: 'ottSandHit.percentage', header: 'Tee Shots in Sand' },
    { field: 'ottdriverHit.percentage', header: 'Fairway Hit Driver' },
    { field: 'ott3wHit.percentage', header: 'Fairway Hit 3w' },
    { field: 'ott5wHit.percentage', header: 'Fairway Hit 5w' },
    { field: 'ott7wHit.percentage', header: 'Fairway Hit 7w' },
    { field: 'ott3hHit.percentage', header: 'Fairway Hit 3h' },
    { field: 'ott4hHit.percentage', header: 'Fairway Hit 4h' },
    { field: 'ott5hHit.percentage', header: 'Fairway Hit 5h' },
    { field: 'ott2iHit.percentage', header: 'Fairway Hit 2i' },
    { field: 'ott3iHit.percentage', header: 'Fairway Hit 3i' },
    { field: 'ott4iHit.percentage', header: 'Fairway Hit 4i' },
    { field: 'teeMissLeft', header: 'Miss Left' },
    { field: 'teeMissRight', header: 'Miss Right' }
  ]
  //Data for Prime tables initizlized to tour avg
  var [ottDataPrime, setOttDataPrime] = useState([tourA])
  //Matches data from databse to columns in prime table
  function getOtt(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      "sgOTT.strokesGainedPerRound": data.sgOTT.strokesGainedPerRound,
      'ottFairwayHit.percentage': data.ottFairwayHit.percentage + '%',
      'ottdriverHit.percentage': data.ottdriverHit.percentage + '%',
      'ott3wHit.percentage': data.ott3wHit.percentage + '%',
      'ott5wHit.percentage': data.ott5wHit.percentage + '%',
      'ott7wHit.percentage': data.ott7wHit.percentage + '%',
      'ott3hHit.percentage': data.ott3hHit.percentage + '%',
      'ott4hHit.percentage': data.ott4hHit.percentage + '%',
      'ott5hHit.percentage': data.ott5hHit.percentage + '%',
      'ott2iHit.percentage': data.ott2iHit.percentage + '%',
      'ott3iHit.percentage': data.ott3iHit.percentage + '%',
      'ott4iHit.percentage': data.ott4iHit.percentage,
      'ottRecoveryHit.percentage': data.ottRecoveryHit.percentage + '%',
      'ottSandHit.percentage': data.ottSandHit.percentage + '%',
      teeMissLeft: data.teeMissLeft + '%',
      teeMissRight: data.teeMissRight + '%'
    }

  }

  //Attempts table
  const tourAvgTee = [
    "Tour Avg",
    "--",
    "60%",
    "--",
    "--",
    "--",
    "--",
    "--",
    "--",
    "--",
    "--",
    "--",
    "--",
    "5.4%",
    "5.8%",
    "--",
    "--",
  ];
  const [teeData, setTeeData] = useState([tourAvgTee]);
  var sampleTee = {
    headers: [
      "Name",
      "SG",
      "Fairway Hit",
      "Fairway Hit Driver",
      "Fairway Hit 3w",
      "Fairway Hit 5w",
      "Fairway Hit 7w",
      "Fairway hit 3h",
      "Fairway hit 4h",
      "Fairway Hit 5h",
      "Fairway Hit 2i",
      "Fairway Hit 3i",
      "Fairway Hit 4i",
      "Tee Shots in Recovery",
      "Tee Shots in Sand",
      "Miss Right",
      "Miss Left",
    ],
    rows: teeData,
  };
  //Organizes data from database to match headers
  function getTeeRow(name, data) {
    return [
      name,
      data.sgOTT.strokesGainedPerRound, //"Strokes gained per round"
      [data.ottFairwayHit.attempts, data.ottFairwayHit.hitCount, data.ottFairwayHit.percentage + "%"],
      [data.ottdriverHit.attempts, data.ottdriverHit.hitCount, data.ottdriverHit.percentage + "%",],
      [data.ott3wHit.attempts, data.ott3wHit.hitCount, data.ott3wHit.percentage + "%"],
      [data.ott5wHit.attempts, data.ott5wHit.hitCount, data.ott5wHit.percentage + "%"],
      [data.ott7wHit.attempts, data.ott7wHit.hitCount, data.ott7wHit.percentage + "%"],
      [data.ott3hHit.attempts, data.ott3hHit.hitCount, data.ott3hHit.percentage + "%"],
      [data.ott4hHit.attempts, data.ott4hHit.hitCount, data.ott4hHit.percentage + "%"],
      [data.ott5hHit.attempts, data.ott5hHit.hitCount, data.ott5hHit.percentage + "%"],
      [data.ott2iHit.attempts, data.ott2iHit.hitCount, data.ott2iHit.percentage + "%"],
      [data.ott3iHit.attempts, data.ott3iHit.hitCount, data.ott3iHit.percentage + "%"],
      [data.ott4iHit.attempts, data.ott4iHit.hitCount, data.ott4iHit.percentage + "%"],
      [data.ottRecoveryHit.attempts, data.ottRecoveryHit.hitCount, data.ottRecoveryHit.percentage + "%"],
      [data.ottSandHit.attempts, data.ottSandHit.hitCount, data.ottSandHit.percentage + "%"],
      data.teeMissRight + "%", //"Miss Right"
      data.teeMissLeft + "%", //"Miss Left"
    ];
  }

  //APPROACH
  const approachColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgAPP.strokesGainedPerRound', header: 'SG' },
    { field: 'sg50100.strokesGainedPerShot', header: 'SG 50-100*' },
    { field: 'sg100150.strokesGainedPerShot', header: 'SG 100-150*' },
    { field: 'sg150200.strokesGainedPerShot', header: 'SG 150-200*' },
    { field: 'sg200.strokesGainedPerShot', header: 'SG 200+*' },
    { field: 'gir.perRound', header: 'GIR' },
    { field: 'gOrfir.perRound', header: 'G/FIR' },
    { field: 'gir50100.percentage', header: 'GIR <100' },
    { field: 'gir100150.percentage', header: 'GIR 100-150' },
    { field: 'gir150200.percentage', header: 'GIR 150-200' },
    { field: 'gir200.percentage', header: 'GIR 200+' },
    { field: 'eG.perRound', header: 'GIR to ≤40ft' },
    { field: 'iP.perRound', header: 'GIR to ≤20ft' },
    { field: 'bJK.perRound', header: 'GIR to ≤8ft' },
    { field: 'appMissLeft', header: 'Miss left' },
    { field: 'appMissRight', header: 'Miss Right' },
    { field: 'appMissLong', header: 'Miss Long' },
    { field: 'appMissShort', header: 'Miss Short' },
    { field: 'easyMissPct', header: 'Miss to easy' },
    { field: 'mediumMissPct', header: 'Miss to medium' },
    { field: 'hardMissPct', header: 'Miss to hard' },
  ]
  const advancedApproachColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgAPP.strokesGainedPerRound', header: 'SG' },
    { field: 'sg5075.strokesGainedPerShot', header: 'SG 50-75*' },
    { field: 'sg75100.strokesGainedPerShot', header: 'SG 75-100*' },
    { field: 'sg100125.strokesGainedPerShot', header: 'SG 100-125*' },
    { field: 'sg125150.strokesGainedPerShot', header: 'SG 150-150*' },
    { field: 'sg150175.strokesGainedPerShot', header: 'SG 150-175*' },
    { field: 'sg175200.strokesGainedPerShot', header: 'SG 175-200*' },
    { field: 'sg200.strokesGainedPerShot', header: 'SG 200+*' },
    { field: 'gir.perRound', header: 'GIR' },
    { field: 'gOrfir.perRound', header: 'G/FIR' },
    { field: 'gir5075.percentage', header: 'GIR <75' },
    { field: 'gir75100.percentage', header: 'GIR 75-100' },
    { field: 'gir100125.percentage', header: 'GIR 100-125' },
    { field: 'gir125150.percentage', header: 'GIR 125-150' },
    { field: 'gir150175.percentage', header: 'GIR 150-175' },
    { field: 'gir175200.percentage', header: 'GIR 175-200' },
    { field: 'gir200.percentage', header: 'GIR 200+' },
    { field: 'eG.perRound', header: 'GIR to ≤40ft' },
    { field: 'iP.perRound', header: 'GIR to ≤20ft' },
    { field: 'bJK.perRound', header: 'GIR to ≤8ft' },
    { field: 'appMissLeft', header: 'Miss left' },
    { field: 'appMissRight', header: 'Miss Right' },
    { field: 'appMissLong', header: 'Miss Long' },
    { field: 'appMissShort', header: 'Miss Short' },
    { field: 'easyMiss.percentage', header: 'Miss to easy' },
    { field: 'mediumMiss.percentage', header: 'Miss to medium' },
    { field: 'hardMiss.percentage', header: 'Miss to hard' },
    { field: 'allExpectedApp.averageExpected', header: 'Expected Strokes afer app' },
    { field: 'girHitExpectedApp.averageExpected', header: 'Expected Strokes afer app (green hit)' },
    { field: 'girMissedExpectedApp.averageExpected', header: 'Expected Strokes afer app (green miss)' }
  ]
  const [approachDataPrime, setApproachDataPrime] = useState([tourA])
  useEffect(() => {
    setApproachDataPrime([tourA])
  }, [statsType])
  function getApproach(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      "sgAPP.strokesGainedPerRound": data.sgAPP.strokesGainedPerRound,
      "sg50100.strokesGainedPerShot": data.sg50100.strokesGainedPerShot,
      "sg100150.strokesGainedPerShot": data.sg100150.strokesGainedPerShot,
      "sg150200.strokesGainedPerShot": data.sg150200.strokesGainedPerShot,
      "sg5075.strokesGainedPerShot": data.sg5075.strokesGainedPerShot,
      "sg75100.strokesGainedPerShot": data.sg75100.strokesGainedPerShot,
      "sg100125.strokesGainedPerShot": data.sg100125.strokesGainedPerShot,
      "sg125150.strokesGainedPerShot": data.sg125150.strokesGainedPerShot,
      "sg150175.strokesGainedPerShot": data.sg150175.strokesGainedPerShot,
      "sg175200.strokesGainedPerShot": data.sg175200.strokesGainedPerShot,
      "sg200.strokesGainedPerShot": data.sg200.strokesGainedPerShot,
      "gir.perRound": data.gir.perRound,
      "gOrfir.perRound": data.gOrfir.perRound,
      "gir50100.percentage": data.gir50100.percentage + '%',
      "gir100150.percentage": data.gir100150.percentage + '%',
      "gir150200.percentage": data.gir150200.percentage + '%',
      "gir5075.percentage": data.gir5075.percentage + '%',
      "gir75100.percentage": data.gir75100.percentage + '%',
      "gir100125.percentage": data.gir100125.percentage + '%',
      "gir125150.percentage": data.gir125150.percentage + '%',
      "gir150175.percentage": data.gir150175.percentage + '%',
      "gir175200.percentage": data.gir175200.percentage + '%',
      "gir200.percentage": data.gir200.percentage + '%',
      "eG.perRound": data.eG.perRound,
      "iP.perRound": data.iP.perRound,
      "bJK.perRound": data.bJK.perRound,
      appMissLeft: data.appMissLeft + '%',
      appMissRight: data.appMissRight + '%',
      appMissLong: data.appMissLong + '%',
      appMissShort: data.appMissShort + '%',
      "easyMiss.percentage": data.easyMiss.percentage + '%',
      "mediumMiss.percentage": data.mediumMiss.percentage + '%',
      "hardMiss.percentage": data.hardMiss.percentage + '%',
      'allExpectedApp.averageExpected': data.allExpectedApp.averageExpected,
      'girHitExpectedApp.averageExpected': data.girHitExpectedApp.averageExpected,
      'girMissedExpectedApp.averageExpected': data.girMissedExpectedApp.averageExpected
    }

  }
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgApproach = [
    "Tour Avg.",
    "--",
    "--",
    "--",
    "--",
    "--",
    "11.8",
    "12.9",
    // "88%",
    // "78%",
    // "75%",
    // "70%",
    // "64%",
    // "55%",
    "85%",
    "72%",
    "59%",
    "44%",
    10.7,
    7,
    2.7,
    "--",
    "--",
    "--",
    "--",

  ];
  const [approachData, setApproachData] = useState([tourAvgApproach]);
  var sampleApproach = {
    headers: [
      "Name",
      "SG Total",
      "SG 50-100*",
      "SG 100-150*",
      "SG 150-200*",
      "SG 200+*",
      "GIR",
      "G/FIR",
      "GIR <100",
      "GIR 100-150",
      "GIR 150-200",
      "GIR 200+",
      "GIR to ≤40ft",
      "GIR to ≤20ft",
      "GIR to ≤8ft",
      "Miss left (%)",
      "Miss right (%)",
      "Miss long (%)",
      "Miss short (%)",
    ],
    rows: approachData,
  };
  //Organizes data from database to match headers
  function getApproachRow(name, data) {
    return [
      name,
      data.sgAPP, //"Strokes Gained Total per Round"
      [data.sg50100.attempts, "--", data.sg50100.strokesGainedPerShot], //"Strokes Gained 50-100 per Shot"
      [data.sg100150.attempts, "--", data.sg100150.strokesGainedPerShot], //"Strokes Gained 100-150 per Shot"
      [data.sg150200.attempts, "--", data.sg150200.strokesGainedPerShot], //"Strokes Gained 150-200 per Shot"
      [data.sg200.attempts, "--", data.sg200.strokesGainedPerShot], //"Strokes Gained 200+ per Shot"
      [data.gir.attempts, data.gir.hitCount, data.gir.percentage],
      [data.gOrfir.attempts, data.gOrfir.hitCount, data.gOrfir.percentage], //gorFIR
      [data.gir50100.attempts, data.gir50100.hitCount, data.gir50100.percentage + "%"], //"GIR <100"
      [data.gir100150.attempts, data.gir100150.hitCount, data.gir100150.percentage + "%"], //"GIR 100-150"
      [data.gir150200.attempts, data.gir150200.hitCount, data.gir150200.percentage + "%"], //"GIR 150-200"
      [data.gir200.attempts, data.gir200.hitCount, data.gir200.percentage + "%"], //"GIR 200+"
      [data.eG.attempts, data.eG.hitCount.success, data.eG.perRound], //"Shots hit to 40ft or Less"
      [data.iP.attempts, data.iP.hitCount.success, data.iP.perRound], //"Shots hit to 20ft or Less"
      [data.bJK.attempts, data.bJK.hitCount.success, data.bJK.perRound], //"Shots hit to 8ft or Less"
      data.appMissLeft + "%", //"Miss left (%)"
      data.appMissRight + "%", //"Miss right (%)"
      data.appMissLong + "%", //"Miss long (%)"
      data.appMissShort + "%", //"Miss short (%)"
    ];
  }




  //AROUND THE GREEN 
  //Prime Tables
  //prime tables headers
  const argColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgARG.strokesGainedPerRound', header: 'SG' },
    { field: 'sg015.strokesGainedPerShot', header: 'SG 0-15' },
    { field: 'sg1530.strokesGainedPerShot', header: 'SG 15-30' },
    { field: 'sg3050.strokesGainedPerShot', header: 'SG 30-50' },
    { field: 'scrambling.percentage', header: 'Scrambling' },
    { field: 'p6.percentage', header: 'Chips Hit to ≤6ft' },
    { field: 'p12.percentage', header: 'Chips Hit to ≤12ft' },
  ]
  const advancedArgColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgARG.strokesGainedPerRound', header: 'SG' },
    { field: 'sg015.strokesGainedPerShot', header: 'SG 0-15' },
    { field: 'sg1530.strokesGainedPerShot', header: 'SG 15-30' },
    { field: 'sg3050.strokesGainedPerShot', header: 'SG 30-50' },
    { field: 'sgFringe.strokesGainedPerShot', header: 'SG Fringe*' },
    { field: 'sgSand.strokesGainedPerShot', header: 'SG Sand*' },
    { field: 'sgEasyChip.strokesGainedPerShot', header: 'SG easy chip' },
    { field: 'scrambling.percentage', header: 'Scrambling' },
    { field: 'p6.percentage', header: 'Chips Hit to ≤6ft' },
    { field: 'p12.percentage', header: 'Chips Hit to ≤12ft' },
  ]
  //prime table arg data
  var [argDataPrime, setArgDataPrime] = useState([tourA]);
  //Matches data from database to columns in prime table
  function getArg(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      sgARGperRound: data.sgARGperRound,
      "sg015.strokesGainedPerShot": data.sg015.strokesGainedPerShot,
      "sg1530.strokesGainedPerShot": data.sg1530.strokesGainedPerShot,
      'sg3050.strokesGainedPerShot': data.sg3050.strokesGainedPerShot,
      'sgFringe.strokesGainedPerShot': data.sgFringe.strokesGainedPerShot,
      'sgSand.strokesGainedPerShot': data.sgSand.strokesGainedPerShot,
      'sgEasyChip.strokesGainedPerShot': data.sgEasyChip.strokesGainedPerShot,
      'scrambling.percentage': data.scrambling.percentage + '%',
      'p6.percentage': data.p6.percentage + '%',
      'p12.percentage': data.p12.percentage + '%',
    }
  }
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgSg = ["Tour Avg", "--", "--", "--", "58.3%", "50%", "67%",];
  const [sgData, setSgData] = useState([tourAvgSg]);
  var sampleSg = {
    headers: [
      "Name",
      "SG",
      "SG Fringe*",
      "SG Sand*",
      "Scrambling",
      "Chips Hit to ≤6ft",
      "Chips Hit to ≤12ft"
    ],
    rows: sgData,
  };
  //Organizes data from database to match headers
  function getSgRow(name, data) {
    return [
      name,
      data.sgARG.strokesGainedPerRound, //"Strokes Gained Total per Round"
      [data.sgFringe.attempts, "--", data.sgFringe.strokesGainedPerShot], //"Strokes Gained from Fringe per Shot"
      [data.sgSand.attempts, "--", data.sgSand.attempts], //"Strokes Gained from Sand per Shot"
      [data.scrambling.attempts, data.scrambling.hitCount, data.scrambling.percentage + "%"], //"Scrambling"
      [data.p6.attempts, data.p6.hitCount, data.p6.percentage + "%"], //"Chips Hit to 6ft or Less"
      [data.p12.attempts, data.p12.hitCount, data.p12.percentage + "%"], //"Chips Hit to 12ft or Less"
    ];
  }

  //PUTTING
  const advancedPuttingColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'PUTT.strokesGainedPerRound', header: 'SG' },
    { field: 'putt3.strokesGainedPerShot', header: "SG 3'" },
    { field: 'putt35.strokesGainedPerShot', header: "SG 3-5'" },
    { field: 'putt510.strokesGainedPerShot', header: "SG 5-10'" },
    { field: 'putt1020.strokesGainedPerShot', header: "SG 10-20'" },
    { field: 'putt2030.strokesGainedPerShot', header: "SG 20-30'" },
    { field: 'putt30.strokesGainedPerShot', header: "SG 30+'" },
    { field: 'putt20Birdie.strokesGainedPerShot', header: "SG <20' for Birdie" },
    { field: 'putt20Birdie.strokesGainedPerShot', header: "SG <20' for Par" },
    { field: 'putt3.percentage', header: "Make 3'" },
    { field: 'putt35.percentage', header: "Make 3-5'" },
    { field: 'putt510.percentage', header: "Make 5-10'" },
    { field: 'totalFirstPuttPerRound', header: "Total ft of 1st Putts" },
    { field: 'totalFeetMadePerRound', header: "Total ft of Putts Made" },
  ]
  const puttingColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'PUTT.strokesGainedPerRound', header: 'SG' },
    { field: 'putt3.strokesGainedPerShot', header: "SG 3'" },
    { field: 'putt35.strokesGainedPerShot', header: "SG 3-5'" },
    { field: 'putt510.strokesGainedPerShot', header: "SG 5-10'" },
    { field: 'putt1020.strokesGainedPerShot', header: "SG 10-20'" },
    { field: 'putt2030.strokesGainedPerShot', header: "SG 20-30'" },
    { field: 'putt30.strokesGainedPerShot', header: "SG 30+'" },
  ]
  const [puttingDataPrime, setPuttingDataPrime] = useState([tourA]);

  function getPutting(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      "PUTT.strokesGainedPerRound": data.putt.strokesGainedPerRound,
      "putt3.strokesGainedPerShot": data.putt3.strokesGainedPerShot,
      "putt35.strokesGainedPerShot": data.putt35.strokesGainedPerShot,
      "putt510.strokesGainedPerShot": data.putt510.strokesGainedPerShot,
      "putt1020.strokesGainedPerShot": data.putt1020.strokesGainedPerShot,
      "putt2030.strokesGainedPerShot": data.putt2030.strokesGainedPerShot,
      "putt30.strokesGainedPerShot": data.putt30.strokesGainedPerShot,
      "putt20Birdie.strokesGainedPerShot": data.putt20Birdie.strokesGainedPerShot,
      "putt20Par.strokesGainedPerShot": data.putt20Par.strokesGainedPerShot,
      "putt3.percentage": data.putt3.percentage + '%',
      "putt35.percentage": data.putt35.percentage + '%',
      "putt510.percentage": data.putt510.percentage + '%',
      totalFirstPuttPerRound: data.totalFirstPuttPerRound + "'",
      totalFeetMadePerRound: data.totalFeetMadePerRound + "'",
    }
  }

  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgPutt = [
    "Tour Avg",
    "--",
    "99.53%",
    "87.87%",
    "56.29%",
    "45%",
    "45%",
    "10%",
    "--",
    `72'9"`,
  ];
  const [puttData, setPuttData] = useState([tourAvgPutt]);
  var samplePutt = {
    headers: [
      "Name",
      "SG",
      "Make Rate 3'",
      "Make Rate 3-5'",
      "Make Rate 5-10'",
      "SG inside 20' for Birdie",
      "SG inside 20' for Par",
      "Prox. of the First Putt >30'",
      "Total ft of 1st Putts",
      "Total ft of Putts Made",
    ],
    rows: puttData,
  };
  //Organizes data from database to match headers
  function getPuttRow(name, data) {
    return [
      name,
      data.sgPutting, //"Strokes gained"
      [data.attempts3, data.success3, data.makeRate3 + "%"], //"Make Rate 3'"
      [data.attempts35, data.success35, data.makeRate35 + "%"], //"Make Rate 3-5'"
      [data.attempts510, data.success510, data.makeRate510 + "%"], //"Make Rate 5-10'"
      [
        data.attempts515Birdie, data.success515Birdie, data.makeRate515Birdie + "%",
      ], //"Make Rate 5-15' for Birdie"
      [data.attempts515Par, data.success515Par, data.makeRate515Par + "%"], //"Make Rate 5-15' for Par"
      data.puttsOver30Proximity + "%", //"Proximity of the First Putt >20'"
      data.totalFirstPuttPerRound + "'", //"Total ft of First Putts Per Round"
      data.totalFeetMadePerRound + "'", //"Total ft of Putts Made"
    ];
  }




  //MISTAKE TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  //No tour avg for mistake data
  const [mistakeData, setMistakeData] = useState([]);
  var sampleMistake = {
    headers: [
      "Name",
      "Good: OTT",
      "Technique: OTT",
      "Mental State: OTT",
      "Decision: OTT",
      "Good: APP",
      "Technique: APP",
      "Mental State: APP",
      "Decision: APP",
      "Good: ARG",
      "Technique: ARG",
      "Mental State: ARG",
      "Decision: ARG",
      "Good: PUTT",
      "Technique: PUTT",
      "Mental State: PUTT",
      "Decision: PUTT",
    ],
    rows: mistakeData,
  };
  //Organizes data from database to match headers
  function getMistakeRow(name, data) {
    return [
      name,
      data.goodTee + "%", //"Good Shots: Tee"
      data.techniqueTee + "%", //"Technique Error: Tee"
      data.mentalTee + "%", //"Mental State Error: Tee"
      data.decisionTee + "%", //"Decision Error: Tee"
      data.goodApp + "%", //"Good Shots: App"
      data.techniqueApp + "%", //"Technique Error: App"
      data.mentalApp + "%", //"Mental State Error: App"
      data.decisionApp + "%", //"Decision Error: App"
      data.goodArg + "%", //"Good Shots: Arg"
      data.techniqueArg + "%", //"Technique Error: Arg"
      data.mentalArg + "%", //"Mental State Error: Arg"
      data.decisionArg + "%", //"Decision Error: Arg"
      data.goodPutting + "%", //"Good Shots: Putting"
      data.techniquePutting + "%", //"Technique Error: Putting"
      data.mentalPutting + "%", //"Mental State Error: Putting"
      data.decisionPutting + "%", //"Decision Error: Putting"
    ];
  }

  //GFI TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgGfi = ["Tour Avg", "57%", "22%", "57%", "--", "24.5 yds"];
  const [gfiData, setGfiData] = useState([tourAvgGfi]);
  var sampleGfi = {
    headers: [
      "Name",
      "Going for the Green",
      "Green Hit Pct",
      "Birdie or Better",
      "Bogey or Worse",
      "Avg. Dst. after Going for it",
    ],
    rows: gfiData,
  };
  //Organizes data from database to match headers
  function getGfiRow(name, data) {
    return [
      name,
      [data.goingForItAttempts, data.goingForItCount, data.goingForItPct + "%"], //"Going for the Green"
      [
        data.goingGreenHitAttempts,
        data.goingGreenHitCount,
        data.goingGreenHitPct + "%",
      ], //"Going for the Green - Green Hit Pct"
      [
        data.goingBirdieAttempts,
        data.goingBirdieCount,
        data.goingBirdiePct + "%",
      ], //"Going for the Green - Birdie or Better"
      [data.goingBogeyAttempts, data.goingBogeyCount, data.goingBogeyPct + "%"], //"Going for the Green - Bogey or Worse"
      data.goingDistanceAfter + "yds", //"Average Distance after Going for it Shot"
    ];
  }


  const goingColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'goingForItPct', header: 'Going for the Green' },
    { field: 'goingGreenHitPct', header: 'Green Hit %' },
    { field: 'goingBirdiePct', header: 'Birdie or Better %' },
    { field: 'goingBogeyPct', header: 'Bogey or Worse %' },
    { field: 'goingDistanceAfter', header: 'Avg. Dst. after Going for it' },
    { field: 'avgGoing', header: 'Going for it avg.' },
    { field: 'avgLayUp', header: 'Lay up avg.' },

  ]

  var [goingDataPrime, setGoingDataPrime] = useState([tourA]);
  function getGoing(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      goingForItPct: data.goingForItPct + '%',
      goingGreenHitPct: data.goingGreenHitPct + '%',
      goingBirdiePct: data.goingBirdiePct + '%',
      goingBogeyPct: data.goingBogeyPct + '%',
      goingDistanceAfter: data.goingDistanceAfter + " yds",
      avgGoing: data.avgGoing,
      avgLayUp: data.avgLayUp
    }

  }

  function getDataType(cell, isClubs) {
    if (showAttempts) {
      if (!isClubs) {
        return <div><li>{cell[1]}/{cell[0]}</li><li>{cell[2]}</li></div>
      } else {
        return <div><li>Att: {cell[0]}</li><li>SG: {cell[1]}</li></div>
      }
    } else {
      if (!isClubs) {
        return <div><li>{cell[2]}</li></div>
      } else {
        return <div><li>{cell[1]}</li></div>
      }
    }

  }

  function getUserName(userName) {
    return userName;
  }

  //Pulls data from database and updates data rows for each table
  function processRounds(userId, data, userName) {
    var name = getUserName(userName);
    setApproachData((approachData) => [
      ...approachData,
      getApproachRow(name, data),
    ]);
    setSgData((sgData) => [...sgData, getSgRow(name, data)]);
    setPuttData((puttData) => [...puttData, getPuttRow(name, data)]);
    setTeeData((teeData) => [...teeData, getTeeRow(name, data)]);
    setTigerData((tigerData) => [...tigerData, getTigerRow(name, data)]);

    setMistakeData((mistakeData) => [
      ...mistakeData,
      getMistakeRow(name, data),
    ]);
    setGfiData((gfiData) => [...gfiData, getGfiRow(name, data)]);

    //prime setters
    setTigerDataPrime((tigerDataPrime) => [...tigerDataPrime, getTiger(name, data)])
    setScoringDataPrime((scoringDataPrime) => [...scoringDataPrime, getScoring(name, data)])
    setApproachDataPrime((approachDataPrime) => [...approachDataPrime, getApproach(name, data)])
    setArgDataPrime((argDataPrime) => [...argDataPrime, getArg(name, data)]);
    setPuttingDataPrime((puttingDataPrime) => [...puttingDataPrime, getPutting(name, data)])
    setOttDataPrime((ottDatePrime) => [...ottDatePrime, getOtt(name, data)]);
    setGoingDataPrime((goingDataPrime) => [...goingDataPrime, getGoing(name, data)]);
    setGeneralDataPrime((generalDataPrime) => [...generalDataPrime, getGeneral(name, data)])

    setWasDataLoaded(true);
  }

  //Creates a table based on data and if it is visible or not
  function generateTable(data, isClubs) {
    return (
      <div>
        <div className="table-container" style={{ overflowX: "scroll" }}>
          <Table className="table" style={{ whiteSpace: "nowrap" }}>
            <thead>
              <tr>
                {data.headers.map((header) => (
                  <th
                    key={header}
                    className={header === "Name" ? "name" : "header"}
                    style={{ textAlign: "center" }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.rows.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={` ${rowIndex % 2 === 0 ? "even" : "odd"}`}
                >
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={!wasDataLoaded ?
                        `loading-cell ${cellIndex === 0
                          ? rowIndex % 2 === 0
                            ? "name1"
                            : "name2"
                          : "data"}` : ` ${cellIndex === 0
                            ? rowIndex % 2 === 0
                              ? "name1"
                              : "name2"
                            : "data"}`
                      }
                      style={
                        cellIndex === 0
                          ? {
                            position: "sticky",
                            top: "0",
                            zIndex: "15",
                            width: "auto",
                            padding: "9px",
                            left: "0",
                            backgroundColor: "white",
                            textAlign: "center",
                          }
                          : { textAlign: "center" }
                      }
                    >
                      {Array.isArray(cell) ? (
                        // If the cell is an array of data values, map through it
                        <ul
                          className="uList"
                          style={{
                            listStyleType: "none",
                            justifyContent: "center",
                            margin: "0",
                            padding: "0",
                          }}
                        >

                          {getDataType(cell, isClubs)}
                        </ul>
                      ) : (
                        // Otherwise, render the cell as a regular table cell
                        cell
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }

  const cellClass = (data, obj) => {
    if (obj.field !== "name") {
      var nameArr = obj.field.split('.')
      var objName = "";
      var name1 = obj.field.split('.')[0];
      if (nameArr.length > 1) {
        var name2 = obj.field.split('.')[1];
        objName = `constants.tourAvg["${name1}"]["${name2}"]`
      } else {
        objName = `constants.tourAvg["${name1}"]`
      }
      if (parseFloat(data) < parseFloat(constants.tourAvg[obj.field]?.redValue) && !constants.tourAvg[obj.field]?.low) {
        return {
          'bg-danger bg-gradient text-white': true
        }
      } else if (parseFloat(data) < parseFloat(constants.tourAvg[obj.field]?.greenValue) && constants.tourAvg[obj.field]?.low) {
        return {
          'bg-success bg-gradient text-white': true
        }
      } else if (parseFloat(data) > parseFloat(constants.tourAvg[obj.field]?.redValue) && constants.tourAvg[obj.field]?.low) {
        return {
          'bg-danger bg-gradient text-white': true
        }
      } else if (parseFloat(data) > parseFloat(constants.tourAvg[obj.field]?.greenValue) && !constants.tourAvg[obj.field]?.low) {
        return {
          'bg-success bg-gradient text-white': true
        }
      }
    }
  }

  const rowClass = (data) => {
    return {
      'fw-bold': data.name === "Tour Avg." || data.name === "Team Avg."
    }
  }


  function generatePrimeTables(data, columns) {
    return (
      <div>
        <DataTable size={primeTableSize < 20 ? "small" : primeTableSize > 80 ? "large" : "normal"} scrollable
          value={data} rowClassName={rowClass} cellClassName={cellClass}
          removableSort reorderableColumns>
          <Column className="font-weight-bold" field="name" header="Name" frozen></Column>
          {columns.map((col, i) => (
            <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }}></Column>
          ))}
        </DataTable>
        {!wasDataLoaded && <ProgressBar className="mb-2 mt-1" mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
      </div>
    )
  }

  async function loadData() {
    setLoading(true);
    //For each user active
    //userId, durationType, timeDuration, numberOfRounds, roundType, callback
    setApproachData([tourAvgApproach]);
    setSgData([tourAvgSg]);
    setPuttData([tourAvgPutt]);
    setTeeData([tourAvgTee]);
    setTigerData([tourAvgTiger]);
    setMistakeData([]);
    setGfiData([tourAvgGfi]);

    //prime setters
    setTigerDataPrime([tourA])
    setScoringDataPrime([tourA])
    setApproachDataPrime([tourA])
    setArgDataPrime([tourA])
    setPuttingDataPrime([tourA])
    setOttDataPrime([tourA])
    setGoingDataPrime([tourA])
    setGeneralDataPrime([tourA])
    const JWT = await getJWT();

    if (custom && startDate !== null && endDate !== null) {
      getRoundsCustomCallbackList(
        displayedPlayers,
        startDate,
        endDate,
        roundType,
        (data) => processRounds(1, data, "Team Avg."),
        JWT
      )
      displayedPlayers.map((user) =>
        getRoundsCustomCallback(
          user.userId.substring(user.userId.indexOf('|') + 1),
          startDate,
          endDate,
          roundType,
          (data) => processRounds(user.userId.substring(user.userId.indexOf('|') + 1), data, user.name),
          JWT
        )
      );
    } else if (durationType.toLowerCase() === "fall") {
      getRoundsSemesterCallbackList(
        displayedPlayers,
        "2024-08-25",
        "2024-10-29",
        roundType,
        (data) => processRounds(1, data, "Team Avg."),
        JWT
      )
      displayedPlayers.map((user) =>
        getRoundsSemesterCallback(
          user.userId.substring(user.userId.indexOf('|') + 1),
          "2023-08-25",
          "2023-10-29",
          roundType,
          (data) => processRounds(user.userId.substring(user.userId.indexOf('|') + 1), data, user.name),
          JWT
        )
      );
    } else if (durationType.toLowerCase() === "spring") {
      getRoundsSemesterCallbackList(
        displayedPlayers,
        "2023-02-10",
        "2023-05-01",
        roundType,
        (data) => processRounds(1, data, "Team Avg."),
        JWT
      )
      displayedPlayers.map((user) =>
        getRoundsSemesterCallback(
          user.userId.substring(user.userId.indexOf('|') + 1),
          "2023-02-10",
          "2023-05-01",
          roundType,
          (data) => processRounds(user.userId.substring(user.userId.indexOf('|') + 1), data, user.name),
          JWT
        )
      );
    } else {
      getRoundsCallbackList(
        displayedPlayers,
        durationType,
        timeDuration,
        numberOfRounds,
        roundType,
        (data) => processRounds(1, data, "Team Avg."),
        JWT
      )
      displayedPlayers.map((user) =>
        getRoundsCallback(
          user.userId.substring(user.userId.indexOf('|') + 1),
          durationType,
          timeDuration,
          numberOfRounds,
          roundType,
          (data) => processRounds(user.userId.substring(user.userId.indexOf('|') + 1), data, user.name),
          JWT
        )
      );
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }


  /**
   * Renders dropdowns for filtering out rounds.
   * If custom is selected it will render two date inputs.
   * If tournament is selected it will render dropdown with tournaments played.
   */
  function renderFilters() {
    if (!custom && !selectTournament) {
      return (
        <div>
          <Row>
            {statsType !== "Basic" && <Col xs="2" lg="2">
              <div>Show attempts</div>
              <InputSwitch checked={showAttempts} onChange={(e) => setShowAttempts(e.value)} />
            </Col>}
            <Col xs="5" lg="1">
              <ToggleButton checked={constants.pgaBase} onChange={(e) => { constants.setPgaBase(e.value); }} onLabel="PGA" offLabel="LPGA" />
            </Col>
            <Col xs="8" lg="2" className="mb-2">
              <div>{primeTableSize < 20 ? "Small" : primeTableSize > 80 ? "Large" : "Normal"}</div>
              <Slider value={primeTableSize} step={50} onChange={(e) => setPrimeTableSize(e.value)} />
            </Col>
            <Col xs="4" md="2" lg="2">
              <RoundTypeDropdown setRoundType={setRoundType} roundType={roundType} roundTypes={roundTypes} setTournamentSelected={() => console.log()} />
            </Col>
            <Col xs="4" md="3" lg="2">
              <DurationDropdown setNumberOfRounds={setNumberOfRounds}
                setTimeDuration={setTimeDuration} setCustom={setCustom} resetSwitches={() => console.log("no switches")}
                setDurationType={setDurationType} durations={durations} />
            </Col>
            <Col className="mt-3" xs="12" md="12" lg="12">
              <MultiSelectPlayers players={allUserAccounts} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} coachTeam={coachTeam} />
            </Col>
          </Row>
        </div>
      );
    } else if (custom) {
      return (
        <div>
          <Row>
            {statsType !== "Basic" && <Col xs="2" lg="2">
              <div>Show attempts</div>
              <InputSwitch checked={showAttempts} onChange={(e) => setShowAttempts(e.value)} />
            </Col>}
            <Col xs="5" lg="1">
              <ToggleButton checked={constants.pgaBase} onChange={(e) => { constants.setPgaBase(e.value); }} onLabel="PGA" offLabel="LPGA" />
            </Col>
            <Col xs="8" lg="2" className="mb-2">
              <div>{primeTableSize < 20 ? "Small" : primeTableSize > 80 ? "Large" : "Normal"}</div>
              <Slider value={primeTableSize} step={50} onChange={(e) => setPrimeTableSize(e.value)} />
            </Col>
            <Col xs="8" md="2" lg="2">
              <RoundTypeDropdown setRoundType={setRoundType} roundType={roundType} roundTypes={roundTypes} setTournamentSelected={() => console.log()} />
            </Col>
            <Col xs="4" md="3" lg="2">
              <DurationDropdown setNumberOfRounds={setNumberOfRounds}
                setTimeDuration={setTimeDuration} setCustom={setCustom} resetSwitches={() => console.log("no switches")}
                setDurationType={setDurationType} durations={durations} />
            </Col>
          </Row>
          <Row className="mt-3 mb-4">
            <Col lg="10">
              <div style={{ display: "flex", justifyContent: "left", gap: "10px" }}>
                <label htmlFor='startDate'>Start Date</label>
                <DateInput id="startDate" selectedDate={startDate} setSelectedDate={setStartDate} />
                <label htmlFor='endDate'>End Date</label>
                <DateInput id="endDate" selectedDate={endDate} setSelectedDate={setEndDate} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-3">
              <MultiSelectPlayers players={allUserAccounts} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} coachTeam={coachTeam} />
            </Col>
          </Row>
        </div>
      );
    }
  }
  function openAll() {
    if (activeTabs.length > 0) {
      setActiveTabs([])
    } else {

      setActiveTabs([0, 1, 2, 3, 4, 5, 6, 7, 8])
    }
  }
  useEffect(() => {
    if (selectedUsers.length > 0) {
      loadData();
    }
  }, [constants.pgaBase])

  return (
    <div>
      <Container className="ps-0">

        {renderFilters()}
        <Row className="mt-3">
          <Col lg="4">
            <div style={{ display: "flex", gap: "30px" }}>
              <Button label="Go" icon="pi pi-check" onClick={() => loadData()} loading={loading} />
              {user?.labels?.includes('advanced') && <SelectButton value={statsType} onChange={(e) => setStatsType(e.value)} options={statsTypes} />}
            </div>
          </Col>
        </Row>
        <Row className="mt-3 mb-5">
          <Col>
            <Button label={activeTabs.length > 0 ? "Close All" : "Open All"}
              icon={activeTabs.length > 0 ? "pi pi-minus" : "pi pi-plus"}
              className="mb-4" onClick={() => openAll()} />
            <Accordion activeIndex={activeTabs} multiple onTabChange={(e) => setActiveTabs(e.index)}>
              <AccordionTab header="General"  >
                {generatePrimeTables(generalDataPrime, generalColumns)}
              </AccordionTab>
              <AccordionTab header="Tiger 5"  >
                {showAttempts ? generateTable(sampleTiger, false) : generatePrimeTables(tigerDataPrime, tigerColumns)}
              </AccordionTab>
              <AccordionTab header="Scoring">
                {generatePrimeTables(scoringDataPrime, scoringColumns)}
              </AccordionTab>
              <AccordionTab header="Off the Tee">
                {showAttempts ? generateTable(sampleTee, false) :
                  generatePrimeTables(ottDataPrime, statsType !== "Basic" ? advancedOttColumns : ottColumns)}
              </AccordionTab>
              <AccordionTab header="Approaches">
                <Message className='mb-0 mt-0 m-1' severity="info" text="*per shot" />
                {showAttempts ? generateTable(sampleApproach, false) :
                  generatePrimeTables(approachDataPrime, statsType !== "Basic" ? advancedApproachColumns : approachColumns)}
              </AccordionTab>
              {statsType !== "Basic" && <AccordionTab header="Going for It">
                {showAttempts ? generateTable(sampleGfi, false) : generatePrimeTables(goingDataPrime, goingColumns)}
              </AccordionTab>}
              <AccordionTab header="Around the Green">
                <Message className='mb-0 mt-0 m-1' severity="info" text="*per shot" />
                {showAttempts ? generateTable(sampleSg, false) :
                  generatePrimeTables(argDataPrime, statsType !== "Basic" ? advancedArgColumns : argColumns)}
              </AccordionTab>
              <AccordionTab header="Putting">
                {showAttempts ? generateTable(samplePutt, false) :
                  generatePrimeTables(puttingDataPrime, statsType !== "Basic" ? advancedPuttingColumns : puttingColumns)}
              </AccordionTab>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
}