import React, { useState, useEffect } from "react";
import "../../css/new-round.css";
import { Dropdown } from "primereact/dropdown";
import { useAuth } from "../appwrite-testing/AuthContext";
import { Teams } from "appwrite";
import { client } from "../appwrite-testing/appwriteConfig";

export function PlayersDropdown({ setSelectedUserId, selectedUser, setSelectedUser }) {


    const { user, getJWT } = useAuth();
    const userId = user?.$id || "000000";
    const [userTeams, setUserTeams] = useState([]);
    const [allUserAccounts, setAllUserAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    //const [selectedUser, setSelectedUser] = useState({ name: "Select Player", userId: 0 })
    const [groupedPlayers, setGroupedPlayers] = useState(null);
    const teams = new Teams(client);


    useEffect(() => {
        teams.list().then(result => setUserTeams(result.teams))
    }, [])

    useEffect(() => {
        const fetchTeamsAndPlayers = async () => {
            if (userTeams) {
                const groups = [];

                for (const t of userTeams) {
                    const group = {
                        groupName: `${t.name}`,
                        players: []
                    };
                    console.log(t)
                    try {
                        const result = await teams.listMemberships(
                            t.$id, // teamId
                            undefined, // queries (optional)
                            undefined // search (optional)
                        );

                        console.log(result);
                        for (const p of result.memberships) {
                            if (p.roles.includes('Player')) {
                                group.players.push(p)
                            }
                        }
                        setLoading(false)
                    } catch (error) {
                        console.error(`Error fetching memberships for team ${t.teamName}:`, error);
                    }
                    groups.push(group);
                }
                // Set the updated group data to state
                setGroupedPlayers(groups);
            }
        };

        fetchTeamsAndPlayers();
    }, [userTeams]);



    useEffect(() => {
        var user = JSON.parse(window.sessionStorage.getItem("selectedUser"));
        if (user) {
            setSelectedUser(user);
            setSelectedUserId(user.userId)
        }
    }, [])

    const groupedPlayerTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.groupName}</div>
            </div>
        );
    };

    return (
        <div>
            <Dropdown id="playersdropdown"
                color="primary"
                value={selectedUser}
                onChange={(event) => {
                    setSelectedUser(event.target.value);
                    window.sessionStorage.setItem("selectedUser", JSON.stringify(event.target.value));
                    setSelectedUserId(event.target.value.$id);
                }}
                options={groupedPlayers}
                optionLabel="userName"
                optionGroupLabel="groupName"
                optionGroupChildren="players"
                optionGroupTemplate={groupedPlayerTemplate}
                placeholder={loading ? "Loading" : "Select a Player"}
                dropdownIcon={loading ? "pi pi-spinner" : "pi pi-chevron-down"}
                filter
                filterPlaceholder="Search Player"
                className="w-full md:w-14rem" />
        </div>
    )
}
