import { useConstant } from "../../../../ConstantContext";
import { useOptions } from "../../StatsOptionsProvider";
import { Table } from "reactstrap";
const ScoringIndividual = ({ state, data, isAdvanced }) => {
    const constants = useConstant();
    const options = useOptions();
    return (
        <div className="table-responsive">
            <Table id='scoringtable' className='stats-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Value</th>
                        <th>Tour average</th>
                    </tr>
                </thead>
                <tbody>
                    {options.scoringStats.map((stat) => (
                        <tr key={`${stat.name}`}
                            style={{
                                color:
                                    (constants.tourAvg[`${stat.name}`].low ?
                                        (state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].greenValue ||
                                            state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].redValue) && 'white' :
                                        (state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ||
                                            state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue) && 'white'),

                                backgroundColor:
                                    (constants.tourAvg[`${stat.name}`].low ?
                                        (state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].greenValue ? constants.green :
                                            state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].redValue ? constants.red : '')
                                        :
                                        (state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ? constants.green :
                                            state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue ? constants.red : ''))
                            }}>
                            <td>{`${stat.display}`}</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]}</td>
                            <td>{constants.tourAvg[`${stat.name}`]?.value}</td>
                        </tr>

                    ))}
                </tbody>
            </Table>
        </div>
    )
}
export default ScoringIndividual;