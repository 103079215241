import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { Teams } from "appwrite";
import { InputText } from "primereact/inputtext";
import { client } from "../appwrite-testing/appwriteConfig";
const InviteMember = ({ category, setIsInvite, team, inviteSent }) => {
    const [isFilledOut, setIsFilledOut] = useState(false);
    const [email, setEmail] = useState("");
    const teams = new Teams(client);
    useEffect(() => {
        setIsFilledOut(email.length > 0);
    }, [email])
    function sendInvitation() {
        const promise = teams.createMembership(
            team.$id,
            [category],
            email,
            undefined,
            undefined,
            "https://001078.xyz/accept-invite"
        );
        promise.then(function (response) {
            console.log(response); // Success
            inviteSent.current.show({ severity: 'success', summary: `${category} invite sent to ${email}!` });
            setIsInvite(false);
        }, function (error) {
            console.log(error); // Failure
        });

    }
    return (
        <div>
            <div>
                <InputText placeholder="email" onChange={(e) => setEmail(e.target.value)} className="m-3" />
            </div>
            <div className="m-3">
                <Button label="Invite" onClick={() => sendInvitation()} className="m-2" disabled={!isFilledOut} />
                <Button label="Back" onClick={() => setIsInvite(false)} severity="danger" className="m-2" />
            </div>
        </div>
    )
}
export default InviteMember;