import React from "react";
import { Card } from "primereact/card";

export const PricingTable = () => {
  return (
    <Card>
      <h1>Pricing Table</h1>
        <stripe-pricing-table pricing-table-id="prctbl_1QZNFFKpdE8841qRBEn5RmF9"
        publishable-key="pk_test_51QPyPgKpdE8841qReWxATxK6Qoh2ksDwR1LGh3iU680MbWjbNwToatbj0UU9D6733OqIkoDawotwCvM3BYoPlsDj00qnVHpm7Z">
        </stripe-pricing-table>
    </Card>
  );
};