import React, { useEffect, useState, useRef } from "react";
import { DisplayCourses } from "./course_components/DisplayCourses";
import { CourseDetails } from "./course_components/CourseDetails";
import { Card } from "primereact/card";
import { Toast } from 'primereact/toast';
import { useAuth } from './appwrite-testing/AuthContext';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function CourseRefractor({ direction, ...args }) {
  const { user, loading, getJWT } = useAuth();
  const [courseData, setCourseData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const toast = useRef(null);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  // Course Page States
  const [showCourseHomepage, setShowCourseHomepage] = useState(true);
  const [showCourseDetails, setShowCourseDetails] = useState(false);

  useEffect(() => {
    async function fetchCourses() {
      try {
        const jwt = await getJWT();
        const response = await fetch("/api/course/all", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch courses');
        }
        
        const data = await response.json();
        setCourseData(data);
        setSubmitButtonClicked(false);
      } catch (error) {
        console.error('Error fetching courses:', error);
        if (toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to load courses',
            life: 3000
          });
        }
      }
    }

    if (user) {
      fetchCourses();
    }
  }, [submitButtonClicked, user, getJWT]);

  const handleCourseClk = (courseSelect) => {
    if (courseSelect != null) {
      setSelectedCourse(courseSelect);
      setShowCourseHomepage(false);
      setShowCourseDetails(true);
    }
  };

  const handleBackBtn = () => {
    setShowCourseHomepage(true);
    setShowCourseDetails(false);
  };

  const handleSubmitClick = () => {
    setSubmitButtonClicked(true);
    setCourseData([]);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to view courses.</div>;
  }

  return (
    <div>
      {showCourseHomepage && (
        <div>
          <Toast ref={toast} />
          <Card title="Courses">
            <DisplayCourses 
              onClick={handleCourseClk} 
              onClick2={handleSubmitClick} 
              courseData={courseData} 
              toast={toast} 
            />
          </Card>
        </div>
      )}
      {showCourseDetails && (
        <CourseDetails 
          onClick={handleBackBtn} 
          course={selectedCourse} 
        />
      )}
    </div>
  );
}

export { CourseRefractor };
