import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { useAuth } from "../appwrite-testing/AuthContext";

const ChangePar = ({ hole, shots, id, updateToast, setWasHoleEdit }) => {
    const [isChangePar, setIsChangePar] = useState(false);
    const [newPar, setNewPar] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { getJWT } = useAuth();
    async function changePar(par) {
        setIsLoading(true);
        const jwt = await getJWT();
        try {
            const jwt = await getJWT();
            const response = await fetch(`/api/Round/change-par?roundId=${id}&holeNo=${hole}&par=${par}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });
            if (response.ok) {
                updateToast.current.show({
                    severity: 'success', summary: 'Par Updated', detail: `Hole: ${hole} Par:${par}`
                });
                setIsChangePar(false)
                setWasHoleEdit(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error deleting shot:', error);
        }
    }
    return (
        <>
            {isChangePar ?
                <>
                    <Dropdown value={newPar} options={[3, 4, 5]} onChange={(e) => setNewPar(e.value)} className="m-1" style={{ maxWidth: "90px" }} />
                    <Button label="Change" className="m-1" onClick={() => changePar(newPar)} disabled={newPar === null} loading={isLoading} />
                    <Button label="Cancel" severity="danger" className="m-1" onClick={() => setIsChangePar(false)} />
                </>
                :
                <div style={{ display: "flex", gap: "10px" }}>
                    <h1>Par {shots.filter((s) => s?.holeNo === hole && s?.shotHole === 1)[0]?.parHole}</h1>
                    <Button label="Change par" size="small" onClick={() => setIsChangePar(true)} className="m-1" />
                </div>
            }

        </>
    )
}
export default ChangePar;