import React, { useEffect, useState } from 'react';
import '../../../custom.css'
import '../../../css/sgHole.css'
import { Container, Form, FormGroup, Input, Label } from "reactstrap";
import { Card } from 'primereact/card';
import { useAuth } from "../../appwrite-testing/AuthContext";
import { Scorecard } from '../../Scorecard';
import { IndividualStats } from './IndividualStats';
import { useParams } from 'react-router-dom';
import { StrokesGainedHolesTable } from '../StrokesGainedHolesTable';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';

const RoundStats = () => {

    const { user, getJWT } = useAuth();
    const [data, setData] = useState("loading");
    const [round, setRound] = useState(null);
    const [baseline, setBaseline] = useState("PGA");
    const [userUnits, setUserUnits] = useState("");
    const { id } = useParams();
    /**Good switch*/
    const [bottom10, setBottom10] = useState(false);
    /**Bad switch */
    const [top20, setTop20] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const jwt = await getJWT();

                // Fetch calculation data
                const calcResponse = await fetch(`/api/Calculation/one-round/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    },
                });
                const calcData = await calcResponse.json();
                setData(calcData);
                console.log("HERE");
                console.log(calcData)
                // Fetch round data
                const roundResponse = await fetch(`/api/Round/id?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    },
                });
                const roundData = await roundResponse.json();
                setRound(roundData[0]);
                setBaseline(roundData[0].baseline);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id, getJWT]);

    useEffect(() => {
        const fetchUserUnits = async () => {
            try {
                const jwt = await getJWT();
                const response = await fetch(`api/user/userId?userId=${user.$id}`, {
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                        'Content-Type': 'application/json'
                    },
                });
                const userData = await response.json();
                setUserUnits(userData[0].units);
            } catch (error) {
                console.error('Error fetching user units:', error);
            }
        };

        const units = window.localStorage.getItem("units");
        if (units) {
            setUserUnits(units);
        } else if (user) {
            fetchUserUnits();
        }
    }, [user, getJWT]);

    const formatDate = (date) => {
        const d = new Date(date);
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const year = d.getFullYear();
        if (isNaN(month)) {
            return "...";
        }
        return `${month}/${day}/${year}`;
    };

    return (
        <div>
            <div className='mb-2' style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                <h1>
                    {round && `${round.type} round on ${formatDate(round.date)} at ${round.course}`}
                </h1>
                <Button className="mb-4" label="Edit Round" severity="secondary" icon="pi pi-file-edit"
                    onClick={() => window.open(`/round-edit/${round.id}`, "_self")} />

            </div>
            <Container>
                <div className='table-responsive'>
                    {round &&
                        <Scorecard round={round} />
                    }
                </div>
                <Accordion multiple className='mt-3 mb-3' activeIndex={[0, 1, 2, 3]}>
                    <AccordionTab header={`Strokes Gained Off the Tee Per Hole `}>
                        {data !== "loading" ?
                            <Card title={`Total: ${data.sgOTT.strokesGainedPerRound}`}
                                style={{
                                    backgroundColor: data.sgOTT.strokesGainedPerRound > 0.45 ? 'green' : data.sgOTT.strokesGainedPerRound < -0.5 ? 'red' : '',
                                    color: data.sgOTT.strokesGainedPerRound > 0.45 ? 'white' : data.sgOTT.strokesGainedPerRound < -0.5 ? 'white' : ''
                                }}>
                                <StrokesGainedHolesTable data={data.sgOttPerHole} header={"Strokes Gained Off the Tee"} />
                            </Card> :
                            <ProgressBar className="mb-2 mt-1" mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        }
                    </AccordionTab>
                    <AccordionTab header={"Strokes Gained Approach Per Hole"}>
                        {data !== "loading" ?
                            <Card title={`Total: ${data.sgAPP.strokesGainedPerRound}`}
                                style={{
                                    backgroundColor: data.sgAPP.strokesGainedPerRound > 0.49 ? 'green' : data.sgAPP.strokesGainedPerRound < -0.53 ? 'red' : '',
                                    color: data.sgAPP.strokesGainedPerRound > 0.49 ? 'white' : data.sgAPP.strokesGainedPerRound < -0.53 ? 'white' : ''
                                }}>
                                <StrokesGainedHolesTable data={data.sgAppPerHole} header={"Strokes Gained Approach"} />
                            </Card> :
                            <ProgressBar className="mb-2 mt-1" mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        }
                    </AccordionTab>
                    <AccordionTab header={"Strokes Gained Around the Green Per Hole"}>
                        {data !== "loading" ?
                            <Card title={`Total: ${data.sgARG.strokesGainedPerRound}`}
                                style={{
                                    backgroundColor: data.sgARG.strokesGainedPerRound > 0.39 ? 'green' : data.sgARG.strokesGainedPerRound < -0.33 ? 'red' : '',
                                    color: data.sgARG.strokesGainedPerRound > 0.39 ? 'white' : data.sgARG.strokesGainedPerRound < -0.33 ? 'white' : ''
                                }}>
                                <StrokesGainedHolesTable data={data.sgArgPerHole} header={"Strokes Gained Around the Green"} />
                            </Card> :
                            <ProgressBar className="mb-2 mt-1" mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        }
                    </AccordionTab>
                    <AccordionTab header={"Strokes Gained Putting Per Hole"}>
                        {data !== "loading" ?
                            <Card title={`Total: ${data.putt.strokesGainedPerRound}`}
                                style={{
                                    backgroundColor: data.putt.strokesGainedPerRound > 0.45 ? 'green' : data.putt.strokesGainedPerRound < -0.46 ? 'red' : '',
                                    color: data.putt.strokesGainedPerRound > 0.45 ? 'white' : data.putt.strokesGainedPerRound < -0.46 ? 'white' : ''
                                }}>
                                <StrokesGainedHolesTable data={data.sgPuttingPerHole} header={"Strokes Gained Putting"} />
                            </Card> :
                            <ProgressBar className="mb-2 mt-1" mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        }
                    </AccordionTab>
                </Accordion>

                <IndividualStats data={data} top20={top20} bottom10={bottom10} baseline={baseline} units={userUnits} />
            </Container>
        </div>
    )
}
export { RoundStats }