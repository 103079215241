import { useEffect, useState, useRef } from "react";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';
import { Tooltip } from 'primereact/tooltip';
import { useAuth } from "../appwrite-testing/AuthContext";
const EditBag = ({ setIsEditBag }) => {
    const { user, getJWT } = useAuth();
    const userId = user?.$id || "000000";
    const [wasDataLoaded, setWasDataLoaded] = useState(false);
    const possibleClubs = ['LW', 'SW', 'GW', 'PW', '9i', '8i', '7i', '6i', '5i', '4i', '3i', '2i', '5h', '4h', '3h', '7w', '5w', '3w', 'Driver'];
    const nullBag = {
        "LW": 0,
        "SW": 0,
        "GW": 0,
        "PW": 0,
        "9i": 0,
        "8i": 0,
        "7i": 0,
        "6i": 0,
        "5i": 0,
        "4i": 0,
        "3i": 0,
        "2i": 0,
        "4h": 0,
        "3h": 0,
        "7w": 0,
        "5w": 0,
        "3w": 0,
        "Driver": 0
    };
    const [bag, setBag] = useState(nullBag);

    const [isMobile, setIsMobile] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const jwt = await getJWT();
                const response = await fetch(`api/User/myself?userId=${user.$id}`, {
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                });
                const responseData = await response.json();
                if (responseData.bag) {
                    setBag(responseData.bag);
                }
                setWasDataLoaded(true);
                window.localStorage.setItem("bag", JSON.stringify(responseData.bag));
            } catch (error) {
                console.error("Error fetching user data:", error);
                setWasDataLoaded(false);
            }
        };
        fetchUser();
    }, []);

    // Handler function to update the bag when a checkbox or input changes
    const handleClubChange = (club, distance) => {
        if (distance !== null) {
            setBag((prevBag) => ({
                ...prevBag,
                [club]: parseInt(distance),
            }));
        } else {
            setBag((prevBag) => ({
                ...prevBag,
                [club]: 0,
            }));
        }

    };

    function bagInfo() {
        return (
            <div style={{ display: "inline-flex" }}>
                <Tooltip target=".UDInfo" />
                <div>Bag:</div>
                <i className='pi pi-info-circle m-1 UDInfo' data-pr-tooltip="Use the same units as you use to input the stats. If you don't use a particular club, enter distance of 0." />
            </div>
        )
    }

    const submitChanges = async () => {
        const sortedBag = {};
        possibleClubs.forEach(key => {
            if (key in bag) {
                sortedBag[key] = bag[key];
            }
        });
        try {
            const jwt = await getJWT();
            await fetch(`api/User/mybag`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body:
                    JSON.stringify({
                        userId: userId,
                        bag: sortedBag
                    })

            }).then(response => {
                window.localStorage.setItem("bag", JSON.stringify(sortedBag));
                toast.current.show({ severity: 'success', summary: 'Bag is Updated!' });
                setIsEditBag(false);
                return response;
            }).catch(error => {
                console.error(error);
                toast.current.show({ severity: 'danger', summary: 'Bag update unsuccessful :(' });

            });
        } catch (error) {
            console.error("Error updating the bag:", error);
            setWasDataLoaded(false);
        }
    };


    return (
        <div className="justify-content-center  align-items-center card flex gap-3">
            <Toast ref={toast} />
            {!wasDataLoaded && <ProgressSpinner className="mt-3" style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />}
            <div className="mt-2 flex justify-content-center  align-items-center gap-3" >
                <label>{bagInfo()}</label>
                {possibleClubs.map((club) => (
                    <div className="mt-2 mb-2" style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                        <label>{club}</label>
                        <InputNumber value={bag[club]}
                            onValueChange={(e) => {
                                handleClubChange(club, e.value)
                            }} />
                    </div>
                ))}
            </div>
            <div className="m-2">
                <Button className="m-2" label="Submit" severity="success" onClick={() => submitChanges()} />
                <Button className="m-2" label="Cancel" severity="danger" onClick={() => setIsEditBag(false)} />
            </div>

        </div >
    )
}

export default EditBag;