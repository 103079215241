import React, { useState, useEffect } from "react";
import {
    Container, Row
} from "reactstrap";
import { TeamApproach } from "./TeamApproach";
import { TeamPutting } from "./TeamPutting";
import { TeamGames } from "./TeamGames";
import { useAuth } from "../../appwrite-testing/AuthContext";
import { TabMenu } from "primereact/tabmenu"
import { TeamPlanner } from "./TeamPlanner";
import { GroupPlanner } from "./GroupPlanner";
import CurrentSchedule from "./CurrentSchedule";
import { Teams } from "appwrite";
import { client } from "../../appwrite-testing/appwriteConfig";
import TeamGreenReading from "./TeamGreenReading";

const TeamPractice = () => {

    const [selectedPractice, setSelectedPractice] = useState("Individual Planner")
    const [players, setPlayers] = useState([]);
    const [allUserAccounts, setAllUserAccounts] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [coachTeams, setCoachTeams] = useState([]);

    const { user, getJWT } = useAuth();
    const userId = user?.$id;
    const teams = new Teams(client);

    const basicOptionsMenu = [{ label: "Individual Planner", icon: "" }, { label: "Group Planner", icon: "" },
    { label: "Current Schedule", icon: "" }, { label: "Putting Combine", icon: "" }, { label: "Approach", icon: "" }];
    const advancedOptionsMenu = [{ label: "Individual Planner", icon: "" }, { label: "Group Planner", icon: "" },
    { label: "Current Schedule", icon: "" }, { label: "Putting Combine", icon: "" }, { label: "Green Reading", icon: "" }, { label: "Putting Games", icon: "" }, { label: "Short Game Games", icon: "" },
    { label: "Approach", icon: "" }, { label: "Approach Games", icon: "" }, { label: "Tee Games", icon: "" }];
    const [options, setOptions] = useState([])
    useEffect(() => {
        if (user?.labels?.includes('advanced')) {
            setOptions(advancedOptionsMenu)
        } else {
            setOptions(basicOptionsMenu)
        }
    }, [user])
    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const result = await teams.list();
                const teamsIdList = result.teams.map((t) => t.$id);
                setCoachTeams(teamsIdList)
                window.localStorage.setItem("teams", teamsIdList);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }

        const team = window.localStorage.getItem("teams");
        if (team) {
            setCoachTeams(team);
        } else {
            fetchTeams();
        }
    }, [userId]);

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const jwt = await getJWT();
                const response = await fetch('api/user', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
                const responseData = await response.json();
                setPlayers(responseData);
                setAllUserAccounts(responseData);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        }

        fetchAllUsers();
    }, []);

    return (
        <Container>
            <Row>
                <div className="p-fluid mb-3" style={{ display: "flex", justifyContent: "center", width: "100%", }} >
                    <TabMenu model={options} activeIndex={activeIndex} onTabChange={(e) => { setSelectedPractice(e.value.label); setActiveIndex(e.index) }} />
                </div>
            </Row>
            <Row>
                {selectedPractice === "Individual Planner" && <TeamPlanner players={players} allUserAccounts={allUserAccounts} coachTeam={coachTeams} user={user} getJWT={getJWT} />}
                {selectedPractice === "Group Planner" && <GroupPlanner players={players} allUserAccounts={allUserAccounts} coachTeam={coachTeams} getJWT={getJWT} />}
                {selectedPractice === "Current Schedule" && <CurrentSchedule players={players} allUserAccounts={allUserAccounts} coachTeam={coachTeams} getJWT={getJWT} />}
                {selectedPractice === "Putting Combine" && <TeamPutting players={players} allUserAccounts={allUserAccounts} coachTeam={coachTeams} />}
                {selectedPractice === "Putting Games" && <TeamGames players={players} allUserAccounts={allUserAccounts} games={"puttinggames"} coachTeam={coachTeams} />}
                {selectedPractice === "Short Game Games" && <TeamGames players={players} allUserAccounts={allUserAccounts} games={"arggames"} coachTeam={coachTeams} />}
                {selectedPractice === "Approach" && <TeamApproach players={players} allUserAccounts={allUserAccounts} coachTeam={coachTeams} />}
                {selectedPractice === "Approach Games" && <TeamGames players={players} allUserAccounts={allUserAccounts} games={"approachgames"} coachTeam={coachTeams} />}
                {selectedPractice === "Tee Games" && <TeamGames players={players} allUserAccounts={allUserAccounts} games={"teegames"} coachTeam={coachTeams} />}
                {selectedPractice === "Green Reading" && <TeamGreenReading players={players} allUserAccounts={allUserAccounts} games={"teegames"} coachTeam={coachTeams} />}

            </Row>
        </Container >
    )
}

export { TeamPractice }