// src/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { account } from "./appwriteConfig";
import { useNavigate, useLocation } from 'react-router-dom';
import { ID, OAuthProvider } from "appwrite";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [jwt, setJwt] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Generate JWT token
  const generateJWT = async () => {
    try {
      const jwt = await account.createJWT();
      setJwt(jwt.jwt);
      return jwt.jwt;
    } catch (error) {
      console.error("Failed to generate JWT:", error);
      return null;
    }
  };

  // New function to validate and refresh JWT if needed
  const validateAndRefreshJWT = async () => {
    try {
      if (jwt) {
        // Decode the JWT to check expiration
        const [, payload] = jwt.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        const currentTime = Math.floor(Date.now() / 1000);
        
        // If JWT is expired or will expire in next 5 minutes, generate new one
        if (decodedPayload.exp <= currentTime + 300) {
          await generateJWT();
        }
      } else {
        // If no JWT exists, generate new one
        await generateJWT();
      }
    } catch (error) {
      console.error("JWT validation failed:", error);
      // If there's any error in validation, generate new JWT
      await generateJWT();
    }
  };

  // Fetch current user session and generate JWT
  const checkSession = async () => {
    try {
      const userData = await account.get();
      setUser(userData);
      await validateAndRefreshJWT();
    } catch (error) {
      console.error("No session found:", error);
      setUser(null);
      setJwt(null);
    } finally {
      setLoading(false);
    }
  };

  // Check session on mount and when location changes
  useEffect(() => {
    checkSession();
  }, [location.pathname]);

  const isSubscriber = () => {
    return user?.labels?.includes('subscriber');
  };

  const isCoach = () => {
    return user?.labels?.includes('coach');
  };

  const login = async (email, password) => {
    try {
      await account.createEmailPasswordSession(email, password);
      await checkSession();
      return user;
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    try {
      await account.createOAuth2Session(
        OAuthProvider.Google,
        `${window.location.origin}/`,
        `${window.location.origin}/login`,
        ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'],
        null,
        { sameSite: 'None', secure: true }
      );
      await checkSession();
    } catch (error) {
      console.error("Google login failed:", error);
      if (error.code === 401) {
        throw new Error("Authentication failed. Please try again.");
      } else if (error.type === 'user_unauthorized') {
        throw new Error("Session expired. Please login again.");
      }
      throw error;
    }
  };

  const loginAnonymously = async () => {
    try {
      await account.createAnonymousSession();
      await checkSession();
    } catch (error) {
      console.error("Anonymous login failed:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await account.deleteSession("current");
      setUser(null);
      setJwt(null);  // Clear JWT on logout
      navigate('/login');
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  // Utility function to get JWT for API calls
  const getJWT = async () => {
    if (!jwt) {
      return await generateJWT();
    }
    return jwt;
  };

  const signup = async (email, password, name) => {
    try {
      // Create the user account
      await account.create(ID.unique(), email, password, name);
      
      // Log them in automatically
      await account.createEmailPasswordSession(email, password);
      
      // Get fresh user data
      const userData = await account.get();
      setUser(userData);
      
      // Generate new JWT
      await validateAndRefreshJWT();
      
      return userData;
    } catch (error) {
      console.error("Signup failed:", error);
      if (error.code === 409) {
        throw new Error("An account with this email already exists");
      }
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      login, 
      loginWithGoogle,
      loginAnonymously,
      logout, 
      loading, 
      isSubscriber,
      isCoach,
      checkSession,
      getJWT,
      jwt,
      signup
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
