import { useEffect, useLayoutEffect, useState } from "react";
import { GetStat } from "../goals/GetStats";
import { useConstant } from "../../ConstantContext";
import { useOptions } from "../stats_components/StatsOptionsProvider";
import { useAuth } from "../appwrite-testing/AuthContext";
import { Table } from "reactstrap";
import { Button } from "primereact/button";

const HomeGoals = ({ userId, data }) => {

    const { getJWT } = useAuth();
    const statsOptions = useOptions();
    const constants = useConstant();
    const [goals, setGoals] = useState([]);
    const [isGoalsFound, setIsGoalsFound] = useState(true);
    const combinedStats = [
        ...(statsOptions?.tiger5stats || []),
        ...(statsOptions?.scoringStats || []),
        ...(statsOptions?.sgTee || []),
        ...(statsOptions?.teeStats || []),
        ...(statsOptions?.sgAppAdvanced || []),
        ...(statsOptions?.rangesAppAdvanced || []),
        ...(statsOptions?.gir || []),
        ...(statsOptions?.gfApp || []),
        ...(statsOptions?.difficultyMiss || []),
        ...(statsOptions?.expected || []),
        ...(statsOptions?.sgApp || []),
        ...(statsOptions?.rangesApp || []),
        ...(statsOptions?.goingForIt || []),
        ...(statsOptions?.goingAvg || []),
        ...(statsOptions?.argSGAdvanced || []),
        ...(statsOptions?.argStats || []),
        ...(statsOptions?.argSG || []),
        ...(statsOptions?.sgPuttAdvanced || []),
        ...(statsOptions?.puttingMake || []),
        ...(statsOptions?.puttingFeet || []),
        ...(statsOptions?.sgPutt || [])
    ];
    useEffect(() => {
        if (goals.length > 0) {
            console.log("here");
            const table = document.getElementById("homegoals");
            const cells = Array.from(table.getElementsByClassName("loading-cell"));
            cells.forEach(cell => cell.classList.remove("loading-cell"));
        }
    }, [goals]);

    async function getGoals() {
        const jwt = await getJWT();

        const response = await fetch(`/api/goals?userId=${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            } // Send all the practice sessions for this user
        });


        // Check if the response was successful (you can add more checks as needed)
        if (!response.ok) {
            console.error(`Failed to get goals for user ${userId}`);
            //toast.current.show({ severity: 'danger', summary: 'Error!', detail: 'Schedules NOT sent!' });
        } else {
            try {
                const data = await response.json();
                setGoals(data.goalsList)
                if (data.goalsList.length > 0) {
                    setIsGoalsFound(true)
                }
            } catch (e) {
                console.error(e);
                setIsGoalsFound(false);
            }

            console.log(`Successfully get goals for user ${userId}`);
            //toast.current.show({ severity: 'success', summary: 'Sent!', detail: 'Schedules sent!' });
        }
    }
    useEffect(() => {
        getGoals();
    }, [])
    return (
        <>
            {!isGoalsFound ?
                <>
                    <h3>
                        Set your goals now!
                    </h3>
                    <Button label="Set goals" onClick={() => window.open(`/stats`, "_self")} />
                </>
                :
                <Table id="homegoals">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Value</th>
                            <th>Goal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {goals.length > 0 ?
                            goals.map((g) => {
                                const statName = combinedStats.find((s) => s.name === g.statName); // Find the matching stat
                                const stat = GetStat(g.statName, data, constants);
                                return (
                                    <tr key={g.statName}
                                        style={{
                                            color: "white",
                                            backgroundColor: stat.low ? (stat.value < g.goalValue ? constants.green : constants.red) :
                                                (stat.value > g.goalValue ? constants.green : constants.red)
                                        }}>
                                        <td>{statName?.display || "N/A"}</td> {/* Display the `display` value or "N/A" if not found */}
                                        <td>{stat.value}</td>
                                        <td>{g.goalValue}</td>
                                    </tr>
                                );
                            })
                            :

                            <tr>
                                <td className="loading-cell"></td>
                                <td className="loading-cell"></td>
                                <td className="loading-cell"></td>
                            </tr>}

                    </tbody >

                </Table>
            }
        </>
    )
}
export default HomeGoals;