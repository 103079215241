import { React } from "react";
import { motion } from "framer-motion";
import { NavItem } from 'reactstrap';
import { useAuth } from "../appwrite-testing/AuthContext";
import { LoginButton } from "./LoginButton";
import { LogoutButton } from "./LogoutButton";

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const LogNavButton = () => {
  const { user } = useAuth();

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.1 }}
    >
      <NavItem>
        {user ? <LogoutButton /> : <LoginButton />}
      </NavItem>
    </motion.div>
  );
};

export { LogNavButton };
