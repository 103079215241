import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { useQuery } from '@tanstack/react-query';
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { useGames } from "../../../GamesProvider";
import { Toast } from "primereact/toast";
import DateWithButtons from "../DateWithButtons";

import { ToggleButton } from 'primereact/togglebutton';

const MyTeamPlan = ({ user, getJWT, setSelectedPractice, setActiveIndex }) => {
    //const [schedule, setSchedule] = useState([day, day]);
    const [visible, setVisible] = useState(false);
    const [descriptionDrill, setDescriptionDrill] = useState(null);
    const [loading, setLoading] = useState(false);
    const gamesConst = useGames();
    const [scores, setScores] = useState({}); // Track scores for drills
    const toast = useRef(null);
    const [practiceDate, setPracticeDate] = useState(new Date());

    const { data: schedule = [], refetch } = useQuery({
        queryKey: ['schedule'],
        queryFn: async () => {
            if (!user) return [];

            const jwt = await getJWT();
            const endpoint = `/api/PracticeSchedule?userId=${user.$id}`;

            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });
            const data = await response.json();
            console.log(data)

            if (!response.ok) throw new Error('Failed to fetch schedule');
            return data;
        },
        enabled: !!user
    });
    useEffect(() => {
        refetch()
    }, []);

    const handleScoreChange = (drillName, value) => {
        setScores((prevScores) => ({
            ...prevScores,
            [drillName]: value,
        }));
    };

    function displayDescription(name, category) {
        setVisible(true);
        setDescriptionDrill(gamesConst[category].find((d) => d.name === name));

    }

    function drillsList(name, drills, category, date) {
        if (drills?.length > 0)
            return (
                <div >
                    <Card subTitle={name} >
                        {drills?.map((d) => (
                            <div
                                key={d.name}
                                style={{
                                    display: "flex",
                                    gap: "1rem",
                                    alignItems: "center",
                                    whiteSpace: "nowrap", // Prevents text from wrapping
                                    overflow: "hidden", // Ensures text doesn't overflow
                                    textOverflow: "ellipsis", // Adds "..." for overflowed text
                                    backgroundColor: d.isCompleted ? "rgba(0, 128, 0, 0.4)" : "rgba(255, 0, 0, 0.4)", // Green or Red with 70% opacity
                                    padding: "8px",
                                    borderRadius: "10px",
                                    flexWrap: "wrap"
                                }}
                                className="m-3"
                            >
                                <span
                                    style={{
                                        textDecoration: d.isCompleted ? "line-through" : "none",
                                        flexShrink: 1, // Allows the name to shrink if necessary
                                        overflow: "hidden", // Ensures overflow is handled gracefully
                                        textOverflow: "ellipsis", // Adds "..." for truncated text
                                    }}
                                >
                                    {d.name}
                                </span>
                                {
                                    d.name !== "Green Reading" && <InputNumber
                                        value={scores[d.name] || d.score} // Default to current score if not changed
                                        onValueChange={(e) => handleScoreChange(d.name, e.value)}
                                        showButtons buttonLayout="horizontal"
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                        decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                        className="p-fluid"
                                        maxLength={2}
                                        style={{ maxWidth: "170px" }}
                                    />}
                                {category !== "combineGames" ?
                                    <>
                                        <Button label="Description" severity="info" onClick={() => displayDescription(d.name, category)} />
                                        <Button label="Submit" loading={loading} onClick={() => submitScore(d.name, category, scores[d.name] || d.score, date)} />
                                    </>
                                    :
                                    <>
                                        <Button label="Go to Combine" severity="info" disabled={d.isCompleted} onClick={() => goToCombine(d.name)} />
                                        {d.name === "Green Reading" &&
                                            <ToggleButton
                                                onIcon="pi pi-check"
                                                onLabel=""
                                                offIcon="pi pi-times"
                                                offLabel=""
                                                checked={d.isCompleted}
                                                onChange={(e) => markCompleted(d.name, date, e.value)}
                                                loading={loading} />}
                                    </>
                                }
                            </div>
                        ))}
                    </Card>
                </div>
            )
    }
    async function submitScore(name, games, score, date) {
        const dbCat = games === "appGames" ? "approachGames" : games;
        setLoading(true);
        try {
            const jwt = await getJWT();
            const response = await fetch(`/api/${dbCat}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                },
                body: JSON.stringify({
                    userId: user.$id,
                    date: new Date().toISOString(),
                    gameName: name,
                    score: score
                })
            });

            if (!response.ok) {
                throw new Error(`Failed to submit score: ${response.statusText}`);
            }

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Score submitted successfully!',
                life: 3000
            });
        } catch (error) {
            console.error('Error submitting score:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to submit score',
                life: 3000
            });
        } finally {
            updateScheduleScore(name, score, date)
            // Add a 2-second delay
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setLoading(false);
        }
    }

    async function updateScheduleScore(name, score, date) {
        try {
            const jwt = await getJWT();
            const response = await fetch(`/api/practiceschedule/update-score?userId=${user.$id}&score=${score}&drillName=${name}&date=${date}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });

            if (!response.ok) {
                throw new Error(`Failed to submit score: ${response.statusText}`);
            }

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Schedule Updated successfully!',
                life: 3000
            });
        } catch (error) {
            console.error('Error submitting score:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to update the schedule!',
                life: 3000
            });
        } finally {
            refetch();
            // Add a 2-second delay
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setLoading(false);
        }
    }

    async function markCompleted(name, date, isComplete) {
        try {
            const jwt = await getJWT();
            const response = await fetch(`/api/practiceschedule/mark-completed?userId=${user.$id}&drillName=${name}&date=${date}&isComplete=${isComplete}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });

            if (!response.ok) {
                throw new Error(`Failed to submit score: ${response.statusText}`);
            }

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Schedule Updated successfully!',
                life: 3000
            });
        } catch (error) {
            console.error('Error submitting score:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to update the schedule!',
                life: 3000
            });
        } finally {
            refetch();
        }
    }

    function goToCombine(name) {
        setSelectedPractice(name === "Putting Combine" ? name : "Approach");
        setSelectedPractice(name === "Green Reading" ? name : "Approach");
        setActiveIndex(name === "Putting Combine" ? 0 : 6)
        setActiveIndex(name === "Green Reading" ? 3 : 6)

    }

    return (
        <Card title="My Practice Plan">
            <Toast ref={toast} />
            <Dialog header={descriptionDrill?.name} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <p className="m-0">
                    {descriptionDrill?.description}
                </p>
            </Dialog>
            <DateWithButtons practiceDate={practiceDate} setPracticeDate={setPracticeDate} />
            {schedule?.schedule
                ?.filter((s) => s.date?.split('T')[0] === practiceDate.toISOString().split('T')[0]).map((s) => (
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "flex-start", flexDirection: "column" }}>
                        {drillsList("Putting", s.puttingDrills, "puttingGames", s.date)}
                        {drillsList("Short Game", s.shortGameDrills, "argGames", s.date)}
                        {drillsList("Appproach", s.approachDrills, "appGames", s.date)}
                        {drillsList("Off the Tee", s.teeDrills, "teeGames", s.date)}
                        {drillsList("Combines", s.combineDrills, "combineGames", s.date)}
                    </div>
                ))}
        </Card>
    )
}
export default MyTeamPlan;