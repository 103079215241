import { Card } from "primereact/card";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { MultiSelectPlayers } from "../../dropdowns/MultiSelectPlayers";
import { Column } from "primereact/column";
import { MultiSelectGames } from "../../dropdowns/MultiSelectGames";
import { useGames } from "../../../GamesProvider";
import { sortAnalysisToList } from "../../stats_components/AnalysisFive";
import PlannerTimeAndNumber from "../Helpers/PlannerTimeAndNumber";
import TimeFilters from "../Helpers/TimeFilters";
import Facilities from "../Helpers/Facilities";
import { FetchSchedule } from "./FetchSchedule";
import { Toast } from "primereact/toast";
import DateWithButtons from "../DateWithButtons";
import { useConstant } from "../../../ConstantContext";
const TeamPlanner = (props) => {
    const constants = useConstant();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [displayedPlayers, setDisplayedPlayers] = useState([]);
    const [allUserAccounts] = useState(props.allUserAccounts);
    useEffect(() => {
        if (selectedUsers.length > 0) {
            setDisplayedPlayers(selectedUsers);
        } else {
            setDisplayedPlayers(allUserAccounts)
        }
    }, [selectedUsers, allUserAccounts])
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [totalMinutes, setTotalMinutes] = useState(0);
    const [elements, setElements] = useState(1);
    const [roundType, setRoundType] = useState("All");
    const [numberOfRounds, setNumberOfRounds] = useState(10);
    const [timeDuration, setTimeDuration] = useState(0);
    const [durationType, setDurationType] = useState("number");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [custom, setCustom] = useState(false);
    const [stats, setStats] = useState(null);
    const roundTypes = ["All", "Tournament", "Qualifying", "Practice"]
    const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds", "Last 15 Rounds",
        "Last 20 Rounds", "Last 30 Rounds", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Fall", "Spring", "Custom"];
    const green = "rgba(0, 128, 0, 0.75)";
    const red = "rgba(220, 0, 0, 0.75)"
    const [numberImprovement, setNumberImprovement] = useState(2);
    const [numberStrength, setNumberStrength] = useState(1);
    const [units, setUnits] = useState("yards")

    const [loadingButton, setLoadningButton] = useState(false)

    const [dataState, setDataState] = useState([]);

    const gamesConst = useGames();
    const [selectedGames, setSelectedGames] = useState([]);



    const facilities = ["Range Driver", "Range Irons", "Range Woods", "Short Game Area", "Putting Green"];
    const [selectedFacilities, setSelectedFacilities] = useState(facilities);
    const [statsType, setStatsType] = useState("Basic");
    const [practiceDate, setPracticeDate] = useState(new Date())

    const toast = useRef(null);

    useEffect(() => {
        switch (elements) {
            case 3:
                setNumberImprovement(2);
                setNumberStrength(1);
                break;
            case 4:
                setNumberImprovement(3);
                setNumberStrength(1);
                break;
            case 5:
                setNumberImprovement(3);
                setNumberStrength(2);
                break;
            case 6:
                setNumberImprovement(4);
                setNumberStrength(2);
                break;
            case 7:
                setNumberImprovement(5);
                setNumberStrength(2);
                break;
            case 8:
                setNumberImprovement(5);
                setNumberStrength(3);
                break;
            case 9:
                setNumberImprovement(6);
                setNumberStrength(3);
                break;
            case 10:
                setNumberImprovement(7);
                setNumberStrength(3);
                break;
        }
    }, [elements])
    useEffect(() => {
        setTotalMinutes(hours * 60 + minutes)
    }, [hours, minutes])

    async function getSelectedRounds(durationType, timeDuration, numberOfRounds, roundType, selectedUserId) {
        try {
            const jwt = await props.getJWT();
            let url;

            if (durationType.toLowerCase() === "time") {
                url = `/api/Calculation/user-last-duration?timeType=month&duration=${timeDuration}&userId=${selectedUserId}&roundType=${roundType}`;
            } else if (durationType.toLowerCase() === "number") {
                url = `/api/Calculation/user-last?numberOfRounds=${numberOfRounds}&userId=${selectedUserId}&roundType=${roundType}`;
            } else if (durationType.toLowerCase() === "custom" && startDate !== null && endDate !== null) {
                url = `/api/Calculation/user-date-range?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}&userId=${selectedUserId}&roundType=${roundType}`;
            } else if (durationType.toLowerCase() === "fall") {
                url = `/api/Calculation/user-date-range?startDate=${constants.fallStart}&endDate=${constants.fallEnd}&userId=${selectedUserId}&roundType=${roundType}`;
            } else if (durationType.toLowerCase() === "spring") {
                url = `/api/Calculation/user-date-range?startDate=${constants.springStart}&endDate=${constants.springEnd}&userId=${selectedUserId}&roundType=${roundType}`;
            } else if (durationType.toLowerCase() === "this year") {
                url = `/api/Calculation/user-date-range?startDate=${constants.thisYearStart}&endDate=${constants.thisYearEnd}&userId=${selectedUserId}&roundType=${roundType}`;
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${jwt}`
                },
            });

            const data = await response.json();
            setDataState((prev) => [
                ...prev,
                { userId: selectedUserId, stats: sortAnalysisToList(data, units, selectedFacilities, numberImprovement, numberStrength, totalMinutes, statsType !== "Basic"), dataLoaded: true }
            ])
        } catch (error) {
            console.error(error);
        }
    }

    const handleGameSelection = (userId, part, games) => {
        const dateStr = practiceDate.toISOString().split('T')[0]; // Get the current date in "MM/DD/YYYY" format
        setSelectedGames((prevSelectedGames) => {
            // Check if the date already exists in the selectedGames array
            const existingDateIndex = prevSelectedGames.findIndex((entry) => entry.date === dateStr);

            // If the date exists, update the user's games for that date
            if (existingDateIndex !== -1) {
                const updatedGames = [...prevSelectedGames];
                updatedGames[existingDateIndex] = {
                    ...updatedGames[existingDateIndex],
                    [userId]: {
                        ...updatedGames[existingDateIndex][userId],
                        [part]: games,
                    }
                };
                return updatedGames;
            } else {
                // If the date doesn't exist, create a new entry for the date
                return [
                    ...prevSelectedGames,
                    {
                        date: dateStr,
                        [userId]: {
                            [part]: games,
                        }
                    }
                ];
            }
        });
    };


    return (
        <div>
            <Toast ref={toast} />
            <Card className="mt-2 mb-2" title="Plan players' day" >
                <PlannerTimeAndNumber hours={hours} setHours={setHours} minutes={minutes} setMinutes={setMinutes}
                    elements={elements} setElements={setElements} />
                <TimeFilters roundType={roundType} setRoundType={setRoundType} durations={durations} setNumberOfRounds={setNumberOfRounds}
                    setDurationType={setDurationType} setTimeDuration={setTimeDuration} setCustom={setCustom} statsType={statsType} setStatsType={setStatsType} />
                <div>
                    <MultiSelectPlayers players={props.players} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} durations={durations} coachTeam={props.coachTeam} />
                </div>
                <Facilities selectedFacilities={selectedFacilities} setSelectedFacilities={setSelectedFacilities} statsType={statsType} />
                <Button loading={loadingButton} className="m-2" disabled={totalMinutes <= 0 || selectedUsers.length <= 0} label="Plan my practice"
                    onClick={() => {
                        setDataState([])
                        selectedUsers.map((u) => {
                            getSelectedRounds(durationType, timeDuration, numberOfRounds, roundType, u.userId)
                        })
                    }}
                />
            </Card >
            {<div>
                <Card className="mt-2 mb-2" title="Suggested Practice">
                    <DateWithButtons practiceDate={practiceDate} setPracticeDate={setPracticeDate} />
                    {selectedUsers.map((u) => {
                        return (
                            <Card className="m-2" title={u.userName}>
                                <DataTable value={dataState.filter((d) => d.userId === u.userId.substring(u.userId.indexOf('|') + 1))
                                    .map((d) => d.stats)[0]}>
                                    <Column field="name" header="Name"></Column>
                                    <Column field="value" header="Total SG"></Column>
                                    <Column field="time" header="Time"></Column>
                                </DataTable>
                                <MultiSelectGames
                                    games={gamesConst.puttingGames}
                                    selectedGames={selectedGames.find(entry => entry.date === practiceDate.toISOString().split('T')[0])?.[u.userId]?.puttingGames || []}
                                    setSelectedGames={(games) => handleGameSelection(u.userId, 'puttingGames', games)}
                                    part="Putting"
                                />
                                <MultiSelectGames
                                    games={gamesConst.argGames}
                                    selectedGames={selectedGames.find(entry => entry.date === practiceDate.toISOString().split('T')[0])?.[u.userId]?.argGames || []}
                                    setSelectedGames={(games) => handleGameSelection(u.userId, 'argGames', games)}
                                    part="Arg"
                                />
                                <MultiSelectGames
                                    games={gamesConst.appGames}
                                    selectedGames={selectedGames.find(entry => entry.date === practiceDate.toISOString().split('T')[0])?.[u.userId]?.appGames || []}
                                    setSelectedGames={(games) => handleGameSelection(u.userId, 'appGames', games)}
                                    part="Approach"
                                />
                                <MultiSelectGames
                                    games={gamesConst.teeGames}
                                    selectedGames={selectedGames.find(entry => entry.date === practiceDate.toISOString().split('T')[0])?.[u.userId]?.teeGames || []}
                                    setSelectedGames={(games) => handleGameSelection(u.userId, 'teeGames', games)}
                                    part="Tee"
                                />
                                <MultiSelectGames
                                    games={gamesConst.combineGames}
                                    selectedGames={selectedGames.find(entry => entry.date === practiceDate.toISOString().split('T')[0])?.[u.userId]?.combineGames || []}
                                    setSelectedGames={(games) => handleGameSelection(u.userId, 'combineGames', games)}
                                    part="Combine"
                                />
                                <div>
                                    <label>Selected games for {practiceDate.toLocaleDateString()}:</label>
                                    {selectedGames
                                        .filter(entry => entry.date === practiceDate.toISOString().split('T')[0]) // Filter by selected date
                                        .map((entry) => (
                                            Object.keys(entry)
                                                .filter((key) => key !== 'date') // Exclude the 'date' key
                                                .map((userId) => {
                                                    const gamesForUser = entry[userId];
                                                    return (
                                                        <div key={userId}>
                                                            {Object.keys(gamesForUser).map((part) => (
                                                                <div key={part}>
                                                                    <h4>{part}</h4>
                                                                    {gamesForUser[part].map((game) => (
                                                                        <li key={game.name}>{game.name}</li>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    );
                                                })
                                        ))}
                                </div>



                            </Card>)
                    })}
                    <Button label="Send All Schedules"
                        onClick={() => FetchSchedule(selectedGames, props.getJWT, toast)}
                        className="m-4" />

                </Card>
            </div>}
        </div >
    )

}
export { TeamPlanner }