import { RoundTypeDropdown } from "../../dropdowns/RoundTypeDropdown";
import { DurationDropdown } from "../../dropdowns/DurationDropdown";
import { SelectButton } from "primereact/selectbutton";
const TimeFilters = ({ roundType, setRoundType, durations, setNumberOfRounds, setDurationType, setTimeDuration, setCustom,
    statsType, setStatsType
}) => {
    const statsTypes = ["Basic", "Advanced"];
    const roundTypes = ["All", "Tournament", "Qualifying", "Practice"]

    return (
        <div>
            <label htmlFor="dropdowns">Rounds for analysis</label>
            <div id="dropdowns">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="m-2">
                        <RoundTypeDropdown className="m-2" roundTypes={roundTypes} roundType={roundType}
                            setRoundType={setRoundType} setTournamentSelected={() => console.log()} />
                    </div>
                    <div className="m-2">
                        <DurationDropdown className="m-2" durations={durations} setNumberOfRounds={setNumberOfRounds}
                            setDurationType={setDurationType} setTimeDuration={setTimeDuration} setCustom={setCustom} />
                    </div>
                    <div className="m-2">
                        <SelectButton value={statsType} onChange={(e) => setStatsType(e.value)} options={statsTypes} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TimeFilters;