import { Tooltip } from "primereact/tooltip"

export function GfOppInfo() {

    return (
        <div style={{ display: "inline-flex" }}>
            <Tooltip target=".gfInfo" />
            <div>Going for it Opp</div>
            <i className='pi pi-info-circle m-1 gfInfo' data-pr-event="both" data-pr-position="top" data-pr-tooltip={`Going for it opportunity is for any shot 1 on a par 4 or shot 2 on a par 5 when at least 1 player on the field went for the green under regulation.`} />
        </div>
    )
}
export function ForcedLayupInfo(baseline, units) {
    return (
        <div style={{ display: "inline-flex" }}>
            <Tooltip target=".UDInfo" />
            <div>Forced Layup</div>
            <i className='pi pi-info-circle m-1 UDInfo' data-pr-event="both" data-pr-position="top" data-pr-tooltip={`Only when below: ${baseline === "LPGA" ? (units === "meters" ? "235m" : "257y") : (units === "meters" ? "272m" : "297y")}`} />
        </div>
    )
}

export function ChipDifficultyInfo() {
    return (
        <div style={{ display: "inline-flex" }}>
            <Tooltip target=".chipInfo" />
            <i className='pi pi-info-circle m-1 chipInfo' data-pr-event="both" data-pr-position="top" data-pr-tooltip="Inside 30yds. Easy chip is a chip where the distance from the ball to the edge of the green is less 
        than 1/3 of the distance of the shot. Hard chip is a chip where the distance from the ball to the edge of the green is more than 2/3
        of the total distance. Medium shot is in between. Adjust for lies. If not sure select medium. Outside of 30yds is more subjective." />
        </div>
    )
}

export function RecoveryDifficultyInfo() {
    return (
        <div style={{ display: "inline-flex" }}>
            <Tooltip target=".chipInfo" />
            <i className='pi pi-info-circle m-1 chipInfo' data-pr-event="both" data-pr-position="top" data-pr-tooltip="Easy recovery is when you don't have a clean shot at the pin, but still
        able to advance the green. For example a tree is in the way and you have to punch out but you're able to put the ball close or on the green.
        Medium reovery is a recovery where you cannot take a normal line at the pin. Hard recovery is basically a sideways or backwards chip out.
        If not sure select medium." />
        </div>
    )
}