import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { FileUpload } from "primereact/fileupload"
import { useEffect, useState } from "react"

const ContactForm = () => {
    const [isOther, setIsOther] = useState(false);
    const subjectList = ["Bug/Error", "New Stat", "New Drill", "Billing", "Other"]
    const [subject, setSubject] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("")
    useEffect(() => {
        setIsOther(subject === "Other")
    }, [subject])
    return (
        <Card title="Contact Form">
            <div style={{ display: 'flex', flexWrap: "wrap", flexDirection: 'column', alignItems: 'center', overflowX: 'hidden', justifyContent: 'center' }} className="mt-3">

                <div >
                    <label htmlFor="email">Email</label>
                    <div>
                        <InputText id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className="mt-3">
                    <label>Subject</label>
                    <div>
                        <Dropdown options={subjectList} value={subject} onChange={(e) => setSubject(e.value)} style={{ minWidth: "180px" }} />
                    </div>
                    {isOther && <InputText className="mt-2" placeholder="Subject" />}
                </div>
                <div className="mt-3">
                    <label>Message</label>
                    <div>
                        <InputTextarea autoResize rows={10} cols={40} value={message} onChange={(e) => setMessage(e.target.value)} />
                    </div>
                </div>
                <div className="mt-3">
                    <label>Screenshot</label>
                    <div>
                        <FileUpload mode="basic" multiple maxFileSize={1000000} />
                    </div>
                </div>
                <div className="mt-3">
                    <Button label="Send" disabled={email === "" || subject === "" || message === ""} style={{ minWidth: "250px" }} />
                </div>
            </div>
        </Card>
    )
}
export default ContactForm