import React, { useEffect, useState } from 'react';
import { Container, Table, Row, Col } from "reactstrap";
import '../../../css/loading.css'
import 'primeicons/primeicons.css';
import '../../../css/stats.css'
import '../../../custom.css'
import { Message } from 'primereact/message';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { useConstant } from '../../../ConstantContext';
import { fillAnalysis } from '../AnalysisFive';
import Strengths from '../Strengths'
import Weaknasses from '../Weaknesses';
import { useOptions } from '../StatsOptionsProvider';
import ARGindividual from './Tables/ARGindividual';
import OTTindividual from './Tables/OTTindividual';
import APPindividual from './Tables/APPindividual';
import { SelectButton } from 'primereact/selectbutton';
import PUTTindividual from './Tables/PUTTindividual';
import PinPosIndividual from './Tables/PinPosIndividual';
import { useAuth } from "../../appwrite-testing/AuthContext";
import Tiger5Individual from './Tables/Tiger5Individual';
import ScoringIndividual from './Tables/ScoringIndividual';
import ClubsIndividual from './Tables/ClubsIndividual';
import GoingForItIndividual from './Tables/GoingForItIndividual';
import MistakesIndividual from './Tables/MistakesIndividual';
import Goals from '../../goals/Goals';

const IndividualStats = ({ data, baseline, requestProcessed, units, userId }) => {
    const { user } = useAuth();

    const [scoreAverage, setScoreAverage] = useState("");
    const [totalImprovement, setTotalImprovement] = useState("");
    const [newScoreAverage, setNewScoreAverage] = useState("");

    const constants = useConstant();
    const options = useOptions();

    // Define the ranges for the values and attempts

    const [isAdvanced, setIsAdvanced] = useState(false);
    const [statsType, setStatsType] = useState("Basic");
    const statsTypes = ["Basic", "Advanced"];

    // Create state variables and setters using loops
    const [state, setState] = useState(() => {
        const initialState = {};
        initialState['noOfRounds'] = 0;
        options.tiger5stats.forEach((stat) => {
            options.optionsTiger.forEach((option) => {
                initialState[`${stat.name}${option}`] = 0;
            });
        });
        options.clubsApp.forEach((clubApp) => {
            initialState[`${clubApp}`] = {};
        });
        initialState[`sgOTT`] = 0.0;
        initialState[`sgOTTdriver`] = 0.0;
        initialState[`sgOTTother`] = 0.0;
        options.teeStats.forEach((stat) => {
            initialState[`${stat}Attempts`] = 0;
            initialState[`${stat}Count`] = 0;
            initialState[`${stat}Hit`] = 0;

        });
        options.teeMiss.forEach((stat) => {
            initialState[`${stat.name}`] = 0;
        });
        options.clubsTee.forEach((clubTee) => {
            initialState[`tee${clubTee}Attempts`] = 0;
            initialState[`tee${clubTee}Success`] = 0;
            initialState[`fairway${clubTee}Hit`] = 0;
        });
        options.scoringStats.forEach((stat) => {
            initialState[`${stat.name}`] = 0;
        });
        options.sgApp.forEach((stat) => {
            initialState[`${stat}`] = 0.0;
        });
        options.sgAppAdvanced.forEach((stat) => {
            initialState[`${stat}`] = 0.0;
        });
        initialState[`sgAPP`] = 0.0;
        options.rangesApp.forEach((range) => {
            options.positionsApp.forEach((position) => {
                initialState[`${range.name}${position}`] = 0;
            });
        });
        options.gfApp.forEach((range) => {
            options.gfOptions.forEach((position) => {
                initialState[`${range}${position}`] = 0;
            });
        });
        options.missApp.forEach((stat) => {
            initialState[`${stat.name}`] = 0;
        });
        options.expected.forEach((stat) => {
            initialState[`${stat.name}`] = 0;
        });
        options.goingForIt.forEach((a) => {
            options.goingForItOptions.forEach((b) => {
                initialState[`${a}${b}`] = 0;
            });
        });
        initialState['goingDistanceAfter'] = 0;
        options.sgPuttAdvanced.forEach((stat) => {
            initialState[`${stat.name}`] = 0.0;
        })
        options.puttingFeet.forEach((stat) => {
            initialState[`${stat.name}`] = 0;
        });
        options.argStats.forEach((a) => {
            initialState[`${a}`] = 0;
        });
        options.argSG.forEach((a) => {
            initialState[`${a}`] = 0;
        });
        options.mistakes.forEach((a) => {
            options.mistakesOptions.forEach((b) => {
                initialState[`${b}${a}`] = 0;
            });
        });
        options.goingAvg.forEach((a) => {
            initialState[`${a.name}`] = 0.0
        })
        options.rangesPins.forEach((a) => {
            options.positions.forEach((b) => {
                initialState[`${a.name}.${b.name}StrokesGainedAttempts`] = 0
                initialState[`${a.name}.${b.name}StrokesGainedPerShot`] = 0.0

            })

        })
        return initialState;
    });

    const [activeTabs, setActiveTabs] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])

    useEffect(() => {
        options.setUnits(units);
        options.setBaseline(baseline);
    }, [units, baseline])

    useEffect(() => {
        if (data || requestProcessed) {
            fillOutTable(data);
        }
        if (baseline === "LPGA") {
            constants.setPgaBase(false);
        }
        if (units === "meters") {
            constants.setUnits("meters");
        }
    }, [data, requestProcessed, units])
    /**
     * Calls all functions that fill out tables
     * @param {JSON} data 
     */
    async function fillOutTable(data) {
        try {
            fillAnalysis(data, units, setState, setScoreAverage, setTotalImprovement, setNewScoreAverage, [], 5, 5, isAdvanced);
            fillStats(data);
            setActiveTabs([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
        } catch (e) {
            console.error(e)
        }
    }
    function fillStats(data) {
        Object.keys(data).forEach((key) => {
            setState((prev) => ({
                ...prev,
                [key]: data[key],
            }));
        });
    }

    function openAll() {
        if (activeTabs.length > 0) {
            setActiveTabs([])
        } else {
            setActiveTabs([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17])
        }
    }
    function onAdvanced(value) {
        setStatsType(value);
        setIsAdvanced(value !== "Basic");
        fillAnalysis(data, units, setState, setScoreAverage, setTotalImprovement, setNewScoreAverage, [], 5, 5, value !== "Basic");
    }
    return (
        < div >
            <>
                <Container className="ps-0">
                    {baseline === "LPGA" &&
                        <Message className='m-3' severity="info" text="*LPGA average/baseline" />}
                    {data && <Message className='m-3' severity="info" text={`${data.noOfRounds} round${data.noOfRounds === 1 ? "" : "s"}`} />}
                    <Row className="mt-3 mb-5">
                        <Col>
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: 'row', alignItems: 'flex-start', overflowX: 'hidden', justifyContent: 'left', gap: "30px" }}>
                                <Button label={activeTabs.length > 0 ? "Close All" : "Open All"}
                                    icon={activeTabs.length > 0 ? "pi pi-minus" : "pi pi-plus"}
                                    className="mb-4" onClick={() => openAll()} />
                                {user?.labels?.includes('advanced') && <SelectButton value={statsType} onChange={(e) => onAdvanced(e.value)} options={statsTypes} />}
                            </div>
                            <Accordion activeIndex={activeTabs} multiple onTabChange={(e) => setActiveTabs(e.index)}>
                                <AccordionTab header="Goals">
                                    <Goals state={state} data={data} userId={userId} isAdvanced={isAdvanced} />
                                </AccordionTab>
                                <AccordionTab header="5 Strengths">
                                    <Strengths state={state} data={data} />
                                </AccordionTab>
                                <AccordionTab header="5 Elements to Improve">
                                    <Weaknasses state={state} data={data} scoreAverage={scoreAverage} totalImprovement={totalImprovement} newScoreAverage={newScoreAverage} />
                                </AccordionTab>
                                <AccordionTab header="Tiger 5">
                                    <Tiger5Individual state={state} data={data} isAdvanced={isAdvanced} />
                                </AccordionTab>
                                <AccordionTab header="Scoring">
                                    <ScoringIndividual state={state} data={data} isAdvanced={isAdvanced} />
                                </AccordionTab>
                                <AccordionTab header="Off the Tee">
                                    <OTTindividual state={state} data={data} isAdvanced={isAdvanced} />
                                </AccordionTab>
                                <AccordionTab header="Approaches">
                                    <APPindividual state={state} data={data} isAdvanced={isAdvanced} />
                                </AccordionTab>
                                {isAdvanced && <AccordionTab header="Pin Positions">
                                    <PinPosIndividual state={state} data={data} />
                                </AccordionTab>}
                                {isAdvanced && <AccordionTab header="Clubs">
                                    <ClubsIndividual state={state} data={data} isAdvanced={isAdvanced} />
                                </AccordionTab>}
                                {isAdvanced && <AccordionTab header="Going for It">
                                    <GoingForItIndividual state={state} data={data} isAdvanced={isAdvanced} units={units} />
                                </AccordionTab>}
                                <AccordionTab header="Around the Green">
                                    <ARGindividual state={state} data={data} isAdvanced={isAdvanced} />
                                </AccordionTab>
                                <AccordionTab header="Putting">
                                    <PUTTindividual state={state} data={data} isAdvanced={isAdvanced} />
                                </AccordionTab>
                                {isAdvanced && <AccordionTab header="Mistakes" >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                        <MistakesIndividual state={state} data={data} isAdvanced={isAdvanced} />
                                    </div>
                                </AccordionTab >}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </>
        </div >
    );
}

export { IndividualStats }