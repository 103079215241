import { Card } from "primereact/card"
import { useState } from "react"
import { Button } from "primereact/button";
const Billing = () => {
    const [accountStatus, setAccountStatus] = useState("Active");
    const [currentPlan, setCurrentPlan] = useState("Individual Monthly");
    const [renewalDate, setRenewalDate] = useState("01/01/2025");
    return (
        <Card title="Subscription & Billing" style={{ maxWidth: "300px" }} className="m-4">
            <div className="mt-2">
                <div className="m-1">
                    <label style={{ fontWeight: "bold" }}>Account Status:</label>
                    <div style={{ color: accountStatus === "Active" ? "green" : "red" }}>{accountStatus}</div>
                </div>
                <div className="m-1">
                    <label style={{ fontWeight: "bold" }}>Current plan:</label>
                    <div>{currentPlan}</div>
                </div>
                <div className="m-1">
                    <label style={{ fontWeight: "bold" }}>Renewal Date:</label>
                    <div>{renewalDate}</div>
                </div>
                <div className="m-2">
                    <Button label="Change Plan" />
                </div>
                <div className="m-2">
                    <Button size="small" outlined severity="danger" label="Cancel Plan" />
                </div>
            </div>

        </Card>
    )
}
export default Billing