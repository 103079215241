import React, { useState, useEffect } from "react";
import { Card } from 'primereact/card';
import { Button } from "primereact/button";

import { ShotDetails } from './ShotDetails.js'
import { useAuth } from "../appwrite-testing/AuthContext.js";
import { useConstant } from "../../ConstantContext.js";
const AddShot = ({ roundId, prevShot, setIsShotSelected, units, addNextHole, toast }) => {

    const constants = useConstant();
    var [shotDst, setShotDst] = useState(null);
    var [club, setClub] = useState(prevShot ? (prevShot.lie === "green" ? "" : "9i") : "driver");
    var [mental, setMental] = useState("good");
    /**Number of total shot for the round */
    var [shotTotal] = useState(prevShot ? (prevShot.penalty ? prevShot.shotTotal + 2 : prevShot.shotTotal + 1) : 1);
    const [parHole, setParHole] = useState(prevShot && prevShot.parHole ? (prevShot.parHole) : 1);
    /**Number of current shot on the hole */
    var [shotHole] = useState(addNextHole ? 1 : prevShot ? (prevShot.penalty ? prevShot.shotHole + 2 : prevShot.shotHole + 1) : 1);
    /**Number of current hole */
    var [holeNo] = useState(prevShot && prevShot.holeNo ? (addNextHole ? prevShot.holeNo + 1 : prevShot.holeNo) : 1);
    /**Current Lir of the shot */
    var [currentLie, setCurrentLie] = useState(addNextHole ? "tee" : prevShot ? (prevShot.lie === "green" ? "green" : "fairway") : "tee");
    /**Is distance input valid */
    var [validInput, setValidInput] = useState(false);
    /**Value of layup check */
    var [layup, setLayup] = useState(false);
    /**Value of penalty check */
    var [penalty, setPenalty] = useState(false);
    //var [penaltyChange, setPenaltyChange] = useState("no");
    /**Value of direction miss */
    var [missDir, setMissDir] = useState("No miss");
    /**Value of distance miss */
    var [missDst, setMissDst] = useState("No miss");

    var [shortSided, setShortSided] = useState(false);

    const [difficulty, setDifficulty] = useState("medium");
    const [pinDst, setPinDst] = useState("middle");
    const [pinDir, setPinDir] = useState("center");
    const [gfOpp, setGfOpp] = useState(false);
    const [forcedLayupDistance, setForcedLayupDistance] = useState(0);
    const { getJWT } = useAuth();

    var shotJSON = {
        shotTotal: "",
        shotHole: "",
        parHole: "",
        holeNo: "",
        shotDst: "",
        lie: "",
        club: "",
        mental: "",
        missDst: "",
        missDir: "",
        penalty: "",
        layup: "",
        shortSided: "",
        gfOpp: ""
    };

    function fillJSON() {
        shotJSON.shotTotal = shotTotal;
        shotJSON.shotHole = shotHole;
        shotJSON.parHole = parHole;
        shotJSON.holeNo = holeNo;
        shotJSON.shotDst = parseInt(shotDst);
        shotJSON.lie = currentLie.toLowerCase();
        shotJSON.club = club.toLowerCase();
        shotJSON.mental = mental.toLowerCase();
        if (missDst === "No miss") {
            shotJSON.missDst = "";
        } else {
            shotJSON.missDst = missDst.toLowerCase();
        }
        if (missDir === "No miss") {

            shotJSON.missDir = "";
        } else {

            shotJSON.missDir = missDir.toLowerCase();
        }
        shotJSON.penalty = penalty;
        shotJSON.layup = layup;
        shotJSON.shortSided = shortSided;
        shotJSON.difficulty = difficulty;
        shotJSON.pinDst = pinDst;
        shotJSON.pinDir = pinDir;
        shotJSON.gfOpp = gfOpp;
        shotJSON.forcedLayupDistance = (units === "meters" ? parseInt(forcedLayupDistance * constants.metersToYards) : parseInt(forcedLayupDistance));
    }


    async function addShot() {
        const jwt = await getJWT();
        fillJSON();
        fetch(`/api/Round/add-shot?roundId=${roundId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            }, body: JSON.stringify(shotJSON)
        })
            .then((response) => {
                return response;
            })
            .then((data) => {
                setIsShotSelected(false)
                toast.current.show({
                    severity: 'success', summary: 'Shot Added Successfully'
                });
            })
            .catch((error) => console.log(error));
    }




    function clubsChange() {
        if (currentLie.toLowerCase() === "green") {
            setClub("");
        } else {
            setClub("9i");
        }
    }
    function handlePenalty(event) {
        setPenalty(event.checked)

    }

    useEffect(() => {

        if (currentLie.toLowerCase() !== "green") {
            if (shotDst <= 50) {
                if (shotDst && currentLie && mental) {
                    setValidInput(true);
                } else {
                    setValidInput(false);
                }
            } else {
                if (shotDst && currentLie && mental && (club || layup)) {
                    setValidInput(true);
                } else {
                    setValidInput(false);
                }
            }
        } else {
            if (shotDst <= 2 && currentLie) {
                setValidInput(true);
            }
            else if (shotDst && currentLie && mental) {
                setValidInput(true);
            } else {
                setValidInput(false);
            }

        }
    }, [shotDst, currentLie, mental, club, layup])

    useEffect(() => {
        clubsChange();
    }, [currentLie])

    const cardFooter = (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "5px" }}>
            <Button style={{ width: "45%" }} label="Add Shot" disabled={!validInput} color="primary" onClick={() => { addShot(); }} />
            <Button style={{ width: "45%" }} label="Go Back" severity="secondary" onClick={() => { setIsShotSelected(false); }} />
        </div>
    )
    return (
        <Card title="Add Shot" footer={cardFooter} >
            <ShotDetails
                shotDst={shotDst}
                setShotDst={setShotDst}
                club={club}
                setClub={setClub}
                mental={mental}
                setMental={setMental}
                parHole={parHole}
                setParHole={setParHole}
                shotHole={shotHole}
                holeNo={holeNo}
                currentLie={currentLie}
                setCurrentLie={setCurrentLie}
                layup={layup}
                setLayup={setLayup}
                penalty={penalty}
                handlePenalty={handlePenalty}
                missDir={missDir}
                setMissDir={setMissDir}
                missDst={missDst}
                setMissDst={setMissDst}
                difficulty={difficulty}
                setDifficulty={setDifficulty}
                pinDst={pinDst}
                setPinDst={setPinDst}
                pinDir={pinDir}
                setPinDir={setPinDir}
                units={units}
                gfOpp={gfOpp}
                setGfOpp={setGfOpp}
                forcedLayupDistance={forcedLayupDistance}
                setForcedLayupDistance={setForcedLayupDistance}
                addNextHole={addNextHole}
            />

        </Card>
    )

};

export { AddShot };
