import { Card } from "primereact/card";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Table } from "reactstrap";
import { Message } from 'primereact/message';
import { useGames } from "../../../GamesProvider";
import { calculateTime } from "../Helpers/PracticeFunctions";
import { getRoundsCallbackList, getRoundsSemesterCallbackList } from "../../stats_components/StatsAPICall";
import { MultiSelectPlayers } from "../../dropdowns/MultiSelectPlayers";
import { MultiSelectGames } from "../../dropdowns/MultiSelectGames";
import Strengths from "../../stats_components/Strengths";
import Weaknasses from "../../stats_components/Weaknesses";
import { fillAnalysis } from "../../stats_components/AnalysisFive";
import PlannerTimeAndNumber from "../Helpers/PlannerTimeAndNumber";
import Facilities from "../Helpers/Facilities";
import TimeFilters from "../Helpers/TimeFilters";
import { FetchScheduleToAll } from "./FetchSchedule";
import { Toast } from "primereact/toast";
import { useConstant } from "../../../ConstantContext";
import DateWithButtons from "../DateWithButtons";
const GroupPlanner = (props) => {
    const [scoreAverage, setScoreAverage] = useState("");
    const [totalImprovement, setTotalImprovement] = useState("");
    const [newScoreAverage, setNewScoreAverage] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [displayedPlayers, setDisplayedPlayers] = useState([]);
    const [allUserAccounts] = useState(props.allUserAccounts);
    const toast = useRef(null);
    useEffect(() => {
        if (selectedUsers.length > 0) {
            setDisplayedPlayers(selectedUsers);
        } else {
            setDisplayedPlayers(allUserAccounts)
        }
    }, [selectedUsers, allUserAccounts])
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [totalMinutes, setTotalMinutes] = useState(0);
    const [elements, setElements] = useState(1);
    const [roundType, setRoundType] = useState("All");
    const [numberOfRounds, setNumberOfRounds] = useState(10);
    const [timeDuration, setTimeDuration] = useState(0);
    const [durationType, setDurationType] = useState("number");
    const [custom, setCustom] = useState(false);
    const [stats, setStats] = useState(null);
    const roundTypes = ["All", "Tournament", "Qualifying", "Practice"]
    const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds", "Last 15 Rounds",
        "Last 20 Rounds", "Last 30 Rounds", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Fall", "Spring",];
    const options = ['Name', 'Total', 'Attempts', 'PerShot', 'Improvement', 'Time'];
    const practiceOptions = ['Name', 'Total', 'Time'];

    const facilities = ["Range Driver", "Range Irons", "Range Woods", "Short Game Area", "Putting Green"];
    const [selectedFacilities, setSelectedFacilities] = useState(facilities)

    const gamesConst = useGames();
    const constants = useConstant();
    const [selectedGames, setSelectedGames] = useState([]);
    const [state, setState] = useState(() => {
        const initialState = {};
        for (var i = 1; i < 8; i++) {
            options.forEach((option) => {
                initialState[`analysis${i}${option}`] = '';
                initialState[`strength${i}${option}`] = '';
            })
        }
        return initialState;
    })
    const [numberImprovement, setNumberImprovement] = useState(2);
    const [numberStrength, setNumberStrength] = useState(1);
    const [units, setUnits] = useState("yards")

    const [loadingButton, setLoadningButton] = useState(false)
    const green = "rgba(0, 128, 0, 0.75)";
    const red = "rgba(220, 0, 0, 0.75)"

    const [statsType, setStatsType] = useState("Basic");
    const [practiceDate, setPracticeDate] = useState(new Date())
    const [loadingSend, setLoadingSend] = useState(false);


    useEffect(() => {
        switch (elements) {
            case 3:
                setNumberImprovement(2);
                setNumberStrength(1);
                break;
            case 4:
                setNumberImprovement(3);
                setNumberStrength(1);
                break;
            case 5:
                setNumberImprovement(3);
                setNumberStrength(2);
                break;
            case 6:
                setNumberImprovement(4);
                setNumberStrength(2);
                break;
            case 7:
                setNumberImprovement(5);
                setNumberStrength(2);
                break;
            case 8:
                setNumberImprovement(5);
                setNumberStrength(3);
                break;
            case 9:
                setNumberImprovement(6);
                setNumberStrength(3);
                break;
            case 10:
                setNumberImprovement(7);
                setNumberStrength(3);
                break;
        }
    }, [elements])

    async function getRounds() {
        setLoadningButton(true);
        const jwt = await props.getJWT();
        if (durationType.toLowerCase() === "fall") {
            getRoundsSemesterCallbackList(
                displayedPlayers,
                constants.fallStart,
                constants.fallEnd,
                roundType,
                (data) => { setStats(data); setLoadningButton(false) },
                jwt
            )
        } else if (durationType.toLowerCase() === "spring") {
            getRoundsSemesterCallbackList(
                displayedPlayers,
                constants.springStart,
                constants.springEnd,
                roundType,
                (data) => { setStats(data); setLoadningButton(false) },
                jwt
            )
        } else {

            getRoundsCallbackList(
                displayedPlayers,
                durationType,
                timeDuration,
                numberOfRounds,
                roundType,
                (data) => {
                    setStats(data); setLoadningButton(false);
                },
                jwt
            )
        }
    }


    useEffect(() => {
        if (stats !== null)
            calculateTime(
                fillAnalysis(stats, units, setState, setScoreAverage, setTotalImprovement, setNewScoreAverage, selectedFacilities, numberImprovement, numberStrength, statsType !== "Basic"),
                numberImprovement, numberStrength, totalMinutes, setState);
    }, [stats])


    useEffect(() => {
        setTotalMinutes(hours * 60 + minutes)
    }, [hours, minutes])

    async function sendToAll() {
        try {
            setLoadingSend(true); // Start loading before sending
            const promises = selectedUsers.map((p) =>
                FetchScheduleToAll(selectedGames, props.getJWT, toast, p.userId)
            );
            await Promise.all(promises); // Wait for all async calls to complete
        } catch (error) {
            console.error('Error sending schedules:', error); // Handle errors if needed
        } finally {
            await new Promise((resolve) => setTimeout(resolve, 2000)); // Add a 3-second delay
            setLoadingSend(false); // Stop loading after the delay}
        }
    }
    const updateSelectedGames = (date, category, games) => {
        setSelectedGames((prev) => {
            const dateString = date.toISOString().split('T')[0];
            const existingDateEntry = prev.find((entry) => entry.date === dateString);

            if (existingDateEntry) {
                // Update existing date entry
                return prev.map((entry) =>
                    entry.date === dateString
                        ? { ...entry, [category]: games }
                        : entry
                );
            } else {
                // Add a new entry for the date
                return [...prev, { date: dateString, [category]: games }];
            }
        });
    };


    return (
        <div>
            <Toast ref={toast} />
            <Card className="mt-2 mb-2" title="Plan team's day" >
                <PlannerTimeAndNumber hours={hours} setHours={setHours} minutes={minutes} setMinutes={setMinutes}
                    elements={elements} setElements={setElements} />
                <TimeFilters roundType={roundType} setRoundType={setRoundType} durations={durations}
                    setNumberOfRounds={setNumberOfRounds} setDurationType={setDurationType} setTimeDuration={setTimeDuration}
                    setCustom={setCustom} statsType={statsType} setStatsType={setStatsType} />
                <Facilities selectedFacilities={selectedFacilities} setSelectedFacilities={setSelectedFacilities}
                    statsType={statsType} />
                <MultiSelectPlayers players={props.players} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers}
                    durations={durations} coachTeam={props.coachTeam} />
                <Button loading={loadingButton} className="m-2" disabled={totalMinutes <= 0 || selectedUsers.length <= 0}
                    label="Plan my practice" onClick={() => getRounds()} />
            </Card >
            {
                <div>
                    <Card className="mt-2 mb-2">

                        <Strengths state={state} data={stats} />
                        <Weaknasses state={state} data={stats} scoreAverage={scoreAverage} totalImprovement={totalImprovement} newScoreAverage={newScoreAverage} />

                    </Card>
                    <Card className="mt-2 mb-2" title="Suggested Practice">
                        <DateWithButtons practiceDate={practiceDate} setPracticeDate={setPracticeDate} />
                        {stats && <Message className='m-3' severity="info" text={`${stats.noOfRounds} round${stats.noOfRounds === 1 ? "" : "s"}`} />}

                        <div className="table-responsive">
                            <Table id='strengthtable' className='stats-table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Total SG</th>
                                        <th>Time (min)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: numberImprovement }).map((_, index) => (
                                        <tr style={{
                                            color: "white",
                                            backgroundColor: red
                                        }}>
                                            {practiceOptions.map((option) => (
                                                <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`analysis${index + 1}${option}`]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    {Array.from({ length: numberStrength }).map((_, index) => (
                                        <tr style={{
                                            color: "white",
                                            backgroundColor: green
                                        }}>
                                            {practiceOptions.map((option) => (
                                                <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`strength${index + 1}${option}`]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <MultiSelectGames
                                games={gamesConst.puttingGames}
                                selectedGames={selectedGames.find((entry) => entry.date === practiceDate.toISOString().split('T')[0])?.puttingGames || []}
                                setSelectedGames={(games) => updateSelectedGames(practiceDate, "puttingGames", games)}
                                part="Putting"
                            />
                            <MultiSelectGames
                                games={gamesConst.argGames}
                                selectedGames={selectedGames.find((entry) => entry.date === practiceDate.toISOString().split('T')[0])?.argGames || []}
                                setSelectedGames={(games) => updateSelectedGames(practiceDate, "argGames", games)}
                                part="Arg"
                            />
                            <MultiSelectGames
                                games={gamesConst.appGames}
                                selectedGames={selectedGames.find((entry) => entry.date === practiceDate.toISOString().split('T')[0])?.appGames || []}
                                setSelectedGames={(games) => updateSelectedGames(practiceDate, "appGames", games)}
                                part="Approach"
                            />
                            <MultiSelectGames
                                games={gamesConst.teeGames}
                                selectedGames={selectedGames.find((entry) => entry.date === practiceDate.toISOString().split('T')[0])?.teeGames || []}
                                setSelectedGames={(games) => updateSelectedGames(practiceDate, "teeGames", games)}
                                part="Tee"
                            />
                            <MultiSelectGames
                                games={gamesConst.combineGames}
                                selectedGames={selectedGames.find((entry) => entry.date === practiceDate.toISOString().split('T')[0])?.combineGames || []}
                                setSelectedGames={(games) => updateSelectedGames(practiceDate, "combineGames", games)}
                                part="Combine"
                            />
                            <div>
                                <label>Selected games for {practiceDate.toLocaleDateString()}:</label>
                                {selectedGames.find((entry) => entry.date === practiceDate.toISOString().split('T')[0]) ? (
                                    Object.entries(
                                        selectedGames.find((entry) => entry.date === practiceDate.toISOString().split('T')[0])
                                    ).map(([category, games]) =>
                                        category !== "date" ? (
                                            <div key={category}>
                                                <h4>{category}</h4>
                                                <ul>
                                                    {games.map((game, index) => (
                                                        <li key={index}>{game.name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : null
                                    )
                                ) : (
                                    <p>No games selected for this date.</p>
                                )}
                            </div>

                            <Button label="Send" disabled={selectedUsers.length <= 0} loading={loadingSend} onClick={() => sendToAll()} />

                        </div>
                    </Card></div>}
        </div >
    )

}
export { GroupPlanner };