import { Button } from "primereact/button";
const DateWithButtons = ({ practiceDate, setPracticeDate }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }} className="m-3">
            <Button label="<<" onClick={() => setPracticeDate(new Date(practiceDate.setDate(practiceDate.getDate() - 1)))} />
            <h1 style={{ margin: '0 10px' }}>
                {practiceDate.toDateString()}
            </h1>
            <Button label=">>" onClick={() => setPracticeDate(new Date(practiceDate.setDate(practiceDate.getDate() + 1)))} />

        </div>
    )
}
export default DateWithButtons;