import { useConstant } from "../../../../ConstantContext";
import { useOptions } from "../../StatsOptionsProvider";
import { Table } from "reactstrap";
const MistakesIndividual = ({ state, data, isAdvanced }) => {
    const constants = useConstant();
    const options = useOptions();
    return (
        <div className="mistake-container">
            {options.mistakesOptions.map((option) => (
                <Table id={`mistaketable${option}`} className='mistake-table'>
                    <thead>
                        <tr>
                            <th>{option}</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>{options.mistakes.map((mistake) => (
                        <tr>
                            <td>{mistake.display}</td>
                            <td className='data-cell'>{state[`${mistake.name}${option}`]}%</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ))}
        </div>
    )
}
export default MistakesIndividual;