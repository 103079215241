import { useOptions } from "../../StatsOptionsProvider";
import { Table } from "reactstrap";
import { Message } from "primereact/message";
const PinPosIndividual = ({ data, state }) => {
    const options = useOptions();
    return (
        <div className="table-responsive">
            <Message className='mb-0 mt-0 m-1' severity="info" text="*per shot" />
            <Message className='mb-0 mt-0 m-1' severity="warn" text="SG*/attempts" />
            <Table id='advapproachtable' className='stats-table'>
                <thead>
                    <tr>
                        <th></th>
                        {options.rangesPins.map((e) => (
                            <th>{e.display}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {options.positions.map((pos) => (
                        <tr>
                            <td>{pos.display}</td>
                            {options.rangesPins.map((p) => (
                                <td>{state[`${p.name}`]?.[`${pos.name}PerShot`]}/{state[`${p.name}`]?.[`${pos.name}Attempts`]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}
export default PinPosIndividual;