import { useEffect, useState, useRef } from "react";
import { client } from "../appwrite-testing/appwriteConfig";
import { Teams } from "appwrite";
import TeamList from "./TeamList";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

const MyTeam = ({ user }) => {

    const [teamsList, setTeamsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const inviteSent = useRef(null);

    const teams = new Teams(client);

    //create a list of all teams coach is member at
    useEffect(() => {
        teams.list().then(result => setTeamsList(result.teams)).then(setIsLoading(false));
    }, [])


    return (
        <div>
            <Toast ref={inviteSent} />
            {isLoading ?
                <ProgressSpinner />
                :
                teamsList.map((t) => (
                    <TeamList teams={teams} team={t} inviteSent={inviteSent} user={user} />
                ))
            }
        </div>
    )
}
export default MyTeam;