import React, { useState, useEffect, useRef } from "react";
import {
    Container, Row, Col, Table
} from "reactstrap";
import { Button } from "primereact/button";
import { EditPutting } from "../Individual/EditPutting";
import { Toast } from 'primereact/toast';
import { Message } from "primereact/message";
const PuttingPracticeResult = ({ userId }) => {

    const [rounds, setRounds] = useState([]);
    /**How many rounds should be shown on the table */
    const [numRoundsToShow, setNumRoundsToShow] = useState(10);
    const [lastScore, setLastScore] = useState(null);
    const [last5Score, setLast5Score] = useState(null);
    const [last10Score, setLast10Score] = useState(null);
    const [last30Score, setLast30Score] = useState(null);
    const tourAvg = 28.5;
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(0);
    const deleteToast = useRef(null);
    const [showMessage, setShowMessage] = useState(false);
    /**Formats the datae to mm/dd/yyyy */
    const formatDate = (date) => {
        const d = new Date(date);
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };
    /**Will increse the number of rounds shown in the table */
    const handleLoadMore = () => {
        setNumRoundsToShow(numRoundsToShow + 10);
    };

    function getRounds() {
        fetch(`/api/puttingpractice/user?userId=${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                setRounds(data);
                setShowMessage(false);
            })
            .catch(error => setShowMessage(true));
    }

    function updateStats() {
        fetch(`/api/puttingpractice/user-last?userId=${userId}&numberOfRounds=30`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                let sum30 = 0;
                let sum10 = 0;
                let sum5 = 0;
                let lastOne = data[0].scores.reduce((sum1, score) => sum1 + score, 0);
                let noR10 = 0;
                let noR5 = 0;
                let noR = 0;

                for (let i = 0; i < data.length; i++) {
                    sum30 += data[i].scores.reduce((sum, score) => sum + score, 0);
                    if (i < 10) {
                        sum10 += data[i].scores.reduce((sum1, score) => sum1 + score, 0);
                        noR10 = i;
                        if (i < 5) {
                            sum5 += data[i].scores.reduce((sum2, score) => sum2 + score, 0);
                            noR5 = i;
                        }
                    }
                    noR = i;


                }
                setLast30Score((tourAvg - sum30 / (noR + 1)).toFixed(2));
                setLast10Score((tourAvg - sum10 / (noR10 + 1)).toFixed(2));
                setLast5Score((tourAvg - sum5 / (noR5 + 1)).toFixed(2));
                setLastScore((tourAvg - lastOne).toFixed(2));
            })
            .catch(error => {
                setShowMessage(true)
            });
    }

    useEffect(() => {
        getRounds();
        updateStats();
    }, [rounds])

    function deleteCombine(id, date) {
        const confirmDelete = window.confirm(`Are you sure you want to delete this putting combine from ${new Date(date).toISOString().substring(0, 10)}?`);
        if (confirmDelete) {
            fetch(`/api/puttingpractice/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(data => {
                    deleteToast.current.show({ severity: 'success', summary: 'Deleted', detail: `Putting combine from ${new Date(date).toISOString().substring(0, 10)}`, life: 3000 });
                    getRounds();
                    updateStats();

                })
                .catch(error => console.error(error));
        }

    }

    function editCombine(id) {
        setEditId(id);
        setEdit(true);

    }

    return (
        <div>
            {showMessage && <Message severity="info" text="No pracice sessions were found" className="mb-3" />}
            {edit ?
                <EditPutting id={editId} setEdit={setEdit} />
                : <Container>
                    <Toast ref={deleteToast} />
                    <Row>
                        <Col md="12" lg="12">
                            <Table striped bordered >
                                <thead>
                                    <tr>
                                        <th>Last </th>
                                        <th>Last 5 avg. </th>
                                        <th>Last 10 avg.</th>
                                        <th>Last 30 avg.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td className={lastScore ? "" : "loading-cell"}>
                                            {lastScore}
                                        </td>
                                        <td className={last5Score ? "" : "loading-cell"}>
                                            {last5Score}
                                        </td>
                                        <td className={last10Score ? "" : "loading-cell"}>
                                            {last10Score}
                                        </td>
                                        <td className={last30Score ? "" : "loading-cell"}>
                                            {last30Score}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="table-responsive">
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Score</th>
                                            <th>Strokes Gained</th>

                                            <th></th>
                                        </tr>
                                    </thead>
                                    {rounds.length > 0 ? (
                                        <tbody>
                                            {rounds.slice(0, numRoundsToShow).map((round) => (
                                                <tr key={round.id}>
                                                    <td>{formatDate(round.date)}</td>
                                                    <td>{round.scores.reduce((sum, score) => sum + score, 0)}</td>
                                                    <td>{tourAvg - round.scores.reduce((sum, score) => sum + score, 0)}</td>
                                                    <td>
                                                        <Button icon="pi pi-file-edit" label="Edit" severity="secondary" size="small" onClick={() => editCombine(round.id)} />
                                                    </td>
                                                    <td>
                                                        <Button icon="pi pi-trash" label="Delete" severity="danger" onClick={() => deleteCombine(round.id, round.date)} size="small" />

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>) : (<tbody>
                                            <tr >
                                                <td className="loading-cell"></td>
                                                <td className="loading-cell"></td>
                                                <td className="loading-cell"></td>
                                                {/* <td className="loading-cell"></td> */}
                                                <td className="loading-cell"></td>
                                            </tr>
                                        </tbody>)}

                                </Table>
                            </div>
                        </Col>
                        <Col lg="12">
                            {numRoundsToShow < rounds.length && (
                                <div className="d-flex justify-content-center">
                                    <Button label="Load More" style={{ width: 300 }} severity="secondary" outlined onClick={handleLoadMore} className="mb-5" />
                                </div>
                            )}
                        </Col>
                    </Row >
                </Container>}
        </div>

    )
}

export { PuttingPracticeResult }