import React, { useState, useEffect } from "react";
import { Row, Col, Container, Alert, UncontrolledAlert } from "reactstrap";
import RoundTable from "../RoundTable";
import HomeStrokesGained from "./HomeStrokesGained";
import { useAuth } from "../appwrite-testing/AuthContext";
import Footer from "./Footer";
import { Badge } from 'primereact/badge';
import { Card } from 'primereact/card';
import DebugComponent from "../appwrite-testing/DebugComponent";
import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import HomeGoals from "./HomeGoals";

export default function Home2() {

  const { user, getJWT } = useAuth();
  const userId = user?.$id || "000000";
  const [wasRoundDeleted, setWasRoundDeleted] = useState(false);
  const [userUnits, setUserUnits] = useState("");
  const [data, setData] = useState(null);
  const [noRounds, setNoRounds] = useState(false);
  const [baseline, setBaseline] = useState("");
  const [role, setRole] = useState('');

  // New function to fetch user preferences
  const fetchUserPreferences = async (userId) => {
    const response = await fetch(`api/User/myself?userId=${userId}`);
    return response.json();
  };

  // React Query hook for user preferences
  const { data: userPreferences } = useQuery({
    queryKey: ['userPreferences', user?.$id],
    queryFn: () => fetchUserPreferences(user?.$id),
    enabled: !!user,
    onSuccess: (data) => {
      setUserUnits(data.units);
      setBaseline(data.baseline);

      // Store in localStorage
      window.localStorage.setItem("units", data.units);
      window.localStorage.setItem("baseline", data.baseline);
      window.localStorage.setItem("team", data.team);

      if (data.bag !== null) {
        window.localStorage.setItem("bag", JSON.stringify(data.bag));
      }
    },
    initialData: () => {
      // Use localStorage as initial data if available
      const units = window.localStorage.getItem("units");
      const baseline = window.localStorage.getItem("baseline");
      const team = window.localStorage.getItem("team");

      if (units && baseline && team) {
        return { units, baseline, team };
      }
      return undefined;
    }
  });

  // Set user data when authenticated
  useEffect(() => {
    if (user) {
      setRole(user.labels?.[0] || 'player');
    }
  }, [user]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const jwt = await getJWT();

        // Fetch calculation data
        const calcResponse = await fetch(`/api/Calculation/user-last-quick?numberOfRounds=20&userId=${userId}&roundType=All`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
          },
        });
        const calcData = await calcResponse.json();
        setData(calcData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId]);

  function roundTitle() {
    return (
      <Button label="Rounds" size="large" severity="secondary" text onClick={() => window.location.href = "/my-rounds"} pt={{
        root: { style: { padding: "5px" } }
      }} />
    )
  }
  function goalsTitle() {
    return (
      <Button label="Goals" size="large" severity="secondary" text onClick={() => window.location.href = "/stats"}
        pt={{
          root: { style: { padding: "5px" } }
        }} />
    )
  }
  function sgTitle() {
    return (
      <Button label="Strokes Gained" size="large" severity="secondary" text onClick={() => window.location.href = "/stats"} pt={{
        root: { style: { padding: "5px" } }
      }} />
    )
  }
  return (
    <>
      {!user ? (
        <>
          <UncontrolledAlert color="warning">
            BETA DISCLAIMER. 1.1. THE BETA SOFTWARE LICENSED HEREUNDER IS STILL IN THE TESTING PHASE...
          </UncontrolledAlert>

          <Alert color="secondary">
            <h1>Welcome to Parfect Performance!</h1>
            <p>
              Parfect Performance is the ultimate web app for college golf teams!...
            </p>
            {/* Rest of your welcome content */}
          </Alert>
          <Footer />
        </>
      ) : (
        <>
          <Container>
            <Row>
              <Col lg="10">
                <div className="mb-4">
                  <h2>
                    Welcome, {user.name} <Badge value={role} size={"xlarge"} />
                  </h2>
                </div>
              </Col>
              <Col>
                <Button
                  label="Add Round"
                  icon="pi pi-plus-circle"
                  onClick={() => window.location.href = `/round`}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={{ offset: 2, size: 8 }} md={{ offset: 0, size: 3 }} lg={{ offset: 0, size: 3 }}>
                <h4 className="text-center mb-3 level">Last 20 Rounds</h4>
                <Card body title={goalsTitle()} className="mb-3">
                  {/*  <HomeTiger5 data={data} noRounds={noRounds} units={userUnits} baseline={baseline} />*/}
                  <HomeGoals userId={userId} data={data} noRounds={noRounds} />
                </Card>
                <Card body title={sgTitle()} className="mb-2">
                  <HomeStrokesGained data={data} noRounds={noRounds} />
                </Card>
                {process.env.REACT_APP_ENV === "development" && <Card
                  body
                  title="Debug Info"
                  className="mb-2"
                  style={{
                    backgroundColor: '#ffeb3b',
                    color: '#000000'
                  }}
                >
                  <DebugComponent />
                </Card>}
              </Col>
              <Col xs="12" md="9" lg="9">
                <Card body className="" style={{ marginBottom: "10px", marginRight: "5px", marginLeft: "10px" }} title={roundTitle()}>
                  <RoundTable home={true} userId={userId} setWasRoundDeleted={setWasRoundDeleted} />
                </Card>
              </Col>
            </Row>

            <Footer />
          </Container>
        </>
      )}
    </>
  );
}
