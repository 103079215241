import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import GoalsDropdown from "./GoalsDropdown";
import { useAuth } from "../appwrite-testing/AuthContext";

const SetGoals = ({ setIsSetGoals, state, data, userId, isAdvanced, toast }) => {
    const [dropdowns, setDropdowns] = useState([0]); // Initial state with one dropdown
    const addDropdown = () => {
        setDropdowns([...dropdowns, dropdowns.length]); // Add a new dropdown identifier
    };
    const { getJWT } = useAuth();

    const [goals, setGoals] = useState([]);

    useEffect(() => {
        console.log(goals);
    }, [goals])

    async function submitGoals() {
        const jwt = await getJWT();
        const response = await fetch(`/api/goals?userId=${userId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify(goals) // Send all the practice sessions for this user
        });

        // Check if the response was successful (you can add more checks as needed)
        if (!response.ok) {
            console.error(`Failed to update goals for user ${userId}`);
            toast.current.show({ severity: 'danger', summary: 'Error!', detail: 'Goals NOT SET!' });
        } else {
            setIsSetGoals(false)
            console.log(`Successfully updated goals for user ${userId}`);
            toast.current.show({ severity: 'success', summary: 'Sent!', detail: "Goals set! Let's go!" });
        }
    }

    return (
        <Card title="Set new Goals">
            {dropdowns.map((key, index) => (
                <GoalsDropdown
                    key={key}
                    index={index}
                    state={state}
                    data={data}
                    setGoals={setGoals}
                    goals={goals}
                    isAdvanced={isAdvanced}
                />
            ))}
            <Button label="Add Goal" onClick={addDropdown} className="m-2 mt-4" icon="pi pi-plus" />
            <div>
                <Button label="Submit" onClick={() => submitGoals()} className="m-1" disabled={goals.length <= 0} />
                <Button label="Cancel" severity="danger" onClick={() => setIsSetGoals(false)} className="m-1" />
            </div>

        </Card>
    );
};

export default SetGoals;
