import { Card } from "primereact/card";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Table } from "reactstrap";
import { Message } from 'primereact/message';

import { calculateTime } from "../Helpers/PracticeFunctions";
import { fillAnalysis } from "../../stats_components/AnalysisFive";
import Strengths from "../../stats_components/Strengths";
import Weaknasses from "../../stats_components/Weaknesses";
import PlannerTimeAndNumber from "../Helpers/PlannerTimeAndNumber";
import TimeFilters from "../Helpers/TimeFilters";
import Facilities from "../Helpers/Facilities";
import { useAuth } from "../../appwrite-testing/AuthContext";
const Planner = () => {
    const green = "rgba(0, 128, 0, 0.75)";
    const red = "rgba(220, 0, 0, 0.75)"
    const { user, getJWT } = useAuth();
    const userId = user?.$id;
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [totalMinutes, setTotalMinutes] = useState(0);
    const [elements, setElements] = useState(1);
    const [roundType, setRoundType] = useState("All");
    const [numberOfRounds, setNumberOfRounds] = useState(10);
    const [timeDuration, setTimeDuration] = useState(0);
    const [durationType, setDurationType] = useState("number");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [custom, setCustom] = useState(false);
    const [stats, setStats] = useState(null);
    const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds", "Last 15 Rounds",
        "Last 20 Rounds", "Last 30 Rounds", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "This Year", "Fall", "Spring", "Custom"];
    const options = ['Name', 'Total', 'Attempts', 'PerShot', 'Improvement', 'Time'];
    const practiceOptions = ['Name', 'Total', 'Time'];
    const [state, setState] = useState(() => {
        const initialState = {};
        for (var i = 1; i < 8; i++) {
            options.forEach((option) => {
                initialState[`analysis${i}${option}`] = '';
                initialState[`strength${i}${option}`] = '';
            })
        }
        return initialState;
    })
    const [numberImprovement, setNumberImprovement] = useState(2);
    const [numberStrength, setNumberStrength] = useState(1);
    const [scoreAverage, setScoreAverage] = useState("");
    const [totalImprovement, setTotalImprovement] = useState("");
    const [newScoreAverage, setNewScoreAverage] = useState("");
    const [units, setUnits] = useState("yards")
    const [statsType, setStatsType] = useState("Basic");

    const [loadingButton, setLoadningButton] = useState(false);
    const facilitiesAdvanced = ["Range Driver", "Range Woods", "Range Irons", "Short Game Area", "Putting Green"];
    const facilities = ["Range Driver", "Range Irons", "Short Game Area", "Putting Green"];
    const [selectedFacilities, setSelectedFacilities] = useState(statsType !== "Basic" ? facilitiesAdvanced : facilities)

    useEffect(() => {
        setSelectedFacilities(statsType !== "Basic" ? facilitiesAdvanced : facilities);
    }, [statsType])

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const jwt = await getJWT();

                const response = await fetch(`api/User/myself?userId=${userId}`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
                const responseData = await response.json();
                setUnits(responseData.units);
                window.localStorage.setItem("units", responseData.units);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
        
        var units = window.localStorage.getItem("units");
        if (units) {
            setUnits(units);
            fetchUser();
        } else {
            fetchUser();
        }
    }, [userId]);

    useEffect(() => {
        switch (elements) {
            case 3:
                setNumberImprovement(2);
                setNumberStrength(1);
                break;
            case 4:
                setNumberImprovement(3);
                setNumberStrength(1);
                break;
            case 5:
                setNumberImprovement(3);
                setNumberStrength(2);
                break;
            case 6:
                setNumberImprovement(4);
                setNumberStrength(2);
                break;
            case 7:
                setNumberImprovement(5);
                setNumberStrength(2);
                break;
            case 8:
                setNumberImprovement(5);
                setNumberStrength(3);
                break;
            case 9:
                setNumberImprovement(6);
                setNumberStrength(3);
                break;
            case 10:
                setNumberImprovement(7);
                setNumberStrength(3);
                break;
        }
    }, [elements])

    useEffect(() => {
        if (stats !== null)
            calculateTime(
                fillAnalysis(stats, units, setState, setScoreAverage, setTotalImprovement, setNewScoreAverage,
                    selectedFacilities, numberImprovement, numberStrength, statsType !== "Basic"),
                numberImprovement, numberStrength, totalMinutes, setState);

    }, [stats])

    useEffect(() => {
        setTotalMinutes(hours * 60 + minutes)
    }, [hours, minutes])

    /**
    * Gets rounds from the logged in user database depending on user's selection. 
    * @param {string} durationType - wheter time/number of rounds/custom/tournament
    * @param {int} timeDuration - how many months back
    * @param {int} numberOfRounds - how many last rounds
    * @param {string} roundType - type of rounds to get
    */
    async function getMyRounds(durationType, timeDuration, numberOfRounds, roundType) {
        setStats(null);
        setLoadningButton(true);
        
        try {
            const jwt = await getJWT();
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            };

            let url;
            if (durationType.toLowerCase() === "time") {
                url = `/api/Calculation/user-last-duration?timeType=month&duration=${timeDuration}&userId=${userId}&roundType=${roundType}`;
            } else if (durationType.toLowerCase() === "number") {
                url = `/api/Calculation/user-last?numberOfRounds=${numberOfRounds}&userId=${userId}&roundType=${roundType}`;
            } else if (durationType.toLowerCase() === "custom" && startDate !== null && endDate !== null) {
                url = `/api/Calculation/user-date-range?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}&userId=${userId}&roundType=${roundType}`;
            } else if (durationType.toLowerCase() === "fall") {
                url = `/api/Calculation/user-date-range?startDate=${"2023-08-25"}&endDate=${"2023-11-01"}&userId=${userId}&roundType=${roundType}`;
            } else if (durationType.toLowerCase() === "spring") {
                url = `/api/Calculation/user-date-range?startDate=${"2023-02-10"}&endDate=${"2023-05-01"}&userId=${userId}&roundType=${roundType}`;
            } else if (durationType.toLowerCase() === "this year") {
                url = `/api/Calculation/user-date-range?startDate=${"2024-01-01"}&endDate=${"2024-12-31"}&userId=${userId}&roundType=${roundType}`;
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: headers
            });
            
            const data = await response.json();
            setStats(data);
            setLoadningButton(false);
        } catch (error) {
            console.error('Error fetching rounds:', error);
            setLoadningButton(false);
        }
    }
    return (
        <div>
            <Card className="mt-2 mb-2" title="Plan your day" >
                <PlannerTimeAndNumber hours={hours} setHours={setHours} minutes={minutes} setMinutes={setMinutes}
                    elements={elements} setElements={setElements} />
                <TimeFilters roundType={roundType} setRoundType={setRoundType} durations={durations} setNumberOfRounds={setNumberOfRounds}
                    setDurationType={setDurationType} setTimeDuration={setTimeDuration} setCustom={setCustom} statsType={statsType} setStatsType={setStatsType} />
                <Facilities selectedFacilities={selectedFacilities} setSelectedFacilities={setSelectedFacilities} statsType={statsType} />
                <Button loading={loadingButton} className="m-2" disabled={totalMinutes <= 0} label="Plan my practice" onClick={() => getMyRounds(durationType, timeDuration, numberOfRounds, roundType)} />
            </Card >
            {
                <div>
                    <Card className="mt-2 mb-2">
                        {stats && <Message className='m-3' severity="info" text={`${stats.noOfRounds} round${stats.noOfRounds === 1 ? "" : "s"}`} />}
                        <Strengths state={state} data={stats} />
                        <Weaknasses state={state} data={stats} scoreAverage={scoreAverage} totalImprovement={totalImprovement} newScoreAverage={newScoreAverage} />
                    </Card>
                    <Card className="mt-2 mb-2" title="Suggested Practice">
                        <div className="table-responsive">
                            <Table id='strengthtable' className='stats-table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Total SG</th>
                                        <th>Time (min)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: numberImprovement }).map((_, index) => (
                                        <tr style={{
                                            color: "white",
                                            backgroundColor: red
                                        }}>
                                            {practiceOptions.map((option) => (
                                                <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`analysis${index + 1}${option}`]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    {Array.from({ length: numberStrength }).map((_, index) => (
                                        <tr style={{
                                            color: "white",
                                            backgroundColor: green
                                        }}>
                                            {practiceOptions.map((option) => (
                                                <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`strength${index + 1}${option}`]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Card></div>}
        </div >
    )

}
export { Planner }