import { StatsPage } from "./components/stats_components/Individual/StatsPage";
import { CreateRound } from "./components/CreateRound";
import Home2 from "./components/home/Home2";
import { CourseRefractor } from "./components/CourseRefractor";
import { TournamentsRefractor } from "./components/TournamentsRefractor";
import { CoachStats } from "./components/stats_components/Team/CoachStats";
import { EditRound } from "./components/edit_components/EditRound";
import { Practice } from "./components/practice_components/Individual/Practice";
import { TeamPractice } from "./components/practice_components/Team/TeamPractice";
import { RoundStats } from "./components/stats_components/Individual/RoundStats";
import { TeamRounds } from "./components/TeamRounds";
import { MyRounds } from "./components/MyRounds"
import MyAccount from "./components/my_account/MyAccount";
import SGcalculator from "./components/SGcalculator";
import { ToDateRounds } from "./components/ToDateRounds";
import ContactForm from "./components/my_account/ContactForm";
import FAQ from "./components/FAQ";
import AppwriteHomePage from "./components/appwrite-testing/AppwriteHomePage";
import Login from "./components/appwrite-testing/Login";
import Subscribe from "./components/appwrite-testing/Subscribe";
import AcceptInvitation from "./components/my_account/AcceptInvitation";
import { PricingTable } from "./components/subscriptions/PricingTable";
import AddRound2 from "./components/add-round/AddRound2";
import SignUp from "./components/appwrite-testing/SignUp";
const AppRoutes = [
  {
    index: true,
    element: <Home2 />,
    requiresAuth: true,
    requireSubscription: false
  },
  // {
  //   path: '/appwrite',
  //   element: <AppwriteHomePage />,
  //   requiresAuth: false,
  //   requireSubscription: false
  // },
  {
    path: '/subscribe',
    element: <Subscribe />,
    requiresAuth: true,
    requireSubscription: false
  },
  {
    path: '/login',
    element: <Login />,
    requiresAuth: false,
    requireSubscription: false
  },
  {
    path: '/signup',
    element: <SignUp />,
    requiresAuth: false,
    requireSubscription: false
  },
  {
    path: '/stats',
    element: <StatsPage areIndividualStats={false} />,
    requiresAuth: true,
  },
  {
    path: '/stats-individual',
    element: <StatsPage areIndividualStats={true} />,
    requiresAuth: true,
  },
  {
    path: '/round',
    element: <CreateRound />,
    requiresAuth: true,
  },
  {
    path: '/courses',
    element: <CourseRefractor />,
    requiresAuth: true,
  },
  {
    path: '/tournaments',
    element: <TournamentsRefractor />,
    requiresAuth: true,
  },
  {
    path: '/coach-stats',
    element: <CoachStats />,
    requiresAuth: true,
  },
  {
    path: '/round-edit/:id',
    element: <EditRound />,
    requiresAuth: true,
  },
  {
    path: '/round-stats/:id',
    element: <RoundStats />,
    requiresAuth: true,
  },
  {
    path: '/practice',
    element: <Practice />,
    requiresAuth: true,
  },
  {
    path: '/team-practice',
    element: <TeamPractice />,
    requiresAuth: true,
  },
  {
    path: '/finish-round',
    element: <AddRound2 />,
    requiresAuth: true,
  },
  {
    path: '/team-rounds',
    element: <TeamRounds />,
    requiresAuth: true,
  },
  {
    path: '/my-rounds',
    element: <MyRounds />,
    requiresAuth: true,
  },
  {
    path: '/user-profile',
    element: <MyAccount />,
    requiresAuth: true,
  },
  {
    path: '/sg-calculator',
    element: <SGcalculator />,
    requiresAuth: true,
  },
  {
    path: '/to-date',
    element: <ToDateRounds />,
    requiresAuth: true,
  },
  {
    path: '/contact',
    element: <ContactForm />
  },
  {
    path: '/faq',
    element: <FAQ />
  },
  {
    path: '/accept-invite',
    element: <AcceptInvitation />
  },
  {
    path: '/pricing',
    element: <PricingTable />
  }
];

export default AppRoutes;
