
export async function GetBag(setBag, setBagArr, jwt, userId) {
    console.log(userId)
    try {
        const response = await fetch(`api/User/myself?userId=${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        });

        // Check if the response is okay
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        // Ensure responseData.bag exists and is an object
        if (responseData.bag && typeof responseData.bag === 'object') {
            console.log(responseData.bag)
            window.localStorage.setItem("bag", JSON.stringify(responseData.bag));
            const bagtmp = responseData.bag;
            const tmp = {};
            const bagarr = []

            Object.keys(bagtmp).forEach((key) => {
                if (bagtmp[key] !== 0) {
                    tmp[key] = bagtmp[key];
                    bagarr.push(key.toString());
                }
            });
            setBagArr(bagarr.reverse())
            setBag(tmp);
        } else {
            console.warn('Bag is null or not an object:', responseData.bag);
        }
    } catch (error) {
        console.error('Error fetching bag:', error);
    }
}


export async function GetUnits(setUserUnits, jwt, userId) {
    try {
        const response = await fetch(`api/User/myself?userId=${userId}`, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        });
        const responseData = await response.json();
        if (responseData.units !== null) {
            window.localStorage.setItem("units", responseData.units);
            setUserUnits(responseData.units);

        }
    } catch (error) {
        console.error('Error fetching bag:', error);
    }
}
export async function GetBaseline(setUserBaseline, jwt, userId) {
    try {
        const response = await fetch(`api/User/myself?userId=${userId}`, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        });
        const responseData = await response.json();
        if (responseData.baseline !== null) {
            window.localStorage.setItem("baseline", responseData.baseline);
            setUserBaseline(responseData.baseline);

        }
    } catch (error) {
        console.error('Error fetching bag:', error);
    }
}