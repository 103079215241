// ConstantContext.js
import React, { createContext, useContext, useState } from 'react';

const ConstantContext = createContext();

export const ConstantProvider = ({ children }) => {

    const [pgaBase, setPgaBase] = useState(true);
    const [units, setUnits] = useState("yards");
    const green = "rgba(0, 128, 0, 0.75)";
    const red = "rgba(220, 0, 0, 0.75)";
    const analysisOptions = ['Name', 'Total', 'Attempts', 'PerShot', 'Improvement'];
    const metersToYards = 1.09361;
    const yardsToMeters = 0.9144;
    const sgPerShotUpper = 0.05;
    const sgPerShotLower = -0.05;
    const fallStart = "2024-08-25";
    const fallEnd = "2024-10-29";
    const springStart = "2025-02-10";
    const springEnd = "2023-05-01";
    const thisYearStart = "2025-01-01";
    const thisYearEnd = "2025-12-31";

    //if low is true that means that it's better to have lower number
    const tourAvg = {
        noOfRounds: { value: "--", low: true },
        scoreAvg: { value: `${pgaBase ? 71.09 : 71.48}`, low: true, greenValue: `${pgaBase ? 69.9 : 70.2}`, redValue: `${pgaBase ? 72.1 : 73.0}` },
        par3Avg: { value: `${pgaBase ? 3.07 : 3.05}`, low: true, greenValue: `${pgaBase ? 3.02 : 2.98}`, redValue: `${pgaBase ? 3.13 : 3.15}` },
        par4Avg: { value: `${pgaBase ? 4.04 : 4.07}`, low: true, greenValue: `${pgaBase ? 3.98 : 4.0}`, redValue: `${pgaBase ? 4.09 : 4.17}` },
        par5Avg: { value: `${pgaBase ? 4.66 : 4.77}`, low: true, greenValue: `${pgaBase ? 4.54 : 4.66}`, redValue: `${pgaBase ? 4.73 : 4.88}` },
        birdiesPerRound: { value: 3.61, low: false, greenValue: 4.1, redValue: 3.3 },
        bogeysPerRound: { value: 2.67, low: true, greenValue: 2.27, redValue: 2.98 },
        doublesPerRound: { value: 0.32, low: true, greenValue: 0.24, redValue: 0.5 },
        otherPerRound: { value: 0.04, low: true, greenValue: 0.03, redValue: 0.1 },
        bogeyPar5PerRound: { value: 0.16, low: true, greenValue: 0.16, redValue: 0.25 },
        doublePerRound: { value: 0.32, low: true, greenValue: 0.24, redValue: 0.5 },
        easyChipPerRound: { value: "90%", low: false, greenValue: 97, redValue: 83 },
        threePuttPerRound: { value: 0.53, low: true, greenValue: 0.53, redValue: 0.66 },
        bogey150PerRound: { value: 1.15, low: true, greenValue: 1.15, redValue: 1.75 },
        sgApp: { value: 0, low: false, greenValue: `${pgaBase ? 0.49 : 0.6}`, redValue: `${pgaBase ? -0.53 : -1}` },
        "sgAPP.strokesGainedPerRound": { value: 0, low: false, greenValue: `${pgaBase ? 0.49 : 0.6}`, redValue: `${pgaBase ? -0.53 : -1}` },
        sgAppShot: { value: 0, low: false, greenValue: `${pgaBase ? 0.49 : 0.6}`, redValue: `${pgaBase ? -0.53 : -1}` },
        "sg50100.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg100150.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg150200.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg200.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg5075.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg75100.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg100125.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg125150.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg150175.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg175200.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "gir.perRound": { value: `${pgaBase ? 11.8 : 12.4}`, low: false, greenValue: `${pgaBase ? 12.4 : 13.4}`, redValue: `${pgaBase ? 11.2 : 11.3}` },
        "gOrfir.perRound": { value: 12.9, low: false, greenValue: 13.6, redValue: 12.1 },
        "gir50100.percentage": { value: "85%", low: false, greenValue: 88.6, redValue: 81.5 },
        "gir100150.percentage": { value: "72%", low: false, greenValue: 78.5, redValue: 64.7 },
        "gir150200.percentage": { value: "59%", low: false, greenValue: 64.5, redValue: 52.7 },
        "gir200.percentage": { value: "44%", low: false, greenValue: 53.2, redValue: 36.6 },
        "gir5075.percentage": { value: "88%", low: false, greenValue: 91.6, redValue: 83.3 },
        "gir75100.percentage": { value: "78%", low: false, greenValue: 85, redValue: 69.6 },
        "gir100125.percentage": { value: "75%", low: false, greenValue: 81.5, redValue: 67.3 },
        "gir125150.percentage": { value: "70%", low: false, greenValue: 75.4, redValue: 62.1 },
        "gir150175.percentage": { value: "64%", low: false, greenValue: 69, redValue: 57.2 },
        "gir175200.percentage": { value: "55%", low: false, greenValue: 60, redValue: 48.1 },
        "eG.perRound": { value: 10.7, low: false, greenValue: 11.3, redValue: 10.1 },
        "iP.perRound": { value: 7, low: false, greenValue: 7.6, redValue: 6.4 },
        "bJK.perRound": { value: 2.7, low: false, greenValue: 3.3, redValue: 2.1 },
        appMissLeft: { value: "--", low: true, greenValue: "--", redValue: "--" },
        appMissRight: { value: "--", low: true, greenValue: "--", redValue: "--" },
        appMissLong: { value: "--", low: true, greenValue: "--", redValue: "--" },
        appMissShort: { value: "--", low: true, greenValue: "--", redValue: "--" },
        "easyMiss.percentage": { value: "--", low: false, greenValue: "--", redValue: "--" },
        "mediumMiss.percentage": { value: "--", low: false, greenValue: "--", redValue: "--" },
        "hardMiss.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "allExpectedApp.averageExpected": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "girHitExpectedApp.averageExpected": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "girMissedExpectedApp.averageExpected": { value: "--", low: true, greenValue: "--", redValue: "--" },
        sgARG: { value: 0, low: false, greenValue: `${pgaBase ? 0.39 : 0.24}`, redValue: `${pgaBase ? -0.33 : -0.53}` },
        "sgARG.strokesGainedPerRound": { value: 0, low: false, greenValue: `${pgaBase ? 0.39 : 0.24}`, redValue: `${pgaBase ? -0.33 : -0.53}` },
        "sgARG.strokesGainedPerShot": { value: 0, low: false, greenValue: `${pgaBase ? 0.39 : 0.24}`, redValue: `${pgaBase ? -0.33 : -0.53}` },
        "sg015.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg1530.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sg3050.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sgFringe.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "sgSand.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "scrambling.percentage": { value: "58%", low: false, greenValue: 63.7, redValue: 53.9 },
        "p6.percentage": { value: "50%", low: false, greenValue: 58.7, redValue: 45 },
        "p12.percentage": { value: "67%", low: false, greenValue: 71, redValue: 60 },
        "sgEasyChip.strokesGainedPerShot": { value: 0, low: false, greenValue: "--", redValue: "--" },
        putt: { value: 0, low: false, greenValue: `${pgaBase ? 0.45 : 0.59}`, redValue: `${pgaBase ? -0.46 : -0.96}` },
        "putt.strokesGainedPerRound": { value: 0, low: false, greenValue: `${pgaBase ? 0.45 : 0.59}`, redValue: `${pgaBase ? -0.46 : -0.96}` },
        "putt3.percentage": { value: "99.42%", low: false, greenValue: 99.9, redValue: 98.95 },
        "putt35.percentage": { value: "87.97%", low: false, greenValue: 91.88, redValue: 82.62 },
        "putt510.percentage": { value: "56.47%", low: false, greenValue: 61.69, redValue: 50.27 },
        "putt20Birdie.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "putt20Par.strokesGainedPerShot": { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "putt3.strokesGainedPerShot": { value: "0", low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "putt35.strokesGainedPerShot": { value: "0", low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "putt510.strokesGainedPerShot": { value: "0", low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "putt1020.strokesGainedPerShot": { value: "0", low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "putt2030.strokesGainedPerShot": { value: "0", low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        "putt30.strokesGainedPerShot": { value: "0", low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        totalFirstPuttPerRound: { value: "--", low: false, greenValue: "--", redValue: "--" },
        totalFeetMadePerRound: { value: "72'", low: false, greenValue: 77, redValue: 66 },
        sgOTT: { value: 0, low: false, greenValue: `${pgaBase ? 0.45 : 0.46}`, redValue: `${pgaBase ? -0.5 : -0.65}` },
        "sgOTT.strokesGainedPerRound": { value: 0, low: false, greenValue: `${pgaBase ? 0.45 : 0.46}`, redValue: `${pgaBase ? -0.5 : -0.65}` },
        "sgOTTdriver.strokesGainedPerRound": { value: 0, low: false, greenValue: `${pgaBase ? 0.45 : 0.46}`, redValue: `${pgaBase ? -0.5 : -0.65}` },
        "sgOTTother.strokesGainedPerRound": { value: 0, low: false, greenValue: `${pgaBase ? 0.45 : 0.46}`, redValue: `${pgaBase ? -0.5 : -0.65}` },
        "ottFairwayHit.percentage": { value: `${pgaBase ? "60%" : "74%"}`, low: false, greenValue: `${pgaBase ? 67 : 80}`, redValue: `${pgaBase ? 52 : 64}` },
        "ottdriverHit.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "ott3wHit.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "ott5wHit.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "ott7wHit.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "ott3hHit.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "ott4hHit.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "ott5hHit.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "ott2iHit.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "ott3iHit.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "ott4iHit.percentage": { value: "--", low: true, greenValue: "--", redValue: "--" },
        "ottRecoveryHit.percentage": { value: "5.4%", low: true, greenValue: 2.7, redValue: 8.3 },
        "ottSandHit.percentage": { value: "5.8%", low: true, greenValue: 4.7, redValue: 7.8 },
        teeMissLeft: { value: "--", low: true, greenValue: "--", redValue: "--" },
        teeMissRight: { value: "--", low: true, greenValue: "--", redValue: "--" },
        goingForItPct: { value: "57%", low: false, greenValue: 69, redValue: 43.5 },
        goingGreenHitPct: { value: "22%", low: false, greenValue: 27.8, redValue: 16.1 },
        goingBirdiePct: { value: "57%", low: false, greenValue: 64.3, redValue: 50.4 },
        goingBogeyPct: { value: "--", low: true, greenValue: "--", redValue: "--" },
        goingDistanceAfter: { value: units === "yards" ? "24.5 yds" : "22.4 m", low: true, greenValue: 22.3, redValue: 28 },
        avgGoing: { value: "--", low: true, greenValue: "--", redValue: "--" },
        avgLayUp: { value: "--", low: true, greenValue: "--", redValue: "--" },


    }

    return (
        <ConstantContext.Provider value={{
            tourAvg, sgPerShotUpper, sgPerShotLower, yardsToMeters, metersToYards,
            pgaBase, setPgaBase, setUnits, green, red, analysisOptions,
            springStart, springEnd, fallStart, fallEnd, thisYearStart, thisYearEnd
        }} >
            {children}
        </ConstantContext.Provider>
    );
};

export const useConstant = () => {
    return useContext(ConstantContext);
};
