import React, { useState, useEffect } from "react";
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import './login.css';

const SignUp = () => {
    const navigate = useNavigate();
    const { user, loading, signup, loginWithGoogle } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user, navigate]);

    const handleSignUp = async (e) => {
        e.preventDefault();
        setError('');
        setIsSubmitting(true);

        // Basic validation
        if (!email || !password || !name) {
            setError('Please fill in all fields');
            setIsSubmitting(false);
            return;
        }

        // Password strength validation
        if (password.length < 8) {
            setError('Password must be at least 8 characters long');
            setIsSubmitting(false);
            return;
        }

        try {
            await signup(email, password, name);
            // Signup successful - user will be automatically logged in
        } catch (error) {
            console.error('Signup failed:', error);
            if (error.code === 409) {
                setError('An account with this email already exists');
            } else {
                setError('An error occurred. Please try again');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            await loginWithGoogle();
        } catch (error) {
            console.error('Google login failed:', error);
            setError('Authentication failed. Please try again.');
        }
    };

    return (
        <div className="login-container">
            <div className="logo-section">
                <div className="logo-content">
                    <img src="./logoNav.png" alt="Company Logo" className="company-logo" />
                    <h1 className="company-name">Parfect Performance</h1>
                    <p className="company-slogan">Elevate Your Golf Game</p>
                </div>
            </div>
            
            <div className="login-section">
                <h1 className="company-name-mobile">Parfect Performance</h1>
                {/* <h2 style={{color: '#283852'}}>Create your Account</h2> */}
                <div className="login-box">
                    <h2>Sign Up</h2>
                    <p className="login-subtitle">Please enter your details</p>
                    
                    {error && (
                        <div className="error-message">
                            <i className="fas fa-exclamation-circle"></i>
                            {error}
                        </div>
                    )}
                    
                    <form className="login-form" onSubmit={handleSignUp}>
                        <div className="form-group">
                            <label htmlFor="name">Full Name</label>
                            <input 
                                type="text" 
                                id="name" 
                                placeholder="Enter your full name"
                                className={`form-input ${error ? 'input-error' : ''}`}
                                value={name}
                                onChange={e => {
                                    setName(e.target.value);
                                    setError('');
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input 
                                type="email" 
                                id="email" 
                                placeholder="Enter your email"
                                className={`form-input ${error ? 'input-error' : ''}`}
                                value={email}
                                onChange={e => {
                                    setEmail(e.target.value);
                                    setError('');
                                }}
                            />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input 
                                type="password" 
                                id="password" 
                                placeholder="Create a password"
                                className={`form-input ${error ? 'input-error' : ''}`}
                                value={password}
                                onChange={e => {
                                    setPassword(e.target.value);
                                    setError('');
                                }}
                            />
                        </div>

                        <button 
                            type="submit" 
                            className="login-button"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Creating Account...' : 'Create Account'}
                        </button>

                        <div className="divider">
                            <span>or continue with</span>
                        </div>

                        <div className="social-buttons">
                            <button 
                                type="button"
                                className="social-button google"
                                onClick={handleGoogleLogin}
                            >
                                <i className="fab fa-google"></i>
                            </button>
                            {/* <button className="social-button apple">
                                <i className="fab fa-apple"></i>
                            </button>
                            <button className="social-button microsoft">
                                <i className="fab fa-microsoft"></i>
                            </button> */}
                        </div>

                        <p className="signup-prompt">
                            Already have an account? 
                            <a href="/login" className="signup-link">Sign in</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignUp; 