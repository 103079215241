import React, { useState, useEffect } from "react";
import {
    Container, Row, Table, Col
} from "reactstrap";

import { MultiSelectPlayers } from "../../dropdowns/MultiSelectPlayers";
import { GetScoresGames } from "./GetScoresGames";
import { Message } from "primereact/message";
import { GamesDropdown } from "../../dropdowns/GamesDropdown";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useGames } from "../../../GamesProvider";

const TeamGames = (props) => {

    /**Possible durations to filter rounds */
    const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds",
        "Last 20 Rounds"];
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [displayedPlayers, setDisplayedPlayers] = useState([]);
    const [allUserAccounts] = useState(props.allUserAccounts);
    const [coachTeam, setCoachTeam] = useState(window.localStorage.getItem("team"))
    const [selectedGame, setSelectedGame] = useState(null);
    const [loading, setLoading] = useState(false);
    const gamesConst = useGames();

    useEffect(() => {
        if (selectedUsers.length > 0) {
            setDisplayedPlayers(selectedUsers);
        } else {
            setDisplayedPlayers(allUserAccounts)
        }
    }, [selectedUsers, allUserAccounts])





    return (
        <div>
            <Container>
                {displayedPlayers.length <= 0 && <Message className='mt-3' severity="warn" text="No player was selected" />}
                <Row className='mt-3'>
                    <Col>
                        <MultiSelectPlayers players={props.players} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} durations={durations} coachTeam={coachTeam} />
                    </Col>
                </Row>
                <Row className="mt-2 mb-3">
                    <Col >
                        {props.games === "puttinggames" && <GamesDropdown className='mt-2' selectedGame={selectedGame} setSelectedGame={setSelectedGame} games={gamesConst.puttingGames} />}
                        {props.games === "arggames" && <GamesDropdown className='mt-2' selectedGame={selectedGame} setSelectedGame={setSelectedGame} games={gamesConst.argGames} />}
                        {props.games === "approachgames" && <GamesDropdown className='mt-2' selectedGame={selectedGame} setSelectedGame={setSelectedGame} games={gamesConst.appGames} />}
                        {props.games === "teegames" && <GamesDropdown className='mt-2' selectedGame={selectedGame} setSelectedGame={setSelectedGame} games={gamesConst.teeGames} />}

                    </Col>
                    <Col>
                        {selectedGame === null && <Message severity="warn" text="No game is selected" />}
                        {loading && selectedGame !== null && <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />}
                    </Col>
                </Row>
                <Row>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Last</th>
                                <th>Last 5</th>
                                <th>Last 10</th>
                                <th>Last 30</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedPlayers.map((player) => {
                                return <GetScoresGames player={player}
                                    selectedGame={selectedGame}
                                    setLoading={setLoading}
                                    games={props.games} />
                            })}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </div>

    )
}

export { TeamGames }