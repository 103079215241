import { useConstant } from "../../../../ConstantContext";
import { useOptions } from "../../StatsOptionsProvider";
import { Table } from "reactstrap";
const GoingForItIndividual = ({ state, data, isAdvanced, units }) => {
    const constants = useConstant();
    const options = useOptions();
    return (
        <div className="table-responsive">
            <Table id='goingtable' className='stats-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Value</th>
                        <th>Count</th>
                        <th>Attempts</th>
                        <th>Tour average</th>
                    </tr>
                </thead>
                <tbody>
                    {options.goingForIt.map((stat) => (
                        <tr key={`${stat.name}`}
                            style={{
                                color: (state[`${stat.name}Attempts`] > 0 || stat.name === "double") &&
                                    (constants.tourAvg[`${stat.name}Pct`].low ?
                                        (state[`${stat.name}Pct`] < constants.tourAvg[`${stat.name}Pct`].greenValue ||
                                            state[`${stat.name}Pct`] > constants.tourAvg[`${stat.name}Pct`].redValue) && 'white' :
                                        (state[`${stat.name}Pct`] > constants.tourAvg[`${stat.name}Pct`].greenValue ||
                                            state[`${stat.name}Pct`] < constants.tourAvg[`${stat.name}Pct`].redValue) && 'white'),

                                backgroundColor: (state[`${stat.name}Attempts`] > 0 || stat.name === "double") &&
                                    (constants.tourAvg[`${stat.name}Pct`].low === true ?
                                        (state[`${stat.name}Pct`] < constants.tourAvg[`${stat.name}Pct`].greenValue ? constants.green :
                                            state[`${stat.name}Pct`] > constants.tourAvg[`${stat.name}Pct`].redValue ? constants.red : '')
                                        :
                                        (state[`${stat.name}Pct`] > constants.tourAvg[`${stat.name}Pct`].greenValue ? constants.green :
                                            state[`${stat.name}Pct`] < constants.tourAvg[`${stat.name}Pct`].redValue ? constants.red : ''))
                            }}>
                            <td>{`${stat.display}`}</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}Pct`]}%</td>
                            <td>{state[`${stat.name}Count`]}</td>
                            <td>{state[`${stat.name}Attempts`]}</td>
                            <td>{constants.tourAvg[`${stat.name}Pct`]?.value}</td>
                        </tr>
                    ))}
                    <tr key={`goingDistanceAfter`}
                        style={{
                            color: (state[`goingForItCount`] > 0) &&
                                (state[`goingDistanceAfter`] < constants.tourAvg[`goingDistanceAfter`].greenValue ||
                                    state[`goingDistanceAfter`] > constants.tourAvg[`goingDistanceAfter`].redValue) && 'white',

                            backgroundColor: (state[`goingForItCount`] > 0) &&
                                (state[`goingDistanceAfter`] < constants.tourAvg[`goingDistanceAfter`].greenValue ? constants.green :
                                    state[`goingDistanceAfter`] > constants.tourAvg[`goingDistanceAfter`].redValue ? constants.red : '')
                        }}>
                        <td>{`Average Distance after Going for it Shot`}</td>
                        <td className={`${data === null && 'loading-cell '} data-cell`}>{units === "yards" ? state[`goingDistanceAfter`] + " y" : (parseFloat(state[`goingDistanceAfter`]) * constants.yardsToMeters).toFixed(1) + " m"}</td>
                        <td>--</td>
                        <td>--</td>
                        <td>{constants.tourAvg[`goingDistanceAfter`]?.value}</td>
                    </tr>
                    {options.goingAvg.map((stat) => (
                        <tr key={`${stat.name}`}>
                            <td>{`${stat.display}`}</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]}</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                        </tr>
                    ))}
                </tbody>

            </Table>
        </div>
    )
}
export default GoingForItIndividual;