import { useConstant } from "../../../../ConstantContext";
import { useOptions } from "../../StatsOptionsProvider";
import { Table } from "reactstrap";
const ClubsIndividual = ({ state, data, isAdvanced }) => {
    const constants = useConstant();
    const options = useOptions();
    const getClubStyle = (club) => {
        return {
            color: (state[`${club}`]?.strokesGainedShot < constants.sgPerShotLower || state[`${club}`]?.strokesGainedShot > constants.sgPerShotUpper) && 'white',
            backgroundColor:
                state[`${club}`]?.strokesGainedShot < constants.sgPerShotLower
                    ? constants.red
                    : state[`${club}`]?.strokesGainedShot > constants.sgPerShotUpper
                        ? constants.green
                        : '',
        };
    };
    return (
        <div className="table-responsive">
            <Table id='clubstable' className='stats-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Value</th>
                        <th>Per Round</th>
                        <th>Attempts</th>
                        <th>Miss Left</th>
                        <th>Miss Right</th>
                        <th>Miss Long</th>
                        <th>Miss Short</th>
                    </tr>
                </thead>
                <tbody>
                    {options.clubsApp.map((club) => (
                        <tr style={{ display: state[`${club}`]?.attempts > 0 ? "" : "none", }}>
                            <td>{club.charAt(0) === "_" ? `${club.substring(1)}` : ` ${club.toUpperCase()}`}</td>
                            <td style={getClubStyle(club)} className={`${data === null && 'loading-cell '} data-cell`}>{state[`${club}`]?.strokesGainedShot}</td>
                            {(data && data.noOfRounds > 1) ? <td style={getClubStyle(club)}>{data && state[`${club}`]?.attempts !== 0 && parseFloat((state[`${club}`]?.strokesGainedShot / state[`${club}`]?.attempts) * (state[`${club}`]?.attempts / data.noOfRounds)).toFixed(3)}</td> : <td>--</td>}
                            <td>{state[`${club}`]?.attempts}</td>
                            <td style={{
                                color: (state[`${club}`]?.missLeft > state[`${club}`]?.missRight) && 'white',
                                backgroundColor: (state[`${club}`]?.missLeft > state[`${club}`]?.missRight) && constants.red
                            }}>{state[`${club}`]?.missLeft}%</td>
                            <td style={{
                                color: (state[`${club}`]?.missLeft < state[`${club}`]?.missRight) && 'white',
                                backgroundColor: (state[`${club}`]?.missLeft < state[`${club}`]?.missRight) && constants.red
                            }}>{state[`${club}`]?.missRight}%</td>
                            <td style={{
                                color: (state[`${club}`]?.missLong > state[`${club}`]?.missShort) && 'white',
                                backgroundColor: (state[`${club}`]?.missLong > state[`${club}`]?.missShort) && constants.red
                            }}>{state[`${club}`]?.missLong}%</td>
                            <td style={{
                                color: (state[`${club}`]?.missLong < state[`${club}`]?.missShort) && 'white',
                                backgroundColor: (state[`${club}`]?.missLong < state[`${club}`]?.missShort) && constants.red
                            }}>{state[`${club}`]?.missShort}%</td>
                        </tr>
                    ))}
                </tbody>

            </Table>
        </div >
    )
}
export default ClubsIndividual;