export async function FetchSchedule(selectedGames, getJWT, toast) {
    try {
        const jwt = await getJWT();

        // Create an object to store all drills per user
        const userSchedules = {};

        // Loop through each entry (date) in selectedGames
        for (const entry of selectedGames) {
            const date = entry.date; // Get the correctly formatted date

            // Loop through each user in this entry (excluding 'date')
            for (const userId in entry) {
                if (userId !== 'date') {
                    const drills = entry[userId];

                    // If the user is not already in the schedules, initialize them
                    if (!userSchedules[userId]) {
                        userSchedules[userId] = [];
                    }

                    // Format the drill names into an object for this date
                    const allGameNames = {
                        puttingDrills: drills.puttingGames?.map(game => game.name) || [],
                        shortGameDrills: drills.argGames?.map(game => game.name) || [],
                        approachDrills: drills.appGames?.map(game => game.name) || [],
                        teeDrills: drills.teeGames?.map(game => game.name) || [],
                        combineDrills: drills.combineGames?.map(game => game.name) || [],
                    };

                    // Check if there is already a schedule for this user on this date
                    const existingSchedule = userSchedules[userId].find(schedule => schedule.date === date);

                    if (existingSchedule) {
                        // Ensure arrays are initialized before merging drills
                        existingSchedule.puttingDrills = existingSchedule.puttingDrills || [];
                        existingSchedule.shortGameDrills = existingSchedule.shortGameDrills || [];
                        existingSchedule.approachDrills = existingSchedule.approachDrills || [];
                        existingSchedule.teeDrills = existingSchedule.teeDrills || [];
                        existingSchedule.combineDrills = existingSchedule.combineDrills || [];

                        // If a schedule exists for this date, just append the new drills
                        existingSchedule.puttingDrills = [...existingSchedule.puttingDrills, ...allGameNames.puttingDrills];
                        existingSchedule.shortGameDrills = [...existingSchedule.shortGameDrills, ...allGameNames.shortGameDrills];
                        existingSchedule.approachDrills = [...existingSchedule.approachDrills, ...allGameNames.approachDrills];
                        existingSchedule.teeDrills = [...existingSchedule.teeDrills, ...allGameNames.teeDrills];
                        existingSchedule.combineDrills = [...existingSchedule.combineDrills, ...allGameNames.combineDrills];
                    } else {
                        // If no schedule exists for this date, create a new one
                        userSchedules[userId].push({
                            date: new Date(date),
                            ...allGameNames
                        });
                    }
                }
            }
        }

        // Now, send a single request for each user
        for (const userId in userSchedules) {
            const userSchedule = userSchedules[userId];
            console.log(userSchedule);  // You can remove this later after confirming the data is correct

            // Send the collected drills for this user in one request
            const response = await fetch(`/api/practiceschedule?userId=${userId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                },
                body: JSON.stringify(userSchedule) // Send all the practice sessions for this user
            });

            // Check if the response was successful (you can add more checks as needed)
            if (!response.ok) {
                console.error(`Failed to update schedule for user ${userId}`);
                toast.current.show({ severity: 'danger', summary: 'Error!', detail: 'Schedules NOT sent!' });
            } else {
                console.log(`Successfully updated schedule for user ${userId}`);
                toast.current.show({ severity: 'success', summary: 'Sent!', detail: 'Schedules sent!' });
            }
        }
    } catch (error) {
        console.error("Error updating practice schedules:", error);
    }
}


export async function FetchScheduleToAll(selectedGames, getJWT, toast, userId) {
    try {
        const jwt = await getJWT();

        // Create an array to store schedules by date
        const schedules = [];

        // Loop through each entry (date) in selectedGames
        for (const entry of selectedGames) {
            const date = entry.date; // Get the correctly formatted date


            // Format the drill names into an object for this date
            const allGameNames = {
                puttingDrills: entry.puttingGames?.map(game => game.name) || [],
                shortGameDrills: entry.argGames?.map(game => game.name) || [],
                approachDrills: entry.appGames?.map(game => game.name) || [],
                teeDrills: entry.teeGames?.map(game => game.name) || [],
                combineDrills: entry.combineGames?.map(game => game.name) || [],
            };

            // Check if there is already a schedule for this date
            const existingSchedule = schedules.find(schedule => schedule.date === date);
            console.log(allGameNames)
            if (existingSchedule) {
                // Ensure arrays are initialized before merging drills
                existingSchedule.puttingDrills = [...existingSchedule.puttingDrills, ...allGameNames.puttingDrills];
                existingSchedule.shortGameDrills = [...existingSchedule.shortGameDrills, ...allGameNames.shortGameDrills];
                existingSchedule.approachDrills = [...existingSchedule.approachDrills, ...allGameNames.approachDrills];
                existingSchedule.teeDrills = [...existingSchedule.teeDrills, ...allGameNames.teeDrills];
                existingSchedule.combineDrills = [...existingSchedule.combineDrills, ...allGameNames.combineDrills];
            } else {
                // If no schedule exists for this date, create a new one
                schedules.push({
                    date: new Date(date),
                    ...allGameNames
                });
            }
        }

        // Send the collected schedules in a single request
        const response = await fetch(`/api/practiceschedule?userId=${userId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify(schedules) // Send all the practice sessions
        });

        // Check if the response was successful (you can add more checks as needed)
        if (!response.ok) {
            console.error(`Failed to update schedules`);
            toast.current.show({ severity: 'danger', summary: 'Error!', detail: 'Schedules NOT sent!' });
        } else {
            console.log(`Successfully updated schedules`);
            toast.current.show({ severity: 'success', summary: 'Sent!', detail: 'Schedules sent!' });
        }
    } catch (error) {
        console.error("Error updating practice schedules:", error);
        toast.current.show({ severity: 'danger', summary: 'Error!', detail: 'An error occurred while updating schedules!' });
    }
}

