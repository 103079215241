import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { useEffect, useState } from "react";
const ChangePassword = ({ setChangePass }) => {

    const [isPassSame, setIsPassSame] = useState(false);
    const [currentPass, setCurrrentPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [newPassRepeat, setNewPassRepeat] = useState("");
    const [arePassFull, setArePassFull] = useState(false);

    useEffect(() => {
        setIsPassSame(newPass === newPassRepeat);
        setArePassFull(currentPass.length > 0 && newPass.length > 0 && newPassRepeat.length > 0)
    }, [newPass, newPassRepeat, currentPass])

    return (
        <Card title="Change Password">
            <div className="m-2">
                <label htmlFor="currentPass">Current Password</label>
                <div>
                    <Password id="currentPass" feedback={false} toggleMask value={currentPass} onChange={(e) => setCurrrentPass(e.target.value)} />
                </div>
            </div>
            <div className="m-2">
                <label htmlFor="newPass">New Password</label>
                <div>
                    <Password id="newPass" toggleMask value={newPass} onChange={(e) => setNewPass(e.target.value)} />
                </div>
            </div>
            <div className="m-2">
                <label htmlFor="newPassRepeat">Repeat New Password</label>
                <div>
                    <div>
                        <Password id="newPassRepeat" feedback={false} toggleMask value={newPassRepeat} onChange={(e) => setNewPassRepeat(e.target.value)} aria-describedby="pass-help" />
                    </div>
                    {!isPassSame && <div>
                        <small id="pass-help" style={{ color: "red" }}>
                            Passwords are not the same.
                        </small>
                    </div>}
                </div>
            </div>
            <div className="m-2">
                <Button label="Change Password" disabled={!isPassSame || !arePassFull} onClick={() => setChangePass(false)} />

            </div>
            <div className="m-2">
                <Button label="Cancel" severity="danger" text onClick={() => setChangePass(false)} />
            </div>


        </Card >
    )
}
export default ChangePassword;