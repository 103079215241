import { useConstant } from "../../ConstantContext"
import { Table } from "reactstrap";
const Strengths = ({ state, data }) => {
    const constants = useConstant();
    return (
        <div className="table-responsive">
            <Table id='strengthtable' className='stats-table'>
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Name</th>
                        <th>Total SG</th>
                        <th>Att.</th>
                        <th>SG per Shot</th>
                        <th>Avg. SG per Round</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: 5 }).map((_, index) => (
                        <tr style={{
                            color: 'white',
                            backgroundColor: constants.green
                        }}>
                            <td className='stats-title'>{index + 1}</td>
                            {constants.analysisOptions.map((option) => (
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`strength${index + 1}${option}`]}</td>
                            ))}
                        </tr>
                    ))}

                </tbody>
            </Table>
        </div>
    )
}
export default Strengths