import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useAuth } from "../appwrite-testing/AuthContext";

const ChangePinPos = ({ shots, hole, id, updateToast, setWasHoleEdit }) => {
    const { getJWT } = useAuth();
    const [pinDir, setPinDir] = useState("");
    const [pinDst, setPinDst] = useState("");
    const [isChangePinPos, setIsChangePinPos] = useState(false);
    const [newPinDir, setNewPinDir] = useState("");
    const [newPinDst, setNewPinDst] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    function capitalizeFirstLetter(str) {
        if (!str) return str; // Handle empty strings
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function getPinPos() {
        var shotWithPin = shots.filter((s) => s.holeNo === hole && (s.pinDir != null && s.pinDst != null && (s.pinDir !== "" || s.pinDst !== "")))[0];
        console.log(shotWithPin)
        setPinDir(shotWithPin?.pinDir);
        setPinDst(shotWithPin?.pinDst);
    }
    useEffect(() => {
        getPinPos();
    }, [])

    async function changePinPos() {
        setIsLoading(true);
        const jwt = await getJWT();
        try {
            const jwt = await getJWT();
            const response = await fetch(`/api/Round/change-pin?roundId=${id}&holeNo=${hole}&pinDir=${newPinDir.toLowerCase()}&pinDst=${newPinDst.toLowerCase()}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });
            if (response.ok) {
                updateToast.current.show({
                    severity: 'success', summary: 'Pin Position Updated', detail: `Hole: ${hole} Pin: ${newPinDst} ${newPinDir}`
                });
                setIsChangePinPos(false);
                setPinDir(newPinDir)
                setPinDst(newPinDst)
                setWasHoleEdit(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error deleting shot:', error);
        }
    }
    return (
        <>
            {isChangePinPos ?
                <>
                    <Dropdown value={newPinDst} options={["Front", "Middle", "Back"]} onChange={(e) => setNewPinDst(e.value)} className="m-1" style={{ maxWidth: "125px", minWidth: "125px" }} />
                    <Dropdown value={newPinDir} options={["Left", "Center", "Right"]} onChange={(e) => setNewPinDir(e.value)} className="m-1" style={{ maxWidth: "125px", minWidth: "125px" }} />
                    <Button label="Change" className="m-1" onClick={() => changePinPos()} disabled={newPinDir === "" || newPinDst === ""} loading={isLoading} />
                    <Button label="Cancel" severity="danger" className="m-1" onClick={() => setIsChangePinPos(false)} />
                </>
                :
                <div >
                    <h1>Pin Position: {capitalizeFirstLetter(pinDst)} {capitalizeFirstLetter(pinDir)}</h1>
                    <Button label="Change Pin Position" className="m-1" onClick={() => setIsChangePinPos(true)} />
                </div>
            }
        </>
    )
}
export default ChangePinPos;