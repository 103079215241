import React, { useState, useEffect, useRef } from "react";
import { Table, Row, Col } from "reactstrap";
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Message } from 'primereact/message';
import { useAuth } from './appwrite-testing/AuthContext';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import '../css/loading.css'
/**Table with all user's rounds. Only last 10 will show then can be expanded by another 10. */
const RoundTable = (props) => {
    const { user, loading, getJWT } = useAuth();
    const queryClient = useQueryClient();
    const toast = useRef(null);
    const [numRoundsToShow, setNumRoundsToShow] = useState(10);
    const [deletedRound, setDeletedRound] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [noRounds, setNoRounds] = useState(false)
    /**Gets all rounds. */
    const { data: rounds = [], isLoading } = useQuery({
        queryKey: ['rounds', props.userId, props.home],
        queryFn: async () => {
            if (!user || !props.userId) return [];

            const jwt = await getJWT();
            const endpoint = props.home
                ? `/api/Round/user-last-test?userId=${props.userId}&numberOfRounds=10&roundType=All`
                : `/api/Round/user?userId=${props.userId}`;

            const response = await fetch(endpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });

            if (!response.ok) throw new Error('Failed to fetch rounds');
            return response.json();
        },
        enabled: !!user && !!props.userId
    });
    /**Formats the datae to mm/dd/yyyy */
    const formatDate = (date) => {
        const d = new Date(date);
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const year = d.getFullYear();
        if (isNaN(month)) {
            return "...";
        }
        return `${month}/${day}/${year}`;
    };
    // Delete round mutation
    const deleteMutation = useMutation({
        mutationFn: async (roundId) => {
            const jwt = await getJWT();
            const response = await fetch(`/api/Round/${roundId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });
            if (!response.ok) throw new Error('Failed to delete round');
            return roundId;
        },
        onSuccess: (_, round) => {
            queryClient.invalidateQueries(['rounds']);
            props.setWasRoundDeleted?.(true);
            toast.current?.show({
                severity: 'success',
                summary: 'Deleted',
                detail: `${round.type} round from ${formatDate(round.date)} with score ${round.score}`
            });
        },
        onError: () => {
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to delete round'
            });
        }
    });
    // Modified delete handler
    const handleDelete = (round) => {
        const confirmDelete = window.confirm(
            `Are you sure you want to delete the ${round.type} round from ${formatDate(round.date)} with score ${round.score}?`
        );
        if (confirmDelete) {
            deleteMutation.mutate(round.id, {
                onSuccess: () => deleteMutation.onSuccess(round.id, round)
            });
        }
    };

    function openContinue(round) {
        const encodedTeeValue = encodeURIComponent(round.teeBox);
        window.location.href = `/finish-round?roundId=${round.id}&courseId=${round.courseId}&tee=${encodedTeeValue}`
    }

    /**Will increse the number of rounds shown in the table */
    const handleLoadMore = () => {
        setNumRoundsToShow(numRoundsToShow + 10);
    };
    useEffect(() => {
        setLoaded(rounds.length > 0 || noRounds);
    }, [rounds, noRounds]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <div>Please log in to view rounds.</div>;
    }

    return (
        <Row>
            <Toast ref={toast} />
            {noRounds && <Message className="mt-2 mb-2" text="No rounds were loaded" />}
            <Col lg="12">
                <div className="table-responsive">
                    <Table>
                        <thead>
                            <tr>
                                <th>Course</th>
                                <th>Round Type</th>
                                <th>Date</th>
                                <th>Score</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loaded ? (
                                <tr>
                                    <td className="loading-cell">
                                        .
                                    </td>
                                    <td className="loading-cell">
                                        .
                                    </td>
                                    <td className="loading-cell">
                                        .
                                    </td>
                                    <td className="loading-cell">
                                        .
                                    </td>
                                </tr>
                            ) :
                                (rounds.slice(0, numRoundsToShow).map((round) => (
                                    <tr key={round.id}>
                                        <td>{round.course}</td>
                                        <td>{round.type}</td>
                                        <td>{formatDate(round.date)}</td>
                                        <td>{round.score}</td>
                                        {round.score !== null && <td>
                                            <Button severity="secondary" label="Stats" size="small" icon="pi pi-chart-bar" onClick={() => window.open(`/round-stats/${round.id}`, "_self")} />
                                        </td>}
                                        {round.score !== null && <td>
                                            <Button severity="secondary" label="Edit" size="small" icon="pi pi-file-edit" onClick={() => window.open(`/round-edit/${round.id}`, "_self")} />
                                        </td>}
                                        {round.score === null && <td>
                                            <Button label="Continue" severity="secondary" size="small" icon="pi pi-play" onClick={() => openContinue(round)} />
                                        </td>}
                                        {round.score === null && <td>
                                        </td>}
                                        <td>
                                            <Button severity="danger" size="small" icon="pi pi-trash" onClick={() => handleDelete(round)} />
                                        </td>
                                    </tr>
                                )))}
                        </tbody>
                    </Table>
                </div>
            </Col>
            <Col lg="12">
                {numRoundsToShow < rounds.length && (
                    <div className="d-flex justify-content-center">
                        <Button label="Show More" style={{ width: 300 }} color="secondary" onClick={handleLoadMore} />
                    </div>
                )}

            </Col>
        </Row >
    );


};

export default RoundTable;
