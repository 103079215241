import { Button } from "primereact/button";
import { useRef, useState } from "react";
import SetGoals from "./SetGoals";
import ListGoals from "./ListGoals";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
const Goals = ({ state, data, userId, isAdvanced }) => {
    const [isSetGoals, setIsSetGoals] = useState(false);
    const toast = useRef(null);
    return (
        <>
            <Toast ref={toast} />
            <Message className='m-1' severity="info" text="*Per Shot" />
            {isSetGoals ?
                <SetGoals setIsSetGoals={setIsSetGoals} state={state} data={data} userId={userId} isAdvanced={isAdvanced} toast={toast} />
                :
                <>
                    <ListGoals state={state} data={data} userId={userId} />
                    <Button label="Set new goals" onClick={() => setIsSetGoals(true)} />
                </>
            }
        </>
    )
}
export default Goals;