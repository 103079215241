import { useEffect, useState } from "react";
import { useAuth } from "../appwrite-testing/AuthContext";
import { Table } from "reactstrap";
import { useConstant } from "../../ConstantContext";
import { GetStat } from "./GetStats";
import { useOptions } from "../stats_components/StatsOptionsProvider";

const ListGoals = ({ setIsSetGoals, state, data, userId }) => {
    const [goals, setGoals] = useState([]);
    const { getJWT } = useAuth();
    const statsOptions = useOptions();
    const constants = useConstant();
    const [isGoalsFound, setIsGoalsFound] = useState(true);
    const combinedStats = [
        ...(statsOptions?.tiger5stats || []),
        ...(statsOptions?.scoringStats || []),
        ...(statsOptions?.sgTee || []),
        ...(statsOptions?.teeStats || []),
        ...(statsOptions?.sgAppAdvanced || []),
        ...(statsOptions?.rangesAppAdvanced || []),
        ...(statsOptions?.gir || []),
        ...(statsOptions?.gfApp || []),
        ...(statsOptions?.difficultyMiss || []),
        ...(statsOptions?.expected || []),
        ...(statsOptions?.sgApp || []),
        ...(statsOptions?.rangesApp || []),
        ...(statsOptions?.goingForIt || []),
        ...(statsOptions?.goingAvg || []),
        ...(statsOptions?.argSGAdvanced || []),
        ...(statsOptions?.argStats || []),
        ...(statsOptions?.argSG || []),
        ...(statsOptions?.sgPuttAdvanced || []),
        ...(statsOptions?.puttingMake || []),
        ...(statsOptions?.puttingFeet || []),
        ...(statsOptions?.sgPutt || [])
    ];




    async function getGoals() {
        const jwt = await getJWT();

        const response = await fetch(`/api/goals?userId=${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            } // Send all the practice sessions for this user
        });

        // Check if the response was successful (you can add more checks as needed)
        if (!response.ok) {
            console.error(`Failed to get goals for user ${userId}`);
            //toast.current.show({ severity: 'danger', summary: 'Error!', detail: 'Schedules NOT sent!' });
        } else {
            try {
                const data = await response.json();
                setGoals(data.goalsList)
                if (data.goalsList.length > 0)
                    setIsGoalsFound(true)
            } catch (e) {
                console.error(e);
                setIsGoalsFound(false)
            }

            console.log(`Successfully get goals for user ${userId}`);
            //toast.current.show({ severity: 'success', summary: 'Sent!', detail: 'Schedules sent!' });
        }
    }
    useEffect(() => {
        getGoals();
    }, [])
    return (
        <>
            {isGoalsFound ?
                <Table id='goalstable' className='stats-table'>
                    <thead>
                        <tr>
                            <th>Stat Name</th>
                            <th>Current Value</th>
                            <th>Tour Average</th>
                            <th>Your goal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {goals.length > 0 ? data && goals.map((g) => {
                            const statName = combinedStats.find((s) => s.name === g.statName); // Find the matching stat
                            const stat = GetStat(g.statName, data, constants);
                            return (
                                <tr key={g.statName}
                                    style={{
                                        color: "white",
                                        backgroundColor: stat.low ? (stat.value < g.goalValue ? constants.green : constants.red) :
                                            (stat.value > g.goalValue ? constants.green : constants.red)
                                    }}>
                                    <td>{statName?.display || "N/A"}</td> {/* Display the `display` value or "N/A" if not found */}
                                    <td>{stat.value}</td>
                                    <td>{stat.tourAverage}</td>
                                    <td>{g.goalValue}{stat.tourAverage.toString().includes("%") && "%"}</td>
                                </tr>
                            );
                        })
                            :
                            <tr>
                                <td className="loading-cell"></td>
                                <td className="loading-cell"></td>
                                <td className="loading-cell"></td>
                            </tr>
                        }
                    </tbody>
                </Table>
                :
                <h1>Player has no goals set!</h1>}
        </>
    )
}
export default ListGoals;