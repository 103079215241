import { useConstant } from "../../../../ConstantContext";
import { useOptions } from "../../StatsOptionsProvider";
import { Table } from "reactstrap";
const PUTTindividual = ({ state, data, isAdvanced }) => {

    const constants = useConstant();
    const options = useOptions();
    function renderPuttStrokesGained() {
        return (
            <div className="table-responsive">
                <Table id='puttingtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Total</th>
                            <th>Att.</th>
                            <th>Per Shot</th>
                            <th>Per Round</th>
                        </tr>

                    </thead>
                    <tbody>
                        {(isAdvanced ? options.sgPuttAdvanced : options.sgPutt).map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`${stat.name}`]?.attempts > 0) &&
                                        stat.name === "PUTT" ?
                                        (state[`${stat.name}`]?.strokesGainedPerRound > constants.value ||
                                            state[`${stat.name}`]?.strokesGainedPerRound < constants.value) && 'white'
                                        : (state[`${stat.name}`]?.strokesGainedPerShot > constants.sgPerShotUpper ||
                                            state[`${stat.name}`]?.strokesGainedPerShot < constants.sgPerShotLower) && 'white',

                                    backgroundColor: (state[`${stat.name}`]?.attempts > 0) &&
                                        stat.name === "PUTT" ?
                                        (state[`${stat.name}`]?.strokesGainedPerRound > constants.value ? constants.green :
                                            state[`${stat.name}`]?.strokesGainedPerRound < constants.value) ? constants.red : ''
                                        : (state[`${stat.name}`]?.strokesGainedPerShot > constants.sgPerShotUpper ? constants.green :
                                            state[`${stat.name}`]?.strokesGainedPerShot < constants.sgPerShotLower ? constants.red : '')
                                }} >
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]?.strokesGainedRounded}</td>
                                <td>{state[`${stat.name}`]?.attempts}</td>
                                <td>{state[`${stat.name}`]?.strokesGainedPerShot}</td>
                                <td>{state[`${stat.name}`]?.strokesGainedPerRoundRounded}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
    function renderMisses() {
        return (
            <div className="table-responsive">
                <Table id='puttingtableMiss' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Low</th>
                            <th>High</th>
                            <th>Short</th>
                        </tr>

                    </thead>
                    <tbody>
                        {options.puttingMissDst.map((stat) => (
                            <tr key={`${stat.name}`} >
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]?.lowPercentage}%</td>
                                <td>{state[`${stat.name}`]?.highPercentage}%</td>
                                <td>{state[`${stat.name}`]?.shortPercentage}%</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
    return (
        <div>
            {renderPuttStrokesGained()}
            <div className="table-responsive">
                <Table id='puttingtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Value</th>
                            <th>Count</th>
                            <th>Attempts</th>
                            <th>Tour average</th>
                        </tr>
                    </thead>
                    <tbody>
                        {options.puttingMake.map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`attempts${stat.name}`] > 0) &&
                                        (state[`makeRate${stat.name}`] > constants.tourAvg[`makeRate${stat.name}`].greenValue ||
                                            state[`makeRate${stat.name}`] < constants.tourAvg[`makeRate${stat.name}`].redValue) && 'white',

                                    backgroundColor: (state[`attempts${stat.name}`] > 0) &&

                                        (state[`makeRate${stat.name}`] > constants.tourAvg[`makeRate${stat.name}`].greenValue ? constants.green :
                                            state[`makeRate${stat.name}`] < constants.tourAvg[`makeRate${stat.name}`].redValue ? constants.red : '')
                                }}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]?.percentage}%</td>
                                <td>{state[`${stat.name}`]?.count}</td>
                                <td>{state[`${stat.name}`]?.attempts}</td>
                                <td>{constants.tourAvg[`${stat.name}.percentage`]?.value}</td>
                            </tr>
                        ))}
                        {isAdvanced && options.puttingFeet.map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`${stat.name}`]) &&
                                        (state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ||
                                            state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue) && 'white',
                                    backgroundColor: (state[`${stat.name}`]) &&
                                        (state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ? constants.green :
                                            state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue ? constants.red : '')
                                }}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]}'</td>
                                <td>--</td>
                                <td>--</td>
                                <td>{constants.tourAvg[`${stat.name}`]?.value}</td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
            </div>
            {renderMisses()}
        </div>
    )
}
export default PUTTindividual;