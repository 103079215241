import React from "react"
import { Image } from "primereact/image"

const Footer = () => <footer className="page-footer   pt-4 mt-4 "
    style={{ backgroundColor: "rgb(39,58,82)", color: "white", width: "100vw", marginLeft: "calc((100vw - 100%) / -2)", fontSize: "small" }}>
    <div className="container-fluid text-center text-md-left">
        <div className="row">
            <div className="col-md-6" style={{ display: 'flex', flexWrap: "wrap", flexDirection: 'row', alignItems: 'center', overflowX: 'hidden', justifyContent: 'center' }}>
                <div className="m-3">
                    <Image src={process.env.PUBLIC_URL + 'logoNav.png'} width="100" />
                </div>
                <div>
                    <h5 className="text-uppercase" style={{ fontSize: "medium" }}>Parfect Performance</h5>
                    <p>Your golf game deserves a taste of PARfection! </p>
                </div>
            </div>
            <hr className="clearfix w-100 d-md-none pb-0" />
            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase" style={{ fontSize: "medium" }}>Policies</h5>
                <ul className="list-unstyled">
                    <li><a href="#!">TERMS & CONDITIONS</a></li>
                    <li><a href="#!">PRIVACY</a></li>
                    <li><a href="#!">COOKIES</a></li>
                </ul>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase" style={{ fontSize: "medium" }}>About</h5>
                <ul className="list-unstyled">
                    <li><a href="/faq">FAQ</a></li>
                    <li><a href="/contact">CONTACT</a></li>
                    <li><a href="https://parfectperformance.wordpress.com/about">ABOUT</a></li>
                    <li><a href="https://parfectperformance.wordpress.com/blog">BLOG</a></li>
                    <li><a link href="https://www.youtube.com/watch?v=pPl9XrII1sc&list=PL24tN7qIdr7XI6fEbKd8l1y97w3vtu-7w&index=1">TUTORIALS</a></li>
                </ul>
            </div>
        </div>
    </div>

</footer >

export default Footer