import React, { useState } from "react";
import RoundTable from "./RoundTable";
import { Card } from "primereact/card";
import { useAuth } from './appwrite-testing/AuthContext';

const MyRounds = () => {
    const [wasRoundDeleted, setWasRoundDeleted] = useState(false);
    const { user } = useAuth();
    const userId = user ? user.$id : null;

    if (!userId) {
        return (
            <Card title="My Rounds">
                <div>Loading...</div>
            </Card>
        );
    }

    return (
        <Card title="My Rounds">
            <RoundTable userId={userId} setWasRoundDeleted={setWasRoundDeleted} home={false} />
        </Card>
    );
};

export { MyRounds };
