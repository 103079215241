import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { Alert } from "reactstrap";


const Subscribe = () => {
    const { user, loading, login, isSubscriber, getCurrentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubscription = async (productKey) => {
        if (isLoading || !user) return;
        
        setIsLoading(true);
        try {
            const response = await fetch('/api/stripe/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    productKey,
                    userId: user.$id // Assuming this is how you access the Appwrite user ID
                }),
            });

            if (response.ok) {
                const { url } = await response.json();
                if (url) {
                    window.location.href = url;
                } else {
                    console.error('No URL in response');
                    alert('An error occurred');
                }
            } else {
                const errorData = await response.json();
                console.error('Error:', errorData.error);
                alert(errorData.error || 'An error occurred');
            }
        } catch (error) {
            console.error('Network error:', error);
            alert('Network error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(!loading && user)
        {
            console.log("Hello");
        }
    }, []);

    return (
      <div>
        <div className="container mt-5">
          {/* <h1 className="text-center mb-4">Choose Your Subscription Plan</h1> */}
          <h1 className="text-center mb-4"> Continue your subscription</h1>
          <p className="row justify-content-center">Looks like your subscription plan has expired please choose a plan below:</p>
          <div className="row justify-content-center">
            {/* Individual Basic Plan */}
            <div className="col-md-4 mb-4">
              <div className="card h-100 shadow">
                <div className="card-body text-center">
                  <h3 className="card-title">Individual Basic</h3>
                  <div className="my-4">
                    <h2 className="card-text">$6.99<small>/month</small></h2>
                    <p>or $69.99/year</p>
                  </div>
                  <ul className="list-unstyled">
                    <li className="mb-2">Basic Statistics</li>
                    <li className="mb-2">Round Tracking</li>
                    <li className="mb-2">Practice Planning</li>
                  </ul>
                  <button 
                    className="btn btn-primary btn-lg mt-3"
                    onClick={() => handleSubscription('IMB')}
                    disabled={isLoading}
                  >
                    Subscribe Monthly
                  </button>
                  <button 
                    className="btn btn-outline-primary btn-lg mt-2"
                    onClick={() => handleSubscription('IAB')}
                    disabled={isLoading}
                  >
                    Subscribe Yearly
                  </button>
                </div>
              </div>
            </div>

            {/* Individual Pro Plan */}
            <div className="col-md-4 mb-4">
              <div className="card h-100 shadow border-primary">
                <div className="card-body text-center">
                  <h3 className="card-title">Individual Pro</h3>
                  <div className="my-4">
                    <h2 className="card-text">$10.99<small>/month</small></h2>
                    <p>or $109.99/year</p>
                  </div>
                  <div className="badge bg-success mb-3">Most Popular!</div>
                  <ul className="list-unstyled">
                    <li className="mb-2">Advanced Statistics</li>
                    <li className="mb-2">All Basic Features</li>
                    <li className="mb-2">Advanced Practice Tools</li>
                    <li className="mb-2">Priority Support</li>
                  </ul>
                  <button 
                    className="btn btn-primary btn-lg mt-3"
                    onClick={() => handleSubscription('IMA')}
                    disabled={isLoading}
                  >
                    Subscribe Monthly
                  </button>
                  <button 
                    className="btn btn-outline-primary btn-lg mt-2"
                    onClick={() => handleSubscription('IAA')}
                    disabled={isLoading}
                  >
                    Subscribe Yearly
                  </button>
                </div>
              </div>
            </div>

            {/* Team Plan */}
            <div className="col-md-4 mb-4">
              <div className="card h-100 shadow border-success">
                <div className="card-body text-center">
                  <h3 className="card-title">Team Plans</h3>
                  <div className="my-4">
                    <h2 className="card-text">Starting at</h2>
                    <p>Basic: $59.99/player/year</p>
                    <p>Pro: $94.99/player/year</p>
                  </div>
                  <div className="badge bg-success mb-3">Coach Account Free!</div>
                  <ul className="list-unstyled">
                    <li className="mb-2">Team Management Tools</li>
                    <li className="mb-2">Coach Dashboard</li>
                    <li className="mb-2">Team Analytics</li>
                    <li className="mb-2">Bulk Discounts Available</li>
                  </ul>
                  <button 
                    className="btn btn-success btn-lg mt-3"
                    disabled={isLoading}
                  >
                    Contact for Team Access
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Academy Section */}
          <div className="row justify-content-center mt-4">
            <div className="col-md-8">
              <div className="card shadow border-info">
                <div className="card-body text-center">
                  <h3 className="card-title">Academy Program</h3>
                  <div className="badge bg-info mb-3">Special Offer!</div>
                  <p className="lead">Free coach account + 10% discount code for all your clients</p>
                  <p>Perfect for golf academies and professional coaches</p>
                  <button 
                    className="btn btn-info btn-lg mt-3"
                    disabled={isLoading}
                  >
                    Contact for Details
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Note */}
          <div className="text-center mt-4">
            <p className="text-muted">For teams with 10+ players, please contact us for special pricing.</p>
          </div>
        </div>
      </div>
    );
  };
  
  export default Subscribe;
