import React from 'react';
import { useAuth } from './AuthContext';

const DebugComponent = () => {
  const { user, loading, isSubscriber, jwt } = useAuth();

  return (
    <div style={{ fontSize: '0.8rem' }}>
      <div>
        <p><strong>Loading:</strong> {loading.toString()}</p>
        <p><strong>Is Subscriber:</strong> {isSubscriber().toString()}</p>
        {user && (
          <>
            <p><strong>User ID:</strong> {user.$id}</p>
            <p><strong>Name:</strong> {user.name}</p>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Labels:</strong> {user.labels?.join(', ') || 'None'}</p>
          </>
        )}
        {!user && <p>No user logged in</p>}
      </div>
    </div>
  );
};

export default DebugComponent;
