import React, { useState, useEffect } from "react";
import {
    Container, Row
} from "reactstrap";
import { PuttingPractice } from "./PuttingPractice";
import { NewPuttingPractice } from "./NewPuttingPractice";
import { PuttingPracticeResult } from "../Team/PuttingPracticeResults";
import { ApproachPractice } from "./ApproachPractice";
import { NewApproachPractice } from "./NewApproachPractice";
import { ApproachPracticeResult } from "./ApproachPracticeResults";
import { TabMenu } from "primereact/tabmenu"
import { useAuth } from "../../appwrite-testing/AuthContext";
import { Games } from "./Games";
import { Planner } from "./Planner";
import MyTeamPlan from "./MyTeamPlan";
import GreenReading from "./GreenReading";
const Practice = () => {

    const { user, loading, getJWT } = useAuth();
    const userId = user?.$id;

    //console.log('Practice component:', { user, userId, loading });

    const [startNewPutting, setStartNewPutting] = useState(false);
    const [showPuttingResult, setShowPuttingResult] = useState(false);

    const [startNewApproach, setStartNewApproach] = useState(false);
    const [showApproachResult, setShowApproachResult] = useState(false);
    const [lowDistance, setLowDistance] = useState(null);
    const [highDistance, setHighDistance] = useState(null);

    const basicOptionsMenu = [{ label: "Putting Combine", icon: "" },
    { label: "Approach", icon: "" }, { label: "My Team Practice Plan", icon: "" }];
    const advancedOptionsMenu = [{ label: "Practice Planner", icon: "" }, { label: "My Team Practice Plan", icon: "" },
    { label: "Putting Combine", icon: "" }, { label: "Green Reading", icon: "" }, { label: "Putting Games", icon: "" }, { label: "Short Game Games", icon: "" },
    { label: "Approach", icon: "" }, { label: "Approach Games", icon: "" }, { label: "Tee Games", icon: "" }
    ];
    const [options, setOptions] = useState([])
    const [activeIndex, setActiveIndex] = useState(-1);
    const [selectedPractice, setSelectedPractice] = useState("")
    useEffect(() => {
        if (user?.labels?.includes('advanced')) {
            setOptions(advancedOptionsMenu)
        } else {
            setOptions(basicOptionsMenu)
        }
    }, [user])
    return (
        <Container >
            {!user && <div>No user found</div>}
            {loading && <div>Loading...</div>}
            <Row >
                <div className="p-fluid mb-3" style={{ display: "flex", justifyContent: "center", width: "100%", }} >
                    <TabMenu model={options} activeIndex={activeIndex} onTabChange={(e) => { setSelectedPractice(e.value.label); setActiveIndex(e.index) }} />
                </div>
            </Row>
            <Row>
                {selectedPractice === "Practice Planner" && <Planner />}
                {selectedPractice === "Putting Combine" && <PuttingPractice setStartNewPutting={setStartNewPutting} setShowPuttingResult={setShowPuttingResult} />}
                {selectedPractice === "Putting Combine" && startNewPutting && <NewPuttingPractice setStartNewPutting={setStartNewPutting} setShowPuttingResult={setShowPuttingResult} />}
                {selectedPractice === "Putting Combine" && showPuttingResult && <PuttingPracticeResult userId={userId} />}
                {selectedPractice === "Putting Games" && <Games games={"puttinggames"} />}
                {selectedPractice === "Short Game Games" && <Games games={"arggames"} />}
                {selectedPractice === "Tee Games" && <Games games={"teegames"} />}
                {selectedPractice === "Approach Games" && <Games games={"approachgames"} />}
                {selectedPractice === "Approach" && <ApproachPractice setStartNewApproach={setStartNewApproach} setShowApproachResult={setShowApproachResult} setLowDistance={setLowDistance} setHighDistance={setHighDistance} />}
                {selectedPractice === "Approach" && startNewApproach && <NewApproachPractice setStartNewApproach={setStartNewApproach} setShowApproachResult={setShowApproachResult} setLowDistance={setLowDistance} lowDistance={lowDistance} highDistance={highDistance} />}
                {selectedPractice === "Approach" && showApproachResult && <ApproachPracticeResult lowDistance={lowDistance} highDistance={highDistance} userId={userId} />}
                {selectedPractice === "My Team Practice Plan" && <MyTeamPlan user={user} getJWT={getJWT} setSelectedPractice={setSelectedPractice} setActiveIndex={setActiveIndex} />}
                {selectedPractice === "Green Reading" && <GreenReading user={user} getJWT={getJWT} setSelectedPractice={setSelectedPractice} setActiveIndex={setActiveIndex} />}
            </Row>
        </Container >
    )
}

export { Practice }