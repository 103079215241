import { Dropdown } from "primereact/dropdown";
import { useOptions } from "../stats_components/StatsOptionsProvider";
import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { InputNumber } from "primereact/inputnumber";
import { GetStat } from "./GetStats";
import { useConstant } from "../../ConstantContext";
const GoalsDropdown = ({ data, state, setGoals, goals, isAdvanced, index }) => {
    const statsOptions = useOptions();
    const constants = useConstant();
    const categories = isAdvanced ? ["Tiger 5", "Scoring", "Off the tee",
        "Approach", "Going for it", "Around the green", "Putting"] : ["Tiger 5", "Scoring", "Off the tee",
        "Approach", "Around the green", "Putting"];
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedStat, setSelectedStat] = useState("");
    const subOptions = {
        "Tiger 5": statsOptions.tiger5stats || [],
        "Scoring": statsOptions.scoringStats || [],
        "Off the tee": isAdvanced ? [
            ...(statsOptions?.sgTee || []), // Spread sgTee options
            ...(statsOptions?.teeStats || []) // Spread teeStats options
        ] :
            [
                ...(statsOptions?.sgTee.filter((s) => !s.advanced) || []), // Spread sgTee options
                ...(statsOptions?.teeStats || []) // Spread teeStats options
            ],
        "Approach": isAdvanced ? [
            ...(statsOptions?.sgAppAdvanced || []),
            ...(statsOptions?.rangesAppAdvanced || []),
            ...(statsOptions?.gir || []),
            ...(statsOptions?.gfApp || []),
            ...(statsOptions?.difficultyMiss || []),
            ...(statsOptions?.expected || []),
        ] :
            [
                ...(statsOptions?.sgApp || []),
                ...(statsOptions?.rangesApp || []),
                ...(statsOptions?.gir || []),
                ...(statsOptions?.gfApp || []),
                ...(statsOptions?.difficultyMiss || []),
            ]
        ,
        "Going for it": [...(statsOptions?.goingForIt || []),
        ...(statsOptions?.goingAvg || []),],
        "Around the green": isAdvanced ? [
            ...(statsOptions?.argSGAdvanced || []),
            ...(statsOptions?.argStats || [])
        ] :
            [
                ...(statsOptions?.argSG || []),
                ...(statsOptions?.argStats || [])
            ]
        ,
        "Putting": isAdvanced ? [
            ...(statsOptions?.sgPuttAdvanced || []),
            ...(statsOptions?.puttingMake || []),
            ...(statsOptions?.puttingFeet || [])
        ] :
            [
                ...(statsOptions?.sgPutt || []),
                ...(statsOptions?.puttingMake || [])
            ]
        ,
    };

    const [currentValue, setCurrentValue] = useState(null);
    const [tourA, setTourA] = useState(null);
    const [goalValue, setGoalValue] = useState(null);
    useEffect(() => {
        if (selectedStat != "") {
            const stat = GetStat(selectedStat.name, data, constants);
            setCurrentValue(stat.value);
            setTourA(stat.tourAverage);
        }

    }, [selectedStat])


    useEffect(() => {
        if (selectedStat && goalValue != null) {
            const newGoal = { "statName": selectedStat.name, "goalValue": goalValue };

            // Ensure the goals array is long enough to accommodate the index
            if (index >= goals.length) {
                // If the index is beyond the current length, add new goal(s) until we reach the index
                const newGoals = [...goals];
                while (newGoals.length <= index) {
                    newGoals.push({ statName: "", goalValue: null });
                }
                newGoals[index] = newGoal;
                setGoals(newGoals);
            } else {
                // If the index is within bounds, update the goal at that index
                const updatedGoals = [...goals];
                updatedGoals[index] = newGoal;
                setGoals(updatedGoals);
            }
        }
    }, [goalValue, index]);


    return (
        <div className="mt-3">
            <Dropdown value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.value)}
                options={categories}
                placeholder="Select a category" className="m-1" />
            {selectedCategory != "" && <Dropdown value={selectedStat}
                onChange={(e) => setSelectedStat(e.value)}
                options={subOptions[selectedCategory]}
                placeholder="Select a stat"
                optionLabel="display" className="m-1" />}
            {selectedStat != "" &&
                <Table >
                    <thead>
                        <tr>
                            <th>Current Value</th>
                            <th>Tour Average</th>
                            <th>Your goal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{currentValue}</td>
                            <td>{tourA}</td>
                            <td><InputNumber value={goalValue} onChange={(e) => setGoalValue(e.value)}
                                maxFractionDigits={2} /></td>
                        </tr>
                    </tbody>
                </Table>}
        </div>
    )
}
export default GoalsDropdown;