import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Table } from "reactstrap";

const GreenReadingTable = ({ data, getJWT, userId, setDelete }) => {
    const deleteToast = useRef(null);
    const options = [
        "0-2% L-R",
        "0-2% R-L",
        "2-4% L-R",
        "2-4% R-L",
    ]
    const [selectedTable, setSelectedTable] = useState("");
    const [arrName, setArrName] = useState("")
    const [currentArr, setCurrentArr] = useState([])
    useEffect(() => {
        switch (selectedTable) {
            case "0-2% L-R":
                setCurrentArr(data.reads02LR);
                setArrName("reads02LR")
                break;
            case "0-2% R-L":
                setCurrentArr(data.reads02RL);
                setArrName("reads02RL")
                break;
            case "2-4% L-R":
                setCurrentArr(data.reads24LR);
                setArrName("reads24LR")
                break;
            case "2-4% R-L":
                setCurrentArr(data.reads24RL);
                setArrName("reads24RL")
                break;
        }
    }, [selectedTable, data])

    async function deleteRead(id) {
        try {
            const jwt = await getJWT();
            const response = await fetch(`/api/greenreadingpractice?userId=${userId}&id=${id}&arrName=${arrName}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });
            if (response.ok) {
                deleteToast.current.show({ severity: "success", summary: 'Deleted', life: 2000 })
                setDelete(true);

            } else {
                console.error(response);
            }
        } catch (error) {
            console.error('Error adding a read:', error);
        }
    }
    return (
        <>
            <Card title="List of inputs">
                <Toast ref={deleteToast} />
                <SelectButton className="mb-2" options={options} value={selectedTable} onChange={(e) => setSelectedTable(e.value)} />
                <Table>
                    <thead>
                        <tr>
                            <th>Slope</th>
                            <th>Read</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentArr.length > 0 &&
                            currentArr.map((r) => (
                                <tr key={r.id}>
                                    <td>{Math.abs(r.slopePct)} %</td>
                                    <td>{Math.abs(r.readPct)} %</td>
                                    <td><Button label="Delete" severity="danger" onClick={() => deleteRead(r.id)} /></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Card>
        </>
    )
}
export default GreenReadingTable;