import { Table } from "reactstrap";
import { useConstant } from "../../ConstantContext";
const Weaknasses = ({ state, data, scoreAverage, totalImprovement, newScoreAverage }) => {
    const consants = useConstant();
    return (
        <div className="table-responsive">
            <Table id='analysistable' className='stats-table'>
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Name</th>
                        <th>Total SG</th>
                        <th>Att.</th>
                        <th>SG per Shot</th>
                        <th>Avg. SG per Round</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: 5 }).map((_, index) => (
                        <tr style={{
                            color: 'white',
                            backgroundColor: consants.red
                        }}>
                            <td className='stats-title'>{index + 1}</td>
                            {consants.analysisOptions.map((option) => (
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`analysis${index + 1}${option}`]}</td>
                            ))}
                        </tr>
                    ))}

                </tbody>
            </Table>
            <div>Current Score Avg.: {scoreAverage}</div>
            <div>Total Score Avg. Improvement: {totalImprovement}</div>
            <div>New Score Avg.: {newScoreAverage}</div>
        </div>
    )
}
export default Weaknasses;