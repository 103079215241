import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

const LoginButton = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <Button 
      className="ms-2 btn-gold" 
      onClick={handleLogin}
    >
      Log In
    </Button>
  );
};

export { LoginButton };