import React, { useState, useEffect } from "react";
import {
    Container, Row, Table, Col
} from "reactstrap";

import { MultiSelectPlayers } from "../../dropdowns/MultiSelectPlayers";
import { GetScoresPutting } from "./GetScoresPutting";
import { Message } from "primereact/message";
import { PuttingPracticeResult } from "./PuttingPracticeResults";
import { Button } from "primereact/button";
const TeamPutting = (props) => {

    /**Possible durations to filter rounds */
    const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds",
        "Last 20 Rounds"];
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [displayedPlayers, setDisplayedPlayers] = useState([]);
    const [allUserAccounts] = useState(props.allUserAccounts);
    const [seeDetails, setSeeDetails] = useState(false);
    const [userDetailsSelected, setUserDetailsSelected] = useState(null);
    useEffect(() => {
        if (selectedUsers.length > 0) {
            setDisplayedPlayers(selectedUsers);
        } else {
            setDisplayedPlayers(allUserAccounts)
        }
    }, [selectedUsers, allUserAccounts])




    return (
        <div>
            {seeDetails ?
                <div>
                    <Button label="Go Back" className="mb-3" onClick={() => setSeeDetails(false)} />
                    <PuttingPracticeResult userId={userDetailsSelected.userId.substring(userDetailsSelected.userId.indexOf('|') + 1)} />
                </div>
                : <Container>
                    {displayedPlayers.length <= 0 && <Message className='mt-3' severity="warn" text="No player was selected" />}
                    <Row className='mt-3'>
                        <Col>
                            <MultiSelectPlayers players={props.players} selectedUsers={selectedUsers}
                                setSelectedUsers={setSelectedUsers} coachTeam={props.coachTeam} />
                        </Col>
                    </Row>
                    <Row>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Last</th>
                                    <th>Last 5</th>
                                    <th>Last 10</th>
                                    <th>Last 30</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedPlayers.map((player) => {
                                    return <GetScoresPutting player={player} setSeeDetails={setSeeDetails} setUserDetailsSelected={setUserDetailsSelected} />
                                })}
                            </tbody>
                        </Table>
                    </Row>
                </Container>}
        </div>

    )
}

export { TeamPutting }