import { useConstant } from "../../ConstantContext";

export function GetStat(name, data, constants) {
    console.log('Received data:', data);  // Log the data for debugging
    if (!data) {
        return { value: null, tourAverage: null };  // Return default if no data
    }
    var statsKeys = {
        'scoreAvg': { value: data.scoreAvg, tourAverage: constants.tourAvg.scoreAvg.value, low: constants.tourAvg.scoreAvg.low },
        'par3Avg': { value: data.par3Avg, tourAverage: constants.tourAvg.par3Avg.value, low: constants.tourAvg.par3Avg.low },
        'par4Avg': { value: data.par4Avg, tourAverage: constants.tourAvg.par4Avg.value, low: constants.tourAvg.par4Avg.low },
        'par5Avg': { value: data.par5Avg, tourAverage: constants.tourAvg.par5Avg.value, low: constants.tourAvg.par5Avg.low },
        'birdiesPerRound': { value: data.birdiesPerRound, tourAverage: constants.tourAvg.birdiesPerRound.value, low: constants.tourAvg.birdiesPerRound.low },
        'bogeysPerRound': { value: data.bogeysPerRound, tourAverage: constants.tourAvg.bogeysPerRound.value, low: constants.tourAvg.bogeysPerRound.low },
        'doublesPerRound': { value: data.doublesPerRound, tourAverage: constants.tourAvg.doublesPerRound.value, low: constants.tourAvg.doublesPerRound.low },
        'otherPerRound': { value: data.otherPerRound, tourAverage: constants.tourAvg.otherPerRound.value, low: constants.tourAvg.otherPerRound.low },
        'bogeyPar5': { value: data.bogeyPar5PerRound, tourAverage: constants.tourAvg.bogeyPar5PerRound.value, low: constants.tourAvg.bogeyPar5PerRound.low },
        'double': { value: data.doublePerRound, tourAverage: constants.tourAvg.doublePerRound.value, low: constants.tourAvg.doublePerRound.low },
        'easyChip': { value: data.easyChipPerRound + '%', tourAverage: constants.tourAvg.easyChipPerRound.value, low: constants.tourAvg.easyChipPerRound.low },
        'threePutt': { value: data.threePuttPerRound, tourAverage: constants.tourAvg.threePuttPerRound.value, low: constants.tourAvg.threePuttPerRound.low },
        'bogey150': { value: data.bogey150PerRound, tourAverage: constants.tourAvg.bogey150PerRound.value, low: constants.tourAvg.bogey150PerRound.low },
        "sgOTT": { value: data.sgOTT.strokesGainedPerRound, tourAverage: constants.tourAvg["sgOTT.strokesGainedPerRound"].value, low: constants.tourAvg["sgOTT.strokesGainedPerRound"].low },
        "sgOTTdriver": { value: data.sgOTTdriver.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sgOTTdriver.strokesGainedPerRound"].value, low: constants.tourAvg["sgOTTdriver.strokesGainedPerRound"].low },
        "sgOTTother": { value: data.sgOTTother.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sgOTTother.strokesGainedPerRound"].value, low: constants.tourAvg["sgOTTother.strokesGainedPerRound"].low },
        'ottFairwayHit': { value: data.ottFairwayHit.percentage + '%', tourAverage: constants.tourAvg["ottFairwayHit.percentage"].value, low: constants.tourAvg["ottFairwayHit.percentage"].low },
        'ottdriverHit': { value: data.ottdriverHit.percentage + '%', tourAverage: constants.tourAvg["ottdriverHit.percentage"].value, low: constants.tourAvg["ottdriverHit.percentage"].low },
        'ott3wHit': { value: data.ott3wHit.percentage + '%', tourAverage: constants.tourAvg["ott3wHit.percentage"].value, low: constants.tourAvg["ott3wHit.percentage"].low },
        'ott5wHit': { value: data.ott5wHit.percentage + '%', tourAverage: constants.tourAvg["ott5wHit.percentage"].value, low: constants.tourAvg["ott5wHit.percentage"].low },
        'ott7wHit': { value: data.ott7wHit.percentage + '%', tourAverage: constants.tourAvg["ott7wHit.percentage"].value, low: constants.tourAvg["ott7wHit.percentage"].low },
        'ott3hHit': { value: data.ott3hHit.percentage + '%', tourAverage: constants.tourAvg["ott3hHit.percentage"].value, low: constants.tourAvg["ott3hHit.percentage"].low },
        'ott4hHit': { value: data.ott4hHit.percentage + '%', tourAverage: constants.tourAvg["ott4hHit.percentage"].value, low: constants.tourAvg["ott4hHit.percentage"].low },
        'ott5hHit': { value: data.ott5hHit.percentage + '%', tourAverage: constants.tourAvg["ott5hHit.percentage"].value, low: constants.tourAvg["ott5hHit.percentage"].low },
        'ott2iHit': { value: data.ott2iHit.percentage + '%', tourAverage: constants.tourAvg["ott2iHit.percentage"].value, low: constants.tourAvg["ott2iHit.percentage"].low },
        'ott3iHit': { value: data.ott3iHit.percentage + '%', tourAverage: constants.tourAvg["ott3iHit.percentage"].value, low: constants.tourAvg["ott3iHit.percentage"].low },
        'ott4iHit': { value: data.ott4iHit.percentage, tourAverage: constants.tourAvg["ott4iHit.percentage"].value, low: constants.tourAvg["ott4iHit.percentage"].low },
        'ottRecoveryHit': { value: data.ottRecoveryHit.percentage + '%', tourAverage: constants.tourAvg["ottRecoveryHit.percentage"].value, low: constants.tourAvg["ottRecoveryHit.percentage"].low },
        'ottSandHit': { value: data.ottSandHit.percentage + '%', tourAverage: constants.tourAvg["ottSandHit.percentage"].value, low: constants.tourAvg["ottSandHit.percentage"].low },
        'teeMissLeft': { value: data.teeMissLeft + '%', tourAverage: constants.tourAvg.teeMissLeft.value, low: constants.tourAvg.teeMissLeft.low },
        'teeMissRight': { value: data.teeMissRight + '%', tourAverage: constants.tourAvg.teeMissRight.value, low: constants.tourAvg.teeMissRight.low },
        "sgAPP": { value: data.sgAPP.strokesGainedPerRound, tourAverage: constants.tourAvg["sgAPP.strokesGainedPerRound"].value, low: constants.tourAvg["sgAPP.strokesGainedPerRound"].low },
        "sg50100": { value: data.sg50100.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg50100.strokesGainedPerShot"].value, low: constants.tourAvg["sg50100.strokesGainedPerShot"].low },
        "sg100150": { value: data.sg100150.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg100150.strokesGainedPerShot"].value, low: constants.tourAvg["sg100150.strokesGainedPerShot"].low },
        "sg150200": { value: data.sg150200.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg150200.strokesGainedPerShot"].value, low: constants.tourAvg["sg150200.strokesGainedPerShot"].low },
        "sg5075": { value: data.sg5075.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg5075.strokesGainedPerShot"].value, low: constants.tourAvg["sg5075.strokesGainedPerShot"].low },
        "sg75100": { value: data.sg75100.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg75100.strokesGainedPerShot"].value, low: constants.tourAvg["sg75100.strokesGainedPerShot"].low },
        "sg100125": { value: data.sg100125.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg100125.strokesGainedPerShot"].value, low: constants.tourAvg["sg100125.strokesGainedPerShot"].low },
        "sg125150": { value: data.sg125150.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg125150.strokesGainedPerShot"].value, low: constants.tourAvg["sg125150.strokesGainedPerShot"].low },
        "sg150175": { value: data.sg150175.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg150175.strokesGainedPerShot"].value, low: constants.tourAvg["sg150175.strokesGainedPerShot"].low },
        "sg175200": { value: data.sg175200.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg175200.strokesGainedPerShot"].value, low: constants.tourAvg["sg175200.strokesGainedPerShot"].low },
        "sg200": { value: data.sg200.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg200.strokesGainedPerShot"].value, low: constants.tourAvg["sg200.strokesGainedPerShot"].low },
        "gir": { value: data.gir.perRound, tourAverage: constants.tourAvg["gir.perRound"].value, low: constants.tourAvg["gir.perRound"].low },
        "gOrfir": { value: data.gOrfir.perRound, tourAverage: constants.tourAvg["gOrfir.perRound"].value, low: constants.tourAvg["gOrfir.perRound"].low },
        "gir50100": { value: data.gir50100.percentage + '%', tourAverage: constants.tourAvg["gir50100.percentage"].value, low: constants.tourAvg["gir50100.percentage"].low },
        "gir100150": { value: data.gir100150.percentage + '%', tourAverage: constants.tourAvg["gir100150.percentage"].value, low: constants.tourAvg["gir100150.percentage"].low },
        "gir150200": { value: data.gir150200.percentage + '%', tourAverage: constants.tourAvg["gir150200.percentage"].value, low: constants.tourAvg["gir150200.percentage"].low },
        "gir5075": { value: data.gir5075.percentage + '%', tourAverage: constants.tourAvg["gir5075.percentage"].value, low: constants.tourAvg["gir5075.percentage"].low },
        "gir75100": { value: data.gir75100.percentage + '%', tourAverage: constants.tourAvg["gir75100.percentage"].value, low: constants.tourAvg["gir75100.percentage"].low },
        "gir100125": { value: data.gir100125.percentage + '%', tourAverage: constants.tourAvg["gir100125.percentage"].value, low: constants.tourAvg["gir100125.percentage"].low },
        "gir125150": { value: data.gir125150.percentage + '%', tourAverage: constants.tourAvg["gir125150.percentage"].value, low: constants.tourAvg["gir125150.percentage"].low },
        "gir150175": { value: data.gir150175.percentage + '%', tourAverage: constants.tourAvg["gir150175.percentage"].value, low: constants.tourAvg["gir150175.percentage"].low },
        "gir175200": { value: data.gir175200.percentage + '%', tourAverage: constants.tourAvg["gir175200.percentage"].value, low: constants.tourAvg["gir175200.percentage"].low },
        "gir200": { value: data.gir200.percentage + '%', tourAverage: constants.tourAvg["gir200.percentage"].value, low: constants.tourAvg["gir200.percentage"].low },
        "eG": { value: data.eG.perRound, tourAverage: constants.tourAvg["eG.perRound"].value, low: constants.tourAvg["eG.perRound"].low },
        "iP": { value: data.iP.perRound, tourAverage: constants.tourAvg["iP.perRound"].value, low: constants.tourAvg["iP.perRound"].low },
        "bJK": { value: data.bJK.perRound, tourAverage: constants.tourAvg["bJK.perRound"].value, low: constants.tourAvg["bJK.perRound"].low },
        'appMissLeft': { value: data.appMissLeft + '%', tourAverage: constants.tourAvg.appMissLeft.value, low: constants.tourAvg.appMissLeft.low },
        'appMissRight': { value: data.appMissRight + '%', tourAverage: constants.tourAvg.appMissRight.value, low: constants.tourAvg.appMissRight.low },
        'appMissLong': { value: data.appMissLong + '%', tourAverage: constants.tourAvg.appMissLong.value, low: constants.tourAvg.appMissLong.low },
        'appMissShort': { value: data.appMissShort + '%', tourAverage: constants.tourAvg.appMissShort.value, low: constants.tourAvg.appMissShort.low },
        "easyMiss": { value: data.easyMiss.percentage + '%', tourAverage: constants.tourAvg["easyMiss.percentage"].value, low: constants.tourAvg["easyMiss.percentage"].low },
        "mediumMiss": { value: data.mediumMiss.percentage + '%', tourAverage: constants.tourAvg["mediumMiss.percentage"].value, low: constants.tourAvg["mediumMiss.percentage"].low },
        "hardMiss": { value: data.hardMiss.percentage + '%', tourAverage: constants.tourAvg["hardMiss.percentage"].value, low: constants.tourAvg["hardMiss.percentage"].low },
        'allExpectedApp': { value: data.allExpectedApp.averageExpected, tourAverage: constants.tourAvg["allExpectedApp.averageExpected"].value, low: constants.tourAvg["allExpectedApp.averageExpected"].low },
        'girHitExpectedApp': { value: data.girHitExpectedApp.averageExpected, tourAverage: constants.tourAvg["girHitExpectedApp.averageExpected"].value, low: constants.tourAvg["girHitExpectedApp.averageExpected"].low },
        'girMissedExpectedApp': { value: data.girMissedExpectedApp.averageExpected, tourAverage: constants.tourAvg["girMissedExpectedApp.averageExpected"].value, low: constants.tourAvg["girMissedExpectedApp.averageExpected"].low },
        'sgARG': { value: data.sgARGperRound, tourAverage: constants.tourAvg["sgARG.strokesGainedPerRound"].value, low: constants.tourAvg["sgARG.strokesGainedPerRound"].low },
        "sg015": { value: data.sg015.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg015.strokesGainedPerShot"].value, low: constants.tourAvg["sg015.strokesGainedPerShot"].low },
        "sg1530": { value: data.sg1530.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg1530.strokesGainedPerShot"].value, low: constants.tourAvg["sg1530.strokesGainedPerShot"].low },
        'sg3050': { value: data.sg3050.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sg3050.strokesGainedPerShot"].value, low: constants.tourAvg["sg3050.strokesGainedPerShot"].low },
        'sgFringe': { value: data.sgFringe.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sgFringe.strokesGainedPerShot"].value, low: constants.tourAvg["sgFringe.strokesGainedPerShot"].low },
        'sgSand': { value: data.sgSand.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sgSand.strokesGainedPerShot"].value, low: constants.tourAvg["sgSand.strokesGainedPerShot"].low },
        'sgEasyChip': { value: data.sgEasyChip.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["sgEasyChip.strokesGainedPerShot"].value, low: constants.tourAvg["sgEasyChip.strokesGainedPerShot"].low },
        'scrambling': { value: data.scrambling.percentage + '%', tourAverage: constants.tourAvg["scrambling.percentage"].value, low: constants.tourAvg["scrambling.percentage"].low },
        'p6': { value: data.p6.percentage + '%', tourAverage: constants.tourAvg["p6.percentage"].value, low: constants.tourAvg["p6.percentage"].low },
        'p12': { value: data.p12.percentage + '%', tourAverage: constants.tourAvg["p12.percentage"].value, low: constants.tourAvg["p12.percentage"].low },
        "PUTT": { value: data.putt.strokesGainedPerRound, tourAverage: constants.tourAvg["putt.strokesGainedPerRound"].value, low: constants.tourAvg["putt.strokesGainedPerRound"].low },
        "putt3": { value: data.putt3.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["putt3.strokesGainedPerShot"].value, low: constants.tourAvg["putt3.strokesGainedPerShot"].low },
        "putt35": { value: data.putt35.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["putt35.strokesGainedPerShot"].value, low: constants.tourAvg["putt35.strokesGainedPerShot"].low },
        "putt510": { value: data.putt510.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["putt510.strokesGainedPerShot"].value, low: constants.tourAvg["putt510.strokesGainedPerShot"].low },
        "putt1020": { value: data.putt1020.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["putt1020.strokesGainedPerShot"].value, low: constants.tourAvg["putt1020.strokesGainedPerShot"].low },
        "putt2030": { value: data.putt2030.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["putt2030.strokesGainedPerShot"].value, low: constants.tourAvg["putt2030.strokesGainedPerShot"].low },
        "putt30": { value: data.putt30.strokesGainedPerRoundRounded, tourAverage: constants.tourAvg["putt30.strokesGainedPerShot"].value, low: constants.tourAvg["putt30.strokesGainedPerShot"].low },
        'goingForIt': { value: data.goingForItPct + '%', tourAverage: constants.tourAvg.goingForItPct.value, low: constants.tourAvg.goingForItPct.low },
        'goingGreenHit': { value: data.goingGreenHitPct + '%', tourAverage: constants.tourAvg.goingGreenHitPct.value, low: constants.tourAvg.goingGreenHitPct.low },
        'goingBirdie': { value: data.goingBirdiePct + '%', tourAverage: constants.tourAvg.goingBirdiePct.value, low: constants.tourAvg.goingBirdiePct.low },
        'goingBogey': { value: data.goingBogeyPct + '%', tourAverage: constants.tourAvg.goingBogeyPct.value, low: constants.tourAvg.goingBogeyPct.low },
        'goingDistanceAfter': { value: data.goingDistanceAfter + " yds", tourAverage: constants.tourAvg.goingDistanceAfter.value, low: constants.tourAvg.goingDistanceAfter.low },
        'avgGoing': { value: data.avgGoing, tourAverage: constants.tourAvg.avgGoing?.value, low: constants.tourAvg.avgGoing?.low },
        'avgLayUp': { value: data.avgLayUp, tourAverage: constants.tourAvg.avgLayUp?.value, low: constants.tourAvg.avgLayUp?.low }
    };

    return { value: statsKeys[`${name}`]?.value, tourAverage: statsKeys[`${name}`]?.tourAverage, low: statsKeys[`${name}`]?.low }


}