import React, { createContext, useContext, useState } from 'react';

const OptionsContext = createContext();

export const StatsOptionsProvider = ({ children }) => {
    const [units, setUnits] = useState("yards");
    const [baseline, setBaseline] = useState("PGA")
    const clubsTee = ['driver', '3w', '5w', '7w', '3h', '4h', '5h', '2i', '3i', '4i'];

    const tiger5stats = [{ name: 'bogeyPar5', display: 'Bogeys on Par 5' },
    { name: 'double', display: "Doubles+" }, { name: 'threePutt', display: "Three Putts" },
    { name: 'bogey150', display: `Bogeys inside ${units === "yards" ? baseline === "LPGA" ? '125y' : '150y' : baseline === "LPGA" ? '114m' : '137m'}` },
    { name: 'easyChip', display: 'Easy u&d' }];
    const optionsTiger = ['PerRound', 'Attempts', 'Count'];

    const scoringStats = [{ name: 'scoreAvg', display: "Score Avg." }, { name: 'par3Avg', display: 'Par 3 Score Avg.' },
    { name: 'par4Avg', display: 'Par 4 Score Avg.' }, { name: 'par5Avg', display: 'Par 5 Score Avg.' },
    { name: 'birdiesPerRound', display: 'Birdies Per Round' }, { name: 'bogeysPerRound', display: 'Bogeys Per Round' },
    { name: 'doublesPerRound', display: 'Double Bogeys Per Round' }, { name: 'otherPerRound', display: 'Other Per Round' },];



    const sgTee = [{ name: 'sgOTT', display: 'SG OTT', advanced: false },
    { name: 'sgOTTdriver', display: 'SG Driver', advanced: true },
    { name: 'sgOTTother', display: 'SG Other', advanced: true }]
    const teeStats = [{ name: 'ottFairwayHit', display: 'Fairway hit' },
    { name: 'ottRecoveryHit', display: 'Tee shots in recovery or penalty' },
    { name: 'ottSandHit', display: 'Tee shots in sand' }]
    const teeMiss = [{ name: 'teeMissLeft', display: "Miss Left" },
    { name: 'teeMissRight', display: 'Miss Right' }];

    const argStats = [{ name: 'scrambling', display: 'Scrambling' },
    { name: 'p6', display: 'Chips to ≤6\'' }, { name: 'p12', display: 'Chips to ≤12\'' }];
    const argSG = [{ name: 'sgARG', display: 'SG' },
    { name: 'sg015', display: `SG ${units === "yards" ? '0-15y' : '0-14m'}` },
    { name: 'sg1530', display: `SG ${units === "yards" ? '15-30y' : '14-27m'}` },
    { name: 'sg3050', display: `SG ${units === "yards" ? '30-50y' : '27-46m'}` }];
    const argSGAdvanced = [{ name: 'sgARG', display: 'SG' },
    { name: 'sg015', display: `SG ${units === "yards" ? '0-15y' : '0-14m'}` },
    { name: 'sg1530', display: `SG ${units === "yards" ? '15-30y' : '14-27m'}` },
    { name: 'sg3050', display: `SG ${units === "yards" ? '30-50y' : '27-46m'}` },
    { name: 'sgSand', display: 'SG from sand*' },
    { name: 'sgFringe', display: 'SG from fringe*' },
    { name: 'sgEasyChip', display: 'SG easy chip' }];

    const gir = [{ name: "gir", display: `GIR` }, { name: "gOrfir", display: `Green or Fringe in Reg` }]

    const sgApp = [{ name: "sgAPP", display: 'SG APP' },
    { name: "sg50100", display: `SG ${units === "yards" ? '50-100y' : '46-91m'}` },
    { name: "sg100150", display: `SG ${units === "yards" ? '100-150y' : '91-137m'}` },
    { name: "sg150200", display: `SG ${units === "yards" ? '150-200y' : '137-182m'}` },
    { name: "sg200", display: `SG ${units === "yards" ? '200+y' : '182+m'}` }];
    const rangesApp = [{ name: "gir50100", display: `GIR ≤ ${units === "yards" ? '100y' : '91m'}` },
    { name: "gir100150", display: `GIR ${units === "yards" ? '100-150y' : '91-137m'}` },
    { name: "gir150200", display: `GIR ${units === "yards" ? '150-200y' : '137-182m'}` },
    { name: "gir200", display: `GIR ${units === "yards" ? '200+y' : '182+m'}` },];

    const sgAppAdvanced = [{ name: "sgAPP", display: 'SG APP' },
    { name: "sg5075", display: `SG ${units === "yards" ? '50-75y' : '46-69m'}` },
    { name: "sg75100", display: `SG ${units === "yards" ? '75-100y' : '69-91m'}` },
    { name: "sg100125", display: `SG ${units === "yards" ? '100-125y' : '91-114m'}` },
    { name: "sg125150", display: `SG ${units === "yards" ? '125-150y' : '114-137m'}` },
    { name: "sg150175", display: `SG ${units === "yards" ? '150-175y' : '137-160m'}` },
    { name: "sg175200", display: `SG ${units === "yards" ? '175-200y' : '160-182m'}` },
    { name: "sg200", display: `SG ${units === "yards" ? '200+y' : '182+m'}` }]
    const rangesAppAdvanced = [{ name: "gir5075", display: `GIR ≤ ${units === "yards" ? '75y' : '69m'}` },
    { name: "gir75100", display: `GIR ${units === "yards" ? '75-100y' : '69-91m'}` },
    { name: "gir100125", display: `GIR ${units === "yards" ? '100-125y' : '91-114m'}` },
    { name: "gir125150", display: `GIR ${units === "yards" ? '125-150y' : '114-137m'}` },
    { name: "gir150200", display: `GIR ${units === "yards" ? '150-175y' : '137-160m'}` },
    { name: "gir150175", display: `GIR ${units === "yards" ? '175-200y' : '160-182m'}` },
    { name: "gir200", display: `GIR ${units === "yards" ? '200+y' : '182+m'}` },];


    const positionsApp = ["", "Attempts", "Success"];
    const gfApp = [{ name: 'eG', display: 'GIR to ≤40\'' }, { name: 'iP', display: 'GIR to ≤20\'' }, { name: 'bJK', display: 'GIR to ≤8\'' }];
    const gfOptions = ['Attempts', 'Success', 'PerRound'];
    const missApp = [{ name: 'appMissLong', display: 'Miss Long' }, { name: 'appMissShort', display: 'Miss Short' },
    { name: 'appMissRight', display: 'Miss Right' }, { name: 'appMissLeft', display: 'Miss Left' },];
    const difficultyMiss = [{ name: 'easyMiss', display: 'Approaches missed to easy spots' },
    { name: 'mediumMiss', display: 'Approaches missed to medium spots' },
    { name: 'hardMiss', display: 'Approaches missed to hard spots' }]
    const expected = [{ name: "allExpectedApp", display: "Exptected Strokes After Approach" }, { name: "girHitExpectedApp", display: "Exptected Strokes After Approach (GIR hit)" },
    { name: "girMissedExpectedApp", display: "Exptected Strokes After Approach (GIR missed)" }]


    const puttingFeet = [{ name: 'totalFirstPuttPerRound', display: 'Total ft of first putts per round' }, { name: 'totalFeetMadePerRound', display: 'Total ft of putts made per round' }];
    const puttingMake = [{ name: 'putt3', display: 'Make rate 3\'' }, { name: 'putt35', display: 'Make rate 3-5\'' }, { name: 'putt510', display: 'Make rate 5-10\'' }];
    const sgPutt = [{ name: "putt", display: "SG PUTT" }, { name: "putt3", display: "SG 3'" }, { name: "putt35", display: "SG 3-5'" }, { name: "putt510", display: "SG 5-10'" },
    { name: "putt1020", display: "SG 10-20'" }, { name: "putt2030", display: "SG 20-30'" }, { name: "putt30", display: "SG 30'+" }]
    const sgPuttAdvanced = [{ name: "putt", display: "SG PUTT" }, { name: "putt3", display: "SG 3'" }, { name: "putt35", display: "SG 3-5'" }, { name: "putt510", display: "SG 5-10'" },
    { name: "putt1020", display: "SG 10-20'" }, { name: "putt2030", display: "SG 20-30'" }, { name: "putt30", display: "SG 30'+" }, { name: 'putt20Birdie', display: 'SG inside 20\' for birdie' }, { name: 'putt20Par', display: 'SG inside 20\' for par' }]
    const puttingMissDst = [{ name: 'putt35', display: 'Miss 3-5\'' }, { name: 'putt510', display: 'Miss 5-10\'' },
    { name: 'putt1020', display: 'Miss 10-20\'' }, { name: 'putt2030', display: 'Miss 20-30\'' }, { name: 'putt30', display: 'Miss 30+\'' }];

    const rangesPins = [
        { name: 'pin', display: "All" },
        { name: 'pin50100', display: `${units === "yards" ? '50-100y' : '46-91m'}` },
        { name: 'pin100150', display: `${units === "yards" ? '100-150y' : '91-137m'}` },
        { name: 'pin150200', display: `${units === "yards" ? '150-200y' : '137-183m'}` },
        { name: 'pin200', display: `${units === "yards" ? '200+y' : '183+m'}` }
    ];
    const positions = [{ name: "backLeft", display: 'Back Left' },
    { name: "backCenter", display: 'Back Center' },
    { name: "backRight", display: 'Back Right' },
    { name: "middleLeft", display: 'Middle Left' },
    { name: "middleCenter", display: 'Middle Center' },
    { name: "middleRight", display: 'Middle Right' },
    { name: "frontLeft", display: 'Front Left' },
    { name: "frontCenter", display: 'Front Center' },
    { name: "frontRight", display: 'Front Right' }];


    const clubsApp = ['lw', 'sw', 'gw', 'pw', '_9i', '_8i', '_7i', '_6i', '_5i', '_4i', '_3i', '_2i', '_4h', '_3h', '_7w', '_5w', '_3w'];



    const goingForIt = [{ name: 'goingForIt', display: 'Going for the green' }, { name: 'goingGreenHit', display: 'Going for the green- Green Hit' },
    { name: 'goingBirdie', display: 'Going for the green - Birdie or better' }, { name: 'goingBogey', display: 'Going for the green - Bogey or worse' }];
    const goingForItOptions = ['Pct', 'Count', 'Attempts'];
    const goingAvg = [{ name: 'avgGoing', display: 'Going for it average score' }, { name: 'avgLayUp', display: 'Laying up average' }]

    const mistakes = [{ name: 'good', display: "Good shots" }, { name: 'technique', display: "Technique error" },
    { name: 'mental', display: "Mental error" }, { name: 'decision', display: "Decision error" }];
    const mistakesOptions = ['Tee', 'App', 'Arg', 'Putting'];


    return (
        <OptionsContext.Provider value={{
            setUnits, setBaseline,
            scoringStats, tiger5stats, optionsTiger,
            clubsTee,
            sgTee, teeStats, teeMiss,
            gir, sgApp, rangesApp, clubsApp,
            sgAppAdvanced, rangesAppAdvanced,
            rangesPins, positions,
            positionsApp, gfApp, gfOptions, missApp, difficultyMiss, expected,
            argStats, argSG, argSGAdvanced,
            sgPutt, sgPuttAdvanced, puttingMake, puttingFeet, puttingMissDst,
            goingForIt, goingForItOptions, goingAvg,
            mistakes, mistakesOptions
        }} >
            {children}
        </OptionsContext.Provider>
    );
}

export const useOptions = () => {
    return useContext(OptionsContext);
};