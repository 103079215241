import { useState } from "react";
import { useAuth } from "../../appwrite-testing/AuthContext";
import { MultiSelectPlayers } from "../../dropdowns/MultiSelectPlayers";
import GreenReadResults from "../Individual/GreenReadResults";
import { Card } from "primereact/card";

const TeamGreenReading = ({ players, coachTeam }) => {
    const { getJWT } = useAuth();
    const [selectedUsers, setSelectedUsers] = useState([]);
    async function getUserData(id) {

        try {
            const jwt = await getJWT();
            const response = await fetch(`/api/greenreadingpractice?userId=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }
            });
            if (response.ok) {
                const data = await response.json()
                return data;
            } else {
                console.error(response);
            }
        } catch (error) {
            console.error('Error adding a read:', error);
        }
    }
    return (
        <>
            <MultiSelectPlayers players={players} selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers} coachTeam={coachTeam} />
            {selectedUsers?.map((u) => (
                <Card title={u.userName}>
                    <GreenReadResults data={getUserData(u.userId)} />
                </Card>
            ))}

        </>
    )
}
export default TeamGreenReading;