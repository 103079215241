import { Card } from "primereact/card";
import { useEffect, useState } from "react";

const PlayerSchedule = ({ player, getJWT, date }) => {

    const [sessions, setSessions] = useState([]);

    async function getSessions() {
        const jwt = await getJWT();
        await fetch(`/api/practiceschedule?userId=${player.userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
        }).then(response => console.log(response.json().then(result => setSessions(result.schedule))))
            .catch(error => console.error(error));


    }

    function drillsList(name, drills) {
        if (drills?.length > 0)
            return (
                <div>
                    <Card subTitle={name} style={{ minWidth: "150px", maxWidth: "250px", }}>
                        {drills?.map((d) => (
                            <div
                                key={d.name}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    whiteSpace: "nowrap", // Prevents text from wrapping
                                    overflow: "hidden", // Ensures text doesn't overflow
                                    textOverflow: "ellipsis", // Adds "..." for overflowed text
                                }}
                            >
                                <span
                                    style={{
                                        textDecoration: d.isCompleted ? "line-through" : "none",
                                        flexShrink: 1, // Allows the name to shrink if necessary
                                        overflow: "hidden", // Ensures overflow is handled gracefully
                                        textOverflow: "ellipsis", // Adds "..." for truncated text
                                    }}
                                >
                                    {d.name}
                                </span>
                                {d.isCompleted && (
                                    <span
                                        style={{
                                            marginLeft: "1rem",
                                            fontWeight: "bold",
                                            flexShrink: 0, // Prevents the score from shrinking
                                        }}
                                    >
                                        Score: {d.score}
                                    </span>
                                )}
                            </div>
                        ))}
                    </Card>
                </div>
            )
    }

    useEffect(() => {
        if (player) {
            getSessions();
        }
    }, [player])
    return (
        <Card title={player.userName} >
            {sessions
                ?.filter((s) => s.date?.split('T')[0] === date.split('T')[0])
                .map((s) => (

                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "1rem",
                            justifyContent: "flex-start",
                        }}
                    >
                        {drillsList("Putting", s.puttingDrills)}
                        {drillsList("Short Game", s.shortGameDrills)}
                        {drillsList("Approach", s.approachDrills)}
                        {drillsList("Off the Tee", s.teeDrills)}
                        {drillsList("Combines", s.combineDrills)}
                    </div>

                ))}

        </Card>
    )
}
export default PlayerSchedule;