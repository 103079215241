import React, { useState } from "react";

import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import { ShotDetails } from './ShotDetails.js';
import { useConstant } from "../../ConstantContext.js";
import { useAuth } from "../appwrite-testing/AuthContext.js";

const EditShot = ({ shot, roundId, index, setIsShotSelected, units, toast }) => {

    const constants = useConstant();
    const { getJWT } = useAuth();
    var [shotDst, setShotDst] = useState(shot.lie === "green" ? shot.shotDst : units === "meters" ? parseInt(shot.shotDst * 0.9144) : shot.shotDst);
    var [club, setClub] = useState(shot.club);
    var [mental, setMental] = useState(shot.mental);
    /**Number of total shot for the round */
    var [shotTotal] = useState(shot.shotTotal);
    /**Number of current shot on the hole */
    var [shotHole] = useState(shot.shotHole);
    /**Number of current hole */
    var [holeNo] = useState(shot.holeNo);
    const [parHole] = useState(shot.parHole);
    /**Current Lir of the shot */
    var [currentLie, setCurrentLie] = useState(shot.lie);
    /**Value of layup check */
    var [layup, setLayup] = useState(shot.layup);
    /**Value of penalty check */
    const [penalty, setPenalty] = useState(shot.penalty);
    const originalPenalty = shot.penalty;
    var [penaltyChange, setPenaltyChange] = useState("no");
    /**Value of direction miss */
    const [missDir, setMissDir] = useState(shot.missDir);
    /**Value of distance miss */
    const [missDst, setMissDst] = useState(shot.missDst);
    var [shortSided, setShortSided] = useState(shot.shortSided);
    const [difficulty, setDifficulty] = useState(shot.difficulty !== null ? shot.difficulty : "medium");
    const [pinDst, setPinDst] = useState(shot.pinDst !== null ? shot.pinDst : null);
    const [pinDir, setPinDir] = useState(shot.pinDir !== null ? shot.pinDir : null);
    const [gfOpp, setGfOpp] = useState(shot.gfOpp);
    const [forcedLayupDistance, setForcedLayupDistance] = useState(shot.forcedLayupDistance ? (units === "meters" ? Math.round(shot.forcedLayupDistance * constants.yardsToMeters) : shot.forcedLayupDistance) : 0)

    var shotJSON = {
        shotTotal: "",
        shotHole: "",
        parHole: "",
        holeNo: "",
        shotDst: "",
        lie: "",
        club: "",
        mental: "",
        missDst: "",
        missDir: "",
        penalty: "",
        layup: "",
        shortSided: ""
    };

    function fillJSON() {
        shotJSON.shotTotal = shotTotal;
        shotJSON.shotHole = shotHole;
        shotJSON.parHole = shot.parHole;
        shotJSON.holeNo = holeNo;
        shotJSON.shotDst = currentLie === "green" ? parseInt(shotDst) : units === "meters" ? parseInt(shotDst * constants.metersToYards) : parseInt(shotDst);
        shotJSON.lie = currentLie.toLowerCase();
        shotJSON.club = club.toLowerCase();
        shotJSON.mental = mental.toLowerCase();
        if (missDst === "No miss") {
            shotJSON.missDst = "";
        } else {
            shotJSON.missDst = missDst.toLowerCase();
        }
        if (missDir === "No miss") {

            shotJSON.missDir = "";
        } else {

            shotJSON.missDir = missDir.toLowerCase();
        }
        shotJSON.penalty = penalty;
        shotJSON.layup = layup;
        shotJSON.shortSided = shortSided;
        shotJSON.difficulty = difficulty;
        shotJSON.pinDst = pinDst;
        shotJSON.pinDir = pinDir;
        shotJSON.gfOpp = gfOpp;
        shotJSON.forcedLayupDistance = (units === "meters" ? Math.round(forcedLayupDistance * constants.metersToYards) : parseInt(forcedLayupDistance));
    }


    async function updateShot() {
        fillJSON();
        const jwt = await getJWT();
        fetch(`/api/Round/edit-shot?roundId=${roundId}&index=${index}&penalty=${penaltyChange}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            }, body: JSON.stringify(shotJSON)
        })
            .then((response) => {
                setIsShotSelected(false);
                toast.current.show({
                    severity: 'success', summary: 'Shot Edited Successfully'
                });
            })
            .then((data) => console.log(data))
            .catch((error) => console.log(error));
    }

    function handlePenalty(event) {
        setPenalty(event.checked)
        if (originalPenalty && !event.checked) {
            setPenaltyChange("deleted");
        } else if (!originalPenalty && event.checked) {
            setPenaltyChange("added");
        } else {
            setPenaltyChange("no");
        }
    }


    const cardFooter = (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "5px" }}>
            <Button style={{ width: "45%" }} label="Edit Shot" onClick={() => { updateShot(); }} />
            <Button style={{ width: "45%" }} label="Go Back" severity="secondary" onClick={() => { setIsShotSelected(false); }} />
        </div>
    )
    return (
        <Card title="Edit Shot" footer={cardFooter}>
            <ShotDetails
                shotDst={shotDst}
                setShotDst={setShotDst}
                club={club}
                setClub={setClub}
                mental={mental}
                setMental={setMental}
                parHole={parHole}
                shotHole={shotHole}
                holeNo={holeNo}
                currentLie={currentLie}
                setCurrentLie={setCurrentLie}
                layup={layup}
                setLayup={setLayup}
                penalty={penalty}
                handlePenalty={handlePenalty}
                missDir={missDir}
                setMissDir={setMissDir}
                missDst={missDst}
                setMissDst={setMissDst}
                difficulty={difficulty}
                setDifficulty={setDifficulty}
                pinDst={pinDst}
                setPinDst={setPinDst}
                pinDir={pinDir}
                setPinDir={setPinDir}
                units={units}
                gfOpp={gfOpp}
                setGfOpp={setGfOpp}
                forcedLayupDistance={forcedLayupDistance}
                setForcedLayupDistance={setForcedLayupDistance}
            />
        </Card>
    )

};

export { EditShot };
